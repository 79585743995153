import Faq from "components/Development/Faq";
import FloatingWp from "components/Development/FloatingWp";
import Footer from "components/Footers/Footer";
import Trusted from "components/Footers/Trusted";
import PopUoForm from "components/Forms/PopUoForm";
import StickyEnquryForm from "components/Forms/StickyEnquryForm";
import IndexNavbar from "components/Navbars/IndexNavbar";
import React from "react";
import Corousel from "components/Corousel/Corousel";
import HeroHeader from "components/Development/HeroHeader";
import MainServices from "components/Development/MainServices";
import { smmWhyChooseUs, ppcServicesData } from "../digitalData";
import WhyChooseUs from "components/Development/WhyChooseUs";
import PrincipalFeatures from "components/Development/PrincipalFeatures";
import {
  principalFeaturesPPC,
  ppcCardServicesData,
  ppcGoogleAdFaq,
} from "./digitalPagesData";
import PPCadBanner from "assets/images/ppc_google_ad_banner.png";
import CardsServices from "components/Development/CardsServices";
import MetaHelmet from "components/Development/MetaHelmet";
import PPCBanner from "assets/images/ppc_banner.png";
import Preloader from "components/Development/Preloader";

const PpcGoogleAd = () => {
  const [isLoading, setisLoading] = React.useState(true);

  React.useEffect(() => {
    setTimeout(() => {
      setisLoading(false);
    }, 2000);
  }, []);
  return (
    <>
      {isLoading === true ? (
        <Preloader />
      ) : (
        <div className="">
          {" "}
          <MetaHelmet
            title="Google Ads Agency | Adwords Lead Generation | PPC Advertising Experts"
            descriptionContent="We Help You To Get Your Business in Front of the Right Audience with PPC Services. 5+ Years Exp. 20+ Industries Served. 100+ Clients Handled. Don't Let Your Ads Budget Go to Waste. Get Our Certified Ads Specialists on your business."
            keywordsContent="google advertising agency, google ads agency near me, google pay per click, pay per click advertising, ppc consultant, ppc marketing services, google ads agency near me"
          />
          <div className="fixed bg-hero-pattern bg-fixed bg-cover bg-center inset-0 -z-20"></div>
          <IndexNavbar />
          <HeroHeader
            title="PPC Google Ad Company"
            description="We specialize in Pay-Per-Click (PPC) advertising on the Google platform, helping businesses maximize their online visibility and drive targeted traffic to their websites. Our team of experienced professionals is dedicated to crafting effective PPC campaigns that deliver results."
            image={PPCBanner}
            smartTagTitle="No.1"
            smartTagDescription="PPC Google Ad Company in India"
          />
          <Corousel />
          <CardsServices
            heading="Google Ads Management That’s Built to Win"
            cardServicesData={ppcCardServicesData}
          />
          <MainServices
            mainServicesData={ppcServicesData}
            apiFeatures={false}
          />
          <WhyChooseUs whyChooseUsData={smmWhyChooseUs} />
          <PrincipalFeatures
            principalFeaturesData={principalFeaturesPPC}
            description="Partner with us to unlock the full potential of social media marketing for your business. Our expertise, creativity, and data-driven approach will help you build a strong online presence, connect with your audience, and achieve your marketing goals. Contact us today to discuss your social media marketing needs and start driving meaningful results."
            image={PPCadBanner}
          />
          <Trusted />
          <Faq faqs={ppcGoogleAdFaq} />
          <StickyEnquryForm />
          <FloatingWp />
          <PopUoForm />
          <Footer />
        </div>
      )}
    </>
  );
};

export default PpcGoogleAd;
