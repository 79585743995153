import React from "react";
import { Link } from "react-router-dom";
import ReactBeforeSliderComponent from "react-before-after-slider-component";
import "react-before-after-slider-component/dist/build.css";
import "./AfterBefore.css";

const BeforeAfter = () => {
  const BeforeSlide =
    "https://res.cloudinary.com/whiztechnology/image/upload/v1691043822/ayiwveekilelzjujqfpx.png";

  const FIRST_IMAGE = {
    imageUrl: BeforeSlide,
  };
  const SECOND_IMAGE = {
    imageUrl:
      "https://res.cloudinary.com/whiztechnology/image/upload/v1690996384/Uploads/hotel-furniture_dufrk6.png",
  };
  const THIRD_IMAGE = {
    imageUrl: BeforeSlide,
  };
  const FOURTH_IMAGE = {
    imageUrl:
      "https://res.cloudinary.com/whiztechnology/image/upload/v1690996403/Uploads/fourth_slide_m64zmq.png",
  };
  const FIFTH_IMAGE = {
    imageUrl: BeforeSlide,
  };
  const SIXTH_IMAGE = {
    imageUrl:
      "https://res.cloudinary.com/whiztechnology/image/upload/v1690996425/Uploads/sixth_slide_siya74.png",
  };

  const delimiterIconStyles = {
    width: "25px",
    height: "25px",
    backgroundSize: "cover",
    borderRadius: "none",
    backgroundImage: "url(src/assets/logo/left-and-right.png)",
    // backgroundColor: "#0088ff",
  };
  return (
    <div className="w-full bg-[#673de6] py-10 mt-14">
      <div className="max-w-screen-xl mx-auto">
        <div className="relative w-full grid gap-12 lg:grid-cols-2 mb-4">
          <div className="overflow-hidden flex justify-center">
            <img
              src={
                "https://res.cloudinary.com/whiztechnology/image/upload/v1690996654/Uploads/host_image_cv6e9z.png"
              }
              alt="Case Study"
            />
          </div>
          <div className="w-full flex justify-center items-center px-5 xl:px-0">
            <div className="sm:max-w-[480px]">
              <h2 className="text-3xl text-white font-bold ">Case Study</h2>
              <p className="mt-6 text-white/80 leading-relaxed">
                Seamless Integration: API integration services facilitate the
                seamless exchange of data and functionality between different
                systems. They enable businesses to connect their internal
                applications, databases, or platforms with external APIs
                provided by third-party services, vendors, or partners.
              </p>
              <Link
                className="group relative inline-flex border border-white focus:outline-none mt-6"
                to={"/technology-certification"}
              >
                <span className="w-full inline-flex items-center justify-center self-stretch px-4 py-2 text-sm text-white text-center font-bold uppercase bg-transparent ring-1 ring-white ring-offset-1 transform transition-transform group-hover:-translate-y-1 group-hover:-translate-x-1 group-focus:-translate-y-1 group-focus:-translate-x-1">
                  See More Case Study
                </span>
              </Link>
            </div>
          </div>
        </div>
        <div className="flex flex-col lg:flex-row space-x-0 lg:space-x-8 mb-4 relative">
          {" "}
          <div className="relative p-2 shadow-md  backdrop-blur-sm bg-transparent transform transition-transform hover:-translate-y-1 hover:-translate-x-1 focus:-translate-y-1 focus:-translate-x-1">
            {" "}
            <ReactBeforeSliderComponent
              firstImage={FIRST_IMAGE}
              secondImage={SECOND_IMAGE}
              delimiterColor="#0088ff"
              delimiterIconStyles={delimiterIconStyles}
              className="rounded-lg"
            />
          </div>
          <div className="relative p-2 shadow-md  backdrop-blur-sm bg-transparent transform transition-transform hover:-translate-y-1 hover:-translate-x-1 focus:-translate-y-1 focus:-translate-x-1">
            {" "}
            <ReactBeforeSliderComponent
              firstImage={THIRD_IMAGE}
              secondImage={FOURTH_IMAGE}
              delimiterColor="#0088ff"
              delimiterIconStyles={delimiterIconStyles}
              className="rounded-lg"
            />
          </div>
          <div className="relative p-2 shadow-md  backdrop-blur-sm bg-transparent transform transition-transform hover:-translate-y-1 hover:-translate-x-1 focus:-translate-y-1 focus:-translate-x-1">
            {" "}
            <ReactBeforeSliderComponent
              firstImage={FIFTH_IMAGE}
              secondImage={SIXTH_IMAGE}
              delimiterColor="#0088ff"
              delimiterIconStyles={delimiterIconStyles}
              className="rounded-lg"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BeforeAfter;
