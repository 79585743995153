import React from "react";
import { Link } from "react-router-dom";
import { Player } from "@lottiefiles/react-lottie-player";
import searchingLottie from "assets/lotties/96916-searching.json";
import Video_Prouduction from "assets/images/video_production.png";
import Film_Makinng from "assets/images/film_making.png";
import Product_production from "assets/images/product_video_production.png";

export default function MoreProduction() {
  const ProductionServices = [
    {
      id: 1,
      title: "Video Production",
      icon: Video_Prouduction,
      content:
        "The Visual House is a video production powerhouse, your business can count on. We are the leading video film production company. We help make beautiful and unique videos curated just for your needs which can assist market your product and brand.",
      listData: [
        {
          id: 1,
          data: "Best Video Production Services for Your Business",
        },
        {
          id: 2,
          data: "The Visual House is a digital production company led by ideas of quality, driven by technology, working round the clock to deliver the best.",
        },
        {
          id: 3,
          data: "Be it, Corporate Videos, Documentary Films, Animation, Explainer Videos, Social Media, Short Films, TV Commercials, Social Media, Digital Marketing and much more!",
        },
        {
          id: 4,
          data: "Our goal with every project is to design processes and workflows to deliver services of the highest quality within the shortest time frame at reasonable prices.",
        },
      ],
      services: "Video Producton Services",
    },
    {
      id: 2,
      title: "Best Documentary Film Makers",
      icon: Film_Makinng,
      content:
        "In fact, Documentary films include the collection of nonfictional motion pictures that are intended to present the reality aspect such as real events, real nature, and real peoples with the main purpose of maintaining the historical records for the upcoming generation. There are several benefits of Documentaries Films that’s why these short films have become most popular on internet or YouTube and other social media sites.",
      listData: [
        {
          id: 1,
          data: "Benefits of Documentary Film",
        },
        {
          id: 2,
          data: "It is also a great way for parents make their children learn the good habits through the top Documentaries films. After watching some interesting films or stories about wildlife, it is easier for kids to get nudged into reading in detail.",
        },
        {
          id: 3,
          data: "The recorded fact in the documentary films is invincible evidence. Therefore documentary films have a greater power to move minds more than the written or the speeches. This is the reason; we can see a voice against the social stigmas like child labor, adult, and children trafficking and forced prostitution more than earlier.",
        },
      ],
      services: "Documentry Services",
    },
    {
      id: 3,
      title: "Product Video Production",
      icon: Product_production,
      content:
        "For any business, product video is the most important tool to inform prospective customers about any product. Creatively made product video not only amplifies interest of the audience, but it also adds to brand value and increases brand recognition. The Visual house is the best product video production company that offers all kinds of video related services ranging from TV and digital commercials to product demo video production.",
      listData: [
        {
          id: 1,
          data: "Benefits of Product Video Production",
        },
        {
          id: 2,
          data: "For ecommerce, product video production has become a norm in recent years. Following the increased traffic on social media and other digital platforms, it is not just important, but rather necessary that the potential customers are informed about a product through product videos as it has more retention power.",
        },
        {
          id: 3,
          data: "We Provide best product video production companies which provides all types of services from pre to post-production, including product video shoot in Delhi and other parts of the country.",
        },
        {
          id: 4,
          data: "We have more than a decade of experience in video production, which makes us a favourable destination for several recognized Indian and international brands.",
        },
      ],
      services: "Product Video Production",
    },
  ];
  return (
    <>
      <section className="bg-transparent">
        <div className="max-w-screen-xl rounded-md px-4 py-8 mx-auto space-y-12 lg:space-y-20 lg:py-24 lg:px-6">
          {/* <!-- Row --> */}
          {ProductionServices.map((item) => (
            // items-center gap-8 lg:grid lg:grid-cols-2 xl:gap-16
            <div className="flex flex-col sm:flex-col-reverse  lg:flex-row lg:odd:flex-row-reverse justify-center items-center px-6">
              <div className="text-slate-600 sm:text-lg dark:text-gray-400">
                <h2 className="mb-4 text-xl md:text-3xl font-extrabold tracking-tight text-slate-600 dark:text-white">
                  {item.title}
                </h2>
                <h3 className="mb-8 text-justify text-base text-slate-400 leading-normal font-light">
                  {item.content}
                </h3>

                <ul className="pt-2 space-y-5 border-t border-gray-200 my-7 dark:border-gray-700">
                  {item.listData.map((item) => (
                    <li className="flex ">
                      <Link
                        to={"/appdevelopement"}
                        className=" cursor-pointer flex space-x-3 hover:underline hover:text-purple-500"
                      >
                        {svgIcon()}
                        <span className="text-base font-medium leading-tight text-slate-400 dark:text-white">
                          {item.data}
                        </span>
                      </Link>
                    </li>
                  ))}
                </ul>
                <div className="mt-4">
                  <div className="flex item-center  ">
                    <div className="flex mx-auto w-full">
                      <Player
                        src={searchingLottie}
                        className="player"
                        loop
                        autoplay
                        style={{ height: "50px", width: "50px" }}
                      />
                      <div className="ml-2  item-center">
                        <div className="flex-col text-sm italic font-poppins font-medium">
                          {item.services}
                        </div>
                        <div className="flex-col text-xs text-blue">
                          <button className="px-2 py-1 bg-[#44619d] text-white font-medium text-xs leading-tight rounded-full shadow-md hover:bg-blue-500 hover:shadow-lg focus:bg-blue-500 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-600 active:shadow-lg transition duration-150 ease-in-out font-poppins cursor-pointer">
                            <a href="/">Learn More</a>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className=" max-w-md py-2">
                {" "}
                <img
                  className="w-full verticalAnimate mb-4 rounded-lg lg:mb-0 lg:flex"
                  src={item.icon}
                  alt="eCoomerce App"
                />
              </div>
            </div>
          ))}
        </div>
      </section>
    </>
  );
}

const svgIcon = () => {
  return (
    <>
      <svg
        className="flex-shrink-0 w-5 h-5 text-purple-500 dark:text-purple-400"
        fill="currentColor"
        viewBox="0 0 20 20"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
          clipRule="evenodd"
        ></path>
      </svg>
    </>
  );
};
