import React from "react";
import { Player } from "@lottiefiles/react-lottie-player";
import { Link } from "react-router-dom";

export default function ExpServices() {
  const expServices = [
    {
      id: 1,
      title: "Website Development",
      image:
        "https://res.cloudinary.com/whiztechnology/raw/upload/v1690996793/Uploads/laptop_eru7bq.json",
      to: "/website-development-company",
    },
    {
      id: 2,
      title: "Mobile App Development",
      image:
        "https://res.cloudinary.com/whiztechnology/raw/upload/v1690996854/Uploads/zkkzi4yfmj4amomy2wdv.json",
      to: "/mobile-app-development-company",
    },
    {
      id: 3,
      title: "Digital Marketing",
      image:
        "https://res.cloudinary.com/whiztechnology/raw/upload/v1690996854/Uploads/k4dblcfplnnmjlghrdet.json",
      to: "/digital-marketing-company",
    },
    {
      id: 4,
      title: "Website Designing",
      image:
        "https://res.cloudinary.com/whiztechnology/raw/upload/v1690996854/Uploads/zmoeshtzxedb1ef4kkhi.json",
      to: "/website-designing-company",
    },
  ];

  return (
    <>
      <section className="py-2 mt-12 max-w-screen-xl px-4 mx-auto lg:py-16 lg:px-6">
        <div className="grid grid-cols-1 gap-y-12 sm:grid-cols-2 sm:gap-x-6 lg:grid-cols-4 lg:gap-x-8 place-content-center place-items-center">
          {expServices.map((item) => (
            <div
              className="flex flex-col items-center justify-center"
              key={item.id}
            >
              <Link to={item.to}>
                <div className=" max-w-xs h-36 flex items-center justify-center overflow-hidden">
                  <div className="w-36">
                    <Player src={item.image} className="player" loop autoplay />
                  </div>
                </div>
                <h3 className="z-3 hover:text-indigo-500 hover:underline mt-2 text-md font-medium text-slate-700 text-center font-poppins">
                  {item.title}
                </h3>
              </Link>
            </div>
          ))}
        </div>
      </section>
    </>
  );
}
