import Faq from "components/Development/Faq";
import FloatingWp from "components/Development/FloatingWp";
import Footer from "components/Footers/Footer";
import Trusted from "components/Footers/Trusted";
import PopUoForm from "components/Forms/PopUoForm";
import StickyEnquryForm from "components/Forms/StickyEnquryForm";
import IndexNavbar from "components/Navbars/IndexNavbar";
import React from "react";
import Corousel from "components/Corousel/Corousel";
import HeroHeader from "components/Development/HeroHeader";
import SmoMarketingBanner from "assets/images/smo_marketing_banner.png";
import MainServices from "components/Development/MainServices";
import { smmFaq, smmServicesData, smmWhyChooseUs } from "../digitalData";
import WhyChooseUs from "components/Development/WhyChooseUs";
import PrincipalFeatures from "components/Development/PrincipalFeatures";
import { principalFeaturesMarketingSmm } from "./digitalPagesData";
import SocialMediaTree from "assets/images/social_media_tree.png";
import MetaHelmet from "components/Development/MetaHelmet";
import Preloader from "components/Development/Preloader";

const SmoMarketing = () => {
  const [isLoading, setisLoading] = React.useState(true);

  React.useEffect(() => {
    setTimeout(() => {
      setisLoading(false);
    }, 2000);
  }, []);
  return (
    <>
      {isLoading === true ? (
        <Preloader />
      ) : (
        <div className="">
          <MetaHelmet
            title="Social Media Marketing Agency |Social Media Services Company - 20+ years of Expertise"
            descriptionContent="Digital Markitors is the most effective social media marketing agency in India. We Offer Affordable Facebook Promotions and Marketing Packages in India."
            keywordsContent="social media marketing agency, social media advertising, social media promotion, social media marketing near me, facebook social media marketing,social media marketing expert"
          />
          <div className="fixed bg-hero-pattern bg-fixed bg-cover bg-center inset-0 -z-20"></div>
          <IndexNavbar />
          <HeroHeader
            title="Social Media Marketing (SMM) Company"
            description="We specialize in creating impactful and results-driven social media strategies to help businesses build their brand, engage with their audience, and drive growth. Our experienced team of social media experts is dedicated to delivering compelling content and maximizing the potential of various social media platforms."
            image={SmoMarketingBanner}
            smartTagTitle="No.1"
            smartTagDescription="Social Media Marketing Company in India"
          />
          <Corousel />
          <MainServices mainServicesData={smmServicesData} />
          <WhyChooseUs whyChooseUsData={smmWhyChooseUs} />
          <PrincipalFeatures
            principalFeaturesData={principalFeaturesMarketingSmm}
            image={SocialMediaTree}
          />
          <Trusted />
          <Faq faqs={smmFaq} />
          <StickyEnquryForm />
          <FloatingWp />
          <PopUoForm />
          <Footer />
        </div>
      )}
    </>
  );
};

export default SmoMarketing;
