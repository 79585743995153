import React from "react";
import { Link } from "react-router-dom";

const WhyChooseUs = (props) => {
  return (
    <section className="max-w-screen-xl mx-auto">
      <div className=" px-4 py-8 mx-auto space-y-6 lg:py-10 lg:px-6">
        {props.whyChooseUsData.map(({ id, title, features, icon }) => (
          <div className="items-center gap-8 lg:grid lg:grid-cols-2 xl:gap-16">
            <div
              className="px-4 py-8 mx-auto space-y-6 lg:py-10 lg:px-6"
              key={id}
            >
              <div className="text-gray-500 sm:text-lg dark:text-gray-400">
                <h2 className="mb-4 text-base md:text-lg font-semibold tracking-tight text-gray-900 dark:text-white">
                  {title}
                </h2>
                <hr className="w-full mt-1 h-1 mb-4 bg-indigo-200" />
                {features.map(({ points }) => (
                  <p className="mb-8 font-normal text-xs md:text-base text-justify justify-start text-gray-800 lg:mb-8 leading-normal">
                    {points}
                  </p>
                ))}
                <Link to={"/contact-us"}>
                  <p className="mt-5 text-white text-center lg:text-xl px-2 py-3 rounded-md border cursor-pointer font-semibold bg-indigo-500 hover:bg-indigo-700">
                    Get a Qoute ?
                  </p>
                </Link>
              </div>
            </div>
            <div className="w-full  mb-4 rounded-lg lg:mb-0 lg:flex">
              <img className="verticalAnimate" src={icon} alt="" />
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default WhyChooseUs;
