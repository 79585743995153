import React, { useState, useEffect } from "react";
import HeroHeader from "components/Development/HeroHeader";
import MetaHelmet from "components/Development/MetaHelmet";
import IndexNavbar from "components/Navbars/IndexNavbar";
import HeroBanner from "assets/images/uiux_designing.png";
import Trusted from "components/Footers/Trusted";
import Footer from "components/Footers/Footer";
import StickyEnquryForm from "components/Forms/StickyEnquryForm";
import PopUoForm from "components/Forms/PopUoForm";
import FloatingWp from "components/Development/FloatingWp";
import Faq from "components/Development/Faq";
import { uiuxfaq } from "../webDesigningData";
import SmartCards from "components/Development/SmartCards";
import Preloader from "components/Development/Preloader";

const UIUXDesigning = () => {
  const [isLoading, setisLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setisLoading(false);
    }, 1000);
  }, []);
  return (
    <>
      {isLoading === true ? (
        <Preloader />
      ) : (
        <div className="">
          <MetaHelmet title="No.1 UI/UX Design Company in India | UI/UX Designing Company" />
          <div className="fixed bg-hero-pattern bg-fixed bg-cover bg-center inset-0 -z-20"></div>
          <IndexNavbar />
          <HeroHeader
            title="UI/UX Designing Company"
            description="We are a team of professional content writers dedicated to helping businesses create engaging, informative, and compelling content that connects with their target audience. Whether you need website content, blog articles, product descriptions, or any other form of written content, we've got you covered. "
            image={HeroBanner}
            smartTagTitle="No.1"
            smartTagDescription="Content Writing Company in India"
          />
          <SmartCards />
          <Trusted />
          <Faq faqs={uiuxfaq} />
          <Footer />
          <StickyEnquryForm />
          <PopUoForm />
          <FloatingWp />
        </div>
      )}
    </>
  );
};

export default UIUXDesigning;
