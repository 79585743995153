import Footer from "components/Footers/Footer";
import IndexNavbar from "components/Navbars/IndexNavbar";
import { useCart } from "components/utils/Products_api";
import React, { useState } from "react";
import { handlePageChange } from "components/utils/useMyFunctions.js";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { doc, setDoc } from "firebase/firestore";
import { db } from "config/firebase";

const Checkout = () => {
  const { cartItems, removeFromCart } = useCart();

  const [name, setName] = useState("");
  const [last_name, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [street_address, setStreetAddress] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");
  const [postal_code, setPostalCode] = useState("");
  const [read, setIsRead] = useState(false);

  const clearCartItems = () => {
    removeFromCart(null); // Call the removeFromCart function with null to clear all items
    localStorage.removeItem("cartItems");
  };

  const handleRemoveFromCart = (productId) => {
    removeFromCart(productId);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const itemNames = cartItems.map((item) => item.title);
    const product_name = itemNames.join(", ");

    const currentDate = new Date();
    // const lastUpdated = new Date();
    const options = { day: "2-digit", month: "short", year: "numeric" };
    const enquiryDate = currentDate.toLocaleDateString("en-US", options);
    // Create an object with the form data
    const customerFullAddress = `${street_address}, ${city}, ${country}, ${postal_code}`;
    const fullName = `${name} ${last_name}`;
    const formData = {
      id: Date.now(),
      fullName,
      email,
      phone,
      customerFullAddress,
      product_name,
      enquiryDate,
      lastUpdated: enquiryDate,
      status: "Add Status",
      followup: "Pending",
      read,
    };
    const docId = Date.now();

    const collectionsRef = doc(db, "smartenquiry", `${docId}`);
    try {
      setDoc(collectionsRef, formData)
        .then(() => {
          console.log("Document created successfully!");
        })
        .catch((error) => {
          console.error("Error creating document: ", error);
        });
      setName("");
      setLastName("");
      setEmail("");
      setPhone("");
      setStreetAddress("");
      setCity("");
      setCountry("");
      setPostalCode("");
      clearCartItems();
      notify();
      localStorage.removeItem("cartItems");
      handlePageChange();
      console.log("Document written with ID: ", collectionsRef.id);
    } catch (error) {
      console.error("Error adding document: ", error);
    }
  };

  const notify = () =>
    toast.success(`Email Sent Successfully`, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });

  return (
    <>
      <IndexNavbar />
      <section className="bg-gray-50">
        <div className="mx-auto max-w-screen-xl px-3 sm:px-10 py-5 md:py-20 bg-white">
          <form onSubmit={handleSubmit} encType="multipart/form-data">
            <div className="py-10 lg:py-12 px-0 2xl:max-w-screen-2xl w-full xl:max-w-screen-xl flex flex-col md:flex-row lg:flex-row">
              <div className="md:w-full lg:w-3/5 flex h-full flex-col order-2 sm:order-1 lg:order-1">
                <div className="mt-5 md:mt-0 md:col-span-2">
                  <div className="form-group">
                    <h2 className="font-semibold font-serif text-base text-gray-700 pb-3">
                      01. Personal Details
                    </h2>
                    <div className="grid grid-cols-6 gap-6">
                      <div className="col-span-6 sm:col-span-3">
                        <label className="block text-gray-500 font-medium text-sm leading-none mb-2">
                          First Name
                        </label>
                        <div className="relative">
                          <input
                            name="firstName"
                            type="text"
                            placeholder="John"
                            className="py-2 px-4 md:px-5 w-full appearance-none border text-sm opacity-75 text-input rounded-md placeholder-body min-h-12 transition duration-200 focus:ring-0 ease-in-out bg-white border-gray-200 focus:outline-none focus:border-emerald-500 h-11 md:h-12"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            required
                          />
                        </div>
                      </div>
                      <div className="col-span-6 sm:col-span-3">
                        <label className="block text-gray-500 font-medium text-sm leading-none mb-2">
                          Last Name
                        </label>
                        <div className="relative">
                          <input
                            name="lastName"
                            type="text"
                            placeholder="Doe"
                            className="py-2 px-4 md:px-5 w-full appearance-none border text-sm opacity-75 text-input rounded-md placeholder-body min-h-12 transition duration-200 focus:ring-0 ease-in-out bg-white border-gray-200 focus:outline-none focus:border-emerald-500 h-11 md:h-12"
                            value={last_name}
                            onChange={(e) => setLastName(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-span-6 sm:col-span-3">
                        <label className="block text-gray-500 font-medium text-sm leading-none mb-2">
                          Email address
                        </label>
                        <div className="relative">
                          <input
                            name="email"
                            type="email"
                            placeholder="youremail@gmail.com"
                            className="py-2 px-4 md:px-5 w-full appearance-none border text-sm opacity-75 text-input rounded-md placeholder-body min-h-12 transition duration-200 focus:ring-0 ease-in-out bg-white border-gray-200 focus:outline-none focus:border-emerald-500 h-11 md:h-12"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                          />
                        </div>
                      </div>
                      <div className="col-span-6 sm:col-span-3">
                        <label className="block text-gray-500 font-medium text-sm leading-none mb-2">
                          Contact Number
                        </label>
                        <div className="relative">
                          <input
                            name="contact"
                            type="tel"
                            placeholder="+062-6532956"
                            className="py-2 px-4 md:px-5 w-full appearance-none border text-sm opacity-75 text-input rounded-md placeholder-body min-h-12 transition duration-200 focus:ring-0 ease-in-out bg-white border-gray-200 focus:outline-none focus:border-emerald-500 h-11 md:h-12"
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                            required
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="form-group mt-12">
                    <h2 className="font-semibold font-serif text-base text-gray-700 pb-3">
                      02. Shipping Details
                    </h2>
                    <div className="grid grid-cols-6 gap-6 mb-8">
                      <div className="col-span-6">
                        <label className="block text-gray-500 font-medium text-sm leading-none mb-2">
                          Street address
                        </label>
                        <div className="relative">
                          <input
                            name="address"
                            type="text"
                            placeholder="123 Boulevard Rd, Beverley Hills"
                            className="py-2 px-4 md:px-5 w-full appearance-none border text-sm opacity-75 text-input rounded-md placeholder-body min-h-12 transition duration-200 focus:ring-0 ease-in-out bg-white border-gray-200 focus:outline-none focus:border-emerald-500 h-11 md:h-12"
                            value={street_address}
                            onChange={(e) => setStreetAddress(e.target.value)}
                            required
                          />
                        </div>
                      </div>
                      <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                        <label className="block text-gray-500 font-medium text-sm leading-none mb-2">
                          City
                        </label>
                        <div className="relative">
                          <input
                            name="city"
                            type="text"
                            placeholder="Los Angeles"
                            className="py-2 px-4 md:px-5 w-full appearance-none border text-sm opacity-75 text-input rounded-md placeholder-body min-h-12 transition duration-200 focus:ring-0 ease-in-out bg-white border-gray-200 focus:outline-none focus:border-emerald-500 h-11 md:h-12"
                            value={city}
                            onChange={(e) => setCity(e.target.value)}
                            required
                          />
                        </div>
                      </div>
                      <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                        <label className="block text-gray-500 font-medium text-sm leading-none mb-2">
                          Country
                        </label>
                        <div className="relative">
                          <input
                            name="country"
                            type="text"
                            placeholder="United States"
                            className="py-2 px-4 md:px-5 w-full appearance-none border text-sm opacity-75 text-input rounded-md placeholder-body min-h-12 transition duration-200 focus:ring-0 ease-in-out bg-white border-gray-200 focus:outline-none focus:border-emerald-500 h-11 md:h-12"
                            value={country}
                            onChange={(e) => setCountry(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                        <label className="block text-gray-500 font-medium text-sm leading-none mb-2">
                          ZIP / Postal
                        </label>
                        <div className="relative">
                          <input
                            name="zipCode"
                            type="text"
                            placeholder="2345"
                            className="py-2 px-4 md:px-5 w-full appearance-none border text-sm opacity-75 text-input rounded-md placeholder-body min-h-12 transition duration-200 focus:ring-0 ease-in-out bg-white border-gray-200 focus:outline-none focus:border-emerald-500 h-11 md:h-12"
                            value={postal_code}
                            onChange={(e) => setPostalCode(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                    <label className="block text-gray-500 font-medium text-sm leading-none mb-2">
                      Shipping Cost
                    </label>
                    <div className="grid grid-cols-6 gap-6">
                      <div className="col-span-6 sm:col-span-3">
                        <div>
                          <div className="p-3 card border border-gray-200 bg-white rounded-md">
                            <label className="cursor-pointer label">
                              <div className="flex items-center justify-between">
                                <div className="flex items-center">
                                  <span className="text-2xl mr-3 text-gray-400">
                                    <svg
                                      stroke="currentColor"
                                      fill="none"
                                      stroke-width="2"
                                      viewBox="0 0 24 24"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      height="1em"
                                      width="1em"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <rect
                                        x="1"
                                        y="3"
                                        width="15"
                                        height="13"
                                      ></rect>
                                      <polygon points="16 8 20 8 23 11 23 16 16 16 16 8"></polygon>
                                      <circle
                                        cx="5.5"
                                        cy="18.5"
                                        r="2.5"
                                      ></circle>
                                      <circle
                                        cx="18.5"
                                        cy="18.5"
                                        r="2.5"
                                      ></circle>
                                    </svg>
                                  </span>
                                  <div>
                                    <h6 className="font-serif font-medium text-sm text-gray-600">
                                      FedEx
                                    </h6>
                                    <p className="text-xs text-gray-500 font-medium">
                                      Delivery: 5-7 Days{" "}
                                      <span className="font-medium text-gray-600">
                                        Cost :According to Package Weight
                                      </span>
                                    </p>
                                  </div>
                                </div>
                                <input
                                  name="shippingOption"
                                  type="radio"
                                  className="form-radio outline-none focus:ring-0 text-emerald-500"
                                  value="FedEx"
                                />
                              </div>
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="col-span-6 sm:col-span-3">
                        <div>
                          <div className="p-3 card border border-gray-200 bg-white rounded-md">
                            <label className="cursor-pointer label">
                              <div className="flex items-center justify-between">
                                <div className="flex items-center">
                                  <span className="text-2xl mr-3 text-gray-400">
                                    <svg
                                      stroke="currentColor"
                                      fill="none"
                                      stroke-width="2"
                                      viewBox="0 0 24 24"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      height="1em"
                                      width="1em"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <rect
                                        x="1"
                                        y="3"
                                        width="15"
                                        height="13"
                                      ></rect>
                                      <polygon points="16 8 20 8 23 11 23 16 16 16 16 8"></polygon>
                                      <circle
                                        cx="5.5"
                                        cy="18.5"
                                        r="2.5"
                                      ></circle>
                                      <circle
                                        cx="18.5"
                                        cy="18.5"
                                        r="2.5"
                                      ></circle>
                                    </svg>
                                  </span>
                                  <div>
                                    <h6 className="font-serif font-medium text-sm text-gray-600">
                                      UPS
                                    </h6>
                                    <p className="text-xs text-gray-500 font-medium">
                                      Delivery: 7-10 Days{" "}
                                      <span className="font-medium text-gray-600">
                                        Cost :According to Package Weight
                                      </span>
                                    </p>
                                  </div>
                                </div>
                                <input
                                  name="shippingOption"
                                  type="radio"
                                  className="form-radio outline-none focus:ring-0 text-emerald-500"
                                  value="UPS"
                                />
                              </div>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="grid grid-cols-6 gap-4 lg:gap-6 mt-10">
                    <div className="col-span-6 sm:col-span-3">
                      <a
                        className="bg-indigo-50 border border-indigo-100 rounded py-3 text-center text-sm font-medium text-gray-700 hover:text-gray-800 hover:border-gray-300 transition-all flex justify-center font-serif w-full"
                        href="/"
                      >
                        <span className="text-xl mr-2">
                          <svg
                            stroke="currentColor"
                            fill="currentColor"
                            stroke-width="0"
                            viewBox="0 0 512 512"
                            height="1em"
                            width="1em"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fill="none"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="32"
                              d="M112 160l-64 64 64 64"
                            ></path>
                            <path
                              fill="none"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="32"
                              d="M64 224h294c58.76 0 106 49.33 106 108v20"
                            ></path>
                          </svg>
                        </span>
                        Continue Shopping
                      </a>
                    </div>
                    <div className="col-span-6 sm:col-span-3">
                      <button
                        type="submit"
                        disabled=""
                        className="bg-[#44619d] hover:bg-emerald-600 border border-[#44619d] transition-all rounded py-3 text-center text-sm font-serif font-medium text-white flex justify-center w-full"
                      >
                        <span className="flex justify-center text-center">
                          Confirm Order{" "}
                          <span className="text-xl ml-2">
                            {" "}
                            <svg
                              stroke="currentColor"
                              fill="currentColor"
                              stroke-width="0"
                              viewBox="0 0 512 512"
                              height="1em"
                              width="1em"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fill="none"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="48"
                                d="M268 112l144 144-144 144m124-144H100"
                              ></path>
                            </svg>
                          </span>
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="md:w-full lg:w-2/5 lg:ml-10 xl:ml-14 md:ml-6 flex flex-col h-full md:sticky lg:sticky top-28 md:order-2 lg:order-2">
                <div className="border p-5 lg:px-8 lg:py-8 rounded-lg bg-white order-1 sm:order-2">
                  <h2 className="font-semibold font-serif text-lg pb-4">
                    Order Summary
                  </h2>
                  <div className="overflow-y-scroll flex-grow scrollbar-hide w-full max-h-64 bg-gray-50 block">
                    {cartItems.length > 0 ? (
                      cartItems.map((item) => (
                        <div className="group w-full h-auto flex justify-start items-center bg-white py-3 px-4 border-b hover:bg-gray-50 transition-all border-gray-100 relative last:border-b-0">
                          <div
                            className="relative flex rounded-lg border border-gray-100 shadow-sm overflow-hidden flex-shrink-0 cursor-pointer mr-4"
                            key={item.id}
                          >
                            <img
                              src={item.imgSrc}
                              alt={item.title}
                              className=" w-24 h-24"
                            />
                          </div>
                          <div className="flex flex-col w-full overflow-hidden">
                            <div
                              className="truncate text-sm font-medium text-gray-700 text-heading line-clamp-1"
                              href="/product/undefined"
                            >
                              {item.title}
                            </div>
                            <span className="text-xs text-gray-400 mb-1">
                              Product Quanitity
                            </span>
                            <div className="flex items-center justify-between">
                              <div className="font-bold text-sm md:text-base text-heading leading-5">
                                <span>Quantity</span>
                              </div>
                              <div className="h-8 w-22 md:w-24 lg:w-24 flex flex-wrap items-center justify-evenly p-1 border border-gray-100 bg-white text-gray-600 rounded-md">
                                <button>
                                  <span className="text-dark text-base">
                                    <svg
                                      stroke="currentColor"
                                      fill="none"
                                      stroke-width="2"
                                      viewBox="0 0 24 24"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      height="1em"
                                      width="1em"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <line
                                        x1="5"
                                        y1="12"
                                        x2="19"
                                        y2="12"
                                      ></line>
                                    </svg>
                                  </span>
                                </button>
                                <p className="text-sm font-semibold text-dark px-1">
                                  1
                                </p>
                                <button>
                                  <span className="text-dark text-base">
                                    <svg
                                      stroke="currentColor"
                                      fill="none"
                                      stroke-width="2"
                                      viewBox="0 0 24 24"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      height="1em"
                                      width="1em"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <line
                                        x1="12"
                                        y1="5"
                                        x2="12"
                                        y2="19"
                                      ></line>
                                      <line
                                        x1="5"
                                        y1="12"
                                        x2="19"
                                        y2="12"
                                      ></line>
                                    </svg>
                                  </span>
                                </button>
                              </div>
                              <button
                                className="hover:text-red-600 text-red-400 text-lg cursor-pointer"
                                onClick={() => handleRemoveFromCart(item.id)}
                              >
                                <svg
                                  stroke="currentColor"
                                  fill="none"
                                  stroke-width="2"
                                  viewBox="0 0 24 24"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  height="1em"
                                  width="1em"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <polyline points="3 6 5 6 21 6"></polyline>
                                  <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                                  <line x1="10" y1="11" x2="10" y2="17"></line>
                                  <line x1="14" y1="11" x2="14" y2="17"></line>
                                </svg>
                              </button>
                            </div>
                          </div>
                        </div>
                      ))
                    ) : (
                      <div className="text-center py-10">
                        <span className="flex justify-center my-auto text-gray-500 font-semibold text-4xl">
                          <svg
                            stroke="currentColor"
                            fill="currentColor"
                            stroke-width="0"
                            viewBox="0 0 512 512"
                            height="1em"
                            width="1em"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M454.65 169.4A31.82 31.82 0 00432 160h-64v-16a112 112 0 00-224 0v16H80a32 32 0 00-32 32v216c0 39 33 72 72 72h272a72.22 72.22 0 0050.48-20.55 69.48 69.48 0 0021.52-50.2V192a31.75 31.75 0 00-9.35-22.6zM176 144a80 80 0 01160 0v16H176zm192 96a112 112 0 01-224 0v-16a16 16 0 0132 0v16a80 80 0 00160 0v-16a16 16 0 0132 0z"></path>
                          </svg>
                        </span>
                        <h2 className="font-medium font-serif text-sm pt-2 text-gray-600">
                          No Item Added Yet!
                        </h2>
                      </div>
                    )}
                  </div>
                  <div className="flex items-center mt-4 py-4 lg:py-4 text-sm w-full font-semibold text-heading last:border-b-0 last:text-base last:pb-0">
                    <form className="w-full">
                      <div className="flex flex-col sm:flex-row items-start justify-end">
                        <input
                          type="text"
                          placeholder="Input your coupon code"
                          className="form-input py-2 px-3 md:px-4 w-full appearance-none transition ease-in-out border text-input text-sm rounded-md h-12 duration-200 bg-white border-gray-200 focus:ring-0 focus:outline-none focus:border-emerald-500 placeholder-gray-500 placeholder-opacity-75"
                        />
                        <button className="md:text-sm leading-4 inline-flex items-center cursor-pointer transition ease-in-out duration-300 font-semibold text-center justify-center border border-gray-200 rounded-md placeholder-white focus-visible:outline-none focus:outline-none px-5 md:px-6 lg:px-8 py-3 md:py-3.5 lg:py-3 mt-3 sm:mt-0 sm:ml-3 md:mt-0 md:ml-3 lg:mt-0 lg:ml-3 hover:text-white hover:bg-emerald-500 h-12 text-sm lg:text-base w-full sm:w-auto">
                          Apply
                        </button>
                      </div>
                    </form>
                  </div>
                  <div className="flex items-center py-2 text-sm w-full font-semibold text-gray-500 last:border-b-0 last:text-base last:pb-0">
                    Subtotal
                    <span className="ml-auto flex-shrink-0 text-gray-800 font-bold">
                      $0.00
                    </span>
                  </div>
                  <div className="flex items-center py-2 text-sm w-full font-semibold text-gray-500 last:border-b-0 last:text-base last:pb-0">
                    Shipping Cost
                    <span className="ml-auto flex-shrink-0 text-gray-800 font-bold">
                      $0.00
                    </span>
                  </div>
                  <div className="flex items-center py-2 text-sm w-full font-semibold text-gray-500 last:border-b-0 last:text-base last:pb-0">
                    Discount
                    <span className="ml-auto flex-shrink-0 font-bold text-orange-400">
                      $0.00
                    </span>
                  </div>
                  <div className="border-t mt-4">
                    <div className="flex items-center font-bold font-serif justify-between pt-5 text-sm uppercase">
                      TOTAL COST
                      <span className="font-serif font-extrabold text-lg">
                        $0.00
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
        <ToastContainer />
        <Footer />
      </section>
    </>
  );
};

export default Checkout;
