import React, { useState, useEffect } from "react";
import { Player } from "@lottiefiles/react-lottie-player";
import searchingLottie from "assets/lotties/96916-searching.json";
import { Link } from "react-router-dom";
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import Footer from "components/Footers/Footer.js";
import { Tab } from "@headlessui/react";
import PopUoForm from "components/Forms/PopUoForm";
import StickyEnquryForm from "components/Forms/StickyEnquryForm";
import Trusted from "components/Footers/Trusted";
import Corousel from "components/Corousel/Corousel";
import HospitalAppBanner from "assets/images/hospital_app_development_banner.png";
import { hospitalAppServiceData } from "../mobAppData";
import SmartTag from "components/Development/SmartTag";
import FloatingWp from "components/Development/FloatingWp";
import Faq from "components/Development/Faq";
import Preloader from "components/Development/Preloader";

const HospitalManagement = () => {
  const accordionData = [
    {
      id: 1,
      title: "Q1: What is a web service?",
      data: "A web service is a software system designed to enable communication and interoperability between different applications over a network, typically the internet. It allows applications to exchange data and perform various operations using standard protocols such as HTTP and XML. Web services are commonly used for integrating different systems, sharing data, and facilitating communication between applications developed on different platforms.",
    },
    {
      id: 2,
      title: "Q2: What are the advantages of using web services?",
      data: "Web services offer several advantages, including: Interoperability: Web services allow applications developed on different platforms to communicate and exchange data seamlessly",
    },
    {
      id: 3,
      title: "Q4: How can I consume a web service in my application?",
      data: `Identify the type of web service (SOAP, REST, GraphQL) and its endpoint URL. Use a programming language or framework that supports web service consumption. Generate or write code to make HTTP requests to the web service endpoint. Handle the responses returned by the web service and parse the data as needed. Integrate the consumed data into your application's logic or user interface.`,
    },
  ];

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  const [categories] = useState({
    "Customer App Pannel": [
      {
        id: 1,
        title: "Quick Onboarding",
        data: "With direct social media profile integration, users can easily register and log in to the app. This hassle-free login process ensures smoother onboarding.",
      },
      {
        id: 2,
        title: "Social Login And Logout",
        data: "We help ease the whole login and sharing process by adding the comfort of Social Media integration to your application.",
      },
      {
        id: 3,
        title: "Easy Product Filtering",
        data: "With advanced product search functionality, users can easily search a product with filters like price, category, offers, promotions, and others.",
      },
      {
        id: 4,
        title: "Real-Time Shipment Tracking",
        data: "Ensure transparency for your users with real-time shipment tracking and notification. Users can track orders with real-time notifications with each milestone achieved.",
      },
      {
        id: 5,
        title: "Multiple Payment Gateways",
        data: "Users can make hassle-free payments with their own choice of payment methods like debit cards, credit cards, net banking, or any other secured payment apps.",
      },
      {
        id: 6,
        title: "Shipping Details Integration",
        data: "To keep your customers updated with how their product is moving in real-time, we add integrations of some of the top shipping providers: USPS, UPS, FedEx, Australian Post, etc.",
      },
      {
        id: 7,
        title: "Quick Push Notifications",
        data: "Inform users of the current status of their order, new offers, product promotion, or any other updates with quick push notifications.",
      },
      {
        id: 8,
        title: "Reviews and Ratings",
        data: "This section allows your users to review and rate the products that they purchased from your ecommerce app. With user feedback, you can work on the flaws.",
      },
    ],
    "Admin Pannel": [
      {
        id: 1,
        title: "Simplified Inventory Management",
        data: "Keep your inventory clean and up to date at all times with an easy-to-use interface. The inventory management dashboard comes with the ability to fetch inventory from various third-party databases.",
      },
      {
        id: 2,
        title: "Easy Product Management",
        data: "Streamline your products by managing the categories with an intuitive interface for adding photos, descriptions, prices, etc. Admins will benefit from the flexibility by adding and managing categories as per their own requirements.",
      },
      {
        id: 3,
        title: "Real-Time Notifications",
        data: "Receive real-time notifications for the placed orders, manage orders & payments, and inventory, across all your systems for optimizing order fulfillment and delivering orders in time.",
      },
      {
        id: 4,
        title: "Advanced Reporting",
        data: "Track the performances of your sales, orders, and delivery agents with AI-powered analytic tools. Generate customized reports, and create various reports for handling your sales, marketing, operations, manufacturing, finance, and other areas.",
      },
      {
        id: 5,
        title: "Manage Users",
        data: "Being an admin, you will be able to manage your users by giving them the right roles of manager, editor, or co-admin. Restrict or offer access to different parts of the app to your users thereby firmly remaining in complete control of your app.",
      },
      {
        id: 6,
        title: "Manage Promotions & Rewards",
        data: "As an admin, you can now create new offers for your users and manage the offer and discounts of your e-commerce app. In this section, your users can apply promo codes and avail discounts as they purchase their preferred products.",
      },
    ],
    "Vendor Pannel": [
      {
        id: 1,
        title: "Order Management",
        data: "With the order management feature, the vendor can keep a constant check on all the orders that are delivered to the customers and those that are still in the queue. With a seamless vendor panel dashboard, managing orders has now become simpler.",
      },
      {
        id: 2,
        title: "Catalog Management",
        data: "Vendors can easily manage their products effectively based on how they choose their featured products and product categories. With proper management of products, the vendors can attract the right target audience and generate more sales.",
      },
      {
        id: 3,
        title: "Manage Delivery Locations",
        data: "Customers get agitated when they choose a particular product and find that it is undeliverable to their location. This means that the product is not locally available. With our solution, vendors can enable product filtering and set their location so that the customers can only see the nearby vendors.",
      },
      {
        id: 4,
        title: "Easy Vendor Membership",
        data: "With a user-friendly interface, vendors cannot only view the membership plans but also opt for an ideal one. Membership can be renewed from the admin panel. Additionally, they can also store and collect their information like product limits, commission fees, categories, and so on.",
      },
      {
        id: 5,
        title: "Vendor Account Reports",
        data: "Allow your vendor to view complete transaction details of each order like commission amount, marketplace fee, net payable amount, and shipping cost. In this way, the vendors can manage their commission invoices for tracking what they are paying to sell on your marketplace.",
      },
      {
        id: 6,
        title: "Product Upload",
        data: "Uploading bulk products can be time-consuming for the vendors. With our multi-vendor marketplace solution, they can seamlessly upload product pictures and edit product information while making changes to the offers in real-time",
      },
    ],
  });

  const [isLoading, setisLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setisLoading(false);
    }, 1000);
  }, []);

  return (
    <>
      {isLoading === true ? (
        <Preloader />
      ) : (
        <div className="">
          <div className="fixed bg-hero-pattern bg-fixed bg-cover bg-center inset-0 -z-20"></div>
          <div className="App relative">
            {" "}
            <IndexNavbar fixed />
            {/* <!-- Start block --> */}
            <section className="">
              <div className="grid max-w-screen-xl px-4 pt-20 pb-8 mx-auto lg:gap-8 xl:gap-0 lg:py-16 lg:grid-cols-12 lg:pt-28">
                <div className="mr-auto place-self-center lg:col-span-7">
                  <SmartTag
                    title="Best"
                    description="Hospital Management App Development Company in India"
                  />
                  <h1 className="max-w-2xl mb-4 font-extrabold leading-normal tracking-tight text-lg lg:text-4xl dark:text-white">
                    Hospital Management Mobile App Development Company
                  </h1>
                  <p className="max-w-2xl mb-6 font-light text-justify justify-start lg:mb-8 md:text-base lg:text-base leading-normal">
                    We are a leading company specializing in creating innovative
                    and user-friendly mobile applications for hospital
                    management. Our team of experienced developers understands
                    the unique needs of the healthcare industry and can deliver
                    a mobile app that streamlines various hospital operations
                    and enhances patient care. Here's an overview of the content
                    for our Hospital Management Mobile App Development company:
                  </p>
                  <div className="space-y-4 sm:flex sm:space-y-0 sm:space-x-4">
                    <Link
                      to={"/"}
                      target=""
                      className="inline-block px-6 py-2.5 bg-[#44619d] text-white font-medium text-xs leading-tight uppercase rounded-full shadow-md hover:bg-blue-500 hover:shadow-lg focus:bg-blue-500 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-600 active:shadow-lg transition duration-150 ease-in-out"
                    >
                      Case Study
                    </Link>
                    <Link
                      to={"/contact-us"}
                      className=" mx-2 inline-block px-6 py-2.5 bg-gray-800 text-white font-medium text-xs leading-tight uppercase rounded-full shadow-md hover:bg-gray-900 hover:shadow-lg focus:bg-gray-900 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-gray-900 active:shadow-lg transition duration-150 ease-in-out"
                      target=""
                    >
                      Contact Us
                    </Link>
                  </div>
                </div>
                <div className="hidden lg:mt-0 lg:col-span-5 lg:flex">
                  <img src={HospitalAppBanner} alt="heroimage" />
                </div>
              </div>
            </section>
            {/* <!-- End block --> */}
            <section className="max-w-screen-xl px-4 pb-8 mx-auto lg:pb-16">
              <Corousel />
            </section>
            <section className=" bg-transparent">
              <div className="max-w-screen-xl rounded-md backdrop-blur-sm bg-white/30 px-4 py-8 mx-auto space-y-12 lg:space-y-20 lg:py-24 lg:px-6">
                {hospitalAppServiceData.map(
                  ({ id, title, description, icon, keyFeatures, para }) => (
                    <div
                      className="flex flex-col sm:flex-col-reverse last:rounded-lg lg:flex-row lg:odd:flex-row-reverse justify-center items-center p-0 md:p-6"
                      id={id}
                    >
                      <div className="text-gray-500  p-4 dark:text-gray-400 w-full md:w-1/2">
                        <h2 className="mb-4 text-lg md:text-xl font-semibold  text-gray-900 dark:text-white">
                          {title}
                        </h2>
                        <p className="text-justify justify-start text-xs md:text-base leading-normal  font-normal text-gray-700 mb-4 ">
                          {description}
                        </p>
                        <hr class="w-full mt-1 h-1 mb-4 bg-indigo-200" />
                        <ul className="pt-2 space-y-5 border-t border-gray-200 mb-4 dark:border-gray-700">
                          {keyFeatures.map(({ keys, keyPoints }) => (
                            <li className="flex" key={id}>
                              <span className=" cursor-pointer flex space-x-3 ">
                                {svgIcon()}
                                <div class="flex flex-col space-y-2">
                                  <span class="text-base font-medium leading-normal text-gray-800 dark:text-white hover:underline hover:text-purple-500">
                                    {keys}
                                  </span>
                                  <span class="text-base font-normal leading-normal text-gray-700 dark:text-white text-justify justify-start">
                                    {keyPoints}
                                  </span>
                                </div>
                              </span>
                            </li>
                          ))}
                        </ul>
                        <hr class="w-full mt-1 h-1 mb-4 bg-indigo-200" />
                        <p className="text-justify justify-start text-xs md:text-base leading-normal  font-normal text-gray-700 mb-4 ">
                          {para}
                        </p>
                        <div className="mt-4">
                          <div className="block text-left">
                            <div className="flex items-center mx-auto w-full">
                              <Player
                                src={searchingLottie}
                                className="player"
                                loop
                                autoplay
                                style={{ height: "50px", width: "50px" }}
                              />
                              <div className="ml-2  item-center">
                                <div className="flex-col text-xs text-blue">
                                  <button className="px-2 py-1 bg-[#44619d] text-white font-medium text-xs leading-tight rounded-full shadow-md hover:bg-blue-500 hover:shadow-lg focus:bg-blue-500 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-600 active:shadow-lg transition duration-150 ease-in-out font-poppins cursor-pointer">
                                    <Link href="/contact-us">
                                      Get a quote ?
                                    </Link>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="w-full md:w-1/2 mb-4 lg:mb-0 lg:flex mx-auto lg:justify-center">
                        {" "}
                        <img
                          className="verticalAnimate"
                          src={icon}
                          alt="ERP Solutions"
                        />
                      </div>
                    </div>
                  )
                )}
              </div>
            </section>
            <Trusted />
            <section className="bg-transparent">
              <div className="w-full mx-auto sm:max-w-md md:max-w-lg lg:max-w-2xl px-2 py-16 sm:px-0">
                <h2 className="text-xl text-center mb-2 font-extrabold tracking-tight text-gray-900 dark:text-white">
                  Explore Our Feature-Rich E-Commerce Application Panels
                </h2>
                <Tab.Group>
                  <Tab.List className="flex space-x-1 rounded-xl bg-indigo-500 p-1">
                    {Object.keys(categories).map((category) => (
                      <Tab
                        key={category}
                        className={({ selected }) =>
                          classNames(
                            "w-full rounded-lg py-2.5 text-sm font-medium leading-5 text-blue-700",
                            "ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2",
                            selected
                              ? "bg-white shadow text-white"
                              : "text-blue-100 hover:bg-white/[0.12] hover:text-white"
                          )
                        }
                      >
                        {category}
                      </Tab>
                    ))}
                  </Tab.List>
                  <Tab.Panels className="mt-2">
                    {Object.values(categories).map((posts, idx) => (
                      <Tab.Panel
                        key={idx}
                        className={classNames(
                          "rounded-xl bg-white p-3",
                          "ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2"
                        )}
                      >
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-2 lg:grid-cols-3">
                          {posts.map((post) => (
                            <ul>
                              <li
                                key={post.id}
                                className="relative rounded-md p-3 hover:bg-gray-100"
                              >
                                <div className=" space-x-2 inline-flex">
                                  <span>
                                    <svg
                                      className="flex-shrink-0 w-5 h-5 text-purple-500 dark:text-purple-400"
                                      fill="currentColor"
                                      viewBox="0 0 20 20"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        fill-rule="evenodd"
                                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                        clipRule="evenodd"
                                      ></path>
                                    </svg>
                                  </span>
                                  <h3 className="text-sm font-medium leading-5">
                                    {post.title}
                                  </h3>
                                </div>

                                <ul className="mt-1 flex space-x-1 text-xs font-normal leading-4 text-gray-500">
                                  <li>{post.data}</li>
                                </ul>
                                <Link
                                  to="/e-commerce-app-development"
                                  className={classNames(
                                    "absolute inset-0 rounded-md",
                                    "ring-blue-400 focus:z-10 focus:outline-none focus:ring-2"
                                  )}
                                ></Link>
                              </li>
                            </ul>
                          ))}
                        </div>
                      </Tab.Panel>
                    ))}
                  </Tab.Panels>
                </Tab.Group>
              </div>
            </section>
            <Faq faqs={accordionData} />
            <FloatingWp />
            <Footer />
            <StickyEnquryForm />
            <PopUoForm />
          </div>
        </div>
      )}
    </>
  );
};

export default HospitalManagement;

const svgIcon = () => {
  return (
    <svg
      className="flex-shrink-0 w-5 h-5 text-purple-500 dark:text-purple-400"
      fill="currentColor"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
};
