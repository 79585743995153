import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {
  Card,
  CardBody,
  CardFooter,
  Typography,
  Button,
} from "@material-tailwind/react";
import HeadphonesPng from "assets/images/offer_headphones.png";
import MousePng from "assets/images/offer_mouse.png";
import KeyboardPng from "assets/images/offer_keyboard.png";

const MobileTopSlider = () => {
  var settings = {
    autoplay: true,
    autoplaySpeed: 3000,
    centerMode: true,
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    arrows: false,
    responsive: [
      {
        breakpoint: 990,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
          centerMode: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
          centerMode: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          centerMode: false,
        },
      },
    ],
  };

  const sliderData = [
    {
      id: 1,
      title: "Sony Headphones",
      description: "Buy any laptop and get headphones for free.",
      image: HeadphonesPng,
      gradient: "bg-gradient-to-br from-indigo-300 via-cyan-300 to-blue-300",
    },
    {
      id: 2,
      title: "Dell Mouse",
      description: "Buy any laptop and get headphones for free.",
      image: MousePng,
      gradient: "bg-gradient-to-br from-gray-200 via-green-200 to-cyan-200",
    },
    {
      id: 3,
      title: "Dell Keyboard",
      description: "Buy any laptop and get headphones for free.",
      image: KeyboardPng,
      gradient: "bg-gradient-to-br from-pink-200 via-red-200 to-pink-300",
    },
  ];

  const [time, setTime] = useState({
    hours: "00",
    minutes: "00",
    seconds: "00",
    milliseconds: "00",
  });

  useEffect(() => {
    let interval = null;

    const startTimer = () => {
      let endTime = localStorage.getItem("endTime");
      if (!endTime) {
        endTime = new Date();
        endTime.setHours(endTime.getHours() + 12);
        localStorage.setItem("endTime", endTime.toString());
      } else {
        endTime = new Date(endTime);
      }

      interval = setInterval(() => {
        const currentTime = new Date().getTime();
        const remainingTime = endTime - currentTime;

        if (remainingTime <= 0) {
          clearInterval(interval);
          localStorage.removeItem("endTime");

          setTime({
            hours: "00",
            minutes: "00",
            seconds: "00",
            milliseconds: "00",
          });
        } else {
          const hours = Math.floor(
            (remainingTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
          );
          const minutes = Math.floor(
            (remainingTime % (1000 * 60 * 60)) / (1000 * 60)
          );
          const seconds = Math.floor((remainingTime % (1000 * 60)) / 1000);
          const milliseconds = Math.floor((remainingTime % 1000) / 10);

          setTime({
            hours: padTime(hours),
            minutes: padTime(minutes),
            seconds: padTime(seconds),
            milliseconds: padTime(milliseconds),
          });
        }
      }, 10);
    };

    startTimer();

    return () => {
      clearInterval(interval);
    };
  }, []);

  const padTime = (time) => {
    return time.toString().padStart(2, "0");
  };
  let sliderProject = "";

  sliderProject = sliderData.map(
    ({ id, title, description, image, gradient }) => (
      <>
        <Card className={`mr-3 rounded-xl ${gradient}`} key={id}>
          <CardBody className="px-3 py-1">
            <img src={image} alt={title} className="w-24 h-auto" />
            <Typography variant="h4" color="blue-gray" className="mb-2">
              {title}
            </Typography>
            <Typography>{description}</Typography>
            <div className="mx-auto w-full my-2">
              <div class="flex items-center justify-center font-semibold">
                <span class="flex items-center justify-center bg-indigo-500 text-white !text-[8px] font-semibold mx-1 px-1 py-1 rounded">
                  {time.hours}
                </span>
                :
                <span class="flex items-center justify-center bg-indigo-500 text-white !text-[8px] font-semibold mx-1 px-1 py-1 rounded">
                  {time.minutes}
                </span>
                :
                <span class="flex items-center justify-center bg-indigo-500 text-white !text-[8px] font-semibold mx-1 px-1 py-1 rounded">
                  {time.seconds}
                </span>
                :
                <span class="flex items-center justify-center bg-indigo-500 text-white !text-[8px] font-semibold mx-1 px-1 py-1 rounded">
                  {time.milliseconds}
                </span>
              </div>
            </div>
          </CardBody>

          <CardFooter className="!pt-0 p-3">
            <Link to="/smart-products" className="inline-block ml-auto">
              <Button
                size="xs"
                variant="text"
                className="flex flex-row !items-center !justify-center uppercase px-2 py-1 gap-1 border border-indigo-500 bg-black text-white text-[10px] font-normal !rounded-md"
              >
                Claim Now
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 20 20"
                  strokeWidth={2}
                  stroke="currentColor"
                  className="h-3 w-3"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3"
                  />
                </svg>
              </Button>
            </Link>
          </CardFooter>
        </Card>
      </>
    )
  );
  return (
    <div className="block lg:hidden mx-auto max-w-screen-sm rounded-lg relative overflow-hidden">
      <div className="">
        <Slider
          {...settings}
          className="max-w-screen-sm mx-auto flex flex-row space-x-3 justify-around items-center py-2 px-2"
        >
          {sliderProject}
        </Slider>
      </div>
    </div>
  );
};

export default MobileTopSlider;
