import Faq from "components/Development/Faq";
import FloatingWp from "components/Development/FloatingWp";
import Footer from "components/Footers/Footer";
import Trusted from "components/Footers/Trusted";
import PopUoForm from "components/Forms/PopUoForm";
import StickyEnquryForm from "components/Forms/StickyEnquryForm";
import IndexNavbar from "components/Navbars/IndexNavbar";
import React from "react";
import { principalFeaturesMarketing, seoFaq } from "./digitalPagesData";
import Corousel from "components/Corousel/Corousel";
import HeroHeader from "components/Development/HeroHeader";
import SEOMarketingBanner from "assets/images/seo_marketing_banner.png";
import MainServices from "components/Development/MainServices";
import { seoServicesData, seoWhyChooseUs } from "../digitalData";
import WhyChooseUs from "components/Development/WhyChooseUs";
import PrincipalFeatures from "components/Development/PrincipalFeatures";
import MetaHelmet from "components/Development/MetaHelmet";
import Preloader from "components/Development/Preloader";

const SeoMarketing = () => {
  const [isLoading, setisLoading] = React.useState(true);

  React.useEffect(() => {
    setTimeout(() => {
      setisLoading(false);
    }, 2000);
  }, []);
  return (
    <>
      {isLoading === true ? (
        <Preloader />
      ) : (
        <div className="">
          {" "}
          <MetaHelmet
            title="Best SEO Services India | Professional SEO Company India"
            descriptionContent="Looking for professional SEO services? whiztechnology SEO company, we curate a result-driven SEO strategy to get more website traffic for your bussiness."
            keywordsContent="seo packages, professional seo services, seo digital marketing, best seo company, seo services near me, local seo company, seo agency near me, best seo services"
          />
          <div className="fixed bg-hero-pattern bg-fixed bg-cover bg-center inset-0 -z-20"></div>
          <IndexNavbar />
          <HeroHeader
            title="Search Engine Optimization (SEO) Company"
            description="SEO marketing is a strategic approach to optimizing your website and its content to rank higher in search engine results pages (SERPs). By utilizing various techniques and best practices, we can improve your website's visibility for relevant keywords and phrases that your target audience is searching for. The goal is to drive organic, targeted traffic to your website, resulting in increased brand awareness, website traffic, and potential customers."
            image={SEOMarketingBanner}
            smartTagTitle="No.1"
            smartTagDescription="Search Engine Optimization Company in India"
          />
          <Corousel />
          <MainServices mainServicesData={seoServicesData} />
          <WhyChooseUs whyChooseUsData={seoWhyChooseUs} />
          <PrincipalFeatures
            principalFeaturesData={principalFeaturesMarketing}
            image={
              "https://res.cloudinary.com/whiztechnology/image/upload/v1691052528/frdxvbvbybowwynzq6jt.png"
            }
          />
          <Trusted />
          <Faq faqs={seoFaq} />
          <StickyEnquryForm />
          <FloatingWp />
          <PopUoForm />
          <Footer />
        </div>
      )}
    </>
  );
};

export default SeoMarketing;
