import React, { useState, useEffect } from "react";
import { createRoot } from "react-dom/client";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import ScrollToTop from "components/utils/ScrollToTop";

import "@fortawesome/fontawesome-free/css/all.min.css";
import "./index.css";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import "@fontsource/roboto/900.css";
import About from "views/About.js";
import Index from "views/Index.js";
import Contact from "components/Contact/Contact";
import MobileAppDevelopement from "components/AppDevelopement/AppDevelopment.jsx";
import ECommerceServices from "components/AppDevelopement/AppServices/ECommerceServices.jsx";
import BusCarBooking from "components/AppDevelopement/AppServices/BusCarBooking.jsx";
import FlightBooking from "components/AppDevelopement/AppServices/FlightBooking.jsx";
import HospitalManagement from "components/AppDevelopement/AppServices/HospitalManagement.jsx";
import HotelBooking from "components/AppDevelopement/AppServices/HotelBooking.jsx";
import OfficeManagement from "components/AppDevelopement/AppServices/OfficeManagement.jsx";
import EducationalAppDevelopement from "components/AppDevelopement/AppServices/EducationalAppDevelopement.jsx";
import ProductView from "components/Production-House/ProductView";
import ThankYou from "components/Development/ThankYou.jsx";
import WebDevelopment from "components/WebDevelopment/WebDevelopment";
import WebDesigning from "components/WebDesigning/WebDesigning";
import DigitalMarketing from "components/digital-marketing/DigitalMarketing";
import StackDevelopment from "components/Stack-Development/StackDevelopment";
import TechnologyCertification from "components/Technology-Certification/TechnologyCertification";
import ErpDevelopment from "components/WebDevelopment/Pages/ErpDevelopment";
import ECommerceDevelopment from "components/WebDevelopment/Pages/ECommerceDevelopment";
import CrmDevelopment from "components/WebDevelopment/Pages/CrmDevelopment";
import TravelPortalDevelopment from "components/WebDevelopment/Pages/TravelPortalDevelopment";
import SchoolManagementDevlopment from "components/WebDevelopment/Pages/SchoolManagementDevlopment";
import HospitalManagementDevelopment from "components/WebDevelopment/Pages/HospitalManagementDevelopment";
import SeoMarketing from "components/digital-marketing/Pages/SeoMarketing";
import SmoMarketing from "components/digital-marketing/Pages/SmoMarketing";
import MediaWorks from "views/MediaWorks";
import SmartProducts from "views/SmartProducts";
import PpcGoogleAd from "components/digital-marketing/Pages/PpcGoogleAd";
import ContentWriting from "components/digital-marketing/Pages/ContentWriting";
import UIUXDesigning from "components/WebDesigning/Pages/UIUXDesigning";
import Checkout from "components/Smart-Class/Checkout";
import { CartProvider } from "components/utils/Products_api";
import CloudinaryContext from "components/utils/CloudinaryContext";
import UploadComponent from "components/utils/UploadComponent";
import AuthContext from "./context/auth-context";
import Error404 from "components/Development/Error404";
import { Provider } from "react-redux";
import store from "redux/Store";

const App = () => {
  const [token, setToken] = useState(null);
  const [userId, setUserId] = useState("");
  const [tokenExpiration, setTokenExpiration] = useState("");

  useEffect(() => {
    const _token = JSON.parse(localStorage.getItem("token"));
    const tokenExp = JSON.parse(localStorage.getItem("tokenExpiration"));
    const userIdLocal = JSON.parse(localStorage.getItem("userId"));
    if (_token && userIdLocal && tokenExp) {
      setToken(_token);
      setUserId(userIdLocal);
      setTokenExpiration(tokenExp);
    }
  }, []);

  const login = (token, userId, tokenExpiration) => {
    setToken(token);
    setUserId(userId);
    setTokenExpiration(tokenExpiration);
  };

  const logout = () => {
    setToken(null);
    setUserId(null);
    setTokenExpiration(null);
    localStorage.removeItem("token");
    localStorage.removeItem("userId");
    localStorage.removeItem("tokenExpiration");
  };

  // Replace these values with your Cloudinary credentials
  const cloudName = "whiztechnology";
  const apiKey = "253726884848443";
  const uploadPreset = "vikings";

  return (
    <Router>
      <ScrollToTop />
      <CloudinaryContext.Provider value={{ cloudName, apiKey, uploadPreset }}>
        <CartProvider>
          <AuthContext.Provider
            value={{
              token,
              userId,
              tokenExpiration,
              login,
              logout,
            }}
          >
            {" "}
            <Routes>
              <Route path="/" element={<Index />} />
              {/* Add the * wildcard */}
              <Route exact path="/media-works" element={<MediaWorks />} />
              <Route exact path="/about-us" element={<About />} />
              <Route exact path="/contact-us" element={<Contact />} />
              <Route exact path="/smart-products" element={<SmartProducts />} />
              <Route
                exact
                path="/website-development-company"
                element={<WebDevelopment />}
              />
              <Route
                exact
                path="/mobile-app-development-company"
                element={<MobileAppDevelopement />}
              />
              <Route
                exact
                path="/e-commerce-app-development-company"
                element={<ECommerceServices />}
              />
              <Route
                exact
                path="/bus-car-booking-app-development-company"
                element={<BusCarBooking />}
              />
              <Route
                exact
                path="/flight-booking-app-development-company"
                element={<FlightBooking />}
              />
              <Route
                exact
                path="/educational-app-development-company"
                element={<EducationalAppDevelopement />}
              />
              <Route
                exact
                path="/office-management-app-development-company"
                element={<OfficeManagement />}
              />
              <Route
                exact
                path="/hospital-management-app-development-company"
                element={<HospitalManagement />}
              />
              <Route
                exact
                path="/hotel-booking-app-development-company"
                element={<HotelBooking />}
              />
              <Route exact path="/product-view" element={<ProductView />} />
              <Route exact path="/thank-you" element={<ThankYou />} />
              <Route
                exact
                path="/website-designing-company"
                element={<WebDesigning />}
              />
              <Route
                exact
                path="/digital-marketing-company"
                element={<DigitalMarketing />}
              />
              <Route
                exact
                path="/full-stack-development-company"
                element={<StackDevelopment />}
              />
              <Route
                exact
                path="/technology-certification"
                element={<TechnologyCertification />}
              />
              <Route
                exact
                path="/erp-development-company"
                element={<ErpDevelopment />}
              />
              <Route
                exact
                path="/e-commerce-development-company"
                element={<ECommerceDevelopment />}
              />
              <Route
                exact
                path="/crm-development-company"
                element={<CrmDevelopment />}
              />
              <Route
                exact
                path="/travel-portal-development-company"
                element={<TravelPortalDevelopment />}
              />
              <Route
                exact
                path="/school-website-development-company"
                element={<SchoolManagementDevlopment />}
              />
              <Route
                exact
                path="/hospital-website-development-company"
                element={<HospitalManagementDevelopment />}
              />
              <Route
                exact
                path="/seo-marketing-company"
                element={<SeoMarketing />}
              />
              <Route
                exact
                path="/social-media-marketing-company"
                element={<SmoMarketing />}
              />
              <Route
                exact
                path="/ppc-google-ad-company"
                element={<PpcGoogleAd />}
              />
              <Route
                exact
                path="/content-writing-company"
                element={<ContentWriting />}
              />
              <Route
                exact
                path="/ui-ux-website-designing-company"
                element={<UIUXDesigning />}
              />
              <Route exact path="/upload-image" element={<UploadComponent />} />
              <Route exact path="/checkout" element={<Checkout />} />
              <Route path="*" element={<Error404 />} />
              <Route
                path="/admin/*"
                element={<Navigate to="/admin/dashboard" />}
              />{" "}
            </Routes>
          </AuthContext.Provider>
        </CartProvider>
      </CloudinaryContext.Provider>
    </Router>
  );
};

createRoot(document.getElementById("root")).render(
  <Provider store={store}>
    <App />
  </Provider>
);
