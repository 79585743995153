import React from "react";
import ListSlider from "./ListSlider";

export default function VideoSection() {
  return (
    <>
      <section className="max-w-screen-xl px-4 py-8 mx-auto space-y-12 lg:space-y-20">
        <div className="items-center gap-8 lg:grid lg:grid-cols-2 xl:gap-16">
          <div className="flex flex-col items-center space-x-3">
            <h1 className="text-2xl text-slate-600 lg:w-[75%] font-semibold font-poppins text-slate-400">
              See How Teams Are Using Whiz Technology..{" "}
            </h1>
            <hr className="my-4 h-[3px] w-[25%] bg-[#44619d] rounded-[25px]" />{" "}
            <video
              width="750"
              height="500"
              controls
              src={
                "https://res.cloudinary.com/whiztechnology/video/upload/v1690891955/Uploads/pexels-pavel-danilyuk-9121382_an6g7f.mp4"
              }
              type="video/mp4"
            />
            <p className="font-light w-full mt-12 max-w-xl text-white text-center lg:text-xl uppercase px-2 py-3 rounded-md border cursor-pointer bg-indigo-500 hover:bg-indigo-700">
              REQUEST A FREE QUOTE?
            </p>
          </div>

          <div className="text-gray-500 sm:text-lg dark:text-gray-400">
            <ul className="pt-2 space-y-5 text-center border-t border-gray-200 my-7 dark:border-gray-700">
              <ListSlider />
            </ul>
            {/* <!-- List --> */}
          </div>
        </div>
      </section>
    </>
  );
}
