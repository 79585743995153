import React, { createContext, useContext, useReducer, useEffect } from "react";
import Projector from "assets/images/projectors_smartproduct.jpg";
import LEDwalls from "assets/images/interactive_panel.jpg";
import ComDisplays from "assets/images/commercial_pannel.jpg";
import LEDWalls from "assets/images/LED_Wall.jpg";
import PendDisplay from "assets/images/pen_display.jpg";
import SmartProduct from "assets/smart-class-img/smart-product.webp";
import StreamingServer from "assets/smart-class-img/streaming-server.webp";
import DigitalSinage from "assets/smart-class-img/digital-sinage.jpg";
import Alpha11 from "assets/smart-class-img/alpha-11pro.webp";
import Benchmark4k from "assets/smart-class-img/benchmark-4k-camera.webp";
import DigitalPodal from "assets/smart-class-img/digital-metallic-podium.webp";
import BenchmarkCard from "assets/smart-class-img/benchmark-capture-card.webp";
import VectraSystem from "assets/smart-class-img/vectra-system.webp";
import LaserPresentetion from "assets/smart-class-img/laser-presentation.webp";
import MacBook from "assets/images/macbookAir.webp";
import Dell from "assets/images/dell.webp";
import Acer from "assets/images/acer.png";
import HP from "assets/images/hp.png";
import Lenovo from "assets/images/lenovo.png";

export const SmartProducts = [
  {
    id: 1,
    title: "Projectors",
    imgSrc: Projector,
    description:
      "Smart class projectors provide high-resolution and vibrant visuals, ensuring that educational content is displayed with clarity and detail. This allows students to easily comprehend and engage with the material being presented.",
  },
  {
    id: 2,
    title: "View Board Interactive Display",
    imgSrc: LEDwalls,
    description:
      "The View Board Interactive Display features a large, high-resolution touchscreen that supports multi-touch gestures. Teachers and students can interact with the display using their fingers or a stylus, allowing for seamless navigation, annotation, and collaboration.",
  },
  {
    id: 3,
    title: "Commercial Display",
    imgSrc: ComDisplays,
    description:
      "Commercial displays offer high-resolution screens with vibrant colors and sharp image quality. This ensures that educational content is displayed with clarity and detail, enhancing the visual experience for both teachers and students.",
  },
  {
    id: 4,
    title: "LED Walls",
    imgSrc: LEDWalls,
    description:
      "LED walls provide a truly immersive visual experience with their large, seamless display. These walls consist of multiple LED panels seamlessly connected to create a vibrant and dynamic visual canvas that captures students' attention and enhances engagement.",
  },
  {
    id: 5,
    title: "Pen Displays",
    imgSrc: PendDisplay,
    description:
      "Pen displays allow teachers and students to write, draw, and annotate directly on the display surface using a stylus or pen. This interactive feature enhances engagement and facilitates hands-on learning experiences.",
  },
  {
    id: 6,
    imgSrc: SmartProduct,
    title: "Benchmark Mic",
    description:
      "Benchmark Microphones are known for their exceptional audio quality, capturing clear and accurate sound. They offer high-fidelity recording and playback, ensuring that students can hear the teacher's voice, instructional videos, and multimedia content with clarity and precision.",
  },
  {
    id: 7,
    imgSrc: StreamingServer,
    title: "Benchmark Server",
    description:
      "Server products are built with powerful hardware components designed to handle demanding workloads. They typically feature high-performance processors, ample memory (RAM), and fast storage options such as solid-state drives (SSDs) or hard disk drives (HDDs).",
  },
  {
    id: 8,
    imgSrc: DigitalSinage,
    title: "Digital Sinage BMT DSPM 03DS",
    description:
      "The BMT DSPM 03DS is a specific model of digital signage product offered by BMT (Brand Marketing Technology). While I don't have access to detailed information about this specific model, I can provide you with a general overview of digital signage and its typical features Digital signage refers to the use of digital displays and multimedia content to convey information, advertisements, or messages to a targeted audience. Here are some key features and benefits commonly associated with digital signage solutions:",
  },
  {
    id: 9,
    imgSrc: Alpha11,
    title: "Benchmark Alpha 11 pro",
    description:
      "Smart class cameras provide high-resolution video capture, ensuring clear and sharp visuals. This enables teachers to deliver lessons with clarity, making it easier for students to see and understand the instructional content. Many smart class cameras feature wide-angle lenses that can capture a broader field of view. This allows teachers to capture the entire classroom or specific areas of interest, such as the whiteboard or student group activities, without the need for frequent camera adjustments.",
  },
  {
    id: 10,
    imgSrc: Benchmark4k,
    title: "Benchmark 4k Online Class PTZ Camera",
    description:
      "When it comes to selecting a 4K PTZ (Pan-Tilt-Zoom) camera for online classes, there are a few factors to consider. A 4K resolution camera will provide excellent image clarity, ensuring that your students can see details clearly. Look for a camera with a high-quality image sensor and lens to capture sharp and vibrant visuals.",
  },
  {
    id: 11,
    imgSrc: DigitalPodal,
    title: "Digital Metallic Podium",
    description:
      "A digital metallic podium is an advanced and interactive presentation platform used in smart class environments. It combines the functionality of a traditional podium with modern technology to enhance presentations and engage the audience. The digital metallic podium typically features a built-in interactive touchscreen display. This allows presenters to navigate through digital content, access multimedia resources, and interact directly with the displayed material using touch gestures or a stylus.",
  },
  {
    id: 12,
    imgSrc: BenchmarkCard,
    title: "Benchmark Capture Card for Online Teaching",
    description:
      "A capture card is a device used to capture and record audio and video signals from external sources, such as cameras, computers, or other multimedia devices. It is commonly used in online teaching to enhance the quality of audiovisual content and improve the overall teaching experience. A capture card allows you to capture high-quality video from external sources, such as a camera or computer screen. This ensures that your online teaching sessions are visually clear and engaging for your students.",
  },
  {
    id: 13,
    imgSrc: VectraSystem,
    title: "Vectra 3 in one Kiosk Systems",
    description:
      "The Vectra 3-in-1 Kiosk System is a versatile and comprehensive solution that combines multiple functionalities into a single kiosk unit. The system often includes self-service capabilities, allowing users to perform various tasks independently. This can include tasks such as check-in, registration, ticketing, information lookup, surveys, or self-checkout in retail environments.",
  },
  {
    id: 14,
    imgSrc: StreamingServer,
    title: "Online Class Streaming Server /Recording Studio",
    description:
      "An online class streaming server and recording studio is a comprehensive solution that enables the streaming and recording of online classes, lectures, or presentations. It combines the necessary hardware and software components to facilitate high-quality streaming and recording experiences. The streaming server is the central component that receives the audio and video feed from the recording studio and delivers it to remote viewers in real-time. It ensures reliable and efficient distribution of the streaming content, supporting multiple simultaneous viewers.",
  },
  {
    id: 15,
    imgSrc: LaserPresentetion,
    title: "R400 Laser Presentaiton Remote",
    description:
      "The R400 Laser Presentation Remote is a handheld device used for controlling presentations and delivering seamless presentations in various settings. The R400 remote provides wireless control over presentation slides, allowing presenters to navigate through their slides without being tethered to a computer. This freedom of movement enhances engagement and interaction with the audience.",
  },
];

export const ProductLine = [
  {
    id: 1,
    title: "MacBook (Air)",
    description: "",
    imgSrc: MacBook,
    gradient: "bg-gradient-to-br from-indigo-300 via-cyan-300 to-blue-300",
  },
  {
    id: 2,
    title: "Dell",
    description: "",
    imgSrc: Dell,
    gradient: "bg-gradient-to-br from-gray-200 via-green-200 to-cyan-200",
  },
  {
    id: 3,
    title: "Acer",
    description: "",
    imgSrc: Acer,
    gradient: "bg-gradient-to-br from-pink-200 via-red-200 to-pink-300",
  },
  {
    id: 4,
    title: "HP",
    description: "",
    imgSrc: HP,
    gradient: "bg-gradient-to-br from-blue-200 via-purple-200 to-blue-300",
  },
  {
    id: 5,
    title: "Lenovo",
    description: "",
    imgSrc: Lenovo,
    gradient: "bg-gradient-to-br from-red-200 via-indigo-200 to-red-300",
  },
];

// Define a cart context
const CartContext = createContext();

// Define initial state
const initialState = {
  cartItems: JSON.parse(localStorage.getItem("cartItems")) || [],
  viewItems: JSON.parse(localStorage.getItem("viewItems")) || [],
};

// Define reducer function
const cartReducer = (state, action) => {
  switch (action.type) {
    case "ADD_TO_CART":
      return {
        ...state,
        cartItems: [...state.cartItems, action.payload],
      };
    case "REMOVE_FROM_CART":
      return {
        ...state,
        cartItems: state.cartItems.filter((item) => item.id !== action.payload),
      };
    case "VIEW_PRODUCT":
      return {
        ...state,
        viewItems: [action.payload],
      };
    case "CLOSE_MODAL":
      return {
        ...state,
        viewItems: [],
      };
    case "UPDATE_CART":
      return {
        ...state,
        cartItems: action.payload,
      };
    case "UPDATE_QUANTITY":
      return {
        ...state,
        cartItems: state.cartItems.map((item) =>
          item.id === action.payload.id
            ? { ...item, quantity: action.payload.quantity }
            : item
        ),
      };
    default:
      return state;
  }
};

// Define CartProvider component
const CartProvider = ({ children }) => {
  const [state, dispatch] = useReducer(cartReducer, initialState);

  // Define addToCart function
  const addToCart = (product) => {
    const existingItemIndex = state.cartItems.findIndex(
      (item) => item.id === product.id
    );

    if (existingItemIndex !== -1) {
      // If the product already exists in cartItems, update its quantity
      const updatedCartItems = [...state.cartItems];
      updatedCartItems[existingItemIndex].quantity += 1;

      dispatch({ type: "UPDATE_CART", payload: updatedCartItems });
    } else {
      // If the product is not in cartItems, add it
      dispatch({ type: "ADD_TO_CART", payload: product });
    }
    dispatch({
      type: "EMPTY_CART",
      payload: product,
    });
  };

  // Function to update the quantity of a product in cart
  const updateQuantity = (productId, quantity) => {
    dispatch({
      type: "UPDATE_QUANTITY",
      payload: { id: productId, quantity: quantity },
    });
  };

  // Define removeFromCart function
  const removeFromCart = (productId) => {
    dispatch({ type: "REMOVE_FROM_CART", payload: productId });
  };

  const viewProduct = (product) => {
    dispatch({ type: "VIEW_PRODUCT", payload: product });
  };

  // Save cartItems to localStorage whenever it changes
  useEffect(() => {
    localStorage.setItem("cartItems", JSON.stringify(state.cartItems));
  }, [state.cartItems]);

  useEffect(() => {
    localStorage.setItem("viewItems", JSON.stringify(state.viewItems));
  }, [state.viewItems]);

  return (
    <CartContext.Provider
      value={{
        cartItems: state.cartItems,
        addToCart,
        removeFromCart,
        viewItems: state.viewItems,
        viewProduct,
        updateQuantity,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};

const useCart = () => useContext(CartContext);

export { CartProvider, useCart };
