import React from "react";
import { useState } from "react";
import { Player } from "@lottiefiles/react-lottie-player";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { UilMessage } from "@iconscout/react-unicons";

import { db } from "config/firebase";
import { doc, setDoc } from "firebase/firestore";

const PopUoForm = () => {
  const [isOpen, setIsOpen] = useState(false);

  const notify = () =>
    toast.success("🦄 Email sent successfully!", {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });

  // Form Validation Using EmailJs
  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [services, setServices] = useState("");
  const [phoneNo, setPhoneNo] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();

    const full_name = `${name} ${lastName}`;
    const currentDate = new Date();
    const options = { day: "2-digit", month: "short", year: "numeric" };
    const createdAt = currentDate.toLocaleDateString("en-US", options);
    const updatedAt = currentDate.toLocaleDateString("en-US", options);

    const formData = {
      id: Date.now(),
      full_name,
      read: false,
      email,
      services,
      phone_no: phoneNo,
      status: "Add Status",
      followup: "Pending",
      createdAt,
      updatedAt,
      source: "webenquiry",
    };

    const docId = Date.now();
    const collectionsRef = doc(db, "webServiceEnquiry", `${docId}`);

    try {
      setDoc(collectionsRef, formData)
        .then(() => {
          console.log("Document created successfully!");
        })
        .catch((error) => {
          console.error("Error creating document: ", error);
        });
    } catch (error) {
      console.error("Error creating document: ", error);
    }
    notify();
    setName("");
    setLastName("");
    setPhoneNo("");
    setEmail("");
    setServices("");
    handleToggle();
    handleToggle();
  };
  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <button
        onClick={handleToggle}
        className="fixed z-40 hidden lg:flex right-5 bottom-5 shadow-lg justify-center items-center w-14 h-14 bg-indigo-500 rounded-full focus:outline-none hover:bg-indigo-600 focus:bg-indigo-600 transition duration-300 ease"
        handleToggle={handleToggle}
      >
        {!isOpen ? (
          <Player
            src={
              "https://res.cloudinary.com/whiztechnology/raw/upload/v1691044466/v4pc9f24nfdaro4nhinb.json"
            }
            className="player"
            loop
            autoplay
            style={{
              height: "100px",
              width: "100px",
            }}
          />
        ) : (
          <svg
            className="w-6 h-6 text-white absolute"
            xShow="open"
            xTransitionEnterStart="opacity-0 rotate-45 scale-75"
            xTransitionEnter="transition duration-200 transform ease"
            xTransitionLeave="transition duration-100 transform ease"
            xTransitionLeave-end="opacity-0 rotate-45"
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <line x1="18" y1="6" x2="6" y2="18"></line>
            <line x1="6" y1="6" x2="18" y2="18"></line>
          </svg>
        )}
      </button>

      {isOpen ? (
        <div
          className={`fixed flex flex-col z-50 bottom-[100px] right-0 left-0 sm:top-auto sm:right-5 top-[15px] sm:left-auto h-auto w-full sm:w-[350px] overflow-auto min-h-[250px] border border-gray-300 bg-white shadow-2xl rounded-md"${
            isOpen ? "translate-x-0 " : "translate-x-full"
          }`}
          handleToggle={handleToggle}
        >
          <div className="flex p-5 flex-col justify-center items-center h-32 bg-gray-200">
            <Player
              src={
                "https://res.cloudinary.com/whiztechnology/raw/upload/v1691044518/gkjuaswa9ofpmyfil9qq.json"
              }
              className="player"
              loop
              autoplay
              style={{
                height: "200px",
                width: "250px",
              }}
            />
          </div>
          <div className="bg-gray-50 flex-grow p-6">
            <form onSubmit={handleSubmit} method="post">
              <div className="relative w-full mb-3 flex flex-shrink ">
                <div className=" w-1/2">
                  <label
                    className="block uppercase text-black text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    First Name
                  </label>
                  <input
                    type="text"
                    className="border-0 px-3 my-inputs py-3 text-black  rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150 bg-white"
                    placeholder="First Name"
                    required
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>
                <div className=" w-1/2 ml-[10px]">
                  <label
                    className="block uppercase  text-slate-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Last Name
                  </label>
                  <input
                    type="text"
                    className="border-0 px-3 py-3 my-inputs placeholder-white0 text-slate-600  rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150 bg-white"
                    placeholder="Last Name"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                  />
                </div>
              </div>

              <div>
                <label
                  htmlFor="phone"
                  className="block uppercase text-black text-xs font-bold mb-2"
                >
                  Contact No:
                </label>
                <input
                  value={phoneNo}
                  onChange={(e) => setPhoneNo(e.target.value)}
                  type="tel"
                  id="phone"
                  className=" text-black text-sm rounded-sm border-none  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 shadow bg-white"
                  placeholder="+0-888-888-8888"
                  required
                />
              </div>

              <div className="">
                <div className="relative w-full mb-3 mt-3">
                  <label
                    className="block uppercase  text-slate-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Email
                  </label>
                  <input
                    type="email"
                    className="border-0 px-3 py-3 my-inputs placeholder-white0 text-slate-600 rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150 bg-white"
                    placeholder="Email"
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </div>
              </div>

              <div className="relative w-full mb-3">
                <label
                  htmlFor="services"
                  className="block uppercase text-black text-xs font-bold mb-2 "
                >
                  Select an option
                </label>
                <select
                  id="countries"
                  className="text-black border-none text-sm rounded-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 shadow  bg-white"
                  onChange={(e) => setServices(e.target.value)}
                  required
                >
                  <option selected className="text-black">
                    Services
                  </option>
                  <option className="text-black" value="Website Development">
                    Website Development
                  </option>
                  <option className="text-black" value="CRM & ERP Development">
                    CRM & ERP Development
                  </option>
                  <option className="text-black" value="App Development">
                    App Development
                  </option>
                  <option className="text-black" value="Digital Marketing">
                    Digital Marketing
                  </option>
                  <option className="text-black" value="Other">
                    Other
                  </option>
                </select>
              </div>
              <div className="text-center mt-6">
                <button
                  className=" flex w-40 justify-center bg-blue-500 items-center bg-slate-800 text-white active:bg-slate-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 m-auto"
                  type="submit"
                >
                  <i className="mr-2">
                    <UilMessage />
                  </i>{" "}
                  Send
                </button>
              </div>
            </form>
          </div>
        </div>
      ) : null}
      <div className=" absolute right-5 top-5">
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
      </div>
    </>
  );
};

export default PopUoForm;
