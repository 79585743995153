import React from "react";
import { Helmet } from "react-helmet";

const MetaHelmet = (props) => {
  return (
    <Helmet>
      <title>{props.title}</title>
      <meta name="description" content={props.descriptionContent} />
      <meta name="keywords" content={props.keywordsContent} />
    </Helmet>
  );
};

export default MetaHelmet;
