import React, { useState, useEffect } from "react";
// import CountUp from "react-countup";
import Footer from "components/Footers/Footer.js";
// import Teamlogo from "assets/img/team-2-800x800.jpeg";
// import AboutSlider from "components/About/AboutSlider";
// import ProjectShowCase from "components/About/ProjectShowCase";
import Journey from "components/About/Journey";
import Trusted from "components/Footers/Trusted";
import FloatingWp from "components/Development/FloatingWp";
import PopUoForm from "components/Forms/PopUoForm";
import Preloader from "components/Development/Preloader";
import AboutHeader from "components/About/AboutHeader";
import MetaHelmet from "components/Development/MetaHelmet";
import IndexNavbar from "components/Navbars/IndexNavbar";
import TeamSection from "components/About/TeamSection";
import VideoSection from "components/Services/VideoSection";

export default function About() {
  const [isLoading, setisLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setisLoading(false);
    }, 1000);
  }, []);

  return (
    <>
      {isLoading === true ? (
        <Preloader />
      ) : (
        <div className="">
          <MetaHelmet
            title="About Whiz Technology Company"
            descriptionContent="In the modern digital world, our mission is to define technology with figure tips of common people. Our professionals always tried to develop applications, websites and software (API) with minimal technical complexity."
            keywordsContent=""
          />
          <div className="fixed bg-hero-pattern bg-fixed bg-cover bg-center inset-0 -z-20"></div>
          <IndexNavbar transparent />
          <AboutHeader />
          <TeamSection />
          <Journey />
          <FloatingWp />
          <PopUoForm />
          <VideoSection />
          <Trusted />
          <Footer />
        </div>
      )}
    </>
  );
}
