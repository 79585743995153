import React from "react";
import "./about.css";
import Virus from "assets/images/virus.png";
import FLightBanner from "assets/images/flight-banner.png";

export default function Journey() {
  return (
    <>
      <section className="relative mx-auto max-w-screen-xl">
        {" "}
        <div className="container  mx-auto">
          {" "}
          <div className="about-section  milestone-box">
            <div className="container-1200 left-box">
              <div className="headingtext">Our Milestones So Far...</div>
              <p className="abouttext">
                We started our journey with challenges and today we have turned
                them into our accomplishments!
              </p>
            </div>
            <div className="milestone-list max-w-screen-xl overflow-hidden overflow-x-scroll no-scrollbar container-1200">
              <div className="ws-progress-box">
                <figure className="space-p5">
                  <img src={FLightBanner} alt="Store" />
                  <figcaption>
                    We started working on big projects like...
                  </figcaption>
                </figure>
                <ul className="about-list">
                  <li>Travel Agency</li>
                  <li>Smart-Class Products</li>
                  <li>Ads Making</li>
                </ul>
                <p className="bullet-point">
                  <span className="ws-year">2021-22</span>
                </p>
              </div>
              <div className="ws-progress-box">
                <figure className="space-p5">
                  <img src={Virus} alt="Store" />
                  <figcaption>
                    Struggle many situations during Covid-19 and stood up as a
                    team.
                  </figcaption>
                </figure>
                <ul className="about-list">
                  <li>
                    During the challenging COVID-19 season in 2020, our team
                    struggled and responded to the customer's demands with a
                    smile.
                  </li>
                </ul>
                <p className="bullet-point">
                  <span className="ws-year">2020</span>
                </p>
              </div>
              <div className="ws-progress-box">
                <figure className="space-p5">
                  <img
                    src="https://whiztechnology.in/static/media/crm_development.24aca186fdb375fbbb1e.png"
                    alt="CRM"
                  />
                  <figcaption>
                    Expanded CRM services, expert and marketing leaders.
                  </figcaption>
                </figure>
                <ul className="about-list">
                  <li>
                    In 2019, our developers, experts and marketing leaders
                    worked together for the company's expansion & ROI and the
                    company achieved various successes.
                  </li>
                </ul>
                <p className="bullet-point">
                  <span className="ws-year">2019</span>
                </p>
              </div>
              <div className="ws-progress-box">
                <figure className="space-p5">
                  <img
                    src="https://whiztechnology.in/static/media/ux-design.0055783981e0715a8701.png"
                    alt="Mattress"
                  />
                  <figcaption>
                    We Started web application development.
                  </figcaption>
                </figure>
                <ul className="about-list">
                  <li>
                    We have developed and managed diverse web applications in
                    many fields such as hotels, interior design, matrimonial and
                    many more in 2018.
                  </li>
                </ul>
                <p className="bullet-point">
                  <span className="ws-year">2018</span>
                </p>
              </div>
              <div className="ws-progress-box">
                <figure className="space-p5">
                  <img
                    src="https://whiztechnology.in/static/media/java_developement.f5dd86c3ef6ef3f85acf.png"
                    alt="RVCF"
                  />
                  <figcaption>Started Web Devlopment Services.</figcaption>
                </figure>
                <ul className="about-list">
                  <li>
                    In 2017, we expanded and began the development of web-based
                    applications.
                  </li>
                </ul>
                <p className="bullet-point">
                  <span className="ws-year">2017</span>
                </p>
              </div>
              <div className="ws-progress-box">
                <figure className="space-p5">
                  <img
                    src="https://whiztechnology.in/static/media/app_developement.484ba15ba2e11711984b.png"
                    alt="Download App"
                  />
                  <figcaption>Started International Journey</figcaption>
                </figure>
                <ul className="about-list">
                  <li>
                    In 2015-2016, we successfully encapsulated a range of
                    national and international customers.
                  </li>
                </ul>
                <p className="bullet-point">
                  <span className="ws-year">2015-16</span>
                </p>
              </div>
              <div className="ws-progress-box">
                <figure className="space-p5">
                  <img
                    src="https://whiztechnology.in/static/media/digital_marketing.a6204665713d6d440a8b.png"
                    alt=""
                  />
                  <figcaption>
                    Expanded our services Search Engine Optimization (SEO) & Web
                    Designing
                  </figcaption>
                </figure>
                <ul className="about-list">
                  <li>
                    In 2014, we expanded our activity with web design and SEO
                    (search engine optimization).
                  </li>
                </ul>
                <p className="bullet-point">
                  <span className="ws-year">2014</span>
                </p>
              </div>
              <div className="ws-progress-box">
                <figure>
                  <img
                    src="https://whiztechnology.in/static/media/logo.35d0886b19c35bd238e3.png"
                    alt="Logo"
                    className=" bg-[#44619d]"
                  />
                </figure>
                <ul className="about-list">
                  <li>
                    We began our trip as a graphic designer company based in New
                    Delhi in 2013.{" "}
                  </li>
                </ul>
                <p className="bullet-point">
                  <span className="ws-year">2013</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
