import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import HeroPng from "assets/images/hote_app_banner.png";
import IndexNavbar from "components/Navbars/IndexNavbar.js";

import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Footer from "components/Footers/Footer.js";
import HotelEnquiryBanner from "assets/images/hotel_booking_enquiry_banner.png";
import LogInPng from "assets/logo/user.png";
import HotelBookingPng from "assets/logo/menu.png";
import degreeViewPng from "assets/logo/360-degrees.png";
import cancelPng from "assets/logo/no-data.png";
import atmPng from "assets/logo/atm-card.png";
import filtersPng from "assets/logo/filter.png";
import Hotelpng from "assets/logo/hotel.png";
import calederPng from "assets/logo/calendar.png";
import filePng from "assets/logo/file.png";
import HotelBooking_2 from "assets/images/hotel_booking_2.png";
import { Tab } from "@headlessui/react";
import PopUoForm from "components/Forms/PopUoForm";
import StickyEnquryForm from "components/Forms/StickyEnquryForm";
import Trusted from "components/Footers/Trusted";
import Corousel from "components/Corousel/Corousel";
import SmartTag from "components/Development/SmartTag";
import FloatingWp from "components/Development/FloatingWp";
import Faq from "components/Development/Faq";
import Preloader from "components/Development/Preloader";

const HotelBooking = () => {
  const cardData = [
    {
      id: "01",
      title: "Quick & Safe Booking",
      content:
        "Hotel booking apps allow users to book nearby hotels faster and with more security. They simply need to download the hotel booking app and get the best suggestions nearby.",
    },
    {
      id: "02",
      title: "Brand Awareness",
      content:
        "Apps are one of the most used commodities, so having a hotel booking app will improve your hotel business's brand awareness.",
    },
    {
      id: "03",
      title: "Hassle-free Payment",
      content:
        "Say goodbye to the concern of offline payment management. Hotel booking apps enable hassle-free payment and allow users to pay on the go with a tap.",
    },
    {
      id: "04",
      title: "Streamlined Booking",
      content:
        "With a feature-rich and well-managed, users and business owners do not have to worry about booking management and get updates on upcoming, ongoing, and cancelled bookings.",
    },
    {
      id: "05",
      title: "Customized Push Notifications.",
      content:
        "Keeping the users timely notified of their booking status, upcoming offers, and more will increase your hotel booking app engagement and customer engagement.",
    },
    {
      id: "06",
      title: "Transparent Business Analytics",
      content:
        "While having hotel booking applications, businesses can look at the business analytics and stats clearly to analyze monthly or weekly profit and growth",
    },
  ];
  const accordionData = [
    {
      id: 1,
      title: "Q1. What Is Hotel App?",
      data: "A hotel booking application is a software created especially for mobile devices, smartphones or tablets. Users can interact with the hotel booking services via an application. Hotels can choose which services to include in their apps and give users an amazing hotel booking experience.",
    },
    {
      id: 2,
      title: "Q2: What Is the Actual Cost to Develop A Hotel Booking App?",
      data: "We cannot define the exact cost of building a hotel booking app. A hotel booking app costs around $15,000 to $45,000, depending on multiple factors. So, when you hire dedicated developers for your app, they will let you know the exact cost after looking into your project requirements.",
    },
    {
      id: 3,
      title: "Q3: How Long Does It Cost to Build A Hotel Booking App?",
      data: `No fixed time is required for hotel booking app development as it solely depends on the project's complexity. So first, share your business requirements with the hotel booking app developers, and they will tell you the time required to create a booking app.`,
    },
    {
      id: 4,
      title: "Q4: Can I Hire Dedicated Developers for My Hotel Booking App?",
      data: (
        <>
          Yes, you can definitely hire dedicated developers for your hotel
          booking software. You can choose from the different hiring models
          available, i.e. -
          <ul className="list-disc mt-3 ml-4">
            <li>Hire developers full-time.</li>
            <li>Hire developers part-time.</li>
            <li>Hire developers hourly.</li>
          </ul>
        </>
      ),
    },
    {
      id: 5,
      title: "Q5: What Are the Benefits Of A Brand Hotel Booking App?",
      data: (
        <>
          There are several benefits businesses can experience with a
          well-developed hotel booking app, such as –
          <ul className="list-disc mt-3 ml-4">
            <li>Improved customers experience.</li>
            <li>Cost-effective process.</li>
            <li>Better brand visibility.</li>
            <li>Transparent communication.</li>
            <li>Visible business analytics.</li>
          </ul>
        </>
      ),
    },
  ];

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  const [categories] = useState({
    "Customer App Pannel": [
      {
        id: 1,
        title: "Quick Onboarding",
        data: "With direct social media profile integration, users can easily register and log in to the app. This hassle-free login process ensures smoother onboarding.",
      },
      {
        id: 2,
        title: "Social Login And Logout",
        data: "We help ease the whole login and sharing process by adding the comfort of Social Media integration to your application.",
      },
      {
        id: 3,
        title: "Easy Product Filtering",
        data: "With advanced product search functionality, users can easily search a product with filters like price, category, offers, promotions, and others.",
      },
      {
        id: 4,
        title: "Real-Time Shipment Tracking",
        data: "Ensure transparency for your users with real-time shipment tracking and notification. Users can track orders with real-time notifications with each milestone achieved.",
      },
      {
        id: 5,
        title: "Multiple Payment Gateways",
        data: "Users can make hassle-free payments with their own choice of payment methods like debit cards, credit cards, net banking, or any other secured payment apps.",
      },
      {
        id: 6,
        title: "Shipping Details Integration",
        data: "To keep your customers updated with how their product is moving in real-time, we add integrations of some of the top shipping providers: USPS, UPS, FedEx, Australian Post, etc.",
      },
      {
        id: 7,
        title: "Quick Push Notifications",
        data: "Inform users of the current status of their order, new offers, product promotion, or any other updates with quick push notifications.",
      },
      {
        id: 8,
        title: "Reviews and Ratings",
        data: "This section allows your users to review and rate the products that they purchased from your ecommerce app. With user feedback, you can work on the flaws.",
      },
    ],
    "Admin Pannel": [
      {
        id: 1,
        title: "Simplified Inventory Management",
        data: "Keep your inventory clean and up to date at all times with an easy-to-use interface. The inventory management dashboard comes with the ability to fetch inventory from various third-party databases.",
      },
      {
        id: 2,
        title: "Easy Product Management",
        data: "Streamline your products by managing the categories with an intuitive interface for adding photos, descriptions, prices, etc. Admins will benefit from the flexibility by adding and managing categories as per their own requirements.",
      },
      {
        id: 3,
        title: "Real-Time Notifications",
        data: "Receive real-time notifications for the placed orders, manage orders & payments, and inventory, across all your systems for optimizing order fulfillment and delivering orders in time.",
      },
      {
        id: 4,
        title: "Advanced Reporting",
        data: "Track the performances of your sales, orders, and delivery agents with AI-powered analytic tools. Generate customized reports, and create various reports for handling your sales, marketing, operations, manufacturing, finance, and other areas.",
      },
      {
        id: 5,
        title: "Manage Users",
        data: "Being an admin, you will be able to manage your users by giving them the right roles of manager, editor, or co-admin. Restrict or offer access to different parts of the app to your users thereby firmly remaining in complete control of your app.",
      },
      {
        id: 6,
        title: "Manage Promotions & Rewards",
        data: "As an admin, you can now create new offers for your users and manage the offer and discounts of your e-commerce app. In this section, your users can apply promo codes and avail discounts as they purchase their preferred products.",
      },
    ],
    "Vendor Pannel": [
      {
        id: 1,
        title: "Order Management",
        data: "With the order management feature, the vendor can keep a constant check on all the orders that are delivered to the customers and those that are still in the queue. With a seamless vendor panel dashboard, managing orders has now become simpler.",
      },
      {
        id: 2,
        title: "Catalog Management",
        data: "Vendors can easily manage their products effectively based on how they choose their featured products and product categories. With proper management of products, the vendors can attract the right target audience and generate more sales.",
      },
      {
        id: 3,
        title: "Manage Delivery Locations",
        data: "Customers get agitated when they choose a particular product and find that it is undeliverable to their location. This means that the product is not locally available. With our solution, vendors can enable product filtering and set their location so that the customers can only see the nearby vendors.",
      },
      {
        id: 4,
        title: "Easy Vendor Membership",
        data: "With a user-friendly interface, vendors cannot only view the membership plans but also opt for an ideal one. Membership can be renewed from the admin panel. Additionally, they can also store and collect their information like product limits, commission fees, categories, and so on.",
      },
      {
        id: 5,
        title: "Vendor Account Reports",
        data: "Allow your vendor to view complete transaction details of each order like commission amount, marketplace fee, net payable amount, and shipping cost. In this way, the vendors can manage their commission invoices for tracking what they are paying to sell on your marketplace.",
      },
      {
        id: 6,
        title: "Product Upload",
        data: "Uploading bulk products can be time-consuming for the vendors. With our multi-vendor marketplace solution, they can seamlessly upload product pictures and edit product information while making changes to the offers in real-time",
      },
    ],
  });

  const keyFeatures = [
    {
      id: 1,
      icon: LogInPng,
      title: "Login/Sign-up",
      data: "Customers can use this feature to register on the hotel booking app and create a user profile to access the application.",
    },
    {
      id: 2,
      icon: HotelBookingPng,
      title: "Book A Hotel",
      data: "Using this booking application feature, users can reserve a room for 24 hours, a day, or even a week from the comfort of their homes.",
    },
    {
      id: 3,
      icon: degreeViewPng,
      title: "360 Degree Hotel View",
      data: "It is a unique feature of hotel booking software that will help the users get a 360 view of the hotel and make a decision accordingly.",
    },
    {
      id: 4,
      icon: cancelPng,
      title: "Cancel Booking",
      data: "If the user’s plan is changed, in that case, they can easily cancel their hotel booking with this ‘Cancel booking’ feature. It will increase client loyalty.",
    },
    {
      id: 5,
      icon: atmPng,
      title: "Online Payment",
      data: "Gone are the days we used to pay at the hotel during check-in/check-out. Now, make hassle-free payments with just a tap with this hotel booking app feature.",
    },
    {
      id: 6,
      icon: filtersPng,
      title: "Advance Filter Search",
      data: "Different people have different demands while booking a hotel. So, this feature will allow users to apply filters like location, price, amenities, and more while booking a hotel online.",
    },
    {
      id: 7,
      icon: Hotelpng,
      title: "Hotel Management",
      data: "This feature is useful for hotel booking owners or admins to keep track of all the properties in one place. It results in improved business management.",
    },
    {
      id: 8,
      icon: calederPng,
      title: "View Booking Plans",
      data: "Very rare hotel booking software has this feature. So, you can make your booking app unique with this feature that allows users to view different hotel booking plans and availability.",
    },
    {
      id: 9,
      icon: filePng,
      title: "Booking History",
      data: "This feature of the hotel booking app will display all the previous hotel booking history. Therefore, it will help the users rebook the hotel if they wish to do so in the future and save much time.",
    },
  ];

  const [isLoading, setisLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setisLoading(false);
    }, 1000);
  }, []);

  return (
    <>
      {isLoading === true ? (
        <Preloader />
      ) : (
        <div className="">
          <div className="fixed bg-hero-pattern bg-fixed bg-cover bg-center inset-0 -z-20"></div>
          <div className="App relative">
            {" "}
            <IndexNavbar fixed />
            {/* <!-- Start block --> */}
            <section className="">
              <div className="grid max-w-screen-xl px-4 pt-20 pb-8 mx-auto lg:gap-8 xl:gap-0 lg:py-16 lg:grid-cols-12 lg:pt-28">
                <div className="mr-auto place-self-center lg:col-span-7">
                  <SmartTag
                    title="Best"
                    description="Hotel Booking App Development Company in India"
                  />
                  <h1 className="max-w-2xl mb-4 text-4xl uppercase font-extrabold leading-normal tracking-tight sm:text- xl:text-4xl dark:text-white">
                    Hotel Booking Mobile App Development COMPANY
                  </h1>
                  <p className="max-w-2xl mb-6 font-light text-justify text-gray-500 lg:mb-8 md:text-base lg:text-bse dark:text-gray-400">
                    Take your hotel booking business to the next level with
                    feature-rich and exceptional hotel booking app development
                    assistance. Dev Technosys incorporates proven technologies
                    and a 100% effective working methodology to build hotel
                    booking app that drives business sales and profit. Now
                    transform your business vision into reality while choosing
                    our hotel app development company.
                  </p>
                  <div className="space-y-4 sm:flex sm:space-y-0 sm:space-x-4">
                    <Link
                      to={"/"}
                      target=""
                      className="inline-block px-6 py-2.5 bg-[#44619d] text-white font-medium text-xs leading-tight uppercase rounded-full shadow-md hover:bg-blue-500 hover:shadow-lg focus:bg-blue-500 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-600 active:shadow-lg transition duration-150 ease-in-out"
                    >
                      Case Study
                    </Link>
                    <Link
                      to={"/contact-us"}
                      className=" mx-2 inline-block px-6 py-2.5 bg-gray-800 text-white font-medium text-xs leading-tight uppercase rounded-full shadow-md hover:bg-gray-900 hover:shadow-lg focus:bg-gray-900 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-gray-900 active:shadow-lg transition duration-150 ease-in-out"
                      target=""
                    >
                      Contact Us
                    </Link>
                  </div>
                </div>
                <div className="hidden lg:mt-0 lg:col-span-5 lg:flex">
                  <img src={HeroPng} alt="heroimage" />
                </div>
              </div>
            </section>
            {/* <!-- End block --> */}
            <section className="">
              <div className="max-w-screen-xl px-4 pb-8 mx-auto lg:pb-16">
                <Corousel />
              </div>
            </section>
            <section className=" bg-transparent">
              {/* {Tab Section} */}
              <div className="w-full space-y-4 mx-auto sm:max-w-md md:max-w-2xl lg:max-w-5xl px-2 py-16 sm:px-0">
                <h2 className="mb-4 text-center text-3xl font-extrabold tracking-tight text-gray-900 dark:text-white">
                  Key Features of Hotel Booking Application Development
                </h2>
                <div className="text-center">
                  <Typography variant="subtitle2" gutterBottom>
                    We employ cutting-edge technologies and tools to make sure
                    your applications are built on the latest technology. Have a
                    look at our tech stack
                  </Typography>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 place-content-center ">
                  <div className="grid grid-rows-1 md:grid-rows-2 lg:grid-rows-3 grid-flow-row lg:grid-flow-col gap-4">
                    {keyFeatures.map((item) => (
                      <Card
                        key={item.id}
                        sx={{
                          minWidth: 352,
                          backgroundColor: "#d8eaff",
                          borderRadius: 5,
                          ":hover": { backgroundColor: "white" },
                        }}
                      >
                        <CardContent
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            marginX: "12",
                            pb: 0,
                          }}
                        >
                          <div className="flex flex-row space-x-3 mb-4">
                            <div className="bg-transparent max-w-fit p-2 hover:bg-white rounded-md">
                              <img
                                src={item.icon}
                                alt=""
                                className=" h-10 w-10
                          "
                                srcset=""
                              />
                            </div>
                            <p className="text-xl text-slate-400 font-semibold">
                              {item.title}
                            </p>
                          </div>
                          <div className="ml-2">{item.data}</div>
                        </CardContent>
                      </Card>
                    ))}
                  </div>
                </div>
              </div>
            </section>
            <section className=" bg-transparent">
              <div className="max-w-screen-xl rounded-md backdrop-blur-sm bg-white/30 px-4 py-8 mx-auto space-y-12 lg:space-y-20 lg:py-24 lg:px-6">
                {/* <!-- Row --> */}
                <div className="items-center gap-8 lg:grid lg:grid-cols-2 xl:gap-16">
                  <div className="text-gray-500 sm:text-lg dark:text-gray-400">
                    <h2 className="mb-2 text-3xl font-extrabold tracking-tight text-gray-900 dark:text-white">
                      Give The People A Place Of Happiness By Developing Hotel
                      Booking App
                    </h2>
                    {/* <!-- List --> */}
                    <ul className=" pt-2 space-y-5 text-justify border-t border-gray-200 my-7 dark:border-gray-700">
                      <li className="flex ">
                        {/* <!-- Icon --> */}
                        <Typography variant="subtitle2" gutterBottom>
                          The hotel booking application is one of the most
                          useful apps for this generation of people. Because
                          whenever we go traveling, the first thing we look for
                          is the hotel to match our requirements. And Vrinsoft
                          has the proper knowledge and understanding of
                          developing such great applications.
                        </Typography>
                      </li>

                      <li className="flex ">
                        {/* <!-- Icon --> */}
                        <p className="uppercase text-indigo-500 font-light lg:text-xl">
                          PERKS OF HAVING A HOTEL BOOKING APPLICATION
                        </p>
                      </li>
                      <li className="flex ">
                        {/* <!-- Icon --> */}
                        <Typography variant="subtitle2" gutterBottom>
                          We build the hotel application with proper functions
                          of location, booking time, date, scheduling, and more
                          to give a secure, accessing, and operating
                          application. We give great exposure and a range of
                          categories in hotel booking applications.
                        </Typography>
                      </li>
                    </ul>
                    <img
                      className="w-full verticalAnimate mb-4 rounded-lg lg:mb-0 lg:flex"
                      src={HotelBooking_2}
                      alt=""
                      srcset=""
                    />
                  </div>
                  <img
                    className="w-full verticalAnimate mb-4 rounded-lg lg:mb-0 lg:flex"
                    src={HotelEnquiryBanner}
                    alt="eCoomerce App"
                  />
                </div>
                {/* <!-- Row --> */}
                <div className="items-center gap-8 lg:grid lg:grid-cols-2 xl:gap-16">
                  <img
                    className="w-full mb-4 rounded-lg lg:mb-0 lg:flex"
                    src={HotelEnquiryBanner}
                    alt="featureimage 2"
                  />
                  <div className="text-gray-500 sm:text-lg dark:text-gray-400">
                    <h2 className="mb-4 text-3xl font-extrabold tracking-tight text-gray-900 dark:text-white">
                      DEVELOPMENT COST
                    </h2>

                    <p className="mb-8 font-light lg:text-xl">
                      To Stay You Need To Pay
                    </p>
                    <ul className="pt-2 space-y-5 border-t border-gray-200 my-7 dark:border-gray-700">
                      <li className="flex space-x-3">
                        {/* <!-- Icon --> */}

                        <span className="text-base font-medium leading-tight text-gray-900 dark:text-white">
                          There are many features that help in booking the
                          hotels of your need and give you proper integrations
                          to make easy access to the customers. The range of
                          development varies between $2,000 to $5,000. It can
                          exceed or limit the price of development.
                        </span>
                      </li>
                      <li className="flex space-x-3">
                        {/* <!-- Icon --> */}
                        <svg
                          className="flex-shrink-0 w-5 h-5 text-purple-500 dark:text-purple-400"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                            clipRule="evenodd"
                          ></path>
                        </svg>
                        <span className="text-base font-medium leading-tight text-gray-900 dark:text-white">
                          Get Your Flight Tickets Booking App Now
                        </span>
                      </li>
                    </ul>
                    {/* <!-- List --> */}
                    <p className="font-light text-white text-center lg:text-xl uppercase px-2 py-3 rounded-md border cursor-pointer bg-indigo-500 hover:bg-indigo-700">
                      REQUEST A FREE QUOTE?
                    </p>
                  </div>
                </div>
                {/* Row */}
              </div>
            </section>
            <section className=" bg-[#f0fafe]">
              {/* {Tab Section} */}
              <div className="w-full space-y-4 mx-auto sm:max-w-md md:max-w-2xl lg:max-w-5xl px-2 py-16 sm:px-0">
                <h2 className="mb-4 text-center text-3xl font-extrabold tracking-tight text-gray-900 dark:text-white">
                  Advantages of Hotel Booking App
                </h2>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-2 lg:grid-cols-3">
                  {cardData.map((item) => (
                    <Card
                      key={item.id}
                      sx={{
                        minWidth: 275,
                        background: "white",
                      }}
                    >
                      <CardContent
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          marginX: "12",
                        }}
                      >
                        <div className="flex flex-row justify-between mb-4">
                          <Typography variant="h6" gutterBottom>
                            {item.title}
                          </Typography>
                          <p className=" text-5xl text-black font-semibold">
                            {item.id}
                          </p>
                        </div>

                        <Typography variant="body2" gutterBottom>
                          {item.content}
                        </Typography>
                      </CardContent>
                      {/* <CardActions>
                    <Button size="small">Learn More</Button>
                  </CardActions> */}
                    </Card>
                  ))}
                </div>
              </div>
            </section>
            <section className="bg-transparent">
              {/* {Tab Section} */}
              <div className="w-full mx-auto sm:max-w-md md:max-w-lg lg:max-w-2xl px-2 py-16 sm:px-0">
                <h2 className=" text-xl text-center mb-2 font-extrabold tracking-tight text-gray-900 dark:text-white">
                  Explore Our Feature-Rich E-Commerce Application Panels
                </h2>
                <Tab.Group>
                  <Tab.List className="flex space-x-1 rounded-xl bg-indigo-500 p-1">
                    {Object.keys(categories).map((category) => (
                      <Tab
                        key={category}
                        className={({ selected }) =>
                          classNames(
                            "w-full rounded-lg py-2.5 text-sm font-medium leading-5 text-blue-700",
                            "ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2",
                            selected
                              ? "bg-white shadow text-white"
                              : "text-blue-100 hover:bg-white/[0.12] hover:text-white"
                          )
                        }
                      >
                        {category}
                      </Tab>
                    ))}
                  </Tab.List>
                  <Tab.Panels className="mt-2">
                    {Object.values(categories).map((posts, idx) => (
                      <Tab.Panel
                        key={idx}
                        className={classNames(
                          "rounded-xl bg-white p-3",
                          "ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2"
                        )}
                      >
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-2 lg:grid-cols-3">
                          {posts.map((post) => (
                            <ul>
                              <li
                                key={post.id}
                                className="relative rounded-md p-3 hover:bg-gray-100"
                              >
                                <div className=" space-x-2 inline-flex">
                                  <span>
                                    <svg
                                      className="flex-shrink-0 w-5 h-5 text-purple-500 dark:text-purple-400"
                                      fill="currentColor"
                                      viewBox="0 0 20 20"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        fill-rule="evenodd"
                                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                        clipRule="evenodd"
                                      ></path>
                                    </svg>
                                  </span>
                                  <h3 className="text-sm font-medium leading-5">
                                    {post.title}
                                  </h3>
                                </div>

                                <ul className="mt-1 flex space-x-1 text-xs font-normal leading-4 text-gray-500">
                                  <li>{post.data}</li>
                                </ul>
                                <Link
                                  to="/e-commerce-app-development"
                                  className={classNames(
                                    "absolute inset-0 rounded-md",
                                    "ring-blue-400 focus:z-10 focus:outline-none focus:ring-2"
                                  )}
                                ></Link>
                              </li>
                            </ul>
                          ))}
                        </div>
                      </Tab.Panel>
                    ))}
                  </Tab.Panels>
                </Tab.Group>
              </div>
            </section>
            <Trusted />
            <Faq faqs={accordionData} />
            <FloatingWp />
            <Footer />
            <StickyEnquryForm />
            <PopUoForm />
          </div>
        </div>
      )}
    </>
  );
};

export default HotelBooking;
