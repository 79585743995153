/*eslint-disable*/
import React from "react";
import { Link } from "react-router-dom";
import Logo from "../../assets/img/logo.png";
import "../../index.css";
// components

import { Player } from "@lottiefiles/react-lottie-player";
// import Lottie from "react-lottie";

import IndexDropdown from "components/Dropdowns/IndexDropdown.js";
// import SmartRing from "assets/lotties/smart-ring.json";
import SmartRing from "assets/logo/calling_anim.svg";
import MobileDrawer from "./MobileDrawer.js";
import ProgressBar from "components/Projects/ProgressBar.jsx";

export default function IndexNavbar() {
  const [sticky, setSticky] = React.useState(false);

  const handleStickyNavbar = () => {
    if (window.scrollY >= 80) {
      setSticky(true);
    } else {
      setSticky(false);
    }
  };

  React.useEffect(() => {
    window.addEventListener("scroll", handleStickyNavbar);

    return () => {
      window.removeEventListener("scroll", handleStickyNavbar);
    };
  }, []);

  return (
    <>
      <nav
        className={`header top-0 left-0 z-40 flex w-full items-center bg-transparent ${
          sticky
            ? "!fixed !z-40 !bg-white !bg-opacity-80 shadow-sticky backdrop-blur-sm !transition dark:!bg-primary dark:!bg-opacity-20"
            : "absolute"
        }`}
      >
        <ProgressBar />
        <div className="container max-w-screen-xl px-4 py-4 md:py-2 mx-auto flex  items-center">
          <div className="w-full relative flex justify-between lg:hidden">
            <div className=" my-auto">
              <div className="block">
                {" "}
                <div className="block rounded-sm bg-[#44619d]">
                  {" "}
                  <Link to={"/"}>
                    <img
                      src={Logo}
                      className=" w-20 h-auto md:w-24"
                      alt="WhizTechnology Logo"
                    />
                  </Link>
                </div>
              </div>
            </div>

            <div className="flex items-center relative lg:hidden">
              {/* <TalkToUs modal={modal} /> */}
              <div className="mr-2">
                <div className="relative">
                  <div className=" relative">
                    <img
                      src={SmartRing}
                      alt=""
                      className="w-auto h-5"
                      srcset=""
                    />
                  </div>
                  <div class="sc-4d58cf57-1 sc-4d58cf57-2 hKYdPO ewAERw pos-abt top-0 bg-green rounded text-center">
                    <strong class="f-size-8 align-v-center">1</strong>
                  </div>
                </div>
              </div>
              <div className="flex text-xs md:text-xl text-black">
                <Link to={"tel:919560322639"}>+91-9560 322639</Link>
              </div>
            </div>
            <div className="flex flex-row justify-center items-center font-semibold lg:hidden">
              <MobileDrawer />
            </div>
          </div>
          <div
            className="hidden mx-auto lg:block lg:w-[1200px] "
            id="example-navbar-warning"
          >
            <div className="flex justify-between items-center bg-white lg:bg-opacity-0 lg:shadow-none">
              {" "}
              <div className="flex">
                <Link
                  to={"/"}
                  className="logo rounded-md font-bold leading-relaxed inline-block mr-4 py-2 whitespace-nowrap uppercase bg-[#44619d]"
                >
                  <img
                    src={Logo}
                    className=""
                    alt="Whiz Technology"
                    width={200}
                    height={100}
                  />
                </Link>
              </div>
              <div className="flex flex-row space-x-6 justify-between items-center relative">
                <div className="flex mob-nav items-center cursor-pointer hover:text-slate-500 text-slate-700 css-t6bsbx text-sm  font-normal">
                  <Link to={"/"}>Home</Link>
                </div>
                <div className="flex mob-nav items-center cursor-pointer hover:text-slate-500 text-slate-700 text-sm css-1vfsv3y  font-normal">
                  <IndexDropdown />
                </div>

                <div className="flex mob-nav items-center cursor-pointer hover:text-slate-500 text-slate-700 text-sm css-1vfsv3y  font-normal">
                  <Link to={"/about-us"}>About Us</Link>
                </div>
                <div className=" cursor-pointer xs:py-1  mob-nav hover:text-slate-500 text-slate-700 flex items-center text-sm css-1vfsv3y font-normal">
                  <Link to={"/smart-products"}>Smart Products</Link>
                </div>
                <div className=" cursor-pointer xs:py-1 mob-nav hover:text-opacity-10 text-slate-700 css-1vfsv3y flex items-center text-sm font-normal">
                  <Link to={"/media-works"}>Media Works</Link>
                </div>
              </div>
              <div className="flex items-center relative ">
                <div className="flex items-center">
                  <div className="mr-2">
                    <div className="relative">
                      <div className=" relative">
                        <img src={SmartRing} alt="" srcset="" />
                      </div>
                      <div class="sc-4d58cf57-1 sc-4d58cf57-2 hKYdPO ewAERw pos-abt top-0 bg-green rounded text-center">
                        <strong class="f-size-8 align-v-center">1</strong>
                      </div>
                    </div>
                  </div>

                  <div className="flex flex-col hZytXO ">
                    <Link
                      to={"mailto:info.whiztechnology@gmail.com"}
                      className="text-xs"
                    >
                      info.whiztechnology@gmail.com
                    </Link>
                    <div className="flex m-auto  ">
                      <a
                        href="tel:919560322639"
                        className="font-medium text-[16px]"
                      >
                        +91-9560 322639
                      </a>
                      <div className="flex flex-row items-center">
                        <span className="flex mx-1 text-[12px]">| </span>
                        <span className="flex text-[12px] ">
                          {" "}
                          Talk To Expert
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
}
