import React from "react";
import DownloadSvg from "assets/images/backgroundSvg.svg";
import DeliveryPng from "assets/images/delivery.png";

const AdsSection = () => {
  return (
    <>
      <section className=" max-w-screen-2xl mx-auto px-4 sm:px-10">
        <div className="lg:p-16 p-6 bg-[#44619d] shadow-sm border rounded-lg">
          <div className="w-full bg-white shadow-sm lg:px-10 lg:py-5 p-6 rounded-lg">
            <div className="flex justify-between items-center">
              <div class="lg:w-3/5">
                <span class="text-base lg:text-lg">
                  Best Quality Products and Gadgets
                </span>
                <h2 class="font-serif text-lg lg:text-2xl font-bold mb-1">
                  Quick Delivery to Your
                  <span class="text-emerald-500"> Home </span>&
                  <span class="text-emerald-500"> Office</span>
                </h2>
                <p class="text-sm font-sans leading-6">
                  {" "}
                  Explore our wide range of products on our website. Use our
                  user-friendly interface to browse through categories, filter
                  options, and read detailed product descriptions. Add your
                  desired items to the cart and proceed to checkout.
                </p>
                <a
                  class="lg:w-1/4 text-xs font-serif font-medium inline-block mt-5 px-8 py-3 bg-[#44619d] text-center text-white rounded-full hover:text-white hover:bg-emerald-600"
                  href="/contact-us"
                >
                  Enquire Now !
                </a>
              </div>
              <div className="w-1/5 flex-grow hidden lg:flex md:flex md:justify-items-center lg:justify-end">
                <span className=" box-border inline-block overflow-hidden relative max-w-full">
                  <span className="box-border inline-block overflow-hidden relative max-w-full">
                    <img
                      src={DownloadSvg}
                      className="block max-w-full"
                      alt="Products"
                    />
                  </span>
                  <img
                    src={DeliveryPng}
                    alt="On Time Delivery"
                    className="absolute inset-0 box-border block m-auto max-w-full min-w-full min-h-full max-h-full"
                  />
                </span>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AdsSection;
