import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { useCart } from "components/utils/Products_api";
import { ProductLine } from "components/utils/Products_api";
import ViewProduct from "./ViewProduct";

export default function SmartProducts() {
  let [isOpen, setIsOpen] = React.useState(false);

  const { viewProduct, viewItems } = useCart();

  function openModal() {
    setIsOpen(true);
  }

  const handleViewProduct = (product) => {
    viewProduct(product);
    openModal();
  };

  function closeModal() {
    setIsOpen(false);
    viewItems([]);
  }

  return (
    <>
      <section className=" max-w-screen-xl mx-auto mb-10">
        <div className="block z-3 justify-center item-center m-auto w-[50%]">
          <p className="mt-4 text-lg font-semibold mb-8 leading-relaxed text-slate-500 justify-center text-center capitalize">
            Buy Refurbished / Use Laptops Online
          </p>
        </div>
        <hr className="bg-[#44619d] h-[5px] w-[20%] mx-auto rounded-[20px] mb-[20px]" />

        <div className="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-5 gap-4 place-content-center ">
          {ProductLine.map((product) => (
            <Card
              key={product.id}
              sx={{
                backgroundColor: "white",
              }}
            >
              <CardContent
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  marginX: "12",
                  pb: 0,
                }}
              >
                <div className="flex flex-col justify-center items-center space-y-3 mb-4">
                  <div className="bg-transparent max-w-fit p-2 hover:bg-white rounded-md">
                    <img
                      src={product.imgSrc}
                      alt={product.title}
                      className="w-40 h-40"
                    />
                  </div>
                  <p className=" text-base text-center text-black font-semibold">
                    {product.title}
                  </p>
                  <button
                    type="button"
                    class="text-white bg-gradient-to-r from-blue-500 via-blue-600 to-blue-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg text-sm px-4 py-1.5 text-center mb-2 text-[10px]"
                    onClick={() => handleViewProduct(product)}
                  >
                    Enquire Now !
                  </button>
                </div>{" "}
              </CardContent>
            </Card>
          ))}
        </div>
        <ViewProduct
          isOpen={isOpen}
          closeModal={closeModal}
          openModal={openModal}
        />
      </section>
    </>
  );
}
