import Quality from "assets/logo/repair-tools.png";
import Increment from "assets/logo/increase.png";
import filePng from "assets/logo/document.png";
import Prototype from "assets/logo/no-data.png";
import RetailSvg from "assets/svgs/retail.svg";
import StudentSvg from "assets/svgs/student.svg";
import HealthcareSvg from "assets/svgs/healthcare.svg";
import LogisticSvg from "assets/svgs/logistic.svg";
import NetworkingSvg from "assets/svgs/networking.svg";
import RealEstateSvg from "assets/svgs/real-estate.svg";
import TravelSvg from "assets/svgs/travel.svg";
import Food from "assets/svgs/food.svg";
import Sofa from "assets/logo/sofa.png";
import Interior from "assets/logo/sketch.png";
import branging_svg from "assets/logo/reshot-icon-branding-RD356ZUGE2.svg";
import webdesign_svg from "assets/logo/reshot-icon-web-design-WKP27CZXR9.svg";
import app_svg from "assets/logo/reshot-icon-app-rating-RE7AWMZUKH.svg";
import graphic_svg from "assets/logo/reshot-icon-vector-TCAWP4NZ5X.svg";

export const webData = [
  {
    id: 1,
    titlle: "Mobile App Development",
    description:
      "Deliver great service experiences fast - without the complexity of traditional solutions. Accelerate critical development work, eliminate toil, and deploy changes with ease.",
    listData: [
      {
        id: 1,
        content: "ERP Development",
        to: "",
      },
      {
        id: 2,
        content: "ERP Development",
      },
    ],
  },
];

export const accordionData = [
  {
    id: 1,
    title: "Q1: What is a web service?",
    data: "A web service is a software system designed to enable communication and interoperability between different applications over a network, typically the internet. It allows applications to exchange data and perform various operations using standard protocols such as HTTP and XML. Web services are commonly used for integrating different systems, sharing data, and facilitating communication between applications developed on different platforms.",
  },
  {
    id: 2,
    title: "Q2: What are the advantages of using web services?",
    data: "Web services offer several advantages, including: Interoperability: Web services allow applications developed on different platforms to communicate and exchange data seamlessly",
  },
  {
    id: 3,
    title: "Q4: How can I consume a web service in my application?",
    data: `Identify the type of web service (SOAP, REST, GraphQL) and its endpoint URL. Use a programming language or framework that supports web service consumption. Generate or write code to make HTTP requests to the web service endpoint. Handle the responses returned by the web service and parse the data as needed. Integrate the consumed data into your application's logic or user interface.`,
  },
];

export const cardData = [
  {
    id: "01",
    title: "Quick & Safe Booking",
    content:
      "Hotel booking apps allow users to book nearby hotels faster and with more security. They simply need to download the hotel booking app and get the best suggestions nearby.",
  },
  {
    id: "02",
    title: "Brand Awareness",
    content:
      "Apps are one of the most used commodities, so having a hotel booking app will improve your hotel business's brand awareness.",
  },
  {
    id: "03",
    title: "Hassle-free Payment",
    content:
      "Say goodbye to the concern of offline payment management. Hotel booking apps enable hassle-free payment and allow users to pay on the go with a tap.",
  },
  {
    id: "04",
    title: "Streamlined Booking",
    content:
      "With a feature-rich and well-managed, users and business owners do not have to worry about booking management and get updates on upcoming, ongoing, and cancelled bookings.",
  },
  {
    id: "05",
    title: "Customized Push Notifications.",
    content:
      "Keeping the users timely notified of their booking status, upcoming offers, and more will increase your hotel booking app engagement and customer engagement.",
  },
  {
    id: "06",
    title: "Transparent Business Analytics",
    content:
      "While having hotel booking applications, businesses can look at the business analytics and stats clearly to analyze monthly or weekly profit and growth",
  },
];

export const keyFeatures = [
  {
    id: 1,
    icon: filePng,
    title: "Better Online Exposure",
    data: "An effective content strategy generates more engagements and social signals, allowing interaction with a broader online community.",
  },
  {
    id: 2,
    icon: Increment,
    title: "High Search Rankings",
    data: "Increased social media sharing of your content leads to higher website traffic and improved search rankings.",
  },
  {
    id: 3,
    icon: Prototype,
    title: "Specific Audience Targeting",
    data: "Experts analyze niche markets to craft relevant content and ads addressing customers' needs based on their online behavior.",
  },
  {
    id: 4,
    icon: Quality,
    title: "Improved Customer Reach",
    data: "A strong social media marketing strategy increases brand awareness and places your company in front of the right customers.",
  },
  {
    id: 5,
    icon: Quality,
    title: "Complete Brand Control",
    data: "Social media marketing packages are customized to fit your needs and budget, giving you control over branding and content strategy.",
  },
  {
    id: 6,
    icon: Quality,
    title: "Enhanced Customer Trust",
    data: "Using social media for marketing builds a thought leadership brand and creates meaningful connections with followers.",
  },
];

export const TechDevelopmentData = [
  {
    id: 1,
    title: "TBO API Certification",
    to: "#react-development",
  },
  {
    id: 2,
    title: "Agoda API Certification",
    to: "#vue-development",
  },
  {
    id: 3,
    title: "Gallilio API Certification",
    to: "#core-php-development",
  },
  {
    id: 4,
    title: "White Label API Certification",
    to: "#angular-development",
    // TravelPayout => White Lable Api Service
  },
  {
    id: 5,
    title: "Train Booking (IRCTC) API Integration",
    to: "#train-booking-api-integration",
  },
  {
    id: 6,
    title: "PCI Certification",
    to: "#next-development",
  },
  {
    id: 7,
    title: "Payment Getway Certification",
    to: "#ai-development",
  },
  {
    id: 8,
    title: "Database Management",
    to: "#ai-development",
  },
];

export const keyFeatures2 = [
  {
    id: 1,
    title: "Flight Booking Portal",
    icon: branging_svg,
  },
  {
    id: 2,
    title: "Hotel Booking Portal",
    icon: webdesign_svg,
  },
  {
    id: 3,
    title: "Cab Booking Portal",
    icon: app_svg,
  },
  {
    id: 4,
    title: "Train Booking Portal",
    icon: graphic_svg,
  },
];

export const industriesData = [
  {
    id: 1,
    title: "Retail, Ecommerce",
    icon: RetailSvg,
  },
  {
    id: 2,
    title: "Education & e-learning",
    icon: StudentSvg,
  },
  {
    id: 3,
    title: "Healthcare & Fitness",
    icon: HealthcareSvg,
  },
  {
    id: 4,
    title: "Logistics & Distribution",
    icon: LogisticSvg,
  },
  {
    id: 5,
    title: "Social Networking",
    icon: NetworkingSvg,
  },
  {
    id: 6,
    title: "Real Estate",
    icon: RealEstateSvg,
  },
  {
    id: 7,
    title: "Travel & Hospitality",
    icon: TravelSvg,
  },
  {
    id: 8,
    title: "Food & Restaurant",
    icon: Food,
  },
  {
    id: 9,
    title: "Decore & Interior Industry",
    icon: Interior,
  },
  {
    id: 10,
    title: "Furniture Industry",
    icon: Sofa,
  },
];

// All Services Data
export const ServicesData = [
  {
    id: 1,
    title: "TBO API Certification",
    to: "react-development",
    description: "What is a Travel Boutique Online (TBO) API?",
    icon: "https://res.cloudinary.com/whiztechnology/image/upload/v1691053775/jq2yy4za2uf5tjegnc04.png",
    keyPoints: [
      {
        id: 1,
        title: "TBO API Certification",
        points:
          "Let’s keep it simple – to make flight bookings and hotel bookings simple phenomenon, Travel Boutique Online (TBO) API comes into being. As India’s largest B2B travel portal, TBO delivers a wide range of travel portal solutions for the travel agents, tour operators, and travel agents to aid them take their business to new heights. But getting the best possible travel services from one place, Whiz Technology should be on the top of your list.",
      },
      {
        id: 2,
        title: "Virtual DOM optimization",
        points:
          "Being the noteworthy Travel and Technology Company in India, Whiz Technology provides you access to TBO’s flight and hotel’s API by integrating with best Travel Boutique Online API supplies available in the market. Once you integrate TBO into your online travel portal, it will enable you to sell a humongous stock of hotels, flights, and other travel related services to your B2B and B2C portal straight.",
      },
    ],
    apiFeatures: [
      { service: "TBO APi Integration" },
      { service: "TBO API Certification" },
      { service: "TBO From Validation" },
      { service: "TBO Flight Booking" },
      { service: "TBO Form Rules Validation" },
      { service: "TBO Fare Qoute Integration" },
      { service: "TBO Fare Qote Manipulation" },
      { service: "Low Cost Flight Integration" },
    ],
  },
  {
    id: 2,
    title: "AGODA API Certification",
    to: "react-development",
    description:
      "Agoda offer a comprehensive API certification program to empower businesses with the knowledge and skills required to integrate and utilize Agoda's powerful travel API effectively. Our certification ensures that you can seamlessly connect with our vast inventory of hotels, access real-time data, and provide exceptional travel experiences to your customers.",
    icon: "https://res.cloudinary.com/whiztechnology/image/upload/v1691053946/c4xgodb7xy2bwyfg1lqh.png",
    keyPoints: [
      {
        id: 1,
        title: "Unparalleled Access to Global Inventory: ",
        points:
          "Agoda is a leading online travel platform with a vast inventory of hotels worldwide. Our API certification program provides you with the expertise to tap into this extensive inventory, giving your customers access to a wide range of accommodation options across the globe.",
      },
      {
        id: 2,
        title: "In-depth API Training Modules: ",
        points:
          "Our certification program covers a comprehensive range of API modules, including hotel search, availability, rates, bookings, and more. Through detailed training sessions, you will gain a deep understanding of Agoda's APIs, their functionalities, and best practices for seamless integration.",
      },
      {
        id: 3,
        title: "Two-Way Data Binding: ",
        points:
          "We believe in hands-on learning. Our certification program offers practical exercises and real-world examples that allow you to apply your knowledge in real-time scenarios. By working directly with the API and solving practical challenges, you'll develop the skills and confidence to implement Agoda's APIs effectively.",
      },
    ],
    apiFeatures: [
      { service: "Agoda APi Integration" },
      { service: "Agoda API Certification" },
      { service: "Agoda From Validation" },
      { service: "Agoda Flight Booking" },
      { service: "Agoda Form Rules Validation" },
      { service: "Agoda Fare Qoute Integration" },
      { service: "Agoda Fare Qote Manipulation" },
      { service: "Low Cost Flight Integration" },
    ],
  },
  {
    id: 3,
    title: "Galileo API Certification",
    to: "react-development",
    description:
      "Galileo GDS (Global Distribution System) API integration allows you to access and leverage the comprehensive travel content provided by the Galileo GDS platform. By integrating the Galileo GDS API into your application, you can access real-time travel data, including flight availability, fares, hotel bookings, car rentals, and more. This integration enables you to provide your users with a seamless and efficient travel booking experience.",
    icon: "https://res.cloudinary.com/whiztechnology/image/upload/v1691053934/u7blovqovz2zwnsrhiuz.png",
    keyPoints: [
      {
        id: 1,
        title: "Unparalleled Access to Global Inventory: ",
        points:
          "By integrating the Galileo GDS API, you can offer your users access to a vast inventory of travel content and streamline the booking process, enhancing the overall travel experience through your application.",
      },
      {
        id: 2,
        title: "Comprehensive Travel Content: ",
        points:
          "The Galileo GDS API provides access to a wide range of travel content, including flights, hotels, car rentals, and more. By integrating this API, you can offer your users an extensive inventory of travel options.",
      },
      {
        id: 3,
        title: "Seamless Booking Experience: ",
        points:
          "With Galileo GDS API integration, you can streamline the booking process for your users. They can search for flights, compare fares, view hotel options, and make reservations directly through your application, creating a seamless and convenient booking experience.",
      },
      {
        id: 4,
        title: "Customization and Personalization: ",
        points:
          "The API enables you to customize and personalize the travel options presented to your users. You can apply filters, sorting options, and specific preferences to tailor the search results based on their requirements, enhancing the user experience.",
      },
    ],
    apiFeatures: [
      { service: "Galileo APi Integration" },
      { service: "Galileo API Certification" },
      { service: "Galileo From Validation" },
      { service: "Galileo Flight Booking" },
      { service: "Galileo Form Rules Validation" },
      { service: "Galileo Fare Qoute Integration" },
      { service: "Galileo Fare Qote Manipulation" },
      { service: "Low Cost Flight Integration" },
    ],
  },
  {
    id: 4,
    title: "White Label API Certification",
    to: "react-development",
    description:
      "White label APIs provide a convenient way for businesses to enhance their website or application with additional functionality and services without having to build them from scratch. Here's some content explaining white label APIs for websites:",
    icon: "https://res.cloudinary.com/whiztechnology/image/upload/v1691053918/mkruky1uw6gnbmohoad3.png",
    keyPoints: [
      {
        id: 1,
        title: "Introduction: ",
        points:
          "White label APIs are pre-built software solutions developed by third-party providers that businesses can integrate into their websites under their own brand. These APIs allow businesses to offer a range of services and features without investing significant time and resources into developing them internally.",
      },
      {
        id: 2,
        title: "Customization and Branding: ",
        points:
          "With white label APIs, businesses can customize the look, feel, and functionality of the integrated services to match their website's branding. This ensures a seamless user experience and maintains consistency with the overall website design.",
      },
      {
        id: 3,
        title: "Scalability and Flexibility: ",
        points:
          "White label APIs are designed to scale with the growth of a business. They offer flexibility in terms of usage and pricing models, allowing businesses to align the API's usage with their specific needs and budget. As businesses expand, they can easily scale up their usage or integrate additional APIs to meet evolving requirements.",
      },
      {
        id: 4,
        title: "Integration and Support: ",
        points:
          "White label APIs typically come with comprehensive documentation and developer support, making it easier for businesses to integrate them into their websites. The provider offers technical assistance and guidance to ensure a smooth integration process and ongoing support for any issues that may arise.",
      },
    ],
    apiFeatures: [
      { service: "White Label APi Integration" },
      { service: "White Label API Certification" },
      { service: "White Label From Validation" },
      { service: "White Label Flight Booking" },
      { service: "White Label Form Rules Validation" },
      { service: "White Label Fare Qoute Integration" },
      { service: "White Label Fare Qote Manipulation" },
      { service: "Low Cost Flight Integration" },
    ],
  },
  {
    id: 5,
    title:
      "Payment Card Industry Data Security Standard (PCI DSS) Certification",
    to: "react-development",
    description:
      "PCI certification refers to the Payment Card Industry Data Security Standard (PCI DSS) certification. It is a set of security standards designed to protect cardholder data during credit card transactions and ensure the secure handling of sensitive information by businesses and organizations.",
    icon: "https://res.cloudinary.com/whiztechnology/image/upload/v1691053895/awvy7ea4t0mlclgwdqto.png",
    keyPoints: [
      {
        id: 1,
        title: "Introduction: ",
        points:
          "The PCI DSS certification is essential for any business that accepts, processes, stores, or transmits credit card information. Achieving PCI compliance involves implementing a series of security measures and best practices to safeguard cardholder data and maintain a secure environment for payment transactions.",
      },
      {
        id: 2,
        title: "Customization and Branding: ",
        points:
          "PCI DSS sets out a comprehensive framework of security requirements, including measures such as network security, access control, encryption, vulnerability management, and regular monitoring and testing of systems. These standards aim to prevent data breaches and protect cardholder information from unauthorized access or misuse.",
      },
      {
        id: 3,
        title: "Scalability and Flexibility: ",
        points:
          "To become PCI compliant, businesses must undergo a validation process that assesses their adherence to the security standards. This process typically involves completing a self-assessment questionnaire (SAQ) or, depending on the business's size and transaction volume, conducting an annual onsite assessment by a qualified security assessor (QSA).",
      },
      {
        id: 4,
        title: "Integration and Support: ",
        points:
          "PCI certification ensures that businesses have implemented robust security measures to protect cardholder data. This includes encrypting sensitive information during transmission and storage, restricting access to cardholder data on a need-to-know basis, and regularly monitoring systems for vulnerabilities or potential security breaches.",
      },
      {
        id: 5,
        title: "Integration and Support: ",
        points:
          "Obtaining PCI certification demonstrates a commitment to data security and instills trust in customers, partners, and stakeholders. It assures them that their payment card information is handled and processed securely, reducing the risk of fraud or data breaches that could harm individuals or businesses.",
      },
    ],
    apiFeatures: [
      { service: "Security Standards" },
      { service: "Compliance Validation" },
      { service: "Cardholder Data Protection" },
      { service: "Trust and Confidence" },
      { service: "Legal and Financial Implications" },
      { service: "Ongoing Compliance" },
    ],
  },
  {
    id: 6,
    title: "Payment Getway Certification",
    to: "react-development",
    description:
      "Payment gateway certification refers to the process of ensuring that a payment gateway meets specific standards and requirements set by regulatory bodies and industry organizations. It involves assessing the security, reliability, and compliance aspects of a payment gateway to ensure it can securely process online transactions and protect sensitive customer payment information.",
    icon: "https://res.cloudinary.com/whiztechnology/image/upload/v1691053875/ezrcsczbplkoyt5ezdke.png",
    keyPoints: [
      {
        id: 1,
        title: "Introduction: ",
        points:
          "Payment gateway certification involves adhering to industry-standard security protocols and implementing robust security measures. This includes encryption of sensitive data during transmission, secure storage of customer payment information, protection against unauthorized access, and adherence to data protection regulations such as the General Data Protection Regulation (GDPR) or Payment Card Industry Data Security Standard (PCI DSS).",
      },
      {
        id: 2,
        title: "Customization and Branding: ",
        points:
          "Payment gateways need to comply with specific regulatory requirements and industry standards based on the regions and countries they operate in. These requirements may include obtaining licenses, certifications, or approvals from regulatory bodies such as financial institutions or card associations. Adhering to these regulations ensures that the payment gateway operates within legal boundaries and provides a secure environment for online transactions.",
      },
      {
        id: 3,
        title: "Scalability and Flexibility: ",
        points:
          "A certified payment gateway should be reliable and available to process transactions without interruptions. It should have redundant systems, backup mechanisms, and failover capabilities to ensure continuous service availability. This includes implementing disaster recovery plans, redundant data centers, and robust infrastructure to handle peak transaction volumes and prevent downtime.",
      },
      {
        id: 4,
        title: "Integration and Support: ",
        points:
          "In summary, payment gateway certification involves meeting security standards, complying with regulatory requirements, ensuring reliability and availability, supporting seamless integration, implementing fraud prevention measures, and providing excellent customer service. Obtaining certification demonstrates a commitment to maintaining a secure payment environment and instills confidence in merchants and customers using the payment gateway for online transactions.",
      },
    ],
    apiFeatures: [
      { service: "Security Standards" },
      { service: "Reliability & Availability" },
      { service: "Integration & Compatibility" },
      { service: "Trust & Confidence" },
      { service: "Fraud Prevention & Risk Management" },
      { service: "Support & Customer Service" },
    ],
  },
  {
    id: 7,
    title: "Databse Management System (DBMS)",
    to: "react-development",
    description:
      "A database management system (DBMS) is a software tool that helps organize, store and retrieve data from a database. It involves a number of functions that collectively work together to ensure that the data is accurate, available and accessible.",
    icon: "https://res.cloudinary.com/whiztechnology/image/upload/v1691053873/tuwtsm1im9biaqujowzs.png",
    keyPoints: [
      {
        id: 1,
        title: "Introduction: ",
        points:
          "DBMS provides a structured approach to organizing data. It defines data models and schemas to represent the logical structure of the database. Common data models include relational, hierarchical, network, and object-oriented models. The DBMS manages the storage and retrieval of data based on these models, allowing efficient access and manipulation of data.",
      },
      {
        id: 2,
        title: "Customization and Branding: ",
        points:
          "DBMS provides data independence, which means the application programs or users accessing the database are isolated from the physical storage and organization of data. This allows changes to the database structure or storage mechanisms without affecting the application programs, enhancing flexibility and adaptability.",
      },
      {
        id: 3,
        title: "Scalability and Flexibility: ",
        points:
          "DBMS ensures data integrity by enforcing data constraints and validation rules. It prevents the entry of invalid or inconsistent data by enforcing integrity constraints, such as primary key uniqueness, foreign key relationships, and data type constraints. DBMS also supports transactions and ACID properties (Atomicity, Consistency, Isolation, Durability) to maintain data consistency and reliability.",
      },
      {
        id: 4,
        title: "Integration and Support: ",
        points:
          "DBMS includes security mechanisms to protect sensitive data from unauthorized access, modification, or disclosure. It allows defining access controls and permissions at various levels, such as user authentication, role-based access control, and encryption. DBMS also provides audit trails and logging features to track and monitor database activities.",
      },
      {
        id: 5,
        title: "Integration and Support: ",
        points:
          "DBMS offers a query language, such as SQL (Structured Query Language), for querying and manipulating data. Users can retrieve data using various query operations, such as SELECT, JOIN, and aggregate functions. DBMS optimizes query execution by analyzing query plans and accessing data efficiently through indexing and query optimization techniques.",
      },
    ],
    apiFeatures: [
      { service: "Data Integrity & Data Security" },
      { service: "Transaction Management" },
      { service: "Data Concurrency" },
      { service: "Data Querying & Manipulation" },
      { service: "Data Backup & Recovery" },
      { service: "Data Administration & Maintenance" },
    ],
  },
];
