import React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const Faq = (props) => {
  return (
    <section className="max-w-screen-xl mx-auto my-6">
      <div className="px-4 pb-8 lg:px-6">
        <h2 className="mb-6 text-base md:text-2xl font-semibold text-center text-gray-900 lg:mb-8 lg:text-2xl dark:text-white">
          Frequently asked questions (FAQ's?)
        </h2>
        <div className="max-w-screen-md mx-auto">
          {props.faqs.map(({ id, title, data }) => (
            <Accordion
              key={id}
              sx={{
                backgroundColor: "transparent",
                border: "none",
                boxShadow: "none",
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                className="border-none shadow-none"
              >
                <Typography className="border-none shadow-none">
                  {title}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>{data}</Typography>
              </AccordionDetails>
            </Accordion>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Faq;
