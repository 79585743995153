/*eslint-disable*/
import React, { useState, useEffect } from "react";
import RigthBg from "assets/img/bg-curved-top-header.svg";
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import Footer from "components/Footers/Footer.js";
import PopUoForm from "components/Forms/PopUoForm";
import Corousel from "components/Corousel/Corousel.jsx";
import MoreService from "components/Services/MoreService.jsx";
import VideoSection from "components/Services/VideoSection";
import DevServices from "components/Development/DevServices";
import TeamServices from "components/Development/TeamServices";
import IndexHeader from "components/Headers/IndexHeader";
import ExpServices from "components/Development/ExpServices";
import StickyEnquryForm from "components/Forms/StickyEnquryForm.jsx";
import Trusted from "components/Footers/Trusted";
import FloatingWp from "components/Development/FloatingWp";
import ScrollToTopbtn from "components/Development/ScrollToTopbtn";
import MetaHelmet from "components/Development/MetaHelmet";
import Preloader from "components/Development/Preloader";
import BeforeAfter from "components/Development/BeforeAfter";
export default function Index() {
  const [isLoading, setisLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setisLoading(false);
    }, 1000);
  }, []);

  return (
    <>
      {isLoading === true ? (
        <Preloader />
      ) : (
        <div>
          <MetaHelmet
            title="Software and Website Development Company"
            descriptionContent="We are a professional software and website development company that specializes in delivering high-quality solutions tailored to your business needs. Our team of experienced developers and designers will work closely with you to create a website or software application that is visually appealing, user-friendly, and functional. Contact us today for all your website and software development requirements."
            keywordsContent="website development, software development, development company, professional solutions, experienced developers, designers, tailored solutions, business needs, visually appealing, user-friendly, functional, website design, software application, development requirements"
          />
          <div className="fixed bg-hero-pattern bg-fixed bg-cover bg-center inset-0 -z-20"></div>
          <div className="App relative w-full">
            <IndexNavbar fixed />
            <div className=" max-w-screen-xl mx-auto">
              <IndexHeader />
              <img
                className="absolute w-full top-0 b-auto right-0 mt-0 md:-mt-48 sm:mt-0 -z-10"
                src={RigthBg}
                alt="Right Vector"
              />
            </div>
            <section className="max-w-screen-xl mx-auto py-5 lg:py-10">
              <div className=" mx-auto text-center max-w-xs md:max-w-2xl">
                <h4 className="mt-4 text-xs md:text-base font-semibold mb-8 leading-normal text-blueGray-500 justify-center text-center">
                  Our strategic partnership with global technology leaders and
                  market innovators helps us provide compelling customer
                  experience and unparalleled business solutions.
                </h4>
              </div>
              <hr className="bg-[#44619d] h-1 w-1/3 mx-auto rounded-[20px] mt-4 mb-4" />
              <Corousel />
            </section>
            <ExpServices />
            <section className="max-w-screen-xl mx-auto">
              <DevServices />
            </section>
            <BeforeAfter />
            <TeamServices />
            <section className="mt-10 mx-auto sm:mt-20 pb-6 relative max-w-screen-xl bg-transperent">
              <MoreService />
            </section>
            {/* <section className="mt-10 sm:mt-20 mx-auto pb-6 relative bg-transperent overflow-hidden">
              <VideoSection />
            </section> */}
            <StickyEnquryForm handlePageChange="handlePageChange" />

            <ScrollToTopbtn />
            <PopUoForm />
            <FloatingWp />
            <Trusted />
            <Footer />
          </div>
        </div>
      )}
    </>
  );
}
