import React from "react";

const Preloader = () => {
  return (
    <>
      <div class="preloader-area text-center left-0 right-0 top-0 bottom-0 bg-white fixed z-9999">
        <div class="preloader absolute -mt-20 left-0 right-0 top-1/2 m-auto -translate-y-2/4">
          <div class="waviy font-bold text-50px space-x-2">
            <span class="text-black text-2xl md:text-4xl inline-block relative">
              W
            </span>
            <span class="text-black text-2xl md:text-4xl inline-block relative">
              H
            </span>
            <span class="text-black-color text-2xl md:text-4xl inline-block relative">
              I
            </span>
            <span class="text-secondary-color text-2xl md:text-4xl inline-block relative">
              Z
            </span>
            <span class="text-secondary-color text-2xl md:text-4xl inline-block relative">
              &nbsp;
            </span>
            <span class="text-secondary-color text-2xl md:text-4xl inline-block relative">
              T
            </span>
            <span class="text-secondary-color text-2xl md:text-4xl inline-block relative">
              E
            </span>
            <span class="text-secondary-color text-2xl md:text-4xl inline-block relative">
              C
            </span>
            <span class="text-secondary-color text-2xl md:text-4xl inline-block relative">
              H
            </span>
            <span class="text-secondary-color text-2xl md:text-4xl inline-block relative">
              N
            </span>
            <span class="text-secondary-color text-2xl md:text-4xl inline-block relative">
              O
            </span>
            <span class="text-secondary-color text-2xl md:text-4xl inline-block relative">
              L
            </span>
            <span class="text-secondary-color text-2xl md:text-4xl inline-block relative">
              O
            </span>
            <span class="text-secondary-color text-2xl md:text-4xl inline-block relative">
              G
            </span>
            <span class="text-secondary-color text-2xl md:text-4xl inline-block relative">
              Y
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default Preloader;
