import React, { useEffect } from "react";
import "./Progress.css";

export default function ProgressBar() {
  useEffect(() => {
    const updateBar = () => {
      let scrollPos =
        (window.scrollY / (document.body.scrollHeight - window.innerHeight)) *
        100;
      const barEl = document.querySelector(".bar");
      barEl.style.width = `${scrollPos}%`;
    };

    window.addEventListener("scroll", updateBar);
    return () => {
      window.removeEventListener("scroll", updateBar);
    };
  }, []);

  return (
    <body>
      <div className="progress">
        <span className="bar"></span>
      </div>
    </body>
  );
}
