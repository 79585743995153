import React from "react";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";

const PrincipalFeatures = (props) => {
  return (
    <section className="bg-[#bfdbfe]">
      <div className="w-full space-y-4 mx-auto sm:max-w-md md:max-w-2xl lg:max-w-5xl px-2 py-16 sm:px-0">
        <h2 className="mb-4 text-center text-base md:text-xl lg:text-3xl font-semibold tracking-normal leading-normal text-gray-900 dark:text-white">
          Principal Features
        </h2>
        <div className="text-center">
          <Typography variant="subtitle2" gutterBottom>
            {props.description}
          </Typography>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 place-content-center ">
          <div className="grid grid-rows-1 md:grid-rows-2 lg:grid-rows-3 grid-flow-row lg:grid-flow-col gap-4">
            {props.principalFeaturesData
              .slice(0, 3)
              .map(({ id, icon, title, data }) => (
                <Card
                  key={id}
                  sx={{
                    minWidth: 275,
                    backgroundColor: "#dbeafe",
                    borderRadius: 5,
                    ":hover": { backgroundColor: "white" },
                  }}
                >
                  <CardContent
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      marginX: "12",
                      pb: 0,
                    }}
                  >
                    <div className="flex flex-row items-center space-x-3 mb-4">
                      <div className="bg-transparent max-w-fit hover:bg-white rounded-md">
                        {icon}
                      </div>
                      <p className=" text-base md:text-xl text-slate-400 font-semibold">
                        {title}
                      </p>
                    </div>
                    <div className="ml-2 text-xs md:text-base">{data}</div>
                  </CardContent>
                </Card>
              ))}
          </div>
          <div className="grid-rows-3 row-span-3 my-auto place-content-center place-items-center">
            <img src={props.image} alt="" className="" />
          </div>
          <div className="grid grid-rows-1 md:grid-rows-2 lg:grid-rows-3 grid-flow-row lg:grid-flow-col gap-4">
            {props.principalFeaturesData
              .slice(-3)
              .map(({ id, icon, title, data }) => (
                <Card
                  key={id}
                  sx={{
                    minWidth: 275,
                    backgroundColor: "#dbeafe",
                    borderRadius: 5,
                    ":hover": { backgroundColor: "white" },
                  }}
                >
                  <CardContent
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      marginX: "12",
                      pb: 0,
                    }}
                  >
                    <div className="flex flex-row items-center space-x-3 mb-4">
                      <div className="bg-transparent max-w-fit p-2 hover:bg-white rounded-md">
                        {icon}
                      </div>
                      <p className="text-xl text-slate-400 font-semibold">
                        {title}
                      </p>
                    </div>
                    <div className="ml-2">{data}</div>
                  </CardContent>
                </Card>
              ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default PrincipalFeatures;
