// UploadComponent.js
import React, { useContext } from "react";
import CloudinaryContext from "./CloudinaryContext.js"; // Import your custom context

const UploadComponent = () => {
  const { cloudName, uploadPreset, apiKey } = useContext(CloudinaryContext); // Access Cloudinary configuration using useContext

  const handleUpload = (result) => {
    // Handle the uploaded image result here (e.g., display or store the image URL)
    console.log(result);
  };

  const openWidget = () => {
    window.cloudinary
      .createUploadWidget(
        {
          cloudName: cloudName, // Use the value from the CloudinaryContext
          uploadPreset: uploadPreset, // Use the value from the CloudinaryContext
          apiKey: apiKey, // Replace with your Cloudinary API Key
          sources: ["local", "url", "camera"], // Sources for the widget
          //   uploadSignature: true, // To use the signature in the unsigned mode
        },
        (error, result) => {
          if (!error && result && result.event === "success") {
            handleUpload(result.info);
          }
        }
      )
      .open();
  };

  return (
    <div className="min-h-screen bg-white flex justify-center items-center">
      <div className="bg-white shadow-md border-t-2 bg-hero-pattern bg-fixed bg-cover bg-center border-blue-500 rounded-lg p-5 w-full md:w-1/2 mx-auto">
        <div className="flex flex-col space-y-3 items-center justify-center">
          <h2>Upload an Image To Cloudinary Server</h2>
          <button
            className="bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded"
            onClick={openWidget}
          >
            Upload Image
          </button>
        </div>
      </div>
    </div>
  );
};

export default UploadComponent;
