import React, { useState, useEffect } from "react";
import { Player } from "@lottiefiles/react-lottie-player";
import searchingLottie from "assets/lotties/96916-searching.json";
import { Link } from "react-router-dom";
import Footer from "components/Footers/Footer";
import Trusted from "components/Footers/Trusted";
import StickyEnquryForm from "components/Forms/StickyEnquryForm";
import IndexNavbar from "components/Navbars/IndexNavbar";
import PopUoForm from "components/Forms/PopUoForm";
import Corousel from "components/Corousel/Corousel";
import { erpServicesData, erpFaq } from "./webPagesData";
import SmartTag from "components/Development/SmartTag";
import FloatingWp from "components/Development/FloatingWp";
import Faq from "components/Development/Faq";
import Preloader from "components/Development/Preloader";
import MetaHelmet from "components/Development/MetaHelmet";

const ErpDevelopment = () => {
  const [isLoading, setisLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setisLoading(false);
    }, 1000);
  }, []);
  return (
    <>
      {isLoading === true ? (
        <Preloader />
      ) : (
        <div className="">
          {" "}
          <MetaHelmet
            title="ERP Development Company In India"
            descriptionContent="Enterprise Resource Planning (ERP) is a comprehensive software solution that integrates and manages all the core business processes and functions of an organization."
            keywordsContent="erp development company, erp solutions, erp bussiness solutions, erp company, erp management, erp product management"
          />
          <div className="fixed bg-hero-pattern bg-fixed bg-cover bg-center inset-0 -z-20"></div>
          <div className="App relative">
            <IndexNavbar />
            <section className="max-w-screen-xl mx-auto">
              <div className="grid px-4 pt-20 pb-8  lg:gap-8 xl:gap-0 lg:py-16 lg:grid-cols-12 lg:pt-28">
                <div className="mr-auto place-self-center lg:col-span-7">
                  <SmartTag
                    title="Best"
                    description="ERP Development Company in India"
                  />
                  <h1 className="max-w-2xl mb-4 font-extrabold leading-normal tracking-tight text-lg lg:text-4xl dark:text-white">
                    ERP (Enterprise Resource Planning) Development{" "}
                  </h1>
                  <p className="max-w-2xl mb-6 font-light text-justify justify-start lg:mb-8 md:text-base lg:text-base leading-normal">
                    Enterprise Resource Planning (ERP) is a comprehensive
                    software solution that integrates and manages all the core
                    business processes and functions of an organization. It
                    enables seamless flow of information and data across
                    different departments, providing real-time insights and
                    facilitating efficient decision-making. ERP systems are
                    designed to streamline operations, improve productivity, and
                    enhance overall business performance.
                  </p>
                  <div className="space-y-4 sm:flex sm:space-y-0 sm:space-x-4">
                    <Link
                      to={"/"}
                      target=""
                      className="inline-block px-6 py-2.5 bg-[#44619d] text-white font-medium text-xs leading-tight uppercase rounded-full shadow-md hover:bg-blue-500 hover:shadow-lg focus:bg-blue-500 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-600 active:shadow-lg transition duration-150 ease-in-out"
                    >
                      Case Study
                    </Link>
                    <Link
                      to={"/contact-us"}
                      className=" mx-2 inline-block px-6 py-2.5 bg-gray-800 text-white font-medium text-xs leading-tight uppercase rounded-full shadow-md hover:bg-gray-900 hover:shadow-lg focus:bg-gray-900 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-gray-900 active:shadow-lg transition duration-150 ease-in-out"
                      target=""
                    >
                      Contact Us
                    </Link>
                  </div>
                </div>
                <div className="hidden lg:mt-0 lg:col-span-5 lg:flex">
                  <img
                    src={
                      "https://res.cloudinary.com/whiztechnology/image/upload/v1691054237/hyrtznw6ueaxulzo726n.png"
                    }
                    alt="heroimage"
                  />
                </div>
              </div>
            </section>
            <section className=" max-w-screen-xl mx-auto">
              <Corousel />
            </section>
            <section className="bg-transparent">
              <div className="max-w-screen-xl backdrop-blur-md shadow-lg bg-[#d8eaff]/30 rounded-md px-4 py-8 mx-auto space-y-6 lg:py-10 lg:px-6">
                {erpServicesData.map(
                  ({ id, title, description, icon, services }) => (
                    <div
                      className="flex flex-col sm:flex-col-reverse last:rounded-lg lg:flex-row lg:odd:flex-row-reverse justify-center items-center p-0 md:p-6"
                      id={id}
                    >
                      <div className="text-gray-500 sm:text-lg p-4 dark:text-gray-400 w-full md:w-1/2">
                        <h2 className="mb-4 text-xl md:text-3xl font-extrabold tracking-tight text-gray-900 dark:text-white">
                          {title}
                        </h2>
                        <h3 className="mb-8 text-justify text-base leading-normal font-light">
                          {description}
                        </h3>
                        <hr class="w-full mt-1 h-1 mb-4 bg-indigo-200" />
                        <ul className="pt-2 space-y-5 border-t border-gray-200 my-7 dark:border-gray-700">
                          {services.map(({ id, key, points }) => (
                            <li className="flex " key={id}>
                              <span className=" cursor-pointer flex space-x-3 hover:underline hover:text-purple-500">
                                {svgIcon()}
                                <div className="flex flex-col space-y-2">
                                  <span className="text-base font-medium leading-tight text-gray-900 dark:text-white">
                                    {key}
                                  </span>
                                  <span className=" text-xs text-black/70">
                                    {points}
                                  </span>
                                </div>
                              </span>
                            </li>
                          ))}
                        </ul>
                        <div className="mt-4">
                          <div className="block text-left">
                            <div className="flex items-center mx-auto w-full">
                              <Player
                                src={searchingLottie}
                                className="player"
                                loop
                                autoplay
                                style={{ height: "50px", width: "50px" }}
                              />
                              <div className="ml-2  item-center">
                                <div className="flex-col text-xs text-blue">
                                  <button className="px-2 py-1 bg-[#44619d] text-white font-medium text-xs leading-tight rounded-full shadow-md hover:bg-blue-500 hover:shadow-lg focus:bg-blue-500 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-600 active:shadow-lg transition duration-150 ease-in-out font-poppins cursor-pointer">
                                    <Link href="/contact-us">
                                      Get a quote ?
                                    </Link>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="w-full md:w-1/2 mb-4 lg:mb-0 lg:flex">
                        {" "}
                        <img
                          className="verticalAnimate"
                          src={icon}
                          alt="ERP Solutions"
                        />
                      </div>
                    </div>
                  )
                )}
              </div>
            </section>
            <section className="max-w-screen-xl mt-10 backdrop-blur-md shadow-lg bg-[#d8eaff]/30 rounded-md px-4 py-8 mx-auto space-y-6 lg:py-10 lg:px-6">
              <div className="py-0 px-0 lg:px-8">
                <h3 className="mb-4 text-lg md:text-2xl font-semibold text-gray-900 dark:text-white">
                  Must-Have Capabilities
                </h3>
                <p className=" text-xs md:text-base mb-4 text-justify justify-start">
                  ERP applications can be loaded with a variety of tools and
                  features, depending on the industry. Let’s look at the
                  must-have features for creating efficient software.
                </p>
                <p className=" text-base font-medium md:text-lg">
                  Hosting Options
                </p>
                <p className="text-xs md:text-base mb-4 text-justify justify-start">
                  Depending on business specifics, companies have different
                  flexibility needs and security requirements, so they should be
                  free to choose where to store their data. A proper enterprise
                  resource planning system should provide all possible options,
                  such as:
                </p>
                <ul className="flex flex-col list-disc space-y-1 ml-2 mb-4 ">
                  <li className=" text-xs md:text-base">on-premise servers</li>
                  <li className=" text-xs md:text-base">cloud storage</li>
                  <li className=" text-xs md:text-base">
                    hybrid integration platforms.
                  </li>
                </ul>
                <p className="text-xs md:text-base mb-4 text-justify justify-start">
                  In this case, companies can decide for themselves what works
                  best for them while having no limitations concerning access,
                  needed space, data protection and maintenance price.
                </p>
              </div>
              <div className="py-0 px-0 lg:px-8">
                <p className=" text-base font-medium md:text-lg">
                  Easy Integration
                </p>
                <p className="text-xs md:text-base mb-4 text-justify justify-start">
                  Because of the modular structure of ERP platforms, they
                  usually incorporate various function-specific solutions, such
                  as CRM and CMS systems, supply chain management, financial, HR
                  management, marketing, manufacturing resource planning and
                  other modules. To avoid silos, such platforms require proper
                  integration of their stand-alone parts, which will enable a
                  holistic overview of all systems and their well-coordinated
                  operation. This also enables an easy and quick migration or
                  updating process.
                </p>
              </div>
              <div className="py-0 px-0 lg:px-8">
                <p className=" text-base font-medium md:text-lg">Scalability</p>
                <p className="text-xs md:text-base mb-4 text-justify justify-start">
                  An ERP system should be scalable to meet the growing
                  requirements of businesses, and easily adapt to any future
                  needs. Otherwise, companies may face problems within a couple
                  of years and will have to either start the process from the
                  very beginning or introduce additional applications,
                  complicating the whole system.
                </p>
              </div>
              <div className="py-0 px-0 lg:px-8">
                <p className=" text-base font-medium md:text-lg">
                  Mobile Compatibility
                </p>
                <p className="text-xs md:text-base mb-4 text-justify justify-start">
                  “Mobile-ization” is a common trend across all industrial
                  sectors. An ideal ERP product is one that allows teams to
                  access a single platform from any device — smartphone, tablet,
                  laptop or desktop.
                </p>
                <p className="text-xs md:text-base mb-4 text-justify justify-start">
                  There are two ways for companies to acquire their own
                  efficient ERP software solutions — getting an out-of-the-box
                  solution followed by its adjusting to a company’s needs, or
                  creating custom ERP software. Read on to learn more about
                  these methods.
                </p>
              </div>
            </section>
            <StickyEnquryForm />
            <Trusted />
            <Faq faqs={erpFaq} />
            <FloatingWp />
            <PopUoForm />
            <Footer />
          </div>
        </div>
      )}
    </>
  );
};

export default ErpDevelopment;

const svgIcon = () => {
  return (
    <svg
      className="flex-shrink-0 w-5 h-5 text-purple-500 dark:text-purple-400"
      fill="currentColor"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
};
