import React, { useState, useEffect } from "react";
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import Footer from "components/Footers/Footer.js";
import PopUoForm from "components/Forms/PopUoForm";
import "../index.css";
import "../App.css";
import ProductVideo from "components/Production-House/ProductVIdeo";
import SmartHeader from "components/Smart-Class/SmartHeader";
import SmartCaraousel1 from "components/Smart-Class/SmartCaraousel1";
import SmartProductsComp from "components/Smart-Class/SmartProductsComp.jsx";
import SmartClassServies from "components/Smart-Class/SmartCLassServices";
import Trusted from "components/Footers/Trusted";
import FloatingWp from "components/Development/FloatingWp";
import MetaHelmet from "components/Development/MetaHelmet.jsx";
import SmartProductGrid from "components/Smart-Class/SmartProductGrid";
import SmartCart from "components/Smart-Class/SmartCart";
import AdsSection from "components/Smart-Class/AdsSection";
import AdsSectionBottom from "components/Smart-Class/AdsSectionBottom";
import Preloader from "components/Development/Preloader";

const SmartProducts = () => {
  const [isLoading, setisLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setisLoading(false);
    }, 1000);
  }, []);

  return (
    <>
      {isLoading === true ? (
        <Preloader />
      ) : (
        <div>
          <MetaHelmet
            title="Smart Classroom Equipment | Smart Classroom | Whiztechnology"
            descriptionContent="Smart Classroom Equipment is a solution designed to help teachers in meeting with new challenges and developing student's abilities and performance."
            keywordsContent="smart classroom, smart classroom equipment, smart classroom equipment price, digital classroom equipment price"
          />
          <div className="absolute bg-hero-pattern bg-fixed bg-cover bg-center inset-0 -z-20"></div>
          <div className="App bg-indigo-200 md:bg-white relative">
            <IndexNavbar fixed />
            <section className="max-w-screen-2xl mx-auto relative">
              <SmartHeader />
            </section>

            <SmartProductGrid />

            <section className="mt-10 hidden md:block mb-10 md:mt-4 pb-6 relative bg-transperent overflow-hidden">
              <SmartProductsComp />
            </section>
            <section className="hidden md:block max-w-screen-xl mx-auto mt-12">
              <SmartCaraousel1 />
            </section>
            <AdsSection />
            <section className="hidden md:block max-w-screen-xl mx-auto mt-12">
              <SmartClassServies />
            </section>
            <AdsSectionBottom />
            <section className="lg:mt-20 lg:mb-20 md:mt-4 pb-6 relative bg-transperent overflow-hidden">
              <ProductVideo />
            </section>
            <SmartCart />
            <PopUoForm />
            <Trusted />
            <Footer />
            <FloatingWp />
          </div>
        </div>
      )}
    </>
  );
};

export default SmartProducts;
