import React from "react";

import { Player } from "@lottiefiles/react-lottie-player";

import { Typography } from "@mui/material";

import Service1 from "assets/production-img/video-editing.json";
import Service2 from "assets/production-img/28784-businessmen-at-the-table.json";
import Service3 from "assets/production-img/34527-watching-tv.json";
import Service4 from "assets/production-img/96761-video-explainer.json";

export default function OurServices() {
  const services = [
    {
      id: 1,
      title: " Video Editing Services",
      desciption:
        "Our producers along with directors and editors convert visions into reality on the editing table. Our video editing services ensure that you get the best packaging and a cohesive structure to narrate your message to the target audiences.",
      image: Service1,
    },
    {
      id: 2,
      title: "Product Documentation",
      desciption:
        "Our approach is not just creative but also tailor-made according to the type of the product and the target audience that our clients are aiming for. Starting from planning and pre-production, our team of experienced writers, directors, researchers and producers conjure up ideas which are best suitable for the product and then along with other team members, they bring this vision on the screen.",
      image: Service2,
    },
    {
      id: 3,
      title: "TV Commercials",
      desciption:
        "Being the leading ad film agency in Delhi, we have worked closely with several Indian and International brands and helped them create an effective communication and marketing tool to increase their brand awareness and to push their businesses to new heights.",
      image: Service3,
    },
    {
      id: 4,
      title: " Short Film Makers",
      desciption:
        "In this digital age, there is no need to adhere to a 280 character limit for conveying your message or to make your customers read lengthy articles about your tools and trends. With a drastic decrease in the attention span of people, delivering your message can be hard and it does not end here, as you do not have to just convey your message but also need to create a long lasting impression with it.",
      image: Service4,
    },
  ];

  return (
    <>
      <section
        className="max-w-screen-xl mx-au
     py-4 px-2"
      >
        {" "}
        <div className="block mt-10 z-3 justify-center item-center m-auto">
          <p className="mt-4 text-lg font-semibold mb-8 leading-relaxed text-slate-500 justify-center text-center">
            Centralize, organize and share customer insights on the platform
            powering the world's best reasearch teams
          </p>
        </div>
        <hr className="bg-[#44619d] h-[5px] w-[20%] mx-auto rounded-[20px] mb-[50px]" />
        <div className="grid grid-cols-1 gap-y-12 sm:grid-cols-2 sm:gap-x-6 lg:grid-cols-4 lg:gap-x-8 justify-center">
          {services.map((item) => (
            <div
              className="flex items-center justify-center text-justify"
              key={item.id}
            >
              {" "}
              <div className="max-w-sm rounded-lg bg-[#0d0d0d] hover:bg-white ease-out duration-1000 shadow z-3 mx-2 hover:drop-shadow-lg">
                <div className=" h-40 flex items-center justify-center">
                  <Player
                    src={item.image}
                    className="player"
                    loop
                    autoplay
                    style={{ height: "150px", width: "150px" }}
                  />
                </div>
                <div className="p-5 ">
                  <a href="/">
                    <h5 className="mb-2 text-md font-semibold font-poppins tracking-tight text-slate-400">
                      {item.title}
                    </h5>
                  </a>
                  <div className="  max-h-56 overflow-y-scroll no-scrollbar">
                    <Typography
                      varient="body2"
                      fontSize={13}
                      gutterBottom
                      className="mb-3 text-slate-400 text-justify"
                    >
                      {item.desciption}
                    </Typography>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </section>
    </>
  );
}
