import React, { useState } from "react";
import { ServicesData } from "./techData.js";
import { Player } from "@lottiefiles/react-lottie-player";
import searchingLottie from "assets/lotties/96916-searching.json";
import { Link } from "react-router-dom";

const AllServices = () => {
  const [showAll, setShowAll] = useState(false);

  const toggleShowAll = () => {
    setShowAll((prevShowAll) => !prevShowAll);
  };

  return (
    <>
      <section className="bg-transparent">
        <div className="max-w-screen-xl backdrop-blur-md shadow-lg bg-[#d8eaff]/30 rounded-md px-4 py-8 mx-auto space-y-6 lg:py-10 lg:px-6">
          {/* <!-- Row --> */}
          {ServicesData.slice(0, showAll ? ServicesData.length : 2).map(
            (item) => (
              <div
                className="flex flex-col sm:flex-col-reverse last:rounded-lg lg:flex-row lg:odd:flex-row-reverse justify-center items-center p-6"
                id={item.to}
              >
                <div className="text-gray-500 sm:text-lg p-4 dark:text-gray-400 w-full md:w-1/2">
                  <h2 className="mb-4 text-xl md:text-3xl font-extrabold tracking-tight text-gray-900 dark:text-white">
                    {item.title}
                  </h2>
                  <h3 className="mb-8 text-justify text-xs md:text-base leading-normal font-normal">
                    {item.description}
                  </h3>
                  <hr class="w-full mt-1 h-1 mb-4 bg-indigo-200" />
                  <ul className="pt-2 space-y-5 border-t border-gray-200 my-7 dark:border-gray-700">
                    {item.keyPoints.map((item) => (
                      <li className="flex ">
                        <span className=" cursor-pointer flex space-x-3 hover:underline hover:text-purple-500">
                          <span>{svgIcon()}</span>
                          <div className="flex flex-col space-y-2">
                            <span className="text-base text-justify justify-start font-normal leading-tight text-gray-900 dark:text-white">
                              {item.points}
                            </span>
                          </div>
                        </span>
                      </li>
                    ))}
                  </ul>
                  <div className=" grid grid-cols-1 md:grid-cols-2 gap-2 place-content-center place-items-center">
                    {item.apiFeatures.map((txt, index) => (
                      <button
                        type="button"
                        class="text-gray-900 bg-white hover:bg-gray-100 border border-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:focus:ring-gray-600 dark:bg-gray-800 dark:border-gray-700 dark:text-white dark:hover:bg-gray-700 mr-2 mb-2 max-w-xl w-full"
                        key={index}
                      >
                        <div className="flex space-x-1 items-center">
                          <span>{svgIcon()}</span>
                          <span className=" text-xs justify-start">
                            {txt.service}
                          </span>
                        </div>
                      </button>
                    ))}
                  </div>
                  <div className="mt-4">
                    <div className="block text-left">
                      <div className="flex items-center mx-auto w-full">
                        <Player
                          src={searchingLottie}
                          className="player"
                          loop
                          autoplay
                          style={{ height: "50px", width: "50px" }}
                        />
                        <div className="ml-2  item-center">
                          <div className="flex-col text-sm italic font-poppins font-medium">
                            {item.services}
                          </div>
                          <div className="flex-col text-xs text-blue">
                            <Link href="/tbo-api-certification">
                              <button className="px-4 py-2 bg-[#44619d] text-white font-medium text-xs leading-tight rounded-full shadow-md hover:bg-blue-500 hover:shadow-lg focus:bg-blue-500 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-600 active:shadow-lg transition duration-150 ease-in-out font-poppins cursor-pointer">
                                Get Integration Qoute ?
                              </button>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="w-full md:w-1/2 p-4">
                  {" "}
                  <img
                    className="w-full verticalAnimate mb-4 rounded-lg lg:mb-0 lg:flex"
                    src={item.icon}
                    alt="eCoomerce App"
                  />
                </div>
              </div>
            )
          )}
          <div className="flex flex-col space-y-3 items-center justify-center">
            <p className="text-xs md:text-base text-center max-w-2xl font-medium text-black/70">
              {showAll
                ? ""
                : "Contact us today to discuss your project and let us be your trusted partner in full stack development!"}
            </p>
            <button
              class="relative inline-flex items-center justify-center p-0.5 mb-2 mr-2 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-purple-600 to-blue-500 group-hover:from-purple-600 group-hover:to-blue-500 hover:text-white dark:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800"
              onClick={toggleShowAll}
            >
              <span class="relative px-5 py-2.5 transition-all ease-in duration-75 bg-white dark:bg-gray-900 rounded-md group-hover:bg-opacity-0">
                {showAll ? "Read Less..." : "Read More..."}
              </span>
            </button>
          </div>
        </div>
      </section>
    </>
  );
};

export default AllServices;

const svgIcon = () => {
  return (
    <>
      <svg
        className="flex-shrink-0 w-5 h-5 text-purple-500 dark:text-purple-400"
        fill="currentColor"
        viewBox="0 0 20 20"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
          clipRule="evenodd"
        ></path>
      </svg>
    </>
  );
};
