import React from "react";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import AppDevelopment from "assets/logo/app_developement.png";
import Java from "assets/logo/java_developement.png";
import NextJs from "assets/logo/next.png";
import Angular from "assets/logo/angular_developement.png";
import UXUI from "assets/logo/ux-design.png";
import Graphic from "assets/logo/photoshop.png";
import SEO from "assets/logo/seo.png";
import DigitalMarketing from "assets/logo/digital_marketing.png";
import ReactJs from "assets/logo/physics.png";
import VueJs from "assets/logo/vuejs.png";
import Wordpress from "assets/logo/wordpress.png";
import Magento from "assets/logo/magento.png";
import Laravel from "assets/logo/Laravel.png";
import PHP from "assets/logo/php.png";
import DotNet from "assets/logo/dotnet.png";

function MoreService() {
  const [showAll, setShowAll] = React.useState(false);

  const toggleShowAll = () => {
    setShowAll((prevShowAll) => !prevShowAll);
  };

  const servicesData = [
    {
      id: 1,
      title: "Android Developement",
      data: "Completed Projects 50+",
      icon: AppDevelopment,
    },
    {
      id: 2,
      title: "Java Development",
      data: "Completed Projects 5+",
      icon: Java,
    },
    {
      id: 3,
      title: "Next Js Development",
      data: "Completed Projects 10+",
      icon: NextJs,
    },
    {
      id: 4,
      title: "Angular Development",
      data: "Completed Projects 50+",
      icon: Angular,
    },
    {
      id: 5,
      title: "UI/UX Design",
      data: "Completed Projects 5000+",
      icon: UXUI,
    },
    {
      id: 6,
      title: "Graphic Desgin",
      data: "Completed Projects 3000+",
      icon: Graphic,
    },
    {
      id: 7,
      title: "Search Engine Optimazation",
      data: "On Wroking Projects 80+",
      icon: SEO,
    },
    {
      id: 8,
      title: "Digital Marketing",
      data: "On Working Projects 150+",
      icon: DigitalMarketing,
    },
    {
      id: 9,
      title: "React Js Develpement",
      data: "Completed Project 50+",
      icon: ReactJs,
    },
    {
      id: 10,
      title: "Vue Js Development",
      data: "Completed Project 100+",
      icon: VueJs,
    },
    {
      id: 11,
      title: "Wordpress Development",
      data: "Completed Project 5000+",
      icon: Wordpress,
    },
    {
      id: 12,
      title: "Magento Development",
      data: "Completed Projects 60+",
      icon: Magento,
    },
    {
      id: 13,
      title: "Laravel Development",
      data: "Completed Projects 50+",
      icon: Laravel,
    },
    {
      id: 14,
      title: "PHP Development",
      data: "Completed Projects 200+",
      icon: PHP,
    },
    {
      id: 15,
      title: ".NET Development",
      data: "Completed Projects 25+",
      icon: DotNet,
    },
    {
      id: 16,
      title: "Lynux Server Development",
      data: "Completed Projects 5+",
      icon: DotNet,
    },
  ];

  return (
    <>
      <section
        tabIndex={1}
        className="relative mx-auto px-4 w-full justify-content focus:outline-none sm:px-3 pb-20 md:px-5"
      >
        <div className="text-center max-w-xs md:max-w-2xl mx-auto">
          <h4 className="mt-4 text-lg font-semibold mb-8 leading-relaxed text-slate-500 capitalize">
            Full Stack Development Company Our Team Understands Local as Well as
            Global Business Markets. Latest Technology
          </h4>
        </div>
        <hr className="bg-[#44619d] h-[5px] w-[20%] mx-auto rounded-[20px] mb-10 sm:mb-20" />
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 place-content-center ">
          {servicesData
            .slice(0, showAll ? servicesData.length : 8)
            .map((item) => (
              <Card
                key={item.id}
                sx={{
                  minWidth: 300,
                  backgroundColor: "#d8eaff",
                  borderRadius: 5,
                  ":hover": { backgroundColor: "white" },
                }}
              >
                <CardContent
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    marginX: "12",
                    pb: 0,
                  }}
                >
                  <div className="flex flex-row items-center space-x-3">
                    <div className="bg-transparent max-w-fit p-2 hover:bg-white rounded-md">
                      <img src={item.icon} alt="" className=" h-10 w-10" />
                    </div>
                    <Typography
                      variant="h6"
                      className=" text-base text-slate-400 font-semibold"
                      fontSize={15}
                    >
                      {item.title}
                    </Typography>
                  </div>
                  <div className="ml-2 text-[14px] text-center">
                    {item.data}
                  </div>
                </CardContent>
              </Card>
            ))}
        </div>
        <div className="flex mt-10 flex-col space-y-3 items-center justify-center mb-4">
          <button
            class="relative inline-flex items-center justify-center p-0.5 mb-2 mr-2 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-purple-600 to-blue-500 group-hover:from-purple-600 group-hover:to-blue-500 hover:text-white dark:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800"
            onClick={toggleShowAll}
          >
            <span class="relative px-5 py-2.5 transition-all ease-in duration-75 bg-white dark:bg-gray-900 rounded-md group-hover:bg-opacity-0">
              {showAll ? "Show Less..." : "Show More..."}
            </span>
          </button>
        </div>
      </section>
    </>
  );
}

export default MoreService;
