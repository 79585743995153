import React from "react";

const FacebookBtn = () => {
  return (
    <iframe
      src="https://www.facebook.com/plugins/like.php?href=https%3A%2F%2Fwww.facebook.com%2Fwhiztechnologyindia&width=450&layout&action&size&share=true&height=35&appId=3871610479520241"
      width="450"
      height="35"
      style={{ border: "none", overflow: "hidden" }}
      scrolling="no"
      frameBorder="0"
      allowFullScreen={true}
      allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
      title="Facebook Like Button"
    ></iframe>
  );
};

export default FacebookBtn;
