import React from "react";
import { Link } from "react-router-dom";
import Scaner from "assets/logo/scanning.png";
import CallManager from "assets/logo/telephone-call.png";
import WhatsApp from "assets/logo/whatsapp.png";
import User from "assets/logo/add-user.png";
import Mock from "assets/images/institute-ad-banner.png";
import ArrowForwardOutlinedIcon from "@mui/icons-material/ArrowForwardOutlined";
import Whizlogo from "assets/logo/whiz-technology-logo.jpeg";
import { Button } from "@material-tailwind/react";

const Scanner = () => {
  return (
    <>
      <Link
        to={"/contact-us"}
        className="flex flex-row w-full space-x-1 px-2 py-1.5 justify-around items-center rounded-xl bg-gradient-to-r from-pink-200 via-opacity-40 to-opacity-70 border-2 border-white mb-4 shadow-lg"
      >
        <span className="text-sm text-black rounded-xl">
          <img src={Scaner} alt="QR Code" srcset="" className="w-12 h-12" />
        </span>
        <span class="text-sm text-black text-center">
          Scan any QR code to pay via{" "}
          <span className="text-xs font-semibold">WhizPro Api</span>
        </span>{" "}
        <span>
          <ArrowForwardOutlinedIcon
            sx={{ color: "black" }}
            className="w-6 h-6"
          />
        </span>
      </Link>

      <div className="mt-4 grid grid-cols-3 place-content-between gap-x-2">
        <div className="shadow-lg rounded-xl bg-white/75  border border-white   p-5">
          <Link
            to={"tel:+91-9560322639"}
            target="_blank"
            className="flex flex-col space-y-2 items-center justify-center"
          >
            <img src={CallManager} alt="call manager" className="w-10 h-10" />
            <p className="text-sm text-center text-black">call manager</p>
          </Link>
        </div>
        <div className="shadow-lg rounded-xl bg-white/75 border border-white  p-5">
          <Link
            className="flex flex-col space-y-2 items-center justify-center"
            to={"https://wa.me/9560322639"}
            target="_blank"
          >
            <img src={WhatsApp} alt="whats'sapp" className="w-10 h-10" />
            <p className="text-sm text-center text-black">what's app</p>
          </Link>
        </div>
        <div className="shadow-lg rounded-xl bg-white/75 border border-white  p-5">
          <Link
            className="flex flex-col space-y-2 items-center justify-center"
            to={"https://www.facebook.com/whiztechnologyindia/"}
            target="_blank"
          >
            <img src={User} alt="refer for rewards" className="w-10 h-10" />
            <p className="text-sm text-center text-black">refer for rewards</p>
          </Link>
        </div>
      </div>

      <Link to={"/school-website-development-company"}>
        <div className="mt-4 rounded-lg bg-white/70 border-2 border-white shadow-lg overflow-hidden">
          <div className="flex flex-col justify-center items-center">
            <img src={Mock} alt="" className="w-full" />
            <div className="flex flex-row w-full space-x-1 px-2 py-1.5 justify-around items-center  bg-white/70 border-2 border-white">
              <img
                src={Whizlogo}
                className="w-10 h-10 rounded-md"
                alt="Whiz Technology"
              />
              <span className="flex flex-col justify-center">
                <span className="!text-[11px] font-bold text-black">
                  Institute Website Development
                </span>
                <span className="!text-[9px] text-black">
                  React out to world
                </span>
              </span>
              <Button
                size="xs"
                variant="text"
                className="flex flex-row !items-center !justify-center uppercase px-2 py-2 gap-1 border border-indigo-500 bg-black text-white text-[10px] font-normal !rounded-md"
              >
                Know More
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 20 20"
                  strokeWidth={2}
                  stroke="currentColor"
                  className="h-3 w-3"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3"
                  />
                </svg>
              </Button>
            </div>
          </div>
        </div>{" "}
      </Link>
    </>
  );
};

export default Scanner;
