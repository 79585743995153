export const erpServicesData = [
  {
    id: 1,
    title: "What Is ERP?",
    icon: "https://res.cloudinary.com/whiztechnology/image/upload/v1691054307/q5mdmf3wpom0a7qgei4e.png",
    description:
      "Deliver great service experiences fast - without the complexity of traditional solutions. Accelerate critical development work, eliminate toil, and deploy changes with ease.",
    services: [
      {
        id: 1,
        key: "Human Resource Management (HRM): ",
        points:
          "Manages employee information, payroll, attendance, and performance evaluations.",
      },
      {
        id: 2,
        key: "Customer Relationship Management (CRM): ",
        points:
          "Handles customer data, sales, marketing, and support activities.",
      },
      {
        id: 3,
        key: "Inventory Management: ",
        points:
          "Tracks and manages inventory levels, orders, and stock movements.",
      },
      {
        id: 4,
        key: "Finance and Accounting: ",
        points:
          "Handles financial transactions, budgeting, invoicing, and reporting.",
      },
      {
        id: 5,
        key: "Supply Chain Management (SCM): ",
        points: "Manages procurement, supplier relationships, and logistics.",
      },
      {
        id: 6,
        key: "Manufacturing: ",
        points:
          "Handles production planning, scheduling, and resource allocation for manufacturing processes.",
      },
      {
        id: 7,
        key: "Project Management: ",
        points:
          "Tracks project progress, resource utilization, and project costs.",
      },
      {
        id: 8,
        key: "Business Intelligence (BI): ",
        points:
          "Provides data analytics and reporting for better decision-making.",
      },
    ],
  },
  {
    id: 2,
    title: "ERP Benefits",
    icon: "https://res.cloudinary.com/whiztechnology/image/upload/v1691054342/w0js6sx3t9jquid5sipw.png",
    description:
      "The ERP system implementation requires a considerable amount of time, financial expenses and the engagement of all departments within the company. Such enormous effort to create the right enterprise resource planning solution pays off with numerous benefits:",
    services: [
      {
        id: 1,
        key: "Reduced product development cost: ",
        points:
          "Effective management and quality assurance systems minimize errors and risks.",
      },
      {
        id: 2,
        key: "Improved collaboration: ",
        points:
          "Information barriers between departments are eliminated as there is a single database.",
      },
      {
        id: 3,
        key: "Management optimization: ",
        points:
          "All processes are controlled via a single center, so no issue can be missed.",
      },
      {
        id: 4,
        key: "Manual labor automation: ",
        points:
          "Smart digital technologies incorporated into ERPs replace humans in many operations.",
      },
      {
        id: 5,
        key: "Data-driven decision-making: ",
        points:
          "Real-time data analysis allows managers to make decisions quicker.",
      },
      {
        id: 6,
        key: "Regulatory compliance: ",
        points:
          "Specialized automated systems are taught to monitor compliance of produced goods with relevant rules and standards.",
      },
      {
        id: 7,
        key: "Increased profitability: ",
        points:
          "The above-mentioned benefits together provide full enterprise visibility and efficient operations, helping reach the common goal of enhancing the output.",
      },
    ],
  },
];

export const eCommerceData = [
  {
    id: 1,
    title: "All Inclusive Solutions for Diversified Ecommerce",
    description: "",
    icon: "https://res.cloudinary.com/whiztechnology/image/upload/v1691054415/quzf5rf4vo03ms4czhor.png",
    features: [
      {
        points:
          "In this competitive world, every business needs to stay ahead of the curve. It demands customized, professional, and enterprise-grade eCommerce mobile app and web development services. We, at Whiz Technology, blend our ecommerce development proficiency with advanced and latest technologies to dig-out the crucial requirements of the clients across industries globally.",
      },
      {
        points:
          "A leading eCommerce development company, Whiz Technology, offers the best eCommerce development services with the required technical consultations and the development plans for their exceptional needs.",
      },
      {
        points:
          "We are the mostly-chosen eCommerce development company holding proven knowledge in eCommerce web design and web development. We have a team of experienced eCommerce developers who offer the best eCommerce Development services to build successful eCommerce development platforms. We target on developing the",
      },
    ],
  },
];

export const eCommerceFeature = [
  {
    id: 1,
    title: "Feature-rich Ecommerce Website Development Services",
    icon: "https://res.cloudinary.com/whiztechnology/image/upload/v1691054453/vpnrikacayv5lrs5aneq.png",
    description:
      "We analyze your business requirements and create sound design which is the best-suited eCommerce website solution for your online store. To lead the tough competition, you should have amazing visual appeal. Whether it is desktop, laptops or any smartphones, we make sure that your eCommerce store functions properly on all devices.",
    keyPoints: [
      {
        pointTitle:
          "HTML5 & CSS3 to enrich responsive interface and Rich-text description for products.",
      },
      {
        pointTitle:
          "Ability to search entire stores, filters and sorting options for products.",
      },
      {
        pointTitle:
          "Unlimited product images with zoom features, facebook login and register.",
      },
    ],
  },
  {
    id: 2,
    title: "Smarter Checkout",
    icon: "https://res.cloudinary.com/whiztechnology/image/upload/v1691054457/fj62n5r94ezjrk5mhxwc.png",
    description:
      "We work with well-strategized methodology. Our approach is to create a user-friendly eCommerce website store with few & easy procedures of checkout. We design efficient & flawless shopping carts with multiple features.",
    keyPoints: [
      {
        pointTitle:
          "Flat Shipping charges / Pin code based shipping charges calculation and free shipping for orders over X amount.",
      },
      {
        pointTitle:
          "Gift wrap option with configurable gift wrap charges and redeem discount coupons.",
      },
      {
        pointTitle:
          "Third party Shipping partner integration like FEDEX, Bluedart and secured Payment Gateway integration.",
      },
    ],
  },
  {
    id: 3,
    title: "Customer Management",
    icon: "https://res.cloudinary.com/whiztechnology/image/upload/v1691054453/kv32a4cersfc7lb1atgh.png",
    description:
      "An eminent eCommerce website development company Angular Minds offers the most flexible and scalable customer management system. We have brought an exceptional management system to fulfill all your expectations.",
    keyPoints: [
      {
        pointTitle:
          "Completely customizable advanced management system with zero obstacles.",
      },
      {
        pointTitle:
          "Wish list, Order tracking features, Order View / print options for customers.",
      },
      {
        pointTitle: "Retry payment option for failed orders",
      },
    ],
  },
  {
    id: 4,
    title: "Content Management",
    icon: "https://res.cloudinary.com/whiztechnology/image/upload/v1691054458/iw2trbwdo6yhiml9ikuc.png",
    description:
      "Our developers and designers served you with numerous add-ons, integrations and support features. We always wanted to better client centric services by delivering flexible models. Here are some features of our content management system.",
    keyPoints: [
      {
        pointTitle: "Full content management system",
      },
      {
        pointTitle:
          "Easy to use WYSIWYG editor with ability to add images and videos",
      },
      {
        pointTitle: "SEO friendly pages",
      },
      {
        pointTitle: "Contact forms, receive responses via emails",
      },
      {
        pointTitle: "Tree view Category Management",
      },
      {
        pointTitle: "Promotional Banners",
      },
    ],
  },
  {
    id: 5,
    title: "Online Payments",
    icon: "https://res.cloudinary.com/whiztechnology/image/upload/v1691054449/yqep2pvbiq8rlwswbkob.png",
    description:
      "For eCommerce stores, Online payment Gateway integration is the ultimate secured and convenient option for payments. You name the payment gateway you want & we get it done for you. Being a reliable eCommerce website designing company in India, we bring your business to the audience all over the globe.",
    keyPoints: [
      {
        pointTitle:
          "Buyers always prefer convenience in payments so we make sure about customers comfortability.",
      },
      {
        pointTitle: "All transactions will be done in the most secured way.",
      },
    ],
  },
  {
    id: 6,
    title: "Web Security",
    icon: "https://res.cloudinary.com/whiztechnology/image/upload/v1691054448/vwk8qwvubkguuomgcicu.png",
    description:
      "Many times browsers do not identify malwares so can you afford to lose your confidential and sensitive data? Angular Minds is one of the best ecommerce development companies in India whose security is considered in each aspect such as UI, addons and many development functionality.",
    keyPoints: [
      {
        pointTitle:
          "We analyze and audit security architecture, identify the gap and fix them to assured protection from malware and other threats.",
      },
      {
        pointTitle:
          "Right from development stage we focus on security enhancing practices.",
      },
    ],
  },
  {
    id: 7,
    title: "Email/SMS Notifications",
    icon: "https://res.cloudinary.com/whiztechnology/image/upload/v1691054448/zg3pfshwpxygeika2agg.png",
    description:
      "With deep industry knowledge and experience, our developers are abreast with every fact of eCommerce website development. Thus to minimize your fake users and orders headache we provide you a practical solution that is email and sms notifications. Customer registration through email verification.",
    keyPoints: [
      {
        pointTitle:
          "After confirmation of order send Order status update notification through Email , SMS to Customers.",
      },
      {
        pointTitle:
          "Configure the order notifications email and SMS templates the way you want.",
      },
      {
        pointTitle:
          "Final checkout through OTP(One Time password) to stop feck order",
      },
    ],
  },
  {
    id: 8,
    title: "Order Processing",
    icon: "https://res.cloudinary.com/whiztechnology/image/upload/v1691054447/e3mwyg1aojkmh77leqj4.png",
    description:
      "Whether it is small eCommerce store or global eCommerce store- Order processing management is crucial. We have proven expertise in eCommerce website development in India to ensure efficient and perfect functioning of order management.",
    keyPoints: [
      {
        pointTitle:
          "Manage various order statuses like Awaiting Pickup, Shipment-in, Transit, Delivered.",
      },
      {
        pointTitle:
          "Configure the order notifications email and SMS templates.",
      },
      {
        pointTitle: "Order status update notification Email , SMS to Customer.",
      },
      {
        pointTitle:
          "Retry Order option for customer for failed payment orders.",
      },
      {
        pointTitle: "Track customer order history, Cancel or refund the orders",
      },
      {
        pointTitle: "Automatic inventory deduction for successful orders.",
      },
      {
        pointTitle:
          "Automatic inventory addition for cancelled and refunded orders.",
      },
    ],
  },
  {
    id: 9,
    title: "Marketing Tools",
    icon: "https://res.cloudinary.com/whiztechnology/image/upload/v1691054449/crycyjn5lgdypztyo6or.png",
    description:
      "Marketing Mechanism is ruling the business world by engaging customers consistently. With the current trend, marketing has reached new heights. With our eCommerce website design and development service you will have opportunities in all angles to improve user enrollments, up-sell and earn customer’s trust.",
    keyPoints: [
      {
        pointTitle:
          "Create Unlimited Discount coupons or Specific coupon for particular Products/Categories or entire store.",
      },
      {
        pointTitle:
          "Download newsletter subscribed customers in excel, Promotional banners.",
      },
      {
        pointTitle: "Integration of Mailchimp of Email Marketing.",
      },
      {
        pointTitle:
          "Share Products on social medias and Marketing Campaign monitoring to analyze campaign performance.",
      },
    ],
  },
  {
    id: 10,
    title: "In-Depth Reports",
    icon: "https://res.cloudinary.com/whiztechnology/image/upload/v1691054447/gv3xrhsyhtfhmf0ckuc9.png",
    description:
      "As a leading ecommerce development company in India, our Advanced Reports gives you a solution that brings your real-time insights on sales, transactions and even updates happening on your website.",
    keyPoints: [
      {
        pointTitle:
          "Graphical weekly sales report on Dashboard & Popular search reports",
      },
      {
        pointTitle: "Top 20 Customers reports",
      },
      {
        pointTitle: "Top Selling Products reports.",
      },
      {
        pointTitle: "Daily, Weekly, Monthly Sales Reports.",
      },
      {
        pointTitle: "Cancelled/Refunded orders reports.",
      },
      {
        pointTitle: "Daily, Weekly, Monthly Sales Reports.",
      },
      {
        pointTitle: "Product wise sales report.",
      },
      {
        pointTitle: "Download all reports in excel.",
      },
    ],
  },
  {
    id: 11,
    title: "Cloud Deployment",
    icon: "https://res.cloudinary.com/whiztechnology/image/upload/v1691054454/abdb4wsw1eikesbud5j0.png",
    description:
      "Experience high-end Amazon Cloud Services at Angular Minds. We are the best ecommerce website development company, equipped with the power of AWS cloud computing to create unique solutions designed to suit your requirements.",
    keyPoints: [
      {
        pointTitle:
          "Pay only for the compute power, storage, & resources you use.",
      },
      {
        pointTitle:
          "End-to-end approach to secure & strengthen infrastructure.",
      },
      {
        pointTitle:
          "You need not worry anymore about being unprepared for traffic fluctuations.",
      },
    ],
  },
  {
    id: 12,
    title: "Technical Support",
    icon: "https://res.cloudinary.com/whiztechnology/image/upload/v1691054449/zvqcqnxbq8bs37zrkyen.png",
    description:
      "We have overcome various industry and/or technological challenges through rigorous internal activities, research and solutions. We offer advanced ecommerce web services through innovative solutions, best business practices and cutting-edge technologies.",
    keyPoints: [
      {
        pointTitle:
          "Any technical or non-technical queries can be clarified with our dedicated support team at your convenience.",
      },
      {
        pointTitle:
          "Queries related to customization will swiftly be diverted to the concerned technical experts.",
      },
    ],
  },
];

export const eCommerceFaq = [
  {
    id: 1,
    title: "Q1: Why do I need an eCommerce website?",
    data: "An eCommerce website allows you to showcase and sell your products or services online, reaching a wider audience and maximizing your sales potential. It provides convenience for your customers to browse and purchase products from the comfort of their homes.",
  },
  {
    id: 2,
    title: "Q2: What features should an eCommerce website have?",
    data: "Key features of an eCommerce website include a user-friendly interface, secure payment gateways, product catalog with detailed descriptions and images, shopping cart functionality, order management system, customer reviews and ratings, search functionality, and mobile responsiveness.",
  },
  {
    id: 3,
    title: "Q3: How long does it take to develop an eCommerce website?",
    data: "The development timeline for an eCommerce website depends on various factors such as the complexity of the project, the number of features required, customization needs, and the availability of content and resources. On average, it can take anywhere from a few weeks to a few months to develop a fully functional eCommerce website.",
  },
  {
    id: 4,
    title: "Q4: Can I customize the design of my eCommerce website?",
    data: "Yes, eCommerce websites can be customized according to your brand identity and preferences. Professional web development agencies offer customization options to ensure that your website reflects your unique branding and provides a seamless user experience.",
  },
  {
    id: 5,
    title: "Q5: How do I manage inventory on an eCommerce website?",
    data: "An eCommerce website can be integrated with an inventory management system that allows you to track and manage your inventory levels, receive notifications for low stock, and update product availability in real-time. This helps in efficient order fulfillment and prevents overselling.",
  },
  {
    id: 6,
    title:
      "Q6: How can I ensure the security of transactions on my eCommerce website?",
    data: "To ensure secure transactions, your eCommerce website should use SSL (Secure Socket Layer) encryption, have a secure payment gateway integration, and comply with PCI DSS (Payment Card Industry Data Security Standard) guidelines. Regular security audits and updates are essential to protect customer information.",
  },
];

export const crmServicesData = [
  {
    id: 1,
    title: "Enterprises",
    icon: "https://res.cloudinary.com/whiztechnology/image/upload/v1691055169/zpwntgs67onoabtwrwnf.png",
    description:
      "We understand that enterprise solution of different sizes conduct business differently. We transform their relationship with their suppliers, vendors and partners via digital solutions.",
    point: "We work with small, medium and large enterprises.",
    keyPoints: [
      { id: 1, keyTitle: "Application Outsourcing" },
      { id: 2, keyTitle: "Enterprise Content Management Systems" },
      { id: 3, keyTitle: "Enterprise Business Automation" },
      { id: 4, keyTitle: "Digital Transformation" },
      { id: 5, keyTitle: "Enterprise Mobility" },
      { id: 6, keyTitle: "Application Integration" },
      { id: 7, keyTitle: "Cloud Computing" },
      { id: 8, keyTitle: "Application Development and Support" },
    ],
  },
  {
    id: 2,
    title: "Agencies",
    icon: "https://res.cloudinary.com/whiztechnology/image/upload/v1691055184/jbnt6tt6s29l2c42k3ss.png",
    description:
      "We work with agencies on a project basis or as an extended team to provide digital solutions.",
    point:
      "We work with consulting companies, software development companies, and agencies.",
    keyPoints: [
      { id: 1, keyTitle: "Offshore and Onsite Team Augmentation" },
      { id: 2, keyTitle: "Technology Migration" },
      { id: 3, keyTitle: "Product Engineering" },
      { id: 4, keyTitle: "Product Maintenance and Support" },
    ],
  },
  {
    id: 3,
    title: "Startups",
    icon: "https://res.cloudinary.com/whiztechnology/image/upload/v1691055206/qntjaqh6y9ipxc44rica.png",
    description:
      "We support startups in bringing their ideas to fruition. We provide our Custom CRM services at every stage of the project.",
    point:
      "We Work with Global Tech Startups to Fulfill Their IT, Digital, IoT and Mobile Requirements",
    keyPoints: [
      { id: 1, keyTitle: "Prototype Design" },
      { id: 2, keyTitle: "Product Engineering" },
      { id: 3, keyTitle: "Product Re-engineering" },
      { id: 4, keyTitle: "Minimum Viable Product" },
      { id: 5, keyTitle: "Product Maintenance And Support" },
      { id: 6, keyTitle: "Quality Assurance And Testing" },
    ],
  },
];

export const crmFaq = [
  {
    id: 1,
    title: "Q1: How much experience do your developers have?",
    data: "All our developers go through a thorough interview process and have over four years of hands-on experience. You can hire dedicated CRM developers from Brainvire. They are proficient in the latest technologies and tools for custom CRM development.",
  },
  {
    id: 2,
    title: "Q2: Will I get dedicated resources for my project?",
    data: "We don't share developers among several projects. If you hire a custom CRM developer, they will work on your project as you need them to. We have flexible hiring models for you to choose from.",
  },
  {
    id: 3,
    title:
      "Q3: How much time will a custom crm software project take to complete?",
    data: "Once you hire a developer for CRM services, we'll start working on your project. The time taken to complete the production and implementation process will depend on your requirements. We will provide an estimated time frame after thoroughly analyzing your business objectives.",
  },
  {
    id: 4,
    title: "Q4: Why should I choose custom CRM development?",
    data: "Custom CRM software enables you to get detailed insights into your business, evaluate your market, tailor your marketing and sales strategies, automate your marketing workflow, and use advanced reporting tools for forecasting and analysis.",
  },
  {
    id: 5,
    title: "Q5: Do you have a portfolio of previous CRM development projects?",
    data: "Indeed, we do. We have created numerous applications and sites with the custom relationship management framework. You can see our case studies and portfolios on this website or get in touch with us for more examples.",
  },
];
export const erpFaq = [
  {
    id: 1,
    title: "Q1: Why should I consider ERP development for my business?",
    data: "ERP systems offer numerous benefits, including improved efficiency, streamlined processes, better data visibility and accuracy, enhanced collaboration, cost savings, and increased productivity. Developing a customized ERP solution allows you to align the system with your specific business needs and gain a competitive advantage.",
  },
  {
    id: 2,
    title: "Q2: How long does it take to develop an ERP system?",
    data: "The development timeline for an ERP system depends on various factors, such as the complexity of your business processes, the scope of functionality required, customization needs, and integration with other systems. Generally, ERP development projects can range from several months to a year or more.",
  },
  {
    id: 3,
    title:
      "Q3: Can an ERP system be tailored to my specific business requirements?",
    data: "Yes, one of the key advantages of ERP development is the ability to customize the system to fit your unique business needs. Customization allows you to incorporate industry-specific features, workflows, and reporting capabilities that align with your organization's processes and objectives.",
  },
  {
    id: 4,
    title: "Q4: Is ERP development expensive?",
    data: "The cost of ERP development varies based on the complexity of the system, the required functionality, customization, and integration needs. While ERP development may involve a significant investment upfront, it is important to consider the long-term benefits and ROI that a well-implemented ERP system can provide.",
  },
  {
    id: 5,
    title: "Q5: How can ERP integration with existing systems be achieved?",
    data: "ERP systems often need to integrate with existing applications such as CRM, accounting software, and inventory management systems. Experienced ERP developers can utilize APIs (Application Programming Interfaces), middleware, and data integration techniques to seamlessly integrate your ERP system with other software solutions.",
  },
  {
    id: 6,
    title: "Q6: Will my employees need training to use the ERP system?",
    data: "Yes, training is an essential part of ERP implementation. Your ERP development partner should provide comprehensive training programs to ensure that your employees understand how to effectively use the system. Training can include user workshops, documentation, and ongoing support to address any questions or challenges.",
  },
];

export const travelPortalService = [
  {
    id: 1,
    title: "White label Travel Solutions",
    icon: "https://res.cloudinary.com/whiztechnology/image/upload/v1690997204/Uploads/travel-portal_bxrpbn.png",
    description:
      "100% Private branded web portal with inbuilt Travel APIs or with the supplier of your choice.",
    keyPoints: [
      {
        id: 1,
        keyTitle:
          "White Label Travel Website that allows travel agents to sell a range of travel products - from hotels to flights to holidays and buses. With adivaha® White Label Travel Portal, you can do everything online and in one place, without the need for any technical knowledge or design skills. adivaha® is a leading travel portal development company providing travel technology solutions and travel website development in India and across the Globe.",
      },
    ],
  },
  {
    id: 2,
    title: "Corporate Travel booking Tool.",
    icon: "https://res.cloudinary.com/whiztechnology/image/upload/v1691054899/citj1u8oreezue2bu4la.png",
    description:
      "Facilitate a smooth business relationship with your corporate clients with special corporate rates.",
    keyPoints: [
      {
        id: 1,
        keyTitle:
          "Our features and benefits are curated to suit the needs of every employee using adivaha® Corporate Management Software. Everything you need to book and manage your business trips on a single platform, more accessible than ever.",
      },
    ],
  },
  {
    id: 3,
    title: "Distribute Rich And Dynamic Itineraries",
    icon: "https://res.cloudinary.com/whiztechnology/image/upload/v1690997204/Uploads/travel-portal_bxrpbn.png",
    description: "",
    keyPoints: [
      {
        id: 1,
        keyTitle:
          "Let your customers build their tour itinerary by themselves. An itinerary is booked all at once or service-by-service in a single checkout ie. hotels, flights, point of interest and transfers. Offer an online service to create and save itinerary for regular use or share it through email.",
      },
    ],
  },
];

export const travelPortalServiceMain = [
  {
    id: 1,
    title: "B2B Travel Booking Software.",
    icon: "https://res.cloudinary.com/whiztechnology/image/upload/v1691054941/dyfvzvojo7nltyfdtffk.png",
    description:
      "The all-in-one B2B Travel booking engine that maximizes your revenue and let your agents book your services seamlessly. Best In Class Service, Huge Inventories, Multiple Deals & Global Expertise!",
    services: [
      {
        id: 1,
        key: "DIY Products",
        points:
          "Quick and instant setup! Start onboarding your travel agents with few minutes under your branding.",
      },
      {
        id: 2,
        key: "Integrated Expense Management System",
        points:
          "Single platform for filing expenses and ensuring easy reconciliation",
      },
      {
        id: 3,
        key: "Dedicated Support",
        points:
          "Enjoy the support from higly skilled travel experts from over 20 Years of experience",
      },
    ],
  },
];

export const travelPortalFaq = [
  {
    id: 1,
    title: "Q1: What is a travel portal?",
    data: "A travel portal is a website or application that provides a platform for customers to search, compare, and book various travel services such as flights, hotels, car rentals, vacation packages, and more. It serves as a centralized platform for travelers to access and book their travel needs.",
  },
  {
    id: 2,
    title: "Q2: Why should I invest in a travel portal for my business?",
    data: "A travel portal offers several benefits for travel agencies, tour operators, and online travel companies. It allows you to expand your reach to a wider audience, provide convenience to customers through online bookings, automate processes, showcase a vast inventory of travel products, increase revenue opportunities, and improve customer satisfaction.",
  },
  {
    id: 3,
    title: "Q3: Can I customize the features and design of my travel portal?",
    data: "Yes, travel portal development is highly customizable. A reputable travel portal development company can tailor the features, functionality, and design of your portal to align with your business goals and requirements. Customization ensures that your portal reflects your brand identity and provides a unique user experience.",
  },
  {
    id: 4,
    title:
      "Q4: How do I integrate suppliers and inventory into my travel portal?",
    data: "Travel portal development involves integrating various suppliers, such as airlines, hotels, car rental companies, and travel aggregators, into your system. The integration is typically achieved through API (Application Programming Interface) connections, allowing real-time access to inventory, availability, and pricing information from suppliers.",
  },
  {
    id: 5,
    title: "Q5: Can I accept online payments through my travel portal?",
    data: "Yes, a well-developed travel portal includes secure payment gateway integration. This enables you to accept online payments from customers using various payment methods, such as credit/debit cards or online wallets. Secure payment gateways ensure the safety and confidentiality of customers' financial information.",
  },
  {
    id: 6,
    title: "Q6: Is mobile compatibility important for a travel portal?",
    data: "Yes, mobile compatibility is crucial for a travel portal. With the increasing use of smartphones and tablets for travel bookings, having a mobile-friendly design ensures that users can access and navigate your portal seamlessly on their mobile devices. Responsive design allows your portal to adapt to different screen sizes and provide an optimal user experience.",
  },
];

export const crmWhyData = [
  {
    id: 1,
    title: "Why Choose Us?",
    description: "",
    features: [
      {
        points:
          "We understand that every business is unique, with its own set of requirements and workflows. Our team of CRM experts works closely with you to develop tailored CRM solutions that align with your specific business needs and goals. Whether you need a CRM system for managing leads, automating sales processes, or delivering exceptional customer support, we have you covered.",
      },
      {
        points:
          "We have extensive experience in integrating CRM systems with various third-party applications, such as email marketing platforms, accounting software, and customer support tools. Our seamless integration ensures that your CRM works seamlessly with your existing systems, providing a unified view of customer data and enabling efficient data flow across your organization.",
      },
      {
        points:
          "We prioritize usability and understand the importance of user adoption for the success of any CRM implementation. Our CRM solutions feature intuitive interfaces that are easy to navigate, allowing your team to quickly adapt and leverage the system's capabilities. We focus on delivering an exceptional user experience that drives user engagement and productivity.",
      },
    ],
  },
];

export const schoolManagementServices = [
  {
    id: 1,
    title: "Benefits Of Having Websites For Schools.",
    icon: "https://res.cloudinary.com/whiztechnology/image/upload/v1691054941/dyfvzvojo7nltyfdtffk.png",
    description:
      "There are numerous benefits of having a school website. Let’s see the following ones.",
    services: [
      {
        id: 1,
        key: "DIY Products",
        points: "More publicity among the current audiences.",
      },
      {
        id: 2,
        key: "Integrated Expense Management System",
        points: "Become accessible to audiences around the globe.",
      },
      {
        id: 3,
        key: "Dedicated Support",
        points:
          "Give information about the school and its facilities to the audience thereby omitting the travel required by the outstation audiences.",
      },
      {
        id: 4,
        key: "Dedicated Support",
        points:
          "Display school videos, visual tours and school staff qualifications, and the curriculum and extracurricular activities provided by the school on the website to give a deeper insight to the audience.",
      },
    ],
  },
];

export const schoolManagementServices2 = [
  {
    id: 1,
    title: "Modern Website Development Services For Schools And Colleges",
    icon: "https://res.cloudinary.com/whiztechnology/image/upload/v1691054979/dgvj5qpktlmqntd29w2m.png",
    description: [
      {
        id: 1,
        keyPoints:
          "Want to build a website for your School but not sure what type of website you should go for? Consult with RankON Technologies today to get best website development for Schools at affordable prices. ",
      },
      {
        id: 2,
        keyPoints:
          "We know what type of functions or features a website should have and we always provide honest advice to get the best and modern website design and development for educational institutions.",
      },
      {
        id: 3,
        keyPoints:
          "This creates the need to display your best features and amenities and the educational facility you provide to your students. Displaying it through your website is the best way to keep up with the pace of the present competition around you.",
      },
      {
        id: 4,
        keyPoints:
          "Creating an SEO-friendly website for your school and giving direct freedom to the audience(parents) to choose your school from the various available options by comparing the options at their own comfort is the best way to grow your service-oriented education business.",
      },
    ],
  },
  {
    id: 2,
    title: "Why Websites For School Are Important?",
    icon: "https://res.cloudinary.com/whiztechnology/image/upload/v1691055009/q4q1v8eefpmonkmphrtu.png",
    description: [
      {
        id: 1,
        keyPoints:
          "Schools a couple of decades ago never had the need of getting their own websites but now that it’s been a bigger world by connecting people through technology. School websites seem to be a need of the hour.",
      },
      {
        id: 2,
        keyPoints:
          "The government of India has taken visible and efficient initiatives to promote education across the country which has given a chance to many people open schools that has ultimately led to competition in the education sector.",
      },
      {
        id: 3,
        keyPoints:
          "This creates the need to display your best features and amenities and the educational facility you provide to your students. Displaying it through your website is the best way to keep up with the pace of the present competition around you.",
      },
      {
        id: 4,
        keyPoints:
          "Creating an SEO-friendly website for your school and giving direct freedom to the audience(parents) to choose your school from the various available options by comparing the options at their own comfort is the best way to grow your service-oriented education business.",
      },
    ],
  },
];

export const whyChooseUsSchool = [
  {
    id: 1,
    title: "Customized Solutions",

    points:
      "We believe that every educational institution is unique, and your website should reflect that. Our team works closely with you to understand your specific requirements and design a website that aligns with your brand image, goals, and target audience.",
  },
  {
    id: 2,
    title: "Responsive Design",
    points:
      "In today's mobile-driven world, it is crucial for your website to be accessible and visually appealing across all devices. Our websites are built using responsive design techniques, ensuring seamless browsing experiences on desktops, tablets, and smartphones.",
  },
  {
    id: 3,
    title: "User-Friendly Interface",
    points:
      "We prioritize user experience and navigation. Our websites are designed with intuitive interfaces that make it easy for students, parents, and staff to find the information they need quickly. We incorporate user-friendly features such as calendars, event registration forms, and online admission portals to streamline processes.",
  },
  {
    id: 4,
    title: "Engaging Content",
    points:
      "We understand the importance of compelling content in attracting and engaging your website visitors. Our team can assist you in crafting informative and engaging content that effectively communicates your institution's values, programs, faculty, and achievements.",
  },
  {
    id: 5,
    title: "Integration and Functionality",
    points:
      "Our websites are built using the latest technologies and frameworks, ensuring seamless integration with existing school management systems, learning management systems, and other software solutions. We can incorporate features such as online course platforms, student portals, alumni networks, and event management systems, enhancing the functionality and efficiency of your institution.",
  },
  {
    id: 6,
    title: "Security and Privacy",
    points:
      "We prioritize the security and privacy of your website and its users. Our developers follow industry best practices to ensure robust security measures, protecting sensitive data and preventing unauthorized access.",
  },
  {
    id: 7,
    title: "Ongoing Support",
    points:
      "Our relationship with you doesn't end after the website launch. We provide ongoing support and maintenance services to keep your website up-to-date, secure, and functioning optimally. Our team is always available to address any concerns or implement additional features as your institution grows..",
  },
];

export const hospitalManagementServices = [
  {
    id: 1,
    title: "Our Hospital Management Website Development Company",
    icon: "https://res.cloudinary.com/whiztechnology/image/upload/v1691055047/sw2ugygxnjjyvxbtvrok.png",
    description: [
      {
        title:
          "Are you looking for a reliable partner to build your hospital management website? Look no further! Our hospital and healthcare website development company in India is the perfect choice. Our team of skilled developers will work behind the scenes to make your website look great and ensure its successful implementation.",
      },
      {
        title:
          "We understand the unique requirements of the medical industry, and our developers specialize in medical website development and healthcare application projects. With our expertise, we can provide you with a customized solution that caters to your specific needs.",
      },
      {
        title:
          "Our skilled and professionals developers provide medical and medical website development and health care application project services to:",
      },
    ],
    services: [
      {
        id: 1,
        key: "Medical Website Design and Development: ",
        points:
          "We pay attention to every detail to ensure the design and functionality of your medical website are top-notch. Our team will create a visually appealing and user-friendly website that showcases your services effectively.",
      },
      {
        id: 2,
        key: "Maintenance and Support: ",
        points:
          "We offer ongoing maintenance and support services to ensure your website runs smoothly. Our team will handle all the technical aspects, allowing you to focus on your core operations.",
      },
      {
        id: 3,
        key: "White Label B2B Services: ",
        points:
          "We understand that your hospital website development needs may be unique. Our white label services are designed to offload the development process entirely. You can rely on us to handle the entire project while maintaining your branding and reputation.",
      },
      {
        id: 4,
        key: "Integration of Advanced Features: ",
        points:
          "We can integrate advanced features into your website, such as appointment scheduling systems, patient portals, electronic medical record (EMR) systems, and more. These features will enhance the efficiency of your hospital management processes.",
      },
      {
        id: 5,
        key: "Responsive Design: ",
        points:
          "Our developers will ensure that your website is fully responsive, meaning it will adapt and display correctly on various devices, including desktops, tablets, and mobile phones. This ensures a seamless user experience for your website visitors.",
      },
      {
        id: 6,
        key: "Search Engine Optimization (SEO): ",
        points:
          "We implement SEO best practices during the development process to improve your website's visibility in search engine results. This will help drive organic traffic to your website and increase your online presence.",
      },
    ],
  },
];

export const hospitalManagementServices2 = [
  {
    id: 1,
    title: "Healthcare Services",
    icon: "https://res.cloudinary.com/whiztechnology/image/upload/v1691055062/zd9x8qmm52qsem6orxq8.png",
    description: [
      {
        id: 1,
        keyPoints:
          "We are a website development company specializing in creating engaging and effective websites for doctors and hospitals. Our team of developers has a deep understanding of the core fundamentals of healthcare website project development, ensuring that our solutions meet your specific requirements. With expertise in advanced WordPress CMS and other programming languages, we deliver the best results to help your business thrive in the competitive world.",
      },
      {
        id: 2,
        keyPoints:
          "We understand the importance of creating a user-friendly and visually appealing website that reflects your hospital's values and attracts patients. Our team will work closely with you to tailor the content and design to your specific requirements, ensuring that your hospital website stands out and delivers a positive experience for visitors. Contact us today to discuss your hospital website development needs!",
      },
    ],
  },
];

export const hospitalManagementFAQ = [
  {
    id: 1,
    title: "Q1: Why do hospitals need a dedicated management website?",
    data: "A hospital management website streamlines various administrative tasks, such as patient management, appointment scheduling, billing and invoicing, and inventory management. It improves efficiency, enhances patient experience, and allows for better coordination among staff members.",
  },
  {
    id: 2,
    title:
      "Q2: What features can be included in a hospital management website?",
    data: "A hospital management website can include features like patient registration and management, appointment scheduling, electronic health records (EHR), laboratory and pharmacy management, billing and invoicing, reporting and analytics, inventory management, and communication tools.",
  },
  {
    id: 3,
    title:
      "Q3: Can a hospital management website integrate with existing hospital systems?",
    data: "Yes, a well-designed hospital management website can integrate with existing systems, such as electronic medical record (EMR) systems or laboratory information systems (LIS). This ensures smooth data flow and avoids duplication of efforts.",
  },
  {
    id: 4,
    title: "Q4: How can a hospital management website benefit patients?",
    data: "A hospital management website provides convenience to patients by allowing them to book appointments online, access their medical records, view test results, and communicate with healthcare providers. It improves patient engagement, reduces wait times, and enhances the overall patient experience.",
  },
  {
    id: 5,
    title: "Q5: Is data security ensured on a hospital management website?",
    data: "Data security is a top priority in hospital management website development. Robust security measures, such as encryption, access controls, and compliance with healthcare regulations (e.g., HIPAA), are implemented to protect patient information and maintain confidentiality.",
  },
  {
    id: 6,
    title:
      "Q6: Can a hospital management website be customized to fit specific hospital requirements?",
    data: "Yes, a hospital management website can be customized to meet the unique needs of a hospital or healthcare facility. The development process involves understanding the specific workflows and requirements of the hospital and tailoring the website accordingly.",
  },
];

export const schoolManagementFaq = [
  {
    id: 1,
    title: "Q1: What is a school management website?",
    data: "A school management website is a digital platform designed to streamline and automate various administrative, academic, and communication tasks within a school or educational institution. It serves as a centralized hub for managing student data, attendance, grades, schedules, parent communication, and other essential aspects of school administration.",
  },
  {
    id: 2,
    title: "Q2: Why do schools need a management website?",
    data: "School management websites offer numerous benefits for both administrators and stakeholders. They simplify administrative tasks, enhance communication between teachers, students, and parents, provide real-time access to information, and improve overall efficiency in managing school operations. They also offer a convenient way to store and retrieve important school-related data.",
  },
  {
    id: 3,
    title:
      "Q3: Can a school management website be customized to fit specific requirements?",
    data: "Yes, school management websites can be customized to meet the unique needs of each educational institution. A reputable website development company can work closely with the school administration to understand their requirements and tailor the website's features, design, and functionality accordingly.",
  },
  {
    id: 4,
    title: "Q4: How long does it take to develop a school management website?",
    data: "The development timeline for a school management website depends on various factors such as the complexity of features, customization requirements, and the availability of content and data. Typically, it can take several weeks to a few months to develop and launch a fully functional school management website.",
  },
  {
    id: 5,
    title:
      "Q5: Is it necessary to have technical expertise to manage a school management website?",
    data: "No, technical expertise is not necessary to manage a school management website. A well-designed and user-friendly website will include an intuitive content management system (CMS) that allows administrators and authorized staff members to update and manage the website's content without technical knowledge. Training and support can also be provided by the website development company.",
  },
  {
    id: 6,
    title:
      "Q6: Can a school management website be integrated with other systems?",
    data: "Yes, school management websites can be integrated with other systems such as student information systems (SIS), learning management systems (LMS), online payment gateways, and communication tools. Integration allows for seamless data transfer, improved efficiency, and a cohesive digital ecosystem within the educational institution.",
  },
];
