import React from "react";
import { Link } from "react-router-dom";
import HeroPng from "assets/images/contactus_banner.png";
import Corousel from "components/Corousel/Corousel";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import CallOutlinedIcon from "@mui/icons-material/CallOutlined";

const ContactHeader = () => {
  return (
    <>
      <section className="">
        <div className="grid max-w-screen-xl px-4 pt-16 pb-8 mx-auto lg:gap-8 xl:gap-0 lg:py-16 lg:grid-cols-12 lg:pt-16">
          <div className="mr-auto place-self-center lg:col-span-7">
            <h1 className="max-w-2xl mb-4 text-xl md:text-4xl font-extrabold leading-none tracking-tight dark:text-white inline-flex items-center">
              Contact Us | &nbsp;
              <Link to={"tel:+91-9560322639"}>
                {" "}
                <span className=" my-auto text-black text-base md:text-xl ">
                  +91-9560322639
                </span>{" "}
              </Link>
            </h1>
            <p className="max-w-2xl font-normal text-black mb-4 text-xs lg:text-base dark:text-gray-400">
              For any general questions, comments, or information, please email
              us or fill out the contact form below. We strive to respond to all
              inquiries within 24 hours.
            </p>
            <div className="flex flex-col justify-start space-y-1 mb-4">
              <Link
                to={"tel:+91-9560322639"}
                className="flex flex-row space-x-1 sm:space-x-3 justify-start items-center "
              >
                <CallOutlinedIcon color="primary" />
                <span className=" text-black text-[10px] sm:text-sm  font-medium">
                  Bussiness Support &nbsp;:
                </span>
                <span className=" text-black text-xs sm:text-sm font-normal">
                  +91-9560322639
                </span>
              </Link>
              <Link
                to={"@mailto:info.whiztechnology@gmail.com"}
                className="flex flex-row space-x-1 sm:space-x-3 justify-start items-center"
              >
                <EmailOutlinedIcon color="primary" />
                <span className=" text-black text-[10px] sm:text-sm font-medium">
                  Email Support &nbsp;:
                </span>
                <span className=" text-black text-xs sm:text-sm font-normal">
                  info.whiztechnology@gmail.com
                </span>
              </Link>
            </div>
            <div className="space-y-4 sm:flex sm:space-y-0 sm:space-x-4">
              <Link
                to={"/"}
                target=""
                className="inline-block px-6 py-2.5 bg-[#44619d] text-white font-medium text-xs leading-tight uppercase rounded-full shadow-md hover:bg-blue-500 hover:shadow-lg focus:bg-blue-500 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-600 active:shadow-lg transition duration-150 ease-in-out"
              >
                Case Study
              </Link>
              <Link
                to={"/about-us"}
                className=" mx-2 inline-block px-6 py-2.5 bg-gray-800 text-white font-medium text-xs leading-tight uppercase rounded-full shadow-md hover:bg-gray-900 hover:shadow-lg focus:bg-gray-900 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-gray-900 active:shadow-lg transition duration-150 ease-in-out"
                target=""
              >
                About Us
              </Link>
            </div>
          </div>
          <div className="hidden lg:mt-0 lg:col-span-5 lg:flex">
            <img src={HeroPng} alt="heroimage" />
          </div>
        </div>
      </section>
      {/* <!-- End block --> */}
      <section className="backdrop-blur-sm my-auto bg-[#ddd6fe]/60">
        <div className="max-w-screen-xl px-4 m-auto">
          <Corousel />
        </div>
      </section>
    </>
  );
};

export default ContactHeader;
