import Faq from "components/Development/Faq";
import FloatingWp from "components/Development/FloatingWp";
import Footer from "components/Footers/Footer";
import Trusted from "components/Footers/Trusted";
import PopUoForm from "components/Forms/PopUoForm";
import StickyEnquryForm from "components/Forms/StickyEnquryForm";
import IndexNavbar from "components/Navbars/IndexNavbar";
import React from "react";
import Corousel from "components/Corousel/Corousel";
import HeroHeader from "components/Development/HeroHeader";
import MainServices from "components/Development/MainServices";
import { contentWritingServicesData, cwWhyChooseUs } from "../digitalData";
import WhyChooseUs from "components/Development/WhyChooseUs";
import PrincipalFeatures from "components/Development/PrincipalFeatures";
import { principalFeaturesCW, contentWritingFaq } from "./digitalPagesData";
import MetaHelmet from "components/Development/MetaHelmet";
import HeroBanner from "assets/images/content_writing_banner.png";
import PFBanner from "assets/images/cw_principal_features.png";
import Preloader from "components/Development/Preloader";

const ContentWriting = () => {
  const [isLoading, setisLoading] = React.useState(true);

  React.useEffect(() => {
    setTimeout(() => {
      setisLoading(false);
    }, 2000);
  }, []);
  return (
    <>
      {isLoading === true ? (
        <Preloader />
      ) : (
        <div className="">
          {" "}
          <MetaHelmet
            title="Content Writing Experts | Content Writing Company"
            descriptionContent="Whiztechnology offers high-quality content writing services. Our content writing services in india are ideal for website content, persuasive sales copy, shareable blog posts and engaging, informative articles."
            keywordsContent="content writer, article writing, freelance writer, content writing services, content writing agency, web content writing, content writing company, freelance technical writing"
          />
          <div className="fixed bg-hero-pattern bg-fixed bg-cover bg-center inset-0 -z-20"></div>{" "}
          <IndexNavbar />
          <HeroHeader
            title="Content Writing Company"
            description="We are a team of professional content writers dedicated to helping businesses create engaging, informative, and compelling content that connects with their target audience. Whether you need website content, blog articles, product descriptions, or any other form of written content, we've got you covered. "
            image={HeroBanner}
            smartTagTitle="No.1"
            smartTagDescription="Content Writing Company in India"
          />
          <Corousel />
          <MainServices mainServicesData={contentWritingServicesData} />
          <WhyChooseUs whyChooseUsData={cwWhyChooseUs} />
          <PrincipalFeatures
            principalFeaturesData={principalFeaturesCW}
            image={PFBanner}
          />
          <Trusted />
          <Faq faqs={contentWritingFaq} />
          <StickyEnquryForm />
          <FloatingWp />
          <PopUoForm />
          <Footer />
        </div>
      )}
    </>
  );
};

export default ContentWriting;
