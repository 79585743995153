import { React, useState } from "react";
import { Link } from "react-router-dom";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import { Fragment } from "react";
import Logo from "assets/img/logo.png";
import { Container } from "@mui/material";
import { Player } from "@lottiefiles/react-lottie-player";
import "react-toastify/dist/ReactToastify.css";
import { handlePageChange } from "components/utils/useMyFunctions.js";
import { ToastContainer, toast } from "react-toastify";

import { UilMessage } from "@iconscout/react-unicons";
import { db } from "config/firebase";
import { doc, setDoc } from "firebase/firestore";

export default function StickyEnquryForm() {
  const [state, setState] = useState(false);
  const [isOpen, setIsOpen] = useState(true);

  // Form Validation Using EmailJs
  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [services, setServices] = useState("");
  const [phoneNo, setPhoneNo] = useState("");

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const successToast = () =>
    toast.success("Email Sent Successfully", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState(open);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const full_name = `${name} ${lastName}`;
    const currentDate = new Date();
    const options = { day: "2-digit", month: "short", year: "numeric" };
    const createdAt = currentDate.toLocaleDateString("en-US", options);
    const updatedAt = currentDate.toLocaleDateString("en-US", options);

    const formData = {
      id: Date.now(),
      full_name,
      read: false,
      email,
      services,
      phone_no: phoneNo,
      status: "Add Status",
      followup: "Pending",
      createdAt,
      updatedAt,
      source: "webenquiry",
    };

    const docId = Date.now();
    const collectionsRef = doc(db, "webServiceEnquiry", `${docId}`);

    try {
      setDoc(collectionsRef, formData)
        .then(() => {
          console.log("Document created successfully!");
        })
        .catch((error) => {
          console.error("Error creating document: ", error);
        });
    } catch (error) {
      console.error("Error creating document: ", error);
    }
    successToast();
    setName("");
    setLastName("");
    setPhoneNo("");
    setEmail("");
    setServices("");
    handleToggle();
    setInterval(() => {
      handlePageChange();
    }, 1500);
  };

  const list = () => (
    <Box
      sx={{ width: true === "top" || true === "bottom" ? "auto" : 350 }}
      role="presentation"
    >
      <Container sx={{ mx: "0px auto", my: "10px" }}>
        <Link to={"/"} className="">
          <div className="flex items-center justify-center w-fit rounded-sm bg-[#44619d]">
            {" "}
            <img
              src={Logo}
              className=" w-16 md:w-24"
              alt="WhizTechnology Logo"
            />
          </div>
        </Link>
      </Container>

      <div className="flex p-5 flex-col justify-center items-center h-32 bg-gray-200">
        <Player
          src={
            "https://res.cloudinary.com/whiztechnology/raw/upload/v1691044518/gkjuaswa9ofpmyfil9qq.json"
          }
          className="player"
          loop
          autoplay
          style={{
            height: "200px",
            width: "250px",
          }}
        />
      </div>
      <div className="bg-gray-50 flex-grow p-6">
        <form onSubmit={handleSubmit} method="post">
          <div className="relative w-full mb-3 flex flex-shrink ">
            <div className=" w-1/2">
              <label
                className="block uppercase text-black text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                First Name
              </label>
              <input
                type="text"
                className="border-0 px-3 my-inputs py-3 text-black  rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150 bg-white"
                placeholder="First Name"
                required
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div className=" w-1/2 ml-[10px]">
              <label
                className="block uppercase  text-slate-600 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                Last Name
              </label>
              <input
                type="text"
                className="border-0 px-3 py-3 my-inputs placeholder-white0 text-slate-600  rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150 bg-white"
                placeholder="Last Name"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
            </div>
          </div>

          <div>
            <label
              htmlFor="phone"
              className="block uppercase text-black text-xs font-bold mb-2"
            >
              Contact No:
            </label>
            <input
              value={phoneNo}
              onChange={(e) => setPhoneNo(e.target.value)}
              type="tel"
              id="phone"
              className=" text-black text-sm rounded-sm border-none  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 shadow bg-white"
              placeholder="+0-888-888-8888"
              required
            />
          </div>

          <div className="">
            <div className="relative w-full mb-3 mt-3">
              <label
                className="block uppercase  text-slate-600 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                Email
              </label>
              <input
                type="email"
                className="border-0 px-3 py-3 my-inputs placeholder-white0 text-slate-600 rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150 bg-white"
                placeholder="Enter Your Email"
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
          </div>

          <div className="relative w-full mb-3">
            <label
              htmlFor="services"
              className="block uppercase text-black text-xs font-bold mb-2 "
            >
              Select an option
            </label>
            <select
              id="countries"
              className="text-black border-none text-sm rounded-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 shadow  bg-white"
              onChange={(e) => setServices(e.target.value)}
              required
            >
              <option selected className="text-black">
                Services
              </option>
              <option className="text-black" value="Website Development">
                Website Development
              </option>
              <option className="text-black" value="CRM & ERP Development">
                CRM & ERP Development
              </option>
              <option className="text-black" value="App Development">
                App Development
              </option>
              <option className="text-black" value="Digital Marketing">
                Digital Marketing
              </option>
              <option className="text-black" value="Other">
                Other
              </option>
            </select>
          </div>
          <div className="text-center mt-6">
            <button
              className=" flex w-40 justify-center bg-blue-500 items-center bg-slate-800 text-white active:bg-slate-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 m-auto"
              type="submit"
            >
              <i className="mr-2">
                <UilMessage />
              </i>
              Send
            </button>
          </div>
        </form>
      </div>
    </Box>
  );

  return (
    <div>
      <Fragment>
        <div
          className="fixed right-0 top-2/4 z-20 flex -translate-y-1/2 transform items-center justify-center bg-button text-white sm:block"
          onClick={toggleDrawer(true)}
        >
          <div
            className=" w-[40px] bg-[#44619d] p-2 text-white rounded-l-xl"
            style={{ writingMode: "vertical-rl", cursor: "pointer" }}
          >
            Enquiry Now
          </div>
        </div>
        <Drawer anchor={"right"} open={state} onClose={toggleDrawer(false)}>
          {list()}

          <div
            className="absolute top-1 right-1 cursor-pointer"
            onClick={toggleDrawer(false)}
          >
            <button
              type="button"
              class="text-blue-700 border border-blue-700 hover:bg-blue-700 hover:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-full text-sm p-2.5 text-center inline-flex items-center dark:border-blue-500 dark:text-blue-500 dark:hover:text-white dark:focus:ring-blue-800 dark:hover:bg-blue-500"
            >
              <svg
                aria-hidden="true"
                className="w-5 h-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                  clipRule="evenodd"
                ></path>
              </svg>
              <span class="sr-only">Icon description</span>
            </button>
          </div>
        </Drawer>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
        {/* Same as */}
      </Fragment>
    </div>
  );
}
