import Development from "assets/logo/user-interface.png";
import Quality from "assets/logo/repair-tools.png";
import Deployment from "assets/logo/physics.png";
import Maintainence from "assets/logo/chat.png";
import filePng from "assets/logo/file.png";
import UIUX from "assets/logo/ux-design.png";
import Prototype from "assets/logo/assignment.png";
import RetailSvg from "assets/svgs/retail.svg";
import StudentSvg from "assets/svgs/student.svg";
import HealthcareSvg from "assets/svgs/healthcare.svg";
import LogisticSvg from "assets/svgs/logistic.svg";
import NetworkingSvg from "assets/svgs/networking.svg";
import RealEstateSvg from "assets/svgs/real-estate.svg";
import TravelSvg from "assets/svgs/travel.svg";
import Food from "assets/svgs/food.svg";
import Sofa from "assets/logo/sofa.png";
import Interior from "assets/logo/sketch.png";
import branging_svg from "assets/logo/reshot-icon-branding-RD356ZUGE2.svg";
import webdesign_svg from "assets/logo/reshot-icon-web-design-WKP27CZXR9.svg";
import app_svg from "assets/logo/reshot-icon-app-rating-RE7AWMZUKH.svg";
import graphic_svg from "assets/logo/reshot-icon-vector-TCAWP4NZ5X.svg";

export const webData = [
  {
    id: 1,
    titlle: "Mobile App Development",
    description:
      "Deliver great service experiences fast - without the complexity of traditional solutions. Accelerate critical development work, eliminate toil, and deploy changes with ease.",
    listData: [
      {
        id: 1,
        content: "ERP Development",
        to: "",
      },
      {
        id: 2,
        content: "ERP Development",
      },
    ],
  },
];

export const accordionData = [
  {
    id: 1,
    title: "Q1: What is a web service?",
    data: "A web service is a software system designed to enable communication and interoperability between different applications over a network, typically the internet. It allows applications to exchange data and perform various operations using standard protocols such as HTTP and XML. Web services are commonly used for integrating different systems, sharing data, and facilitating communication between applications developed on different platforms.",
  },
  {
    id: 2,
    title: "Q2: What are the advantages of using web services?",
    data: "Web services offer several advantages, including: Interoperability: Web services allow applications developed on different platforms to communicate and exchange data seamlessly",
  },
  {
    id: 3,
    title: "Q4: How can I consume a web service in my application?",
    data: `Identify the type of web service (SOAP, REST, GraphQL) and its endpoint URL. Use a programming language or framework that supports web service consumption. Generate or write code to make HTTP requests to the web service endpoint. Handle the responses returned by the web service and parse the data as needed. Integrate the consumed data into your application's logic or user interface.`,
  },
];

export const cardData = [
  {
    id: "01",
    title: "Quick & Safe Booking",
    content:
      "Hotel booking apps allow users to book nearby hotels faster and with more security. They simply need to download the hotel booking app and get the best suggestions nearby.",
  },
  {
    id: "02",
    title: "Brand Awareness",
    content:
      "Apps are one of the most used commodities, so having a hotel booking app will improve your hotel business's brand awareness.",
  },
  {
    id: "03",
    title: "Hassle-free Payment",
    content:
      "Say goodbye to the concern of offline payment management. Hotel booking apps enable hassle-free payment and allow users to pay on the go with a tap.",
  },
  {
    id: "04",
    title: "Streamlined Booking",
    content:
      "With a feature-rich and well-managed, users and business owners do not have to worry about booking management and get updates on upcoming, ongoing, and cancelled bookings.",
  },
  {
    id: "05",
    title: "Customized Push Notifications.",
    content:
      "Keeping the users timely notified of their booking status, upcoming offers, and more will increase your hotel booking app engagement and customer engagement.",
  },
  {
    id: "06",
    title: "Transparent Business Analytics",
    content:
      "While having hotel booking applications, businesses can look at the business analytics and stats clearly to analyze monthly or weekly profit and growth",
  },
];

export const keyFeatures = [
  {
    id: 1,
    icon: filePng,
    title: "Requirement Gathering",
    data: "We follow the first and foremost priority of gathering requirements, resources, and information to begin our project.",
  },
  {
    id: 2,
    icon: UIUX,
    title: "UI/UX Design",
    data: "We create catchy and charming designs with the latest tools of designing to make it a best user-friendly experience.",
  },
  {
    id: 3,
    icon: Prototype,
    title: "Prototype",
    data: "After designing, you will get your prototype, which will be sent ahead for the development process for the product.",
  },
  {
    id: 4,
    icon: Development,
    title: "Development",
    data: "Development of mobile application/web/blockchain started using latest tools and technologies with transparency.",
  },
  {
    id: 5,
    icon: Quality,
    title: "Quality Assurance",
    data: "Hyperlink values quality and provides 100% bug free application with no compromisation in it.",
  },
  {
    id: 6,
    icon: Deployment,
    title: "Deployment",
    data: "After trial and following all processes, your app is ready to launch on the App store or Play Store.",
  },
  {
    id: 7,
    icon: Maintainence,
    title: "Support & Maintenance",
    data: "Our company offers you all support and the team is always ready to answer every query after deployment.",
  },
];

export const keyFeatures2 = [
  {
    id: 1,
    title: "Branding",
    icon: branging_svg,
  },
  {
    id: 2,
    title: "Web Design",
    icon: webdesign_svg,
  },
  {
    id: 3,
    title: "Mobile App Design",
    icon: app_svg,
  },
  {
    id: 4,
    title: "Graphic Design",
    icon: graphic_svg,
  },
];

export const webServices = [
  {
    id: 1,
    title: "UI/UX Design",
    to: "/ui-ux-development",
  },
  {
    id: 2,
    title: "Responsive WebSite Design",
    to: "/responsive-website-design",
  },
  {
    id: 3,
    title: "Graphic Design",
    to: "/graphic-design",
  },
  {
    id: 4,
    title: "Wordpress Website Design",
    to: "/wordpress-website-design",
  },
  {
    id: 5,
    title: "eCommerce Website Design",
    to: "/e-commerce-website-design",
  },
  {
    id: 6,
    title: "Landing Page Website Design",
    to: "/landing-page-website-design",
  },
];

export const industriesData = [
  {
    id: 1,
    title: "Retail, Ecommerce",
    icon: RetailSvg,
  },
  {
    id: 2,
    title: "Education & e-learning",
    icon: StudentSvg,
  },
  {
    id: 3,
    title: "Healthcare & Fitness",
    icon: HealthcareSvg,
  },
  {
    id: 4,
    title: "Logistics & Distribution",
    icon: LogisticSvg,
  },
  {
    id: 5,
    title: "Social Networking",
    icon: NetworkingSvg,
  },
  {
    id: 6,
    title: "Real Estate",
    icon: RealEstateSvg,
  },
  {
    id: 7,
    title: "Travel & Hospitality",
    icon: TravelSvg,
  },
  {
    id: 8,
    title: "Food & Restaurant",
    icon: Food,
  },
  {
    id: 9,
    title: "Decore & Interior Industry",
    icon: Interior,
  },
  {
    id: 10,
    title: "Furniture Industry",
    icon: Sofa,
  },
];

// Web Development Pages Data
export const ERPDevelopment = [
  {
    name: "Erp Development",
    version: "0.1.0",
    data: {},
  },
];

export const uiuxfaq = [
  {
    id: 1,
    title: "Q1: What is UI and UX design?",
    data: "UI (User Interface) design focuses on the visual elements of a digital product, such as layouts, buttons, and typography. UX (User Experience) design, on the other hand, deals with the overall user journey, usability, and satisfaction when interacting with a product.",
  },
  {
    id: 2,
    title: "Q2: Why is UI/UX design important?",
    data: "UI/UX design is crucial because it directly impacts how users perceive and interact with a product. Good design enhances user satisfaction, improves usability, and increases customer loyalty and retention.",
  },
  {
    id: 3,
    title: "Q3: What does a UI/UX designer do?",
    data: "UI/UX designers are responsible for creating intuitive, visually appealing, and user-friendly interfaces. They conduct research, create wireframes and prototypes, conduct user testing, and collaborate with developers to ensure the design vision is implemented effectively.",
  },
  {
    id: 4,
    title: "Q4: How long does the UI/UX design process take?",
    data: "The duration of the design process depends on various factors, such as the complexity of the project, the number of iterations required, and the availability of client feedback. It can range from a few weeks to several months.",
  },
  {
    id: 5,
    title: "Q5: How long does the UI/UX design process take?",
    data: "The duration of the design process depends on various factors, such as the complexity of the project, the number of iterations required, and the availability of client feedback. It can range from a few weeks to several months.",
  },
  {
    id: 6,
    title: "Q6: What deliverables can I expect from a UI/UX designer?",
    data: "Common deliverables include wireframes, interactive prototypes, visual design mockups, style guides, and design specifications. The specific deliverables may vary based on the project requirements.",
  },
];
