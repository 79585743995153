import React from "react";
import { Link } from "react-router-dom";
import TextLoop from "react-text-loop";
import Star from "assets/img/star.png";
import MiniStar from "assets/img/mini-star.png";
import AngleShape from "assets/img/hero-angel-shape.png";
import CircleShape from "assets/img/hero-circle-shape.png";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Tager from "components/Development/Tager";

export default function IndexHeader() {
  return (
    <>
      <section className=" max-w-screen-xl">
        <div className="md:grid block px-4 pt-20 pb-8 mx-auto lg:gap-8 xl:gap-0 lg:py-16 lg:grid-cols-12 lg:pt-28">
          <div className="mr-auto place-self-center lg:col-span-7">
            <Box sx={{ maxWidth: "md" }}>
              <h1
                id="text"
                className="text-xl md:text-4xl text-ellipsis py-2 text-transparent font-semibold leading-normal has-gradient"
              >
                Software Development Company
              </h1>
            </Box>
            <Box sx={{ maxWidth: "sm" }}>
              <div className=" max-w-md">
                {" "}
                <h2 className="font-semibold text-2xl mt-2">
                  <TextLoop
                    className="max-w-xs md:max-w-none  italic text-xs md:text-[21px] text-blue-500"
                    springConfig={{ stiffness: 180, damping: 8 }}
                    children={[
                      "Transforming Ideas into Powerful Software Solutions",
                      "Crafting Seamless Websites and Intuitive Software",
                      "Driving Digital Growth through Custom Software and Websites",
                    ]}
                  />
                </h2>
              </div>{" "}
              <div className="mb-5">
                {" "}
                <Typography
                  variant="subtitle2"
                  textAlign={"justify"}
                  justifyContent={"start"}
                  gutterBottom
                  // fontSize={15}
                  sx={{ fontSize: "14px" }}
                  fontStyle={"normal"}
                  fontWeight={400}
                >
                  We develop robust and business oriented software, high quality
                  search engine friendly website and result oriented SEO
                  services that become helpful for our clients to emerge as a
                  business tycoon. It features multiple HTML elements and it
                  comes with dynamic components for ReactJS, Vue and Angular.
                </Typography>
              </div>
            </Box>

            <div className="space-y-4 sm:flex sm:space-y-0 sm:space-x-4">
              <Link
                to={"/"}
                target=""
                className="inline-block px-3 py-1.5 md:px-6 md:py-2.5 bg-[#44619d] text-white font-medium text-xs leading-tight uppercase rounded-full shadow-md hover:bg-blue-500 hover:shadow-lg focus:bg-blue-500 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-600 active:shadow-lg transition duration-150 ease-in-out"
              >
                Case Study
              </Link>
              <Link
                to={"/contact-us"}
                className=" mx-2 inline-block px-3 py-1.5 md:px-6 md:py-2.5 bg-gray-800 text-white font-medium text-xs leading-tight uppercase rounded-full shadow-md hover:bg-gray-900 hover:shadow-lg focus:bg-gray-900 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-gray-900 active:shadow-lg transition duration-150 ease-in-out"
                target=""
              >
                Contact Us
              </Link>
            </div>
          </div>
          <div className="hidden lg:mt-0 lg:col-span-5 relative max-w-md lg:flex">
            <div className="tp-hero-shape-one absolute top-[5px] right-[20px] z-3 animate-jumpTwo">
              <img src={Star} alt="" />
            </div>

            <div className="tp-hero-shape-two absolute">
              <img src={CircleShape} alt="" />
            </div>

            <div className="tp-hero-shape-three z-3 absolute">
              <img src={AngleShape} alt="" />
            </div>

            <div className="left-0 bottom-0 z-3 absolute">
              <img src={MiniStar} alt="" />
            </div>

            <div className="absolute inset-x-0 max-w-xs mx-auto">
              <img
                src={
                  "https://res.cloudinary.com/whiztechnology/image/upload/v1690990307/Uploads/h6uey2lwsvtq6bkxnuvb.png"
                }
                className=""
                alt="Our Services"
              />
            </div>
            <div className="right-vector tp-hero-thumb mx-auto relative">
              <Tager />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
