import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";

const CardsServices = (props) => {
  return (
    <section className="max-w-screen-xl mx-auto">
      <div className=" px-2 md:px-8 py-4 md:py-8">
        <h3 className="mb-4 text-center text-base md:text-xl lg:text-3xl font-semibold tracking-normal leading-normal text-gray-900 dark:text-white">
          {props.heading}
        </h3>
        <div className="grid grid-cols-1 md:grid-cols-2 place-items-center gap-4">
          {props.cardServicesData.map(({ id, title, description, icon }) => (
            <Card
              sx={{
                minWidth: 275,
                backgroundColor: "#dbeafe",
                borderRadius: 3,
                ":hover": { backgroundColor: "white" },
                height: "100%",
              }}
              key={id}
            >
              <CardContent
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  marginX: "12",
                  pb: 0,
                }}
                gutterBottom
              >
                <div className="flex flex-col justify-center items-center space-x-3 mb-4">
                  <div className="bg-transparent max-w-fit p-2 hover:bg-white rounded-md">
                    <img src={icon} alt={title} className=" h-20 w-auto" />
                  </div>
                  <p className=" text-base md:text-xl text-slate-400 font-semibold">
                    {title}
                  </p>
                </div>
                <div className="ml-2 text-xs md:text-base text-center leading-loose">
                  {description}
                </div>
              </CardContent>
            </Card>
          ))}
        </div>
      </div>
    </section>
  );
};

export default CardsServices;
