import { createContext } from "react";

// Create a new context for Cloudinary configuration
const CloudinaryContext = createContext({
  cloudName: "whiztechnology", // Replace with your Cloudinary cloud name
  apiKey: "253726884848443", // Replace with your Cloudinary API key
  uploadPreset: "k8ncss3x",
});

export default CloudinaryContext;
