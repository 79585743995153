import React from "react";
import SliderComp from "./SliderComp";

const Corousel = () => {
  return (
    <>
      {" "}
      <div className="max-w-screen-xl mx-auto">
        <div className="flex flex-col items-center justify-center py-4 px-2 overflow-hidden">
          <div className=" max-w-screen-xs sm:max-w-screen-sm md:max-w-screen-md lg:max-w-screen-xl">
            <SliderComp />
          </div>
        </div>
      </div>
    </>
  );
};

export default Corousel;
