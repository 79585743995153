import React from "react";
import { Link } from "react-router-dom";
import SanjaySir from "assets/images/sanjay_sir.png";
import HeroSlider, { Slide, MenuNav } from "hero-slider";
import "./about.css";
import Corousel from "components/Corousel/Corousel";
import FacebookBtn from "./FacebookBtn";
import HimanshuSir from "assets/images/himanshu_sir.jpeg";
import { ChevronRight } from "@mui/icons-material";
import Logo from "assets/logo/whiz-technology-logo.jpeg";

const AboutHeader = () => {
  return (
    <>
      <HeroSlider
        height={"100vh"}
        // autoplay
        controller={{
          initialSlide: 1,
          slidingDuration: 500,
          slidingDelay: 100,
          onSliding: (nextSlide) =>
            console.debug("onSliding(nextSlide): ", nextSlide),
          onBeforeSliding: (previousSlide, nextSlide) =>
            console.debug(
              "onBeforeSliding(previousSlide, nextSlide): ",
              previousSlide,
              nextSlide
            ),
          onAfterSliding: (nextSlide) =>
            console.debug("onAfterSliding(nextSlide): ", nextSlide),
        }}
        className="max-w-screen-xl lg:max-w-screen-2xl mx-auto"
      >
        <Slide
          label="Company Information"
          className="max-w-screen-xl lg:max-w-screen-2xl mx-auto"
        >
          <div className="relative max-w-screen-xl w-full mx-auto xl:px-5 mt-10 lg:mt-40">
            <div className="relative w-full grid gap-12 lg:grid-cols-3 place-content-center lg:place-content-between">
              <div className="w-full flex justify-center items-center px-5 xl:px-0 lg:col-span-2">
                <div className="w-full">
                  <h2 className="text-4xl font-bold md:text-4xl">
                    About Company
                  </h2>
                  <p className="mt-6 text-gray-700 leading-relaxed">
                    On Whiz Technology, our designers and developers offer our
                    clients ingenious and creative products and services that
                    offer a comprehensive solution. Among the plenty of
                    services,{" "}
                    <span className="hover:underline hover:text-indigo-600">
                      {" "}
                      <Link to={"/mobile-app-development-company"}>
                        Software Development
                      </Link>{" "}
                    </span>
                    ,{" "}
                    <span className=" hover:underline hover:text-indigo-600">
                      {" "}
                      <Link to={"/website-designing-company"}>
                        Web design and development
                      </Link>{" "}
                    </span>{" "}
                    ,{" "}
                    <span className=" hover:underline hover:text-indigo-600">
                      {" "}
                      <Link to={"/erp-develpment-company"}>
                        ERP Solutions
                      </Link>{" "}
                    </span>
                    ,
                    <span className=" hover:underline hover:text-indigo-600">
                      {" "}
                      <Link to={"/crm-development-company"}>CRM</Link>{" "}
                    </span>
                    ,{" "}
                    <span className=" hover:underline hover:text-indigo-600">
                      {" "}
                      <Link to={"/flight-booking-app-development-company"}>
                        Flight
                      </Link>{" "}
                    </span>{" "}
                    and{" "}
                    <span className=" hover:underline hover:text-indigo-600">
                      {" "}
                      <Link to={"/travel-portal-development-company"}>
                        Hotel booking portal
                      </Link>{" "}
                    </span>
                    ,{" "}
                    <span className=" hover:underline hover:text-indigo-600">
                      {" "}
                      <Link to={"/bus-car-booking-app-development-company"}>
                        Bus & Train booking
                      </Link>{" "}
                    </span>{" "}
                    and{" "}
                    <span className=" hover:underline hover:text-indigo-600">
                      {" "}
                      <Link to={"/bus-car-booking-app-development-company"}>
                        Cab booking
                      </Link>{" "}
                    </span>{" "}
                    and{" "}
                    <span className=" hover:underline hover:text-indigo-600">
                      {" "}
                      <Link to={"/technology-certification"}>
                        API integration
                      </Link>{" "}
                    </span>{" "}
                    are a few examples of services that company offers. Our
                    success story is being shared by a large number of satisfied
                    and happy customers worldwide, demonstrating the level of
                    satisfaction they have with our company.
                  </p>
                  <div className="mt-10 grid grid-cols-2 gap-10 md:grid-cols-3 rounded-xl">
                    <Link
                      to="/website-development-company"
                      className="inline-flex items-center bg-transparent"
                    >
                      <svg
                        className="text-indigo-600 font-bold w-8 h-8"
                        viewBox="0 0 32 32"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path stroke="currentColor" d="M.5.5h31v31H.5z"></path>
                        <path
                          d="M10 9c7.18 0 13 5.82 13 13m-13-7a7 7 0 0 1 7 7m-6 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0Z"
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        ></path>
                      </svg>
                      <span className="ml-3 text-sm">Web Services</span>
                    </Link>
                    <Link
                      to="/smart-products"
                      target="_blank"
                      className="inline-flex items-center"
                    >
                      <svg
                        className="text-indigo-600 w-8 h-8"
                        viewBox="0 0 32 32"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path stroke="currentColor" d="M.5.5h31v31H.5z"></path>
                        <path
                          d="M11 12h10-10Zm0 4h4-4Zm5 8-4-4H9a2 2 0 0 1-2-2v-8a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2h-3l-4 4Z"
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        ></path>
                      </svg>
                      <span className="ml-3 text-sm">Smart Products</span>
                    </Link>
                    <Link
                      to="/media-works"
                      className="inline-flex items-center"
                    >
                      <svg
                        className="text-indigo-600 w-8 h-8"
                        viewBox="0 0 32 32"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path stroke="currentColor" d="M.5.5h31v31H.5z"></path>
                        <path
                          d="M25 17.255A23.93 23.93 0 0 1 16 19c-3.183 0-6.22-.62-9-1.745M16 16h.01M20 10V8a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v2h8ZM9 24h14a2 2 0 0 0 2-2V12a2 2 0 0 0-2-2H9a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2Z"
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        ></path>
                      </svg>
                      <span className="ml-3 text-sm">Media Works</span>
                    </Link>
                  </div>
                  <div className="mt-8">
                    <FacebookBtn />
                  </div>
                </div>
              </div>{" "}
              <div className="flex justify-center lg:justify-end ">
                <div className="max-w-xs w-full py-8 px-5 border rounded-3xl bg-white/70 backdrop-blur-2xl hover:bg-white/30 border-gray-200 lg:px-12 transition hover:delay-200 hover:ease-in-out lg:border-indigo-600">
                  <div className="rounded-full mx-auto max-w-[150px] ">
                    <img
                      src={Logo}
                      className="w-full mx-auto rounded-full"
                      alt="Whiz-Technology"
                      srcSet=""
                    />
                  </div>

                  <h3 className="mt-8 font-bold text-indigo-600 uppercase text-xs">
                    Company Information
                  </h3>
                  <h2 className="mt-3 font-bold text-base">Company Message</h2>
                  <p className="mt-2 text-gray-700 text-sm leading-normal">
                    Your satisfaction is our priority, and we are committed to
                    providing prompt and effective assistance whenever you need
                    it.
                  </p>
                  <div className="group relative inline-flex mt-4">
                    <span className="w-full underline -rotate-12 inline-flex items-center rounded-lg justify-center self-stretch text-base lg:text-xl text-black text-center font-semibold font-sign">
                      Whiztechnolgy
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Slide>
        <Slide
          label="CEO & Founder"
          className="max-w-screen-xl lg:max-w-screen-2xl mx-auto"
        >
          <div className="relative max-w-screen-xl w-full mx-auto xl:px-5 mt-10 lg:mt-40">
            <div className="relative w-full grid gap-12 lg:grid-cols-3 place-content-center lg:place-content-between">
              <div className="w-full flex justify-center items-center px-5 xl:px-0 lg:col-span-2">
                <div className="w-full">
                  <h2 className="text-4xl font-bold md:text-4xl">About CEO</h2>
                  <p className="mt-6 text-gray-700 leading-relaxed">
                    On Whiz Technology, our designers and developers offer our
                    clients ingenious and creative products and services that
                    offer a comprehensive solution. Among the plenty of
                    services, Software development, Web design and development,
                    ERP, CRM, Flight and hotel booking portal, Train ticket and
                    cab booking portal and API integration are a few examples of
                    services that company offers. Our success story is being
                    shared by a large number of satisfied and happy customers
                    worldwide, demonstrating the level of satisfaction they have
                    with our company.
                  </p>
                  <div className="mt-10 grid grid-cols-2 gap-10 md:grid-cols-3 rounded-xl">
                    <Link
                      to="/website-development-company"
                      className="inline-flex items-center bg-transparent"
                    >
                      <svg
                        className="text-indigo-600 font-bold w-8 h-8"
                        viewBox="0 0 32 32"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path stroke="currentColor" d="M.5.5h31v31H.5z"></path>
                        <path
                          d="M10 9c7.18 0 13 5.82 13 13m-13-7a7 7 0 0 1 7 7m-6 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0Z"
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        ></path>
                      </svg>
                      <span className="ml-3 text-sm">Web Services</span>
                    </Link>
                    <Link
                      to="/smart-products"
                      target="_blank"
                      className="inline-flex items-center"
                    >
                      <svg
                        className="text-indigo-600 w-8 h-8"
                        viewBox="0 0 32 32"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path stroke="currentColor" d="M.5.5h31v31H.5z"></path>
                        <path
                          d="M11 12h10-10Zm0 4h4-4Zm5 8-4-4H9a2 2 0 0 1-2-2v-8a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2h-3l-4 4Z"
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        ></path>
                      </svg>
                      <span className="ml-3 text-sm">Smart Products</span>
                    </Link>
                    <Link
                      to="/media-works"
                      className="inline-flex items-center"
                    >
                      <svg
                        className="text-indigo-600 w-8 h-8"
                        viewBox="0 0 32 32"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path stroke="currentColor" d="M.5.5h31v31H.5z"></path>
                        <path
                          d="M25 17.255A23.93 23.93 0 0 1 16 19c-3.183 0-6.22-.62-9-1.745M16 16h.01M20 10V8a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v2h8ZM9 24h14a2 2 0 0 0 2-2V12a2 2 0 0 0-2-2H9a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2Z"
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        ></path>
                      </svg>
                      <span className="ml-3 text-sm">Media Works</span>
                    </Link>
                  </div>
                  <div className="mt-8">
                    <FacebookBtn />
                  </div>
                </div>
              </div>{" "}
              <div className="flex justify-center lg:justify-end ">
                <div className="max-w-xs w-full py-8 px-5 border rounded-3xl bg-white/70 backdrop-blur-2xl hover:bg-white/30 border-gray-200 lg:px-12 transition hover:delay-200 hover:ease-in-out lg:border-indigo-600">
                  <div className="rounded-full mx-auto max-w-[150px] ">
                    <img
                      src={SanjaySir}
                      className="w-full mx-auto rounded-full"
                      alt="CEO & Founder"
                      srcSet=""
                    />
                  </div>

                  <h3 className="mt-8 font-bold text-indigo-600 uppercase text-xs">
                    CEO & Founder
                  </h3>
                  <h2 className="mt-3 font-bold text-base">(CEO) Message</h2>
                  <p className="mt-2 text-gray-700 text-sm leading-normal">
                    Position yourself and your company as thought leaders in the
                    IT industry. Produce high-quality, informative content that
                    showcases your expertise and demonstrates your deep
                    understanding of industry trends, challenges, and solutions.
                  </p>
                  <div className="group relative inline-flex mt-4">
                    <span className="w-full underline -rotate-12 inline-flex items-center rounded-lg justify-center self-stretch text-base lg:text-xl text-black text-center font-semibold font-sign">
                      Sanjaysingh
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Slide>
        <Slide
          label="Managing Director"
          className="max-w-screen-xl lg:max-w-screen-2xl mx-auto"
        >
          <div className="relative md:max-w-screen-xl max-w-xs w-full mx-auto xl:px-5 mt-10 lg:mt-40">
            <div className="relative w-full grid gap-12 lg:grid-cols-3 place-content-center lg:place-content-between">
              <div className="w-full flex justify-center items-center px-5 xl:px-0 lg:col-span-2">
                <div className="w-full">
                  <h2 className="text-4xl font-bold md:text-4xl">
                    About Director
                  </h2>
                  <p className="mt-6 text-gray-700 leading-relaxed">
                    On Whiz Technology, our designers and developers offer our
                    clients ingenious and creative products and services that
                    offer a comprehensive solution. Among the plenty of
                    services, Software development, Web design and development,
                    ERP, CRM, Flight and hotel booking portal, Train ticket and
                    cab booking portal and API integration are a few examples of
                    services that company offers. Our success story is being
                    shared by a large number of satisfied and happy customers
                    worldwide, demonstrating the level of satisfaction they have
                    with our company.
                  </p>
                  <div className="mt-10 grid grid-cols-2 gap-10 md:grid-cols-3 rounded-xl">
                    <Link
                      to="/website-development-company"
                      className="inline-flex items-center bg-transparent"
                    >
                      <svg
                        className="text-indigo-600 font-bold w-8 h-8"
                        viewBox="0 0 32 32"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path stroke="currentColor" d="M.5.5h31v31H.5z"></path>
                        <path
                          d="M10 9c7.18 0 13 5.82 13 13m-13-7a7 7 0 0 1 7 7m-6 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0Z"
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        ></path>
                      </svg>
                      <span className="ml-3 text-sm">Web Services</span>
                    </Link>
                    <Link
                      to="/smart-products"
                      target="_blank"
                      className="inline-flex items-center"
                    >
                      <svg
                        className="text-indigo-600 w-8 h-8"
                        viewBox="0 0 32 32"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path stroke="currentColor" d="M.5.5h31v31H.5z"></path>
                        <path
                          d="M11 12h10-10Zm0 4h4-4Zm5 8-4-4H9a2 2 0 0 1-2-2v-8a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2h-3l-4 4Z"
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        ></path>
                      </svg>
                      <span className="ml-3 text-sm">Smart Products</span>
                    </Link>
                    <Link
                      to="/media-works"
                      className="inline-flex items-center"
                    >
                      <svg
                        className="text-indigo-600 w-8 h-8"
                        viewBox="0 0 32 32"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path stroke="currentColor" d="M.5.5h31v31H.5z"></path>
                        <path
                          d="M25 17.255A23.93 23.93 0 0 1 16 19c-3.183 0-6.22-.62-9-1.745M16 16h.01M20 10V8a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v2h8ZM9 24h14a2 2 0 0 0 2-2V12a2 2 0 0 0-2-2H9a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2Z"
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        ></path>
                      </svg>
                      <span className="ml-3 text-sm">Media Works</span>
                    </Link>
                  </div>
                  <div className="mt-8">
                    <FacebookBtn />
                  </div>
                </div>
              </div>{" "}
              <div className="flex justify-center lg:justify-end w-full">
                <div className="max-w-xs w-full py-8 px-5 border rounded-3xl bg-white/70 backdrop-blur-2xl hover:bg-white/30 border-gray-200 lg:px-12 transition hover:delay-200 hover:ease-in-out lg:border-indigo-600">
                  <div className="rounded-full mx-auto max-w-[150px] ">
                    <img
                      src={HimanshuSir}
                      className="mx-auto rounded-full w-full"
                      alt="Whiz-Technology-Director"
                    />
                  </div>

                  <h3 className="mt-8 font-bold text-indigo-600 uppercase text-xs">
                    Managing Director
                  </h3>
                  <h2 className="mt-3 font-bold text-base">
                    (Director) Message
                  </h2>
                  <p className="mt-2 text-gray-700 text-sm leading-normal">
                    Position yourself and your company as thought leaders in the
                    IT industry. Produce high-quality, informative content that
                    showcases your expertise and demonstrates your deep
                    understanding of industry trends, challenges, and solutions.
                  </p>
                  <div className="group relative inline-flex mt-4">
                    <span className="w-full underline -rotate-12 inline-flex items-center rounded-lg justify-center self-stretch text-base lg:text-xl text-black text-center font-semibold font-sign">
                      Himanshushekhar
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Slide>
        <MenuNav />
      </HeroSlider>
      <div className="relative max-w-screen-xl w-full mx-auto xl:px-5 mt-20 lg:mt-40 block lg:hidden">
        <div className="relative w-full flex flex-col lg:flex-row justify-center md:justify-around">
          <div className="w-full flex justify-center items-center px-5 xl:px-0 col-span-1 lg:col-span-2 max-w-screen-xl lg:w-2/3">
            <div className="w-full mx-auto">
              <h2 className="text-4xl font-bold md:text-4xl">About Company</h2>
              <p className="mt-6 text-gray-700 leading-relaxed">
                On Whiz Technology, our designers and developers offer our
                clients ingenious and creative products and services that offer
                a comprehensive solution. Among the plenty of services, Software
                development, Web design and development, ERP, CRM, Flight and
                hotel booking portal, Train ticket and cab booking portal and
                API integration are a few examples of services that company
                offers. Our success story is being shared by a large number of
                satisfied and happy customers worldwide, demonstrating the level
                of satisfaction they have with our company.
              </p>
              <div className="mt-10 grid grid-cols-2 gap-10 md:grid-cols-3 rounded-xl">
                <Link
                  to="/website-development-company"
                  className="inline-flex items-center bg-transparent"
                >
                  <svg
                    className="text-indigo-600 font-bold w-8 h-8"
                    viewBox="0 0 32 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path stroke="currentColor" d="M.5.5h31v31H.5z"></path>
                    <path
                      d="M10 9c7.18 0 13 5.82 13 13m-13-7a7 7 0 0 1 7 7m-6 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0Z"
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></path>
                  </svg>
                  <span className="ml-3 text-sm">Web Services</span>
                </Link>
                <Link
                  to="/smart-products"
                  target="_blank"
                  className="inline-flex items-center"
                >
                  <svg
                    className="text-indigo-600 w-8 h-8"
                    viewBox="0 0 32 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path stroke="currentColor" d="M.5.5h31v31H.5z"></path>
                    <path
                      d="M11 12h10-10Zm0 4h4-4Zm5 8-4-4H9a2 2 0 0 1-2-2v-8a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2h-3l-4 4Z"
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></path>
                  </svg>
                  <span className="ml-3 text-sm">Smart Products</span>
                </Link>
                <Link to="/media-works" className="inline-flex items-center">
                  <svg
                    className="text-indigo-600 w-8 h-8"
                    viewBox="0 0 32 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path stroke="currentColor" d="M.5.5h31v31H.5z"></path>
                    <path
                      d="M25 17.255A23.93 23.93 0 0 1 16 19c-3.183 0-6.22-.62-9-1.745M16 16h.01M20 10V8a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v2h8ZM9 24h14a2 2 0 0 0 2-2V12a2"
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></path>
                  </svg>
                  <span className="ml-3 text-sm">Media Works</span>
                </Link>
              </div>
              <div className="mt-8 hidden md:block">
                <FacebookBtn />
              </div>
            </div>
          </div>{" "}
          <div className="flex justify-center lg:justify-end w-full lg:w-1/3 mt-6 p-3 md:p-0">
            <div className="md:max-w-xs w-full py-8 px-5 border rounded-3xl bg-white/70 backdrop-blur-2xl hover:bg-white/30 border-gray-200 lg:px-12 transition hover:delay-200 hover:ease-in-out lg:border-indigo-600">
              <div className="rounded-full mx-auto max-w-[150px] ">
                <img
                  src={SanjaySir}
                  className="w-full mx-auto rounded-full"
                  alt="CEO & Founder"
                  srcSet=""
                />
              </div>

              <h3 className="mt-8 font-bold text-indigo-600 uppercase text-xs">
                CEO & Founder
              </h3>
              <h2 className="mt-3 font-bold text-base">(CEO) Message</h2>
              <p className="mt-2 text-gray-700 text-sm leading-normal">
                Position yourself and your company as thought leaders in the IT
                industry. Produce high-quality, informative content that
                showcases your expertise and demonstrates your deep
                understanding of industry trends, challenges, and solutions.
              </p>
              <div className="group relative inline-flex mt-4">
                <span className="w-full underline -rotate-12 inline-flex items-center rounded-lg justify-center self-stretch text-base lg:text-xl text-black text-center font-semibold font-sign">
                  Sanjaysingh
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Corousel />
      <div className="max-w-screen-xl mx-auto py-4 md:py-8 px-4 bg-white/70 rounded-xl">
        <div className="text-start">
          <div className="flex flex-row max-w-fit px-2 py-1.5 space-x-3 justify-center items-center rounded-xl bg-indigo-500 mb-4">
            <span className="text-[10px] md:text-xs text-white">Our Story</span>{" "}
            <span>
              <ChevronRight sx={{ color: "white" }} className="w-3 h-3" />
            </span>
          </div>
          <p className="text-gray-700 leading-relaxed">
            Whiz Technology began its engagement in 2013 as a graphic designer
            company with a small group of professionals in New Delhi. During
            2014 - 17 we expand our activity with web based application and SEO
            (Search Engine Optimization). Together with our experienced
            developers and a robust marketing team, we have successfully
            encapsulated a wide range of domestic and international clients. In
            2018-19, our developers, SEO experts and marketing leaders worked
            together for the company's growth and return on investment. The
            company developed and managed diverse web applications in many
            fields such as hotels, interior design, matrimonial and many more.
            During the challenging times of COVID-19 in 2020, our team struggled
            and answered the customer's request with a dazzling smile. We began
            developing CRM, ERP and mobile applications in 2022 with a
            distinctive team of developers and marketing experts.
          </p>
        </div>
        <div className="text-start mt-5">
          <div className="flex flex-row max-w-fit px-2 py-1.5 space-x-3 justify-center items-center rounded-xl bg-indigo-500 mb-4">
            <span className="text-[10px] md:text-xs text-white">
              Our Mission
            </span>{" "}
            <span>
              <ChevronRight sx={{ color: "white" }} className="w-3 h-3" />
            </span>
          </div>
          <p className="text-gray-700 leading-relaxed">
            We analyse that technology is havoc for common people. They have a
            lot of fear and confusion when they try to use technology. In the
            modern digital world, our mission is to define technology through
            the fingertips of everyday people. Our professionals have always
            tried to develop applications, Web sites, ERP, CRM, software (API)
            and much more with minimal technical complexity and user-friendly
            environment. Our professionals empower clients or individuals with
            their technical and marketing expertise to save money and time while
            they are planning to start a new business or enhancing their
            existing business
          </p>
        </div>
      </div>
    </>
  );
};

export default AboutHeader;
