import React, { useRef } from "react";
import Slider from "react-slick";
import RightArrow from "assets/logo/right-chevron.png";
import LeftArrow from "assets/logo/left-chevron.png";
import styled from "styled-components";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const sliderData = [
  {
    id: 1,
    image:
      "https://res.cloudinary.com/whiztechnology/image/upload/v1690995794/Uploads/slide-01_wusjq9.jpg",
  },
  {
    id: 2,
    image:
      "https://res.cloudinary.com/whiztechnology/image/upload/v1690995794/Uploads/slide-06_vrvflq.jpg",
  },
  {
    id: 3,
    image:
      "https://res.cloudinary.com/whiztechnology/image/upload/v1690995794/Uploads/slide-02_wk4uar.jpg",
  },
  {
    id: 4,
    image:
      "https://res.cloudinary.com/whiztechnology/image/upload/v1690995794/Uploads/slide-04_tsvdzb.jpg",
  },
  {
    id: 5,
    image:
      "https://res.cloudinary.com/whiztechnology/image/upload/v1690995794/Uploads/slide-05_cjuemh.jpg",
  },
  {
    id: 6,
    image:
      "https://res.cloudinary.com/whiztechnology/image/upload/v1690995794/Uploads/slide-03_upqw3k.jpg",
  },
  {
    id: 7,
    image:
      "https://res.cloudinary.com/whiztechnology/image/upload/v1690995794/Uploads/slide-07_tad85w.jpg",
  },
  // {
  //   id: 8,
  //   image:
  //     "https://res.cloudinary.com/whiztechnology/image/upload/v1690995945/Uploads/slide-08_hlxufh.jpg",
  // },
];

var settings = {
  className: "center",
  autoplay: true,
  autoplaySpeed: 2000,
  centerMode: true,
  dots: false,
  infinite: true,
  speed: 1000,
  slidesToShow: 5,
  slidesToScroll: 4,
  initialSlide: 0,
  arrows: false,
  responsive: [
    {
      breakpoint: 990,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 1,
        infinite: true,
        dots: false,
        centerMode: false,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 2,
        initialSlide: 2,
        centerMode: false,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        centerMode: false,
      },
    },
  ],
};
const SliderComp = () => {
  const arrowRef = useRef(null);
  let sliderProject = "";
  sliderProject = sliderData.map((item) => (
    <div className="">
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        as="div"
      >
        <Card
          sx={{ maxWidth: 207, mx: "auto", backgroundColor: "transparent" }}
          className="max-w-xs bg-transparent"
        >
          <img
            className="bg-transparent"
            src={item.image}
            title="Our Partners"
            alt=""
          />
        </Card>
      </Box>
    </div>
  ));
  return (
    <Container className=" bg-transparent max-w-screen-xl overflow-hidden rounded-md py-4 px-2">
      <Slider ref={arrowRef} {...settings} className=" max-w-screen-xl">
        {sliderProject}
      </Slider>
      <Buttons>
        <button onClick={() => arrowRef.current.slickPrev()} className="back">
          <img
            src={LeftArrow}
            alt="Left Arrow"
            className=" h-5 w-5"
            srcset=""
          />
          {/* <IoIosArrowBack /> */}
        </button>
        <button onClick={() => arrowRef.current.slickNext()} className="next">
          <img
            src={RightArrow}
            alt="Right Arrow"
            className=" h-5 w-5"
            srcset=""
          />
          {/* <IoIosArrowForward /> */}
        </button>
      </Buttons>
    </Container>
  );
};

export default SliderComp;

const Container = styled.div`
  position: relative;
  overflow: hidden;
`;

const Buttons = styled.div`
  button {
    width: 2rem;
    height: 2rem;
    background-color: rgba(255, 255, 255, 0.1);
    cursor: pointer;
    color: #01be96;
    border: none;
    position: absolute;
    top: 50%;
    right: 1rem;
    margin-top: -20px;
  }

  .back {
    left: 1rem;
  }
`;
