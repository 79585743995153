import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import CardPayment from "assets/images/card-payment.png";
import Typography from "@mui/material/Typography";
import Footer from "components/Footers/Footer.js";
import BusinessPng from "assets/images/busness.png";
import { Tab } from "@headlessui/react";
import BusCarBookingPng from "assets/images/bus_car_booking_banner.png";
import BusCarSection1png from "assets/images/bus_car_bokking1.png";
import PopUoForm from "components/Forms/PopUoForm";
import StickyEnquryForm from "components/Forms/StickyEnquryForm";
import Trusted from "components/Footers/Trusted";
import Corousel from "components/Corousel/Corousel";
import SmartTag from "components/Development/SmartTag";
import FloatingWp from "components/Development/FloatingWp";
import Faq from "components/Development/Faq";
import Preloader from "components/Development/Preloader";

const BusCarBooking = () => {
  const accordionData = [
    {
      id: 1,
      title: "Q1: What is a web service?",
      data: "A web service is a software system designed to enable communication and interoperability between different applications over a network, typically the internet. It allows applications to exchange data and perform various operations using standard protocols such as HTTP and XML. Web services are commonly used for integrating different systems, sharing data, and facilitating communication between applications developed on different platforms.",
    },
    {
      id: 2,
      title: "Q2: What are the advantages of using web services?",
      data: "Web services offer several advantages, including: Interoperability: Web services allow applications developed on different platforms to communicate and exchange data seamlessly",
    },
    {
      id: 3,
      title: "Q4: How can I consume a web service in my application?",
      data: `Identify the type of web service (SOAP, REST, GraphQL) and its endpoint URL. Use a programming language or framework that supports web service consumption. Generate or write code to make HTTP requests to the web service endpoint. Handle the responses returned by the web service and parse the data as needed. Integrate the consumed data into your application's logic or user interface.`,
    },
  ];

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  const [categories] = useState({
    "Customer/Passenger Panel": [
      {
        id: 1,
        title: "Registration/Login",
      },
      {
        id: 2,
        title: "Online payment via various modes",
      },
      {
        id: 3,
        title: "Secure login",
      },
      {
        id: 4,
        title: "View top routes, top cities & offers",
      },
      {
        id: 5,
        title:
          "Search bus availability by source, destination, date range, stops",
      },
      {
        id: 6,
        title: "Manage profile",
      },
      {
        id: 7,
        title: "View Available buses with different transport agencies",
      },
      {
        id: 8,
        title: "Multicurrency payment support",
      },
      {
        id: 9,
        title:
          "Sort & filter buses based on Amenities, dropping and boarding points, fare, time, seats available",
      },
      {
        id: 10,
        title: "Ask for customer support",
      },
      {
        id: 11,
        title: "Graphical Map of booked & available seats",
      },
      {
        id: 12,
        title: "Request refund or cancel tickets",
      },
      {
        id: 13,
        title: "Choose seat and book tickets",
      },
      {
        id: 14,
        title: "Track bus live location",
      },
    ],
    "Operator/Travel Agency Panel": [
      {
        id: 1,
        title: "Registration and secure login",
      },
      {
        id: 2,
        title: "Push Notification and alerts",
      },
      {
        id: 3,
        title: "Create/Manage profile",
      },
      {
        id: 4,
        title: "Manage Ticket scanning",
      },
      {
        id: 5,
        title: "On Dashboard view total bookings, refunds, total revenue",
      },
      {
        id: 6,
        title: "View Sales summary",
      },
      {
        id: 7,
        title: "Manage bus routes and booking",
      },
      {
        id: 8,
        title: "View Customer reviews and ratings",
      },
      {
        id: 9,
        title: "Manage ticket booking",
      },
      {
        id: 10,
        title: "Create/Manage tourist packages",
      },
      {
        id: 11,
        title: "Reporting and analytics",
      },
      {
        id: 12,
        title: "Check real-time passenger boarding",
      },
      {
        id: 13,
        title: "Live bus tracking",
      },
    ],
    "Admin Panel": [
      {
        id: 1,
        title: "Register and Secure Login",
      },
      {
        id: 2,
        title: "Push Notifications",
      },
      {
        id: 3,
        title: "Manage profile",
      },
      {
        id: 4,
        title: "Manage user permission",
      },
      {
        id: 5,
        title: "View admin dashboard",
      },
      {
        id: 6,
        title: "Manage email and SMS marketing",
      },
      {
        id: 7,
        title: "Manage users and travel agency/operators",
      },
      {
        id: 8,
        title: "CMS and CRM integration",
      },
      {
        id: 9,
        title: "Manage ad promotions and affiliate partners",
      },
      {
        id: 10,
        title: "Payment gateway integration",
      },
      {
        id: 11,
        title: "Create/Manage vouchers and discounts",
      },
      {
        id: 12,
        title: "Live bus tracking",
      },
      {
        id: 13,
        title: "Manage Customer reviews and ratings",
      },
      {
        id: 14,
        title:
          "Manage transactions and billing (refund, payout to operators, profit and income)",
      },
      {
        id: 15,
        title: "Manage ticket refund and cancellations",
      },
    ],
  });

  const [isLoading, setisLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setisLoading(false);
    }, 1000);
  }, []);

  return (
    <>
      {isLoading === true ? (
        <Preloader />
      ) : (
        <div className="">
          {" "}
          <div className="fixed bg-hero-pattern bg-fixed bg-cover bg-center inset-0 -z-20"></div>
          <div className="App relative">
            {" "}
            <IndexNavbar fixed />
            {/* <!-- Start block --> */}
            <section className="">
              <div className="grid max-w-screen-xl px-4 pt-20 pb-8 mx-auto lg:gap-8 xl:gap-0 lg:py-16 lg:grid-cols-12 lg:pt-28">
                <div className="mr-auto place-self-center lg:col-span-7">
                  <SmartTag
                    title="Best"
                    description="Bus & Car Booking App Development Company in India"
                  />
                  <h1 className="max-w-2xl mb-4 text-4xl uppercase font-extrabold leading-none tracking-tight sm:text- xl:text-4xl dark:text-white">
                    Bus & Car Booking Mobile App Development COMPANY
                  </h1>
                  <p className="max-w-2xl mb-6 font-light text-gray-500 lg:mb-8 md:text-base lg:text-bse dark:text-gray-400">
                    We specialize in building high-quality, user-friendly, and
                    feature-rich bus and flight booking applications. Our goal
                    is to provide seamless and convenient travel experiences to
                    users while helping businesses in the travel industry expand
                    their reach and enhance their customer base.
                  </p>
                  <div className="space-y-4 sm:flex sm:space-y-0 sm:space-x-4">
                    <Link
                      to={"/"}
                      target=""
                      className="inline-block px-6 py-2.5 bg-[#44619d] text-white font-medium text-xs leading-tight uppercase rounded-full shadow-md hover:bg-blue-500 hover:shadow-lg focus:bg-blue-500 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-600 active:shadow-lg transition duration-150 ease-in-out"
                    >
                      Case Study
                    </Link>
                    <Link
                      to={"/contact-us"}
                      className=" mx-2 inline-block px-6 py-2.5 bg-gray-800 text-white font-medium text-xs leading-tight uppercase rounded-full shadow-md hover:bg-gray-900 hover:shadow-lg focus:bg-gray-900 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-gray-900 active:shadow-lg transition duration-150 ease-in-out"
                      target=""
                    >
                      Contact Us
                    </Link>
                  </div>
                </div>
                <div className="hidden lg:mt-0 lg:col-span-5 lg:flex">
                  <img src={BusCarBookingPng} alt="heroimage" />
                </div>
              </div>
            </section>
            {/* <!-- End block --> */}
            <section className=" max-w-screen-xl mx-auto">
              <Corousel />
            </section>
            <section className=" bg-transparent">
              <div className="max-w-screen-xl rounded-md backdrop-blur-sm bg-white/30 px-4 py-8 mx-auto space-y-12 lg:space-y-20 lg:py-24 lg:px-6">
                {/* <!-- Row --> */}
                <div className="items-center gap-8 lg:grid lg:grid-cols-2 xl:gap-16">
                  <div className="text-gray-500 sm:text-lg dark:text-gray-400">
                    <h2 className="mb-4 text-3xl font-extrabold tracking-tight text-gray-900 dark:text-white">
                      Advance Features To Develop Bus Ticket Booking App
                    </h2>
                    <Typography
                      variant="subtitle"
                      className="mb-8 leading-normal font-light"
                      gutterBottom
                    >
                      We are known globally as the best ecommerce app
                      development company that offers world-class ecommerce
                      development services. Our experts know what features
                      convert the fence viewers into loyal buyers.
                    </Typography>

                    {/* <!-- List --> */}
                    <ul className="pt-2 space-y-5 border-t border-gray-200 my-7 dark:border-gray-700">
                      <li className="flex ">
                        {/* <!-- Icon --> */}
                        <Link
                          to={"/appdevelopement"}
                          className=" cursor-pointer flex space-x-3 hover:underline hover:text-purple-500"
                        >
                          <svg
                            className="flex-shrink-0 w-5 h-5 text-purple-500 dark:text-purple-400"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                              clipRule="evenodd"
                            ></path>
                          </svg>
                          <span className="text-base font-medium leading-tight text-gray-900 dark:text-white">
                            Social Signup/Login
                          </span>
                        </Link>
                      </li>
                      <li className="flex space-x-3">
                        {/* <!-- Icon --> */}
                        <svg
                          className="flex-shrink-0 w-5 h-5 text-purple-500 dark:text-purple-400"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                            clipRule="evenodd"
                          ></path>
                        </svg>
                        <span className="text-base font-medium leading-tight text-gray-900 dark:text-white">
                          Push Notifications
                        </span>
                      </li>
                      <li className="flex space-x-3">
                        {/* <!-- Icon --> */}
                        <svg
                          className="flex-shrink-0 w-5 h-5 text-purple-500 dark:text-purple-400"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                            clipRule="evenodd"
                          ></path>
                        </svg>
                        <span className="text-base font-medium leading-tight text-gray-900 dark:text-white">
                          Live Bus Tracking
                        </span>
                      </li>
                      <li className="flex space-x-3">
                        {/* <!-- Icon --> */}
                        <svg
                          className="flex-shrink-0 w-5 h-5 text-purple-500 dark:text-purple-400"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                            clipRule="evenodd"
                          ></path>
                        </svg>
                        <span className="text-base font-medium leading-tight text-gray-900 dark:text-white">
                          Real-Time Seat Check{" "}
                        </span>
                      </li>
                      <li className="flex space-x-3">
                        {/* <!-- Icon --> */}
                        <svg
                          className="flex-shrink-0 w-5 h-5 text-purple-500 dark:text-purple-400"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                            clipRule="evenodd"
                          ></path>
                        </svg>
                        <span className="text-base font-medium leading-tight text-gray-900 dark:text-white">
                          Ticket Validation by QR Code{" "}
                        </span>
                      </li>
                      <li className="flex space-x-3">
                        {/* <!-- Icon --> */}
                        <svg
                          className="flex-shrink-0 w-5 h-5 text-purple-500 dark:text-purple-400"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                            clipRule="evenodd"
                          ></path>
                        </svg>
                        <span className="text-base font-medium leading-tight text-gray-900 dark:text-white">
                          CRM Integration{" "}
                        </span>
                      </li>
                      <li className="flex space-x-3">
                        {/* <!-- Icon --> */}
                        <svg
                          className="flex-shrink-0 w-5 h-5 text-purple-500 dark:text-purple-400"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                            clipRule="evenodd"
                          ></path>
                        </svg>
                        <span className="text-base font-medium leading-tight text-gray-900 dark:text-white">
                          Chat Support Integration{" "}
                        </span>
                      </li>
                      <li className="flex space-x-3">
                        {/* <!-- Icon --> */}
                        <svg
                          className="flex-shrink-0 w-5 h-5 text-purple-500 dark:text-purple-400"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                            clipRule="evenodd"
                          ></path>
                        </svg>
                        <span className="text-base font-medium leading-tight text-gray-900 dark:text-white">
                          Marketing & Promotion Tools{" "}
                        </span>
                      </li>
                      <li className="flex space-x-3">
                        {/* <!-- Icon --> */}
                        <svg
                          className="flex-shrink-0 w-5 h-5 text-purple-500 dark:text-purple-400"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                            clipRule="evenodd"
                          ></path>
                        </svg>
                        <span className="text-base font-medium leading-tight text-gray-900 dark:text-white">
                          Real Analytics & Dashboard
                        </span>
                      </li>
                      <li className="flex space-x-3">
                        {/* <!-- Icon --> */}
                        <svg
                          className="flex-shrink-0 w-5 h-5 text-purple-500 dark:text-purple-400"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                            clipRule="evenodd"
                          ></path>
                        </svg>
                        <span className="text-base font-medium leading-tight text-gray-900 dark:text-white">
                          Loyalty Program Integration{" "}
                        </span>
                      </li>
                      <li className="flex space-x-3">
                        {/* <!-- Icon --> */}
                        <svg
                          className="flex-shrink-0 w-5 h-5 text-purple-500 dark:text-purple-400"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                            clipRule="evenodd"
                          ></path>
                        </svg>
                        <span className="text-base font-medium leading-tight text-gray-900 dark:text-white">
                          In-App Navigation{" "}
                        </span>
                      </li>
                      <li className="flex space-x-3">
                        {/* <!-- Icon --> */}
                        <svg
                          className="flex-shrink-0 w-5 h-5 text-purple-500 dark:text-purple-400"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                            clipRule="evenodd"
                          ></path>
                        </svg>
                        <span className="text-base font-medium leading-tight text-gray-900 dark:text-white">
                          Third-Party Hotel & Taxi Booking Integration{" "}
                        </span>
                      </li>
                    </ul>
                    <p className="mb-8 font-light lg:text-xl">
                      Deliver great service experiences fast - without the
                      complexity of traditional ITSM solutions.
                    </p>
                  </div>
                  <img
                    className="w-full verticalAnimate mb-4 rounded-lg lg:mb-0 lg:flex"
                    src={BusCarSection1png}
                    alt="eCoomerce App"
                  />
                </div>
                {/* <!-- Row --> */}
                <div className="items-center gap-8 lg:grid lg:grid-cols-2 xl:gap-16">
                  <img
                    className="w-full mb-4 verticalAnimate rounded-lg lg:mb-0 lg:flex"
                    src={BusinessPng}
                    alt="featureimage 2"
                  />
                  <div className="text-gray-500 sm:text-lg dark:text-gray-400">
                    <h2 className="mb-4 text-3xl font-extrabold tracking-tight text-gray-900 dark:text-white">
                      Whiz Technology Bus Booking App Solution
                    </h2>
                    <Typography
                      variant="subtitle"
                      className="mb-8 leading-normal font-light"
                      gutterBottom
                    >
                      Do you want to upgrade your bus ticket booking system?
                      Connect with the OnDemand App and get readymade online bus
                      ticketing software like RedBus, ixigo, and Goibibo. <br />
                      <br />
                      We have been in the OnDemand industry for the past ten
                      years and have successfully developed several online bus
                      booking software solutions. We can help you automate and
                      streamline your operations and give your customers the
                      convenience of booking bus tickets online. <br />
                      <br />
                      Grow your travel business with the top bus booking app
                      development company in India.
                    </Typography>
                    {/* <!-- List --> */}
                    <ul className="pt-8 space-y-5 border-t border-gray-200 my-7 dark:border-gray-700">
                      <li className="flex space-x-3">
                        {/* <!-- Icon --> */}
                        <svg
                          className="flex-shrink-0 w-5 h-5 text-purple-500 dark:text-purple-400"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                            clipRule="evenodd"
                          ></path>
                        </svg>
                        <span className="text-base font-medium leading-tight text-gray-900 dark:text-white">
                          Automated Operations
                        </span>
                      </li>
                      <li className="flex space-x-3">
                        {/* <!-- Icon --> */}
                        <svg
                          className="flex-shrink-0 w-5 h-5 text-purple-500 dark:text-purple-400"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                            clipRule="evenodd"
                          ></path>
                        </svg>
                        <span className="text-base font-medium leading-tight text-gray-900 dark:text-white">
                          Improved Brand Recall
                        </span>
                      </li>
                      <li className="flex space-x-3">
                        {/* <!-- Icon --> */}
                        <svg
                          className="flex-shrink-0 w-5 h-5 text-purple-500 dark:text-purple-400"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                            clipRule="evenodd"
                          ></path>
                        </svg>
                        <span className="text-base font-medium leading-tight text-gray-900 dark:text-white">
                          Higher Revenues
                        </span>
                      </li>
                      <li className="flex space-x-3">
                        {/* <!-- Icon --> */}
                        <svg
                          className="flex-shrink-0 w-5 h-5 text-purple-500 dark:text-purple-400"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                            clipRule="evenodd"
                          ></path>
                        </svg>
                        <span className="text-base font-medium leading-tight text-gray-900 dark:text-white">
                          Improved Occupancy
                        </span>
                      </li>
                    </ul>
                    <p className="font-light lg:text-xl">
                      Deliver great service experiences fast - without the
                      complexity of traditional ITSM solutions.
                    </p>
                  </div>
                </div>
                {/* Row */}
                <div className="items-center gap-8 lg:grid lg:grid-cols-2 xl:gap-16">
                  <div className="text-gray-500 sm:text-lg dark:text-gray-400">
                    <h2 className="mb-4 text-3xl font-extrabold tracking-tight text-gray-900 dark:text-white">
                      Ecommerce Software Development Solutions that Target
                      Conversion and Retention
                    </h2>
                    <p className="mb-8 font-light lg:text-xl">
                      The moment a visitor lands in your ecommerce application
                      at the checkout stage, we monitor every interaction. We
                      are obsessed with analytics. Our ecommerce application
                      development services utilize scientific metrics to analyze
                      visitor history and decipher patterns that impact their
                      purchase decisions.
                    </p>
                    {/* <!-- List --> */}
                    <ul className="pt-8 space-y-5 border-t border-gray-200 my-7 dark:border-gray-700">
                      <li className="flex space-x-3">
                        {/* <!-- Icon --> */}
                        <svg
                          className="flex-shrink-0 w-5 h-5 text-purple-500 dark:text-purple-400"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                            clipRule="evenodd"
                          ></path>
                        </svg>
                        <span className="text-base font-medium leading-tight text-gray-900 dark:text-white">
                          eCommerce market analysis and assessment
                        </span>
                      </li>
                      <li className="flex space-x-3">
                        {/* <!-- Icon --> */}
                        <svg
                          className="flex-shrink-0 w-5 h-5 text-purple-500 dark:text-purple-400"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                            clipRule="evenodd"
                          ></path>
                        </svg>
                        <span className="text-base font-medium leading-tight text-gray-900 dark:text-white">
                          Mobile Commerce App Development
                        </span>
                      </li>
                      <li className="flex space-x-3">
                        {/* <!-- Icon --> */}
                        <svg
                          className="flex-shrink-0 w-5 h-5 text-purple-500 dark:text-purple-400"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                            clipRule="evenodd"
                          ></path>
                        </svg>
                        <span className="text-base font-medium leading-tight text-gray-900 dark:text-white">
                          Migration from eCommerce to mCommerce
                        </span>
                      </li>
                      <li className="flex space-x-3">
                        {/* <!-- Icon --> */}
                        <svg
                          className="flex-shrink-0 w-5 h-5 text-purple-500 dark:text-purple-400"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                            clipRule="evenodd"
                          ></path>
                        </svg>
                        <span className="text-base font-medium leading-tight text-gray-900 dark:text-white">
                          Ecommerce app design
                        </span>
                      </li>
                      <li className="flex space-x-3">
                        {/* <!-- Icon --> */}
                        <svg
                          className="flex-shrink-0 w-5 h-5 text-purple-500 dark:text-purple-400"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                            clipRule="evenodd"
                          ></path>
                        </svg>
                        <span className="text-base font-medium leading-tight text-gray-900 dark:text-white">
                          mCommerce banking
                        </span>
                      </li>
                      <li className="flex space-x-3">
                        {/* <!-- Icon --> */}
                        <svg
                          className="flex-shrink-0 w-5 h-5 text-purple-500 dark:text-purple-400"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                            clipRule="evenodd"
                          ></path>
                        </svg>
                        <span className="text-base font-medium leading-tight text-gray-900 dark:text-white">
                          Mobile wallet development
                        </span>
                      </li>
                    </ul>
                    <p className="mb-8 font-light lg:text-xl">
                      Deliver great service experiences fast - without the
                      complexity of traditional ITSM solutions.
                    </p>
                  </div>
                  <img
                    className="w-full mb-4 rounded-lg lg:mb-0 lg:flex"
                    src={CardPayment}
                    alt="dashboardfeatureimage"
                  />
                </div>
              </div>
            </section>
            <section className="bg-transparent">
              {/* {Tab Section} */}
              <div className="w-full mx-auto sm:max-w-md md:max-w-lg lg:max-w-2xl px-2 py-16 sm:px-0">
                <h2 className="text-xl text-center mb-2 font-extrabold tracking-tight text-gray-900 dark:text-white">
                  Explore Our Feature-Rich Bus & Car Ticket Booking Application
                  Panels
                </h2>
                <Tab.Group>
                  <Tab.List className="flex space-x-1 rounded-xl bg-indigo-500 p-1">
                    {Object.keys(categories).map((category) => (
                      <Tab
                        key={category}
                        className={({ selected }) =>
                          classNames(
                            "w-full rounded-lg py-2.5 text-sm font-medium leading-5 text-blue-700",
                            "ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2",
                            selected
                              ? "bg-white shadow text-white"
                              : "text-blue-100 hover:bg-white/[0.12] hover:text-white"
                          )
                        }
                      >
                        {category}
                      </Tab>
                    ))}
                  </Tab.List>
                  <Tab.Panels className="mt-2">
                    {Object.values(categories).map((posts, idx) => (
                      <Tab.Panel
                        key={idx}
                        className={classNames(
                          "rounded-xl bg-white p-3",
                          "ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2"
                        )}
                      >
                        <div className="flex flex-col space-y-1">
                          {posts.map((post) => (
                            <ul>
                              <li
                                key={post.id}
                                className="relative rounded-md p-3 hover:bg-gray-100"
                              >
                                <div className=" space-x-2 inline-flex">
                                  <span>
                                    <svg
                                      className="flex-shrink-0 w-5 h-5 text-purple-500 dark:text-purple-400"
                                      fill="currentColor"
                                      viewBox="0 0 20 20"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        fill-rule="evenodd"
                                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                        clipRule="evenodd"
                                      ></path>
                                    </svg>
                                  </span>
                                  <h3 className="text-sm font-medium leading-5">
                                    {post.title}
                                  </h3>
                                </div>

                                <ul className="mt-1 flex space-x-1 text-xs font-normal leading-4 text-gray-500">
                                  <li>{post.data}</li>
                                </ul>
                                <Link
                                  to="/e-commerce-app-development"
                                  className={classNames(
                                    "absolute inset-0 rounded-md",
                                    "ring-blue-400 focus:z-10 focus:outline-none focus:ring-2"
                                  )}
                                ></Link>
                              </li>
                            </ul>
                          ))}
                        </div>
                      </Tab.Panel>
                    ))}
                  </Tab.Panels>
                </Tab.Group>
              </div>
            </section>
            <Trusted />
            <Faq faqs={accordionData} />
            <FloatingWp />
            <Footer />
            <StickyEnquryForm />
            <PopUoForm />
          </div>
        </div>
      )}
    </>
  );
};

export default BusCarBooking;
