import React, { useState } from "react";
import { Link } from "react-router-dom";
import ViewProduct from "./ViewProduct";
import { SmartProducts } from "components/utils/Products_api";
import { useCart } from "components/utils/Products_api";
import { ToastContainer, toast } from "react-toastify";
import Ad_media from "assets/images/media-works-ad.png";
import "react-toastify/dist/ReactToastify.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { ProductLine } from "components/utils/Products_api";
import {
  Card,
  CardBody,
  CardFooter,
  Typography,
  Button,
} from "@material-tailwind/react";

const SmartProductGrid = () => {
  let [isOpen, setIsOpen] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(0);
  const { addToCart, viewProduct, viewItems } = useCart();

  function openModal() {
    setIsOpen(true);
  }

  const handleViewProductLaptop = (product) => {
    viewProduct(product);
    openModal();
  };

  function closeModal() {
    setIsOpen(false);
    viewItems([]);
  }

  const StockUpdate = () => {
    return Math.floor(Math.random() * (100 - 1) + 1);
  };

  const handleAddToCart = (product) => {
    addToCart(product);
    notify();
  };

  const handleViewProduct = (product) => {
    viewProduct(product);
    openModal();
  };

  const notify = () =>
    toast.success(`Added to cart successfully`, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });

  var settings = {
    className: "center",
    autoplay: true,
    autoplaySpeed: 3000,
    centerMode: true,
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    arrows: false,
    afterChange: (index) => {
      setCurrentSlide(index);
    },
    responsive: [
      {
        breakpoint: 990,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
          centerMode: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
          centerMode: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: true,
          dots: false,
        },
      },
    ],
  };

  const generateRandomGradient = () => {
    const colors = [
      "gray",
      "green",
      "cyan",
      "indigo",
      "blue",
      "purple",
      "yellow",
      "orange",
    ];
    const randomFrom = colors[Math.floor(Math.random() * colors.length)];
    let randomVia = colors[Math.floor(Math.random() * colors.length)];

    // Ensure randomVia is different from randomFrom
    while (randomVia === randomFrom) {
      randomVia = colors[Math.floor(Math.random() * colors.length)];
    }

    const randomTo = colors[Math.floor(Math.random() * colors.length)];

    return `!bg-gradient-to-br !from-${randomFrom}-200 !via-${randomVia}-200 !to-${randomTo}-200`;
  };

  const totalItems = () => {
    return SmartProducts.length;
  };
  const totalLaptops = () => {
    return ProductLine.length;
  };

  const gradientClass = generateRandomGradient();

  const newProductList = SmartProducts.map((item) => ({
    ...item,
    gradientClass: gradientClass,
  }));

  let sliderProject = "";

  sliderProject = newProductList.map((product) => (
    <>
      <Card
        className={`mx-2 rounded-xl !bg-white/80 flex flex-col justify-center items-center py-2 h-[240px] ${product.gradientClass} border-2 border-white`}
        key={product.id}
      >
        <CardBody className="px-3 py-1 text-center">
          <img
            src={product.imgSrc}
            alt={product.title}
            className="w-24 h-auto mx-auto"
          />
          <Typography
            variant="h4"
            color="blue-gray"
            className="mb-2 !text-xs text-center"
          >
            {product.title}
          </Typography>
          <Typography className="text-center"></Typography>
        </CardBody>

        <CardFooter className="!pt-0 p-3 mx-auto mt-2">
          <Button
            size="xs"
            variant="text"
            className="flex flex-row !items-center !justify-center uppercase px-3 py-2 gap-1 border border-indigo-500 bg-black text-white text-[10px] font-normal !rounded-md"
            onClick={() => handleAddToCart(product)}
          >
            add to cart
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 20 20"
              strokeWidth={2}
              stroke="currentColor"
              className="h-3 w-3"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3"
              />
            </svg>
          </Button>
        </CardFooter>
      </Card>
    </>
  ));

  let productData = "";
  productData = ProductLine.map((product) => (
    <>
      <Card
        className={`mx-3 rounded-xl flex flex-col justify-center items-center ${product.gradient}`}
        key={product.id}
      >
        <CardBody className="px-3 py-1">
          <img
            src={product.imgSrc}
            alt={product.title}
            className="w-24 h-auto mx-auto"
          />
          <Typography
            variant="h4"
            color="blue-gray"
            className="mb-2 text-center"
          >
            {product.title}
          </Typography>
          <Typography className="text-center">
            Best refurbished laptops on your way
          </Typography>
        </CardBody>

        <CardFooter className="!pt-0 p-3">
          <Link to="/smart-products" className="inline-block ml-auto">
            <Button
              size="xs"
              variant="text"
              className="flex flex-row !items-center !justify-center uppercase px-2 py-1 gap-1 border border-indigo-500 bg-black text-white text-[10px] font-normal !rounded-md"
              onClick={() => handleViewProductLaptop(product)}
            >
              Enquire Now
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 20 20"
                strokeWidth={2}
                stroke="currentColor"
                className="h-3 w-3"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3"
                />
              </svg>
            </Button>
          </Link>
        </CardFooter>
      </Card>
    </>
  ));

  return (
    <>
      <section className="w-full mx-auto px-3 py-2 block md:hidden">
        <div className="bg-white/70 rounded-lg border-2 border-white shadow-lg overflow-hidden">
          <div className="flex flex-row justify-between items-center">
            <div className="flex flex-col justify-center px-4 py-3">
              <h4 className="font-medium text-sm text-black">
                recommended smart products for you
              </h4>
              <span className="text-xs text-black font-light">
                explore more smart products
              </span>
            </div>
            <div className="bg-gray-100 rounded-md border-2 border-white">
              <span className="text-xs text-black font-semibold px-2 py-1.5">
                {currentSlide + 1}&nbsp;/&nbsp;{totalItems()}
              </span>
            </div>
          </div>

          <Slider
            {...settings}
            className="max-w-screen-xl overflow-hidden relative flex flex-row py-4"
          >
            {sliderProject}
          </Slider>
        </div>
      </section>
      <div className="py-4 px-3 w-full mx-auto flex justify-center items-center md:hidden">
        <Link to={"/media-works"}>
          <img
            src={Ad_media}
            alt="Media Works"
            className="rounded-lg border border-white shadow-lg"
          />
        </Link>
      </div>
      <section className="w-full mx-auto px-3 pt-2 block md:hidden pb-6">
        <div className="bg-white/70 rounded-lg border-2 border-white shadow-lg overflow-hidden">
          <div className="flex flex-row justify-between items-center">
            <div className="flex flex-col justify-center px-4 py-3">
              <h4 className="font-medium text-sm text-black">
                best refurbished laptops for you
              </h4>
              <span className="text-xs text-black font-light">
                explore more laptops
              </span>
            </div>
            <div className="bg-gray-100 rounded-md border-2 border-white">
              <span className="text-xs text-black font-semibold px-2 py-1.5">
                {currentSlide + 1}&nbsp;/&nbsp;{totalLaptops()}
              </span>
            </div>
          </div>

          <Slider
            {...settings}
            className="max-w-screen-xl overflow-hidden relative flex flex-row py-4"
          >
            {productData}
          </Slider>
        </div>
      </section>
      <section className="bg-indigo-200 hidden md:block md:bg-gray-50 py-6 mx-auto max-w-screen-2xl px-3 sm:px-10">
        <div className="mb-10 flex justify-center">
          <div className="text-center w-full lg:w-2/5">
            <h2 className="text-base lg:text-2xl mb-2 font-serif font-semibold">
              Popular Products for Smart Classroom
            </h2>
            <p className="text-xs font-sans text-gray-600 leading-normal">
              See all our popular products in this week. You can choose your
              daily needs products from this list and get some special offer
              with free shipping.
            </p>
          </div>
        </div>
        <div className="flex">
          <div className="w-full">
            <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-5 xl:grid-cols-5 2xl:grid-cols-6 gap-2 md:gap-3 lg:gap-3">
              {SmartProducts.map((product) => (
                <div
                  className="group box-border overflow-hidden flex rounded-md shadow-sm pe-0 flex-col items-center bg-white relative border-2 "
                  key={product.id}
                >
                  <div className="relative flex justify-center w-full cursor-pointer pt-2">
                    <div
                      className="left-3"
                      closeModal={closeModal}
                      openModal={openModal}
                      isOpen={isOpen}
                      onClick={() => handleViewProduct(product)}
                    >
                      <span className="bg-gray-100 absolute z-10 text-emerald-500 rounded-full text-xs px-2 py-0 font-medium">
                        Stock :
                        <span className="text-red-500 dark:text-red-400 pl-1 font-bold">
                          {StockUpdate()}
                        </span>
                      </span>
                      <span className=" inline-block box-border overflow-hidden w-auto h-auto relative max-w-full">
                        <span className="inline-block box-border overflow-hidden w-auto h-auto relative max-w-full">
                          <img
                            src="data:image/svg+xml,%3csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20width=%27210%27%20height=%27210%27/%3e"
                            alt=""
                            srcset=""
                          />
                        </span>
                      </span>
                      <img
                        src={product.imgSrc}
                        className="object-contain transition duration-150 ease-linear transform group-hover:scale-105 min-w-full min-h-full box-border block absolute inset-0 h-36 w-auto"
                        alt={product.title}
                        srcset=""
                      />
                    </div>
                  </div>
                  <div class="w-full px-3 lg:px-4 pb-4 overflow-hidden">
                    <div class="relative mb-1">
                      <span class="text-gray-400 font-medium text-xs d-block mb-1"></span>
                      <h2 class="text-heading truncate mb-0 block text-sm font-medium text-gray-600">
                        <span class="line-clamp-2"></span>
                      </h2>
                    </div>
                    <div class="flex justify-between items-center text-heading text-sm sm:text-base space-s-2 md:text-base lg:text-xl mt-4">
                      <div class="font-serif product-price font-bold">
                        <span class="inline-block text-xs md:text-base font-normal text-gray-800">
                          {product.title}
                        </span>
                      </div>
                      <button
                        aria-label="cart"
                        class="h-9 w-9 flex items-center justify-center border border-gray-200 rounded text-[#44619d] hover:border-[#44619d] hover:bg-[#44619d] hover:text-white transition-all flex-shrink-0"
                        onClick={() => handleAddToCart(product)}
                      >
                        {console.log(product)}{" "}
                        <span class="text-xl">
                          <svg
                            stroke="currentColor"
                            fill="currentColor"
                            stroke-width="0"
                            viewBox="0 0 512 512"
                            height="1em"
                            width="1em"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M460 160h-88v-12A116.13 116.13 0 00258.89 32h-5.78A116.13 116.13 0 00140 148v12H52a4 4 0 00-4 4v300a16 16 0 0016 16h384a16 16 0 0016-16V164a4 4 0 00-4-4zm-280-11c0-41.84 33.41-76.56 75.25-77A76.08 76.08 0 01332 148v12H180zm156 187h-64v64h-32v-64h-64v-32h64v-64h32v64h64z"></path>
                          </svg>
                        </span>{" "}
                      </button>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
      <ViewProduct
        isOpen={isOpen}
        closeModal={closeModal}
        openModal={openModal}
      />
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      {/* Same as */}
      <ToastContainer />
    </>
  );
};

export default SmartProductGrid;
