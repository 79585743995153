import React from "react";
import Ganesh from "assets/images/Ganesh.png";
import PrinceSir from "assets/img/princesir.png";
import NitishSir from "assets/images/nitish-sir.png";
import NeerajSir from "assets/images/neeraj_sir.png";
import PotraitVideo from "assets/images/potrait-video.mp4";
import AnanyaMam from "assets/images/ananya-mam.png";
import AnuragSir from "assets/images/anurag-sir.png";
import ArvindSir from "assets/images/arvind-sir.png";
import HarrySir from "assets/images/harry-sir.png";
import { ChevronRight } from "@mui/icons-material";

const TeamSection = () => {
  return (
    <>
      <section className="w-full bg-[#673de6] py-10 mt-14">
        <div className="max-w-screen-xl mx-auto">
          {" "}
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-2">
            <div className="flex justify-center items-center p-3 md:p-0">
              <div className="md:max-w-xs w-full py-8 px-5 border rounded-3xl bg-white backdrop-blur-2xl  border-gray-200 lg:px-12  lg:border-indigo-600">
                <div className=" rounded-full mx-auto max-w-[150px] ">
                  <img
                    src={PrinceSir}
                    className="mx-auto rounded-full w-full"
                    alt="Whiz-Technology-Sr-Programmer"
                  />
                </div>

                <h3 className="mt-8 font-bold text-indigo-600 uppercase text-xs">
                  Sr. Programmer & Project Manager
                </h3>
                <h2 className="mt-3 font-bold text-base">Message</h2>
                <p class="mt-2 text-gray-700 text-sm leading-normal">
                  As we move forward, we look forward to collaborating with you
                  on new and exciting projects. Together, we can embrace the
                  opportunities brought by technology and achieve remarkable
                  results.
                </p>
                <div class="group relative inline-flex mt-4">
                  <span class="w-full underline -rotate-12 inline-flex items-center rounded-lg justify-center self-stretch text-base lg:text-xl text-black text-center font-semibold font-sign">
                    Princedev
                  </span>
                </div>
              </div>
            </div>

            <div className="grid gap-10 row-gap-8 mx-auto row-gap-10 lg:max-w-screen-lg grid-cols-3 p-3 md:p-0">
              <div className="flex flex-col justify-center items-center relative max-w-[130px] border-2 border-white rounded-sm transition ease-in-out delay-150 hover:scale-110 duration-300">
                <img
                  className="object-cover w-40 h-auto rounded-sm shadow "
                  src={NitishSir}
                  alt="Nitish"
                />
                <div className="flex flex-col justify-center absolute bottom-[2px]">
                  <p className="text-sm font-bold text-white text-center">
                    Nitish Kumar
                  </p>
                  <p className="text-xs text-white text-center font-semibold">
                    Sr. Marketing Head
                  </p>
                </div>
              </div>
              <div className="flex flex-col justify-center items-center relative max-w-[130px] border-2 border-white rounded-sm transition ease-in-out delay-150 hover:scale-110 duration-300">
                <img
                  className="object-cover w-40 h-auto rounded-sm shadow "
                  src={Ganesh}
                  alt="Ganesh"
                />
                <div className="flex flex-col justify-center absolute bottom-[2px]">
                  <p className="text-sm font-bold text-white text-center">
                    Ganesh
                  </p>
                  <p className="text-xs text-white text-center font-semibold">
                    Jr. Developer
                  </p>
                </div>
              </div>

              <div className="flex flex-col justify-center items-center relative max-w-[130px] border-2 border-white rounded-sm transition ease-in-out delay-150 hover:scale-110 duration-300">
                <img
                  className="object-cover w-40 h-auto rounded-sm shadow "
                  src={NeerajSir}
                  alt="Neeraj-Singh"
                />
                <div className="flex flex-col justify-center absolute bottom-[2px]">
                  <p className="text-sm font-bold text-white text-center">
                    Neeraj Singh
                  </p>
                  <p className="text-xs text-white text-center font-semibold">
                    Production Designer
                  </p>
                </div>
              </div>
              <div className="flex flex-col justify-center items-center relative max-w-[130px] border-2 border-white rounded-sm transition ease-in-out delay-150 hover:scale-110 duration-300">
                <img
                  className="object-cover w-40 h-auto rounded-sm shadow "
                  src={AnanyaMam}
                  alt="Ananya"
                />
                <div className="flex flex-col justify-center absolute bottom-[2px]">
                  <p className="text-sm font-bold text-white text-center">
                    Ananya
                  </p>
                  <p className="text-xs text-white text-center font-semibold">
                    Content Writer
                  </p>
                </div>
              </div>
              <div className="flex flex-col justify-center items-center relative max-w-[130px] border-2 border-white rounded-sm transition ease-in-out delay-150 hover:scale-110 duration-300">
                <img
                  className="object-cover w-40 h-auto rounded-sm shadow"
                  src={HarrySir}
                  alt="Harry"
                />
                <div className="flex flex-col justify-center absolute bottom-[2px]">
                  <p className="text-sm font-bold text-white text-center">
                    Harry
                  </p>
                  <p className="text-xs text-white text-center font-semibold">
                    Sr. Photographer
                  </p>
                </div>
              </div>
              <div className="flex flex-col justify-center items-center relative max-w-[130px] border-2 border-white rounded-sm transition ease-in-out delay-150 hover:scale-110 duration-300">
                <img
                  className="object-cover w-40 h-auto rounded-sm shadow "
                  src={AnuragSir}
                  alt="Anurag"
                />
                <div className="flex flex-col justify-center absolute bottom-[2px]">
                  <p className="text-sm font-bold text-white text-center">
                    Anurag
                  </p>
                  <p className="text-xs text-white text-center font-semibold">
                    Int. BDM
                  </p>
                </div>
              </div>
              <div className="flex flex-col justify-center items-center relative max-w-[130px] border-2 border-white rounded-sm transition ease-in-out delay-150 hover:scale-110 duration-300">
                <img
                  className="object-cover w-40 h-auto rounded-sm shadow "
                  src="https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&amp;cs=tinysrgb&amp;dpr=3&amp;h=750&amp;w=1260"
                  alt="Asifa"
                />
                <div className="flex flex-col justify-center absolute bottom-[2px]">
                  <p className="text-sm font-bold text-white text-center">
                    Asifa Khan
                  </p>
                  <p className="text-xs text-white text-center font-semibold">
                    Domestic BDM
                  </p>
                </div>
              </div>
              <div className="flex flex-col justify-center items-center relative max-w-[130px] border-2 border-white rounded-sm transition ease-in-out delay-150 hover:scale-110 duration-300">
                <img
                  className="object-cover w-40 h-auto rounded-sm shadow"
                  src="https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&amp;cs=tinysrgb&amp;dpr=3&amp;h=750&amp;w=1260"
                  alt="Virat-Singh"
                />
                <div className="flex flex-col justify-center absolute bottom-[2px]">
                  <p className="text-sm font-bold text-white text-center">
                    Virat Singh
                  </p>
                  <p className="text-xs text-white text-center font-semibold">
                    Sales & Marketing
                  </p>
                </div>
              </div>
              <div className="flex flex-col justify-center items-center relative max-w-[130px] border-2 border-white rounded-sm transition ease-in-out delay-150 hover:scale-110 duration-300">
                <img
                  className="object-cover w-40 h-auto rounded-sm shadow "
                  src={ArvindSir}
                  alt="Person"
                />
                <div className="flex flex-col justify-center absolute bottom-[2px]">
                  <p className="text-sm font-bold text-white text-center">
                    Arvind Kumar
                  </p>
                  <p className="text-xs text-white text-center font-semibold">
                    Sales & Marketing
                  </p>
                </div>
              </div>
            </div>

            <div className="grid grid-cols-1 place-content-center place-items-center px-2">
              <video
                typeof="video/mp4"
                width={330}
                height={"auto"}
                controls={true}
                src={PotraitVideo}
                className="border-2 border-white rounded-sm"
              />
            </div>
          </div>
        </div>
      </section>
      <section className="max-w-screen-xl mx-auto py-4 md:py-8 px-4 bg-white/70 rounded-xl mt-10">
        <div className="text-start">
          <div className="flex flex-row max-w-fit px-2 py-1.5 space-x-3 justify-center items-center rounded-xl bg-indigo-500 mb-4">
            <span className="text-[10px] md:text-xs text-white">
              Smart Products
            </span>{" "}
            <span>
              <ChevronRight sx={{ color: "white" }} className="w-3 h-3" />
            </span>
          </div>
          <p className="text-gray-700 leading-relaxed">
            In the Modern era, digitization has been a vital component for both
            business houses and student's classrooms. The trends towards the
            smart office or classrooms is based on latest digital technology to
            make physical teaching and work environment smart and intelligent
            which is adaptable to students as well as corporate offices. We are
            offering smart office or classroom technology based on latest
            technologies such as IoT, Machine Learning, smart devices (smart
            switches, projectors, LED walls, pen displays, laptops etc.),
            software and applications for interconnectivity and communication.
            <br className="mt-2" />
            Smart classroom teaching is one of the latest and innovative
            teaching methodology. Smart teaching tools help teachers enhance
            teacher-student communication through technology, and teaching is
            done with the help of smart teaching tools which enhance students’
            learning autonomy, and provide new ideas for the realization of
            students’ deep learning. We offer latest and affordable smart
            classroom products with proper software solutions based on
            Information and Communication Technologies (ICT).
          </p>
        </div>
        <div className="text-start mt-5">
          <div className="flex flex-row max-w-fit px-2 py-1.5 space-x-3 justify-center items-center rounded-xl bg-indigo-500 mb-4">
            <span className="text-[10px] md:text-xs text-white">
              Media Works
            </span>{" "}
            <span>
              <ChevronRight sx={{ color: "white" }} className="w-3 h-3" />
            </span>
          </div>
          <p className="text-gray-700 leading-relaxed">
            A production house is a company that specializes in the creation and
            production of various forms of media content, such as films,
            television shows, commercials, music videos, and digital content.
            Outline the range of services your production house offers. This may
            include pre-production services such as scriptwriting,
            storyboarding, and casting, as well as production services like
            filming, directing, and coordinating the shoot. Additionally,
            mention post-production services such as editing, visual effects,
            sound design, and color grading.
          </p>
        </div>
      </section>
    </>
  );
};

export default TeamSection;
