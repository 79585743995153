export const handlePageChange = () => {
  setTimeout(() => {
    window.location.href = "/thank-you";
  }, 2000);
};

export const navigation = [
  {
    id: 1,
    title: "Web Development",
    to: "/website-development-company",
    navigations: [
      {
        id: 1,
        name: "ERP Development",
        to: "/erp-development",
      },
      {
        id: 2,
        name: "eCommerce Development",
        to: "/eCommerce-development",
      },
      {
        id: 3,
        name: "CRM Development",
        to: "/",
      },
      {
        id: 4,
        name: "Travel Portal Development",
        to: "/",
      },
      {
        id: 5,
        name: "School Website Development",
        to: "/",
      },
      {
        id: 6,
        name: "Hospital Website Development",
        to: "/",
      },
    ],
  },
  {
    id: 2,
    title: "Mobile App Development",
    to: "/mobile-app-development-company",
    navigations: [
      {
        id: 1,
        name: "eCommerce Mobile App",
        to: "/e-commerce-app-development-company",
      },
      {
        id: 2,
        name: "Flight Booking App",
        to: "/flight-booking-app-development-company",
      },
      {
        id: 3,
        name: "Bus & Car Booking App",
        to: "/bus-car-booking-app-development-company",
      },
      {
        id: 4,
        name: "Hotel Booking App",
        to: "/hotel-booking-app-development-company",
      },
      {
        id: 5,
        name: "Educational App Development",
        to: "/educational-app-development-company",
      },
      {
        id: 6,
        name: "Hospital Management App",
        to: "/hospital-management-app-development-company",
      },
      {
        id: 6,
        name: "Office Management App",
        to: "/office-management-app-development-company",
      },
    ],
  },
  {
    id: 3,
    title: "Digital Marketing",
    to: "/digital-marketing",
    navigations: [
      {
        id: 1,
        name: "SEO Marketing",
        to: "/",
      },
      {
        id: 2,
        name: "SMO Marketing",
        to: "/",
      },
      {
        id: 3,
        name: "PPC Google AD Marketing",
        to: "/",
      },
      {
        id: 4,
        name: "Content Writing",
        to: "/",
      },
    ],
  },
  {
    id: 4,
    title: "Web Designing",
    to: "/web-designing",
    navigations: [
      {
        id: 1,
        name: "UI/UX Design",
        to: "/",
      },
      {
        id: 2,
        name: "Responsive Website Design",
        to: "/",
      },
      {
        id: 3,
        name: "Graphic Design",
        to: "/",
      },
      {
        id: 4,
        name: "WordPress Website Design",
        to: "/",
      },
      {
        id: 5,
        name: "eCommerec Website Design",
        to: "/",
      },
      {
        id: 6,
        name: "Landing Page Web Design",
        to: "/",
      },
    ],
  },
  {
    id: 5,
    title: "Stack Development",
    to: "/stack-development",
    navigations: [
      {
        id: 1,
        name: "ReactJs Development",
        to: "/",
      },
      {
        id: 2,
        name: "VueJs Development",
        to: "/",
      },
      {
        id: 3,
        name: "Core PHP Development",
        to: "/",
      },
      {
        id: 4,
        name: "Angular Development",
        to: "/",
      },
      {
        id: 5,
        name: "NextJs Development",
        to: "/",
      },
      {
        id: 6,
        name: "AI Development",
        to: "/",
      },
      {
        id: 7,
        name: "Python Development",
        to: "/",
      },
    ],
  },
  {
    id: 6,
    title: "Technology Certification",
    to: "/technology-certification",
    navigations: [
      {
        id: 1,
        name: "TBO API Certification",
        to: "/",
      },
      {
        id: 2,
        name: "PCI Certification",
        to: "/",
      },
      {
        id: 3,
        name: "Payment Gateway Certification",
        to: "/",
      },
      {
        id: 4,
        name: "Datebase Management",
        to: "/",
      },
    ],
  },
];
