import { UilMapMarker } from "@iconscout/react-unicons";
import { UilSearch } from "@iconscout/react-unicons";
import { UilUsdCircle } from "@iconscout/react-unicons";
import { UilPercentage } from "@iconscout/react-unicons";
import { UilBell } from "@iconscout/react-unicons";
import { UilFileCheck } from "@iconscout/react-unicons";
import { UilFacebookF } from "@iconscout/react-unicons";
import { UilInstagram } from "@iconscout/react-unicons";
import { UilShare } from "@iconscout/react-unicons";
import { UilUser } from "@iconscout/react-unicons";
import SearchAds from "assets/logo/no-data.png";
import ShoppingPng from "assets/logo/trolley.png";
import Remarketing from "assets/logo/digital_marketing.png";
import LandingPng from "assets/logo/coding.png";
// import Check from "assets/logo/check.png";
import { UilCheckCircle } from "@iconscout/react-unicons";

export const seoFaq = [
  {
    id: 1,
    title: "Q1: What is SEO?",
    data: "SEO stands for Search Engine Optimization. It is the practice of optimizing a website's content, structure, and technical aspects to improve its visibility in search engine results. The goal of SEO is to increase organic (non-paid) traffic to a website and improve its rankings on search engine results pages (SERPs).",
  },
  {
    id: 2,
    title: "Q2: Why is SEO important for my business?",
    data: "SEO is important because the majority of online experiences start with a search engine. Having a strong SEO strategy helps your website rank higher in search results, driving more targeted traffic and potential customers to your site. It improves your online visibility, brand credibility, and ultimately leads to business growth.",
  },
  {
    id: 3,
    title: "Q3: How long does it take to see results from SEO?",
    data: "SEO is a long-term strategy, and the time it takes to see results can vary depending on various factors such as website age, competition, keyword difficulty, and the effectiveness of your SEO efforts. Generally, it takes several months to start seeing noticeable improvements in rankings and organic traffic. Patience and consistency are key in SEO.",
  },
  {
    id: 4,
    title: "Q4: What are keywords and why are they important in SEO?",
    data: "Keywords are the words or phrases that people use to search for information on search engines. They play a vital role in SEO as they help search engines understand the relevance and intent of web pages. By optimizing your website's content with relevant keywords, you increase the chances of ranking higher in search results for those queries.",
  },
  {
    id: 5,
    title: "Q6: What is on-page optimization?",
    data: "On-page optimization refers to the practice of optimizing individual web pages to improve their visibility and relevancy to search engines. This includes optimizing meta tags (title, description), headings, URL structure, content quality, keyword usage, internal linking, and other factors that influence how search engines interpret and rank your web pages.",
  },
  {
    id: 6,
    title: "Q6: How does SEO interact with other digital marketing strategies?",
    data: "SEO works synergistically with other digital marketing strategies such as content marketing, social media marketing, and paid advertising. SEO provides the foundation for better organic visibility, while other strategies help drive traffic, engage users, and amplify your online presence. Integration of these strategies can lead to a more comprehensive and effective digital marketing campaign.",
  },
];

export const principalFeaturesMarketing = [
  {
    id: 1,
    icon: (
      <>
        <UilMapMarker />
      </>
    ),
    title: "Increased Online Visibility",
    data: "Tailored, integrated digital marketing strategies can help brands increase conversion rates and provide better returns on a constant basis.",
  },
  {
    id: 2,
    icon: (
      <>
        <UilSearch />
      </>
    ),
    title: "Enhanced Brand Recognition",
    data: "With targeted messages, build trust with the audience, leading to greater brand recognition with better engagements and business presence online.",
  },
  {
    id: 3,
    icon: (
      <>
        <UilUsdCircle />
      </>
    ),
    title: "Cost Effective",
    data: "Digital marketing helps you save money and obtain more leads. It is an affordable way to market your business to interested consumers.",
  },
  {
    id: 4,
    icon: (
      <>
        <UilPercentage />
      </>
    ),
    title: "Drives Targeted Traffic",
    data: "Digital Marketing Services target more specific leads and build a steady pipeline of qualified leads, resulting in sales growth and conversion optimization.",
  },
  {
    id: 5,
    icon: (
      <>
        <UilBell />
      </>
    ),
    title: "Increased Marketing ROI",
    data: "Efficient Digital Marketing techniques make it possible to reduce the overall cost of acquiring and retaining customers and offers a substantial return on investments.",
  },
  {
    id: 6,
    icon: (
      <>
        <UilFileCheck />
      </>
    ),
    title: "Easily Measurable",
    data: "With digital marketing methods, you can accurately track the results of your campaign. You’ll get insight into your campaign to see how you can improve it.",
  },
];

export const principalFeaturesMarketingSmm = [
  {
    id: 1,
    icon: (
      <>
        <UilShare />
      </>
    ),
    title: "Organic Social Posting",
    data: "Schedule our instantly public post across Facebook, Instagram, Twitter & LinkedIn",
  },
  {
    id: 2,
    icon: (
      <>
        <UilFacebookF />
      </>
    ),
    title: "Facebook Ad's",
    data: "Find new friends connect with existing one's, our target you'r website visitors.",
  },
  {
    id: 3,
    icon: (
      <>
        <UilInstagram />
      </>
    ),
    title: "Instagram Ad's",
    data: "Promote your brand in a visual way to find your fans and re-target site visitors.",
  },
  {
    id: 4,
    icon: (
      <>
        <UilPercentage />
      </>
    ),
    title: "Repurpose your content",
    data: "Create new content and reuse elements that worked in your other compaigns",
  },
  {
    id: 5,
    icon: (
      <>
        <UilUser />
      </>
    ),
    title: "Reach More People",
    data: "Craft right message to share with the right audience in the right place.",
  },
  {
    id: 6,
    icon: (
      <>
        <UilFileCheck />
      </>
    ),
    title: "Know Whats Working",
    data: "With all your purformance data in one place, you get more insights on the work.",
  },
];

export const ppcCardServicesData = [
  {
    id: 1,
    title: "Search Ads",
    icon: SearchAds,
    description:
      "Search campaigns are at the heart of any Google Ads strategy. From keyword research, to killer ad copy, to relentless optimization, we build and management campaigns that drive serious ROI.",
  },
  {
    id: 2,
    title: "Shopping Ads",
    icon: ShoppingPng,
    description:
      "If you’re an ecommerce business, Google Shopping Ads are essential. And though Shopping Ads and Search Ads are closely related, the strategies for success are very different. You need an agency that knows the difference.",
  },
  {
    id: 3,
    title: "Remarketing",
    icon: Remarketing,
    description:
      "We’ll bring potential customers back to your site and recapture lost conversions with search, display and video remarketing campaigns..",
  },
  {
    id: 4,
    title: "Landing Page Design & CRO",
    icon: LandingPng,
    description:
      "Our job doesn’t end with an ad click. We want to make sure your clicks convert. Our design team builds landing pages that will take your conversion rates into uncharted territory.",
  },
];

export const ppcGoogleAdFaq = [
  {
    id: 1,
    title: "Q1: What is PPC advertising?",
    data: "PPC (Pay-Per-Click) advertising is an online advertising model where advertisers pay a fee each time their ad is clicked. It is a way to buy visits to your website rather than earning them organically.",
  },
  {
    id: 2,
    title: "Q2: How does Google Ads work?",
    data: "Google Ads is an advertising platform where advertisers bid on keywords relevant to their business. When users search for those keywords on Google, the ads appear above or below the organic search results. Advertisers pay when someone clicks on their ad.",
  },
  {
    id: 3,
    title: "Q3: Why should I use PPC Google Ads for my website?",
    data: "PPC Google Ads can provide immediate visibility for your website, driving targeted traffic and potential customers. It allows you to reach a wide audience, target specific demographics, and track and measure the performance of your campaigns.",
  },
  {
    id: 4,
    title: "Q4: How do I set up a PPC Google Ads campaign?",
    data: "To set up a PPC campaign, you need to create a Google Ads account, define your campaign goals, select relevant keywords, create compelling ad copy, set a budget, and launch your campaign. It's recommended to work with an experienced agency or professional to ensure optimal results.",
  },
  {
    id: 5,
    title: "Q5: How much does Google Ads cost?",
    data: "The cost of Google Ads depends on various factors, including your industry, competition, and the keywords you target. You can set a daily budget and adjust bids based on your budget and goals. Google Ads offers flexibility, allowing you to start with any budget.",
  },
  {
    id: 6,
    title: "Q6: How can I optimize my PPC Google Ads campaign?",
    data: "Optimization involves continually monitoring and refining your campaigns for better performance. You can optimize your ads by improving ad copy, targeting relevant keywords, refining landing pages, testing different variations, and analyzing data to make data-driven decisions.",
  },
];

export const principalFeaturesPPC = [
  {
    id: 1,
    icon: (
      <>
        <UilShare />
      </>
    ),
    title: "Social Media Strategy Development",
    data: "We start by understanding your business objectives, target audience, and industry landscape. Based on this analysis, we develop a comprehensive social media strategy that aligns with your goals, identifies key platforms, and outlines the tactics required to achieve success.",
  },
  {
    id: 2,
    icon: (
      <>
        <UilFacebookF />
      </>
    ),
    title: "Social Media Content Creation",
    data: "Our creative team produces high-quality, engaging, and shareable content tailored to each social media platform. This includes a mix of text, images, videos, infographics, and interactive elements to capture the attention of your audience and promote social engagement.",
  },
  {
    id: 3,
    icon: (
      <>
        <UilInstagram />
      </>
    ),
    title: "Social Media Advertising",
    data: "We leverage the targeting capabilities of social media platforms to reach your ideal audience effectively. Our team creates and manages highly targeted and optimized social media ad campaigns to increase brand awareness, drive website traffic, generate leads, and boost conversions.",
  },
  {
    id: 4,
    icon: (
      <>
        <UilPercentage />
      </>
    ),
    title: "Community Management",
    data: "We engage with your audience by monitoring and responding to comments, messages, and reviews on your social media channels. Our team ensures timely and professional communication, fostering a positive brand image and building meaningful connections with your followers.",
  },
  {
    id: 5,
    icon: (
      <>
        <UilUser />
      </>
    ),
    title: "Influencer Marketing",
    data: "We identify and collaborate with influential individuals in your industry or niche to amplify your brand's reach and credibility. Through strategic partnerships, we leverage their followers and authority to promote your products or services and drive engagement and conversions.",
  },
  {
    id: 6,
    icon: (
      <>
        <UilFileCheck />
      </>
    ),
    title: "Social Media Analytics and Reporting",
    data: "We track and analyze key metrics and performance indicators to measure the success of your social media campaigns. Our detailed reports provide insights into audience demographics, engagement rates, reach, conversions, and ROI, helping you make data-driven decisions for future campaigns.",
  },
];

export const contentWritingFaq = [
  {
    id: 1,
    title: "Q1: What is content writing?",
    data: "Content writing is the process of creating written material for various mediums such as websites, blogs, social media, advertisements, and more. It involves crafting engaging, informative, and persuasive content that resonates with the target audience and achieves specific goals.",
  },
  {
    id: 2,
    title: "Q2: Why is quality content important?",
    data: "Quality content plays a crucial role in attracting and engaging readers. It helps to establish credibility, build trust, and communicate your brand's message effectively. Well-written content also improves search engine visibility, drives organic traffic, and encourages conversions.",
  },
  {
    id: 3,
    title: "Q3: What types of content can a content writer create?",
    data: "Content writers can create a wide range of content, including website pages, blog articles, product descriptions, social media posts, email newsletters, press releases, whitepapers, case studies, and more. The type of content depends on the specific needs and goals of your business.",
  },
  {
    id: 4,
    title: "Q4: How does content writing help with SEO?",
    data: "Content writing plays a significant role in search engine optimization (SEO). By incorporating relevant keywords, optimizing meta tags, and creating valuable, user-focused content, search engines can better understand your website and rank it higher in search results, driving organic traffic.",
  },
  {
    id: 5,
    title: "Q5: Can a content writer write for different industries or niches?",
    data: "Yes, experienced content writers can adapt their writing style to different industries or niches. They conduct research to gain a thorough understanding of the subject matter and target audience, allowing them to create content that speaks directly to the intended readers.",
  },
  {
    id: 6,
    title: "Q6: How do content writers ensure originality in their work?",
    data: "Content writers strive to produce original content by conducting extensive research, using reliable sources, and citing references where necessary. Plagiarism checks and proofreading are also performed to ensure that the content is unique and free of any copied material.",
  },
];

export const principalFeaturesCW = [
  {
    id: 1,
    icon: (
      <>
        <UilCheckCircle className=" text-indigo-500" />
      </>
    ),
    title: "Clarity and Coherence",
    data: "Clarity and coherence make the content easy to understand and digest for readers.",
  },
  {
    id: 2,
    icon: (
      <>
        <UilCheckCircle className=" text-indigo-500" />
      </>
    ),
    title: "Engaging and Captivating",
    data: "Engaging content holds the reader's interest and encourages them to continue reading or take the desired action.",
  },
  {
    id: 3,
    icon: (
      <>
        <UilCheckCircle className=" text-indigo-500" />
      </>
    ),
    title: "Audience Relevance",
    data: "It involves understanding the audience's demographics, motivations, and pain points to create content that resonates with them.",
  },
  {
    id: 4,
    icon: (
      <>
        <UilCheckCircle className=" text-indigo-500" />
      </>
    ),
    title: "SEO Optimization",
    data: "SEO optimization helps to improve organic rankings and drive targeted traffic to the website.",
  },
  {
    id: 5,
    icon: (
      <>
        <UilCheckCircle className=" text-indigo-500" />
      </>
    ),
    title: "Originality and Uniqueness",
    data: "Original content stands out from the competition, establishes thought leadership, and enhances credibility.",
  },
  {
    id: 6,
    icon: (
      <>
        <UilCheckCircle className=" text-indigo-500" />
      </>
    ),
    title: "Value and Utility",
    data: "Valuable content helps to establish trust with the audience, positions the brand as an industry expert, and encourages readers to return for more.",
  },
];
