import React from "react";

import { Player } from "@lottiefiles/react-lottie-player";
import IndustrialShoot from "assets/production-img/photography.json";
import CorporatVd from "assets/production-img/videography.json";
import ProductDc from "assets/production-img/productivity.json";
import VideoProduct from "assets/production-img/movie.json";

export default function ProductServices() {
  return (
    <>
      <section className="py-2 mt-[100px]">
        <div className="block z-3 justify-center item-center m-auto w-[50%]">
          <p className="mt-4 text-lg font-semibold mb-8 leading-relaxed text-slate-500 justify-center text-center capitalize">
            Best Branding Services
          </p>
        </div>
        <hr className="bg-[#44619d] h-[5px] w-[20%] mx-auto rounded-[20px] mb-[20px]" />
        <div className="max-w-2xl mx-auto py-6 px-4 sm:px-6 sm:py-6 lg:max-w-7xl lg:px-8">
          <div className="grid grid-cols-1 gap-y-12 sm:grid-cols-2 sm:gap-x-6 lg:grid-cols-4 lg:gap-x-8 justify-center">
            <div className="flex flex-col">
              <div className="">
                <div className="w-full h-[100px] overflow-hidden m-auto">
                  <Player
                    src={IndustrialShoot}
                    className="player"
                    loop
                    autoplay
                    style={{ height: "150px", width: "150px" }}
                  />
                </div>
              </div>
              <h3 className="z-3 mt-[3rem] text-md font-medium text-slate-700 text-center font-poppins">
                Industrial Shoot
              </h3>
            </div>
            <div className="flex flex-col">
              <div className="">
                <div className="w-full h-fit overflow-hidden m-auto">
                  <Player
                    src={CorporatVd}
                    className="player"
                    loop
                    autoplay
                    style={{ height: "150px", width: "150px" }}
                  />
                </div>
              </div>
              <h3 className="z-3 text-md font-medium text-slate-700 text-center font-poppins">
                Corporate Video
              </h3>
            </div>
            <div className="flex flex-col">
              <div className="">
                <div className="w-full h-fit overflow-hidden m-auto">
                  <Player
                    src={ProductDc}
                    className="player"
                    loop
                    autoplay
                    style={{ height: "150px", width: "150px" }}
                  />
                </div>
              </div>
              <h3 className="z-3 text-md font-medium text-slate-700 text-center font-poppins">
                Product Demonstration
              </h3>
            </div>
            <div className="flex flex-col">
              <div className="">
                <div className="w-full h-fit overflow-hidden m-auto">
                  <Player
                    src={VideoProduct}
                    className="player"
                    loop
                    autoplay
                    style={{ height: "150px", width: "150px" }}
                  />
                </div>
              </div>
              <h3 className="z-3 text-md font-medium text-slate-700 text-center font-poppins">
                Video Production
              </h3>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
