import Quality from "assets/logo/repair-tools.png";
import Increment from "assets/logo/increase.png";
import filePng from "assets/logo/document.png";
import Prototype from "assets/logo/no-data.png";
import RetailSvg from "assets/svgs/retail.svg";
import StudentSvg from "assets/svgs/student.svg";
import HealthcareSvg from "assets/svgs/healthcare.svg";
import LogisticSvg from "assets/svgs/logistic.svg";
import NetworkingSvg from "assets/svgs/networking.svg";
import RealEstateSvg from "assets/svgs/real-estate.svg";
import TravelSvg from "assets/svgs/travel.svg";
import Food from "assets/svgs/food.svg";
import Sofa from "assets/logo/sofa.png";
import Interior from "assets/logo/sketch.png";
import branging_svg from "assets/logo/reshot-icon-branding-RD356ZUGE2.svg";
import webdesign_svg from "assets/logo/reshot-icon-web-design-WKP27CZXR9.svg";
import app_svg from "assets/logo/reshot-icon-app-rating-RE7AWMZUKH.svg";
import graphic_svg from "assets/logo/reshot-icon-vector-TCAWP4NZ5X.svg";
import SeoMarketing2 from "assets/images/seo_banner_2.png";
import WhyChooseUsPng from "assets/images/whyChooseUs.png";
import PpcService_1 from "assets/images/ppc_services_1.png";
import PpcService_2 from "assets/images/ppc_services_2.png";
import ContentWritingBanner from "assets/images/content_writing_service_1.png";

export const webData = [
  {
    id: 1,
    titlle: "Mobile App Development",
    description:
      "Deliver great service experiences fast - without the complexity of traditional solutions. Accelerate critical development work, eliminate toil, and deploy changes with ease.",
    listData: [
      {
        id: 1,
        content: "ERP Development",
        to: "",
      },
      {
        id: 2,
        content: "ERP Development",
      },
    ],
  },
];

export const accordionData = [
  {
    id: 1,
    title: "Q1: What is a web service?",
    data: "A web service is a software system designed to enable communication and interoperability between different applications over a network, typically the internet. It allows applications to exchange data and perform various operations using standard protocols such as HTTP and XML. Web services are commonly used for integrating different systems, sharing data, and facilitating communication between applications developed on different platforms.",
  },
  {
    id: 2,
    title: "Q2: What are the advantages of using web services?",
    data: "Web services offer several advantages, including: Interoperability: Web services allow applications developed on different platforms to communicate and exchange data seamlessly",
  },
  {
    id: 3,
    title: "Q4: How can I consume a web service in my application?",
    data: `Identify the type of web service (SOAP, REST, GraphQL) and its endpoint URL. Use a programming language or framework that supports web service consumption. Generate or write code to make HTTP requests to the web service endpoint. Handle the responses returned by the web service and parse the data as needed. Integrate the consumed data into your application's logic or user interface.`,
  },
];

export const cardData = [
  {
    id: "01",
    title: "Quick & Safe Booking",
    content:
      "Hotel booking apps allow users to book nearby hotels faster and with more security. They simply need to download the hotel booking app and get the best suggestions nearby.",
  },
  {
    id: "02",
    title: "Brand Awareness",
    content:
      "Apps are one of the most used commodities, so having a hotel booking app will improve your hotel business's brand awareness.",
  },
  {
    id: "03",
    title: "Hassle-free Payment",
    content:
      "Say goodbye to the concern of offline payment management. Hotel booking apps enable hassle-free payment and allow users to pay on the go with a tap.",
  },
  {
    id: "04",
    title: "Streamlined Booking",
    content:
      "With a feature-rich and well-managed, users and business owners do not have to worry about booking management and get updates on upcoming, ongoing, and cancelled bookings.",
  },
  {
    id: "05",
    title: "Customized Push Notifications.",
    content:
      "Keeping the users timely notified of their booking status, upcoming offers, and more will increase your hotel booking app engagement and customer engagement.",
  },
  {
    id: "06",
    title: "Transparent Business Analytics",
    content:
      "While having hotel booking applications, businesses can look at the business analytics and stats clearly to analyze monthly or weekly profit and growth",
  },
];

export const keyFeatures = [
  {
    id: 1,
    icon: filePng,
    title: "Better Online Exposure",
    data: "An effective content strategy generates more engagements and social signals, allowing interaction with a broader online community.",
  },
  {
    id: 2,
    icon: Increment,
    title: "High Search Rankings",
    data: "Increased social media sharing of your content leads to higher website traffic and improved search rankings.",
  },
  {
    id: 3,
    icon: Prototype,
    title: "Specific Audience Targeting",
    data: "Experts analyze niche markets to craft relevant content and ads addressing customers' needs based on their online behavior.",
  },
  {
    id: 4,
    icon: Quality,
    title: "Improved Customer Reach",
    data: "A strong social media marketing strategy increases brand awareness and places your company in front of the right customers.",
  },
  {
    id: 5,
    icon: Quality,
    title: "Complete Brand Control",
    data: "Social media marketing packages are customized to fit your needs and budget, giving you control over branding and content strategy.",
  },
  {
    id: 6,
    icon: Quality,
    title: "Enhanced Customer Trust",
    data: "Using social media for marketing builds a thought leadership brand and creates meaningful connections with followers.",
  },
];

export const digitalMarketinServies = [
  {
    id: 1,
    title: "SEO Marketing",
    to: "/seo-marketing-company",
  },
  {
    id: 2,
    title: "SMM Marketing",
    to: "/social-media-marketing-company",
  },
  {
    id: 3,
    title: "PPC Google Ad Marketing",
    to: "/ppc-google-ad-company",
  },
  {
    id: 4,
    title: "Content Writting",
    to: "/content-writing-company",
  },
];

export const keyFeatures2 = [
  {
    id: 1,
    title: "Branding",
    icon: branging_svg,
  },
  {
    id: 2,
    title: "Web Design",
    icon: webdesign_svg,
  },
  {
    id: 3,
    title: "Mobile App Design",
    icon: app_svg,
  },
  {
    id: 4,
    title: "Graphic Design",
    icon: graphic_svg,
  },
];

export const industriesData = [
  {
    id: 1,
    title: "Retail, Ecommerce",
    icon: RetailSvg,
  },
  {
    id: 2,
    title: "Education & e-learning",
    icon: StudentSvg,
  },
  {
    id: 3,
    title: "Healthcare & Fitness",
    icon: HealthcareSvg,
  },
  {
    id: 4,
    title: "Logistics & Distribution",
    icon: LogisticSvg,
  },
  {
    id: 5,
    title: "Social Networking",
    icon: NetworkingSvg,
  },
  {
    id: 6,
    title: "Real Estate",
    icon: RealEstateSvg,
  },
  {
    id: 7,
    title: "Travel & Hospitality",
    icon: TravelSvg,
  },
  {
    id: 8,
    title: "Food & Restaurant",
    icon: Food,
  },
  {
    id: 9,
    title: "Decore & Interior Industry",
    icon: Interior,
  },
  {
    id: 10,
    title: "Furniture Industry",
    icon: Sofa,
  },
];

export const seoServicesData = [
  {
    id: 1,
    title: "Search Engine Optimization",
    description:
      "Search engine optimization is one of the most critical aspects of online marketing. We offer on-page and off-page search engine optimization services that ensure business growth by improving your company’s visibility on major search engines. We set up realistic goals, do keyword researches, carry out competitor analysis, gather intel, optimize your website, perform off-page optimization and monitor the results via Analytics",
    icon: SeoMarketing2,
    keyPoints: [
      {
        id: 1,
        title: "Keyword Research:",
        points:
          "We perform comprehensive keyword research to identify the most relevant and valuable keywords for your business. By targeting the right keywords, we ensure that your website ranks higher for searches that matter most to your target audience.",
      },
      {
        id: 2,
        title: "On-Page Optimization:",
        points:
          "Our team of experts will optimize your website's on-page elements, including meta tags, headings, URL structure, and content. We ensure that search engines can easily understand and index your web pages, improving their visibility in search results.",
      },
      {
        id: 3,
        title: "Content Creation and Optimization:",
        points:
          "Engaging, informative, and SEO-friendly content is crucial for attracting and retaining visitors. Our talented content creators will develop high-quality content that aligns with your target audience's needs and search intent. We optimize the content with relevant keywords to maximize its impact on search engine rankings.",
      },
      {
        id: 3,
        title: "Link Building:",
        points:
          "Building high-quality backlinks from authoritative websites is a critical aspect of SEO marketing. Our team employs proven link-building strategies to enhance your website's authority and credibility in the eyes of search engines.",
      },
    ],
    apiFeatures: [
      { service: "Website Optimization" },
      { service: "Keyword Research" },
      { service: "SEO Copywriting" },
      { service: "Backlink Analysis" },
      { service: "Off Page Activities" },
    ],
  },
];

export const smmServicesData = [
  {
    id: 1,
    title: "Unlock the Power of Social Media with Effective SMM Marketing",
    description:
      "In today's digital landscape, social media has become the cornerstone of successful marketing strategies. Social Media Marketing (SMM) is the key to reaching and engaging with your target audience on platforms like Facebook, Instagram, Twitter, and LinkedIn. Harness the immense potential of social media and elevate your brand's online presence with our expert SMM marketing services.",
    icon: SeoMarketing2,
    keyPoints: [
      {
        id: 1,
        title: "Keyword Research:",
        points:
          "SMM marketing is the practice of using social media platforms to connect, engage, and promote your brand to a wider audience. It involves creating and sharing content, running targeted advertising campaigns, and fostering meaningful interactions with your followers. With SMM marketing, you can build brand awareness, drive website traffic, generate leads, and ultimately boost conversions.",
      },
    ],
    apiFeatures: [
      { service: "Content Creation and Curation" },
      { service: "Social Media Advertising" },
      { service: "Community Management" },
      { service: "Influencer Marketing" },
      { service: "Social Listening and Analytics" },
    ],
  },
];

export const seoWhyChooseUs = [
  {
    id: 1,
    title: "All Inclusive Solutions for Diversified Ecommerce",
    description: "",
    icon: WhyChooseUsPng,
    features: [
      {
        points:
          "In this competitive world, every business needs to stay ahead of the curve. It demands customized, professional, and enterprise-grade eCommerce mobile app and web development services. We, at Whiz Technology, blend our ecommerce development proficiency with advanced and latest technologies to dig-out the crucial requirements of the clients across industries globally.",
      },
      {
        points:
          "A leading eCommerce development company, Whiz Technology, offers the best eCommerce development services with the required technical consultations and the development plans for their exceptional needs.",
      },
      {
        points:
          "We are the mostly-chosen eCommerce development company holding proven knowledge in eCommerce web design and web development. We have a team of experienced eCommerce developers who offer the best eCommerce Development services to build successful eCommerce development platforms. We target on developing the",
      },
    ],
  },
];

export const smmWhyChooseUs = [
  {
    id: 1,
    title: "Why Choose Us for SMM Marketing?",
    description: "",
    icon: WhyChooseUsPng,
    features: [
      {
        points:
          "Our SMM marketing team comprises skilled professionals with extensive experience in creating successful social media campaigns. We stay up-to-date with industry trends and best practices to deliver outstanding results.",
      },
      {
        points:
          "We understand that each business is unique, so we tailor our strategies to align with your specific objectives, target audience, and brand identity. Our customized approach ensures that your SMM efforts are tailored to your business needs.",
      },
      {
        points:
          "Our content creators are adept at crafting captivating content that resonates with your audience. From eye-catching visuals to compelling copy, we create content that sparks engagement and drives social media interactions.",
      },
    ],
  },
];

export const smmFaq = [
  {
    id: 1,
    title: "Q1: What is social media marketing?",
    data: "Social media marketing is the process of using social media platforms to promote and market products, services, or brands. It involves creating and sharing engaging content, running targeted advertising campaigns, and fostering interactions with the target audience to achieve specific marketing objectives.",
  },
  {
    id: 2,
    title:
      "Q2: Which social media platforms should I focus on for my business?",
    data: "The choice of social media platforms depends on your target audience and business goals. It's essential to identify where your audience is most active and engage on platforms like Facebook, Instagram, Twitter, LinkedIn, YouTube, Pinterest, or others. A comprehensive social media strategy may involve a combination of platforms to reach a wider audience.",
  },
  {
    id: 3,
    title: "Q3: How can social media marketing benefit my business?",
    data: "Social media marketing offers numerous benefits, including increased brand awareness, expanded reach, improved customer engagement, higher website traffic, lead generation, enhanced customer loyalty, and even increased sales. It allows you to connect with your audience in a more personal and interactive way, building strong relationships and driving business growth.",
  },
  {
    id: 4,
    title: "Q4: What types of content work best for social media marketing?",
    data: "The content that works best for social media marketing varies depending on the platform and the preferences of your target audience. Generally, visual content such as images, videos, infographics, and animated GIFs tend to perform well. Additionally, informative articles, entertaining posts, user-generated content, and behind-the-scenes glimpses can also engage your audience effectively.",
  },
  {
    id: 5,
    title: "Q5: How often should I post on social media?",
    data: "The frequency of posting on social media depends on the platform and your audience's preferences. It's important to maintain a consistent posting schedule to stay active and keep your audience engaged. However, quality should always take precedence over quantity. Posting valuable and relevant content is more important than posting excessively.",
  },
  {
    id: 6,
    title:
      "Q6: How can I measure the success of my social media marketing efforts?",
    data: "Measuring the success of social media marketing requires tracking relevant metrics such as engagement rate, reach, impressions, click-through rates, conversions, and follower growth. Social media analytics tools and platforms provide insights into these metrics, allowing you to assess the effectiveness of your campaigns and make data-driven decisions for improvement.",
  },
];

export const ppcServicesData = [
  {
    id: 1,
    title: "Why Choose PPC Advertising?",
    description:
      "PPC advertising is an online advertising model where advertisers pay a fee each time their ad is clicked. It allows businesses to display ads on search engines or other platforms and only pay for the clicks they receive. This approach offers a cost-effective way to reach potential customers and promote your products or services.",
    icon: PpcService_1,
    keyPoints: [
      {
        id: 1,
        title: "Immediate Results: ",
        points:
          "PPC campaigns can generate instant visibility for your business. Once your ads are live, they can appear on search engine results pages or relevant websites, driving traffic to your website immediately.",
      },
      {
        id: 2,
        title: "Targeted Reach: ",
        points:
          "PPC allows you to target specific keywords, demographics, locations, and other relevant criteria. This precision targeting ensures that your ads are shown to the right audience, increasing the likelihood of conversion.",
      },
      {
        id: 3,
        title: "Cost Control: ",
        points:
          "With PPC, you have full control over your budget. You can set daily or monthly spending limits and adjust bids to manage costs effectively. This flexibility allows you to optimize your campaigns for the best return on investment (ROI).",
      },
      {
        id: 4,
        title: "Measurable Results: ",
        points:
          "PPC provides detailed insights into campaign performance. You can track metrics such as impressions, clicks, click-through rates, conversions, and cost per acquisition. This data helps you measure the effectiveness of your campaigns and make data-driven optimizations.",
      },
    ],
    apiFeatures: [],
  },
  {
    id: 2,
    title: "Our PPC Services",
    description:
      "We specialize in helping businesses drive targeted traffic, increase conversions, and maximize their online advertising investment through strategic PPC campaigns. Here's are our PPC service:",
    icon: PpcService_2,
    keyPoints: [
      {
        id: 1,
        title: "Campaign Strategy: ",
        points:
          "We develop customized PPC strategies aligned with your business goals and target audience. Our team conducts thorough keyword research, competitor analysis, and audience profiling to ensure your campaigns are optimized for success.",
      },
      {
        id: 2,
        title: "Ad Creation and Optimization: ",
        points:
          "Our creative experts craft compelling ad copy that grabs attention and drives clicks. We continuously monitor and optimize your ads, refining keywords, ad positioning, and messaging to improve performance and maximize conversions.",
      },
      {
        id: 3,
        title: "Landing Page Optimization: ",
        points:
          "We analyze your landing pages to enhance user experience and increase conversion rates. By making data-backed optimizations, we ensure that your landing pages align with your PPC campaigns, resulting in higher engagement and better conversion rates.",
      },
      {
        id: 4,
        title: "Conversion Tracking and Analytics: ",
        points:
          "We set up advanced conversion tracking and analytics to accurately measure campaign success. Our team provides in-depth reports and insights, enabling you to make informed decisions and drive continuous improvements.",
      },
      {
        id: 5,
        title: "A/B Testing: ",
        points:
          "We conduct rigorous A/B testing to compare different ad variations, landing pages, and targeting options. This iterative testing process allows us to identify the most effective elements and refine your campaigns accordingly.",
      },
    ],
    apiFeatures: [],
  },
];

export const contentWritingServicesData = [
  {
    id: 1,
    title: "Various Types Of Services We Offer.",
    description:
      "Our team of experienced content writers is dedicated to delivering exceptional content that aligns with your brand voice and resonates with your readers. Here's an overview of the content writing services we offer:",
    icon: ContentWritingBanner,
    keyPoints: [
      {
        id: 1,
        title: "",
        points: "Website Content",
      },
      {
        id: 2,
        title: "",
        points: "Blog and Article Writing",
      },
      {
        id: 3,
        title: "",
        points: "SEO Content",
      },
      {
        id: 4,
        title: "",
        points: "Product Descriptions",
      },
      {
        id: 5,
        title: "",
        points: "Social Media Content",
      },
      {
        id: 6,
        title: "",
        points: "Press Releases and Newsletters",
      },
      {
        id: 7,
        title: "",
        points: "Editing and Proofreading",
      },
      {
        id: 8,
        title: "",
        points: "Customized Content Solutions",
      },
    ],
    apiFeatures: [],
  },
];

export const cwWhyChooseUs = [
  {
    id: 1,
    title: "Why Choose Us?",
    description: "",
    icon: WhyChooseUsPng,
    features: [
      {
        points:
          "Exceptional Quality: We are committed to delivering content of the highest quality. Our team of professional content writers is experienced in crafting engaging and well-researched content that captivates readers, reflects your brand's voice, and meets your specific requirements.",
      },
      {
        points:
          "Industry Expertise: Our content writers have expertise in various industries and niches. We take the time to understand your business, target audience, and industry nuances, allowing us to create content that resonates with your readers and positions you as an authority in your field.",
      },
      {
        points:
          "Customized Approach: We recognize that every business is unique, and cookie-cutter solutions don't work. That's why we take a customized approach to content writing. We collaborate with you to understand your goals, brand identity, and target audience, tailoring our content to meet your specific needs.",
      },
      {
        points:
          "Engaging and Persuasive: Our content writers are skilled in crafting content that grabs attention and keeps readers engaged. We know how to tell a story, evoke emotions, and persuade your audience to take the desired actions, whether it's making a purchase, subscribing to a newsletter, or contacting your business.",
      },
      {
        points:
          "Timely Delivery: We understand the importance of meeting deadlines. Our team is committed to delivering content on time, ensuring that you have the materials you need to support your marketing campaigns, website updates, or content publishing schedule.",
      },
    ],
  },
];
