import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import { Player } from "@lottiefiles/react-lottie-player";
import Footer from "components/Footers/Footer.js";
import PopUoForm from "components/Forms/PopUoForm";
import StickyEnquryForm from "components/Forms/StickyEnquryForm";
import Trusted from "components/Footers/Trusted";
import Corousel from "components/Corousel/Corousel";
import { appServices } from "./mobAppData";
import searchingLottie from "assets/lotties/96916-searching.json";
import SmartTag from "components/Development/SmartTag";
import FloatingWp from "components/Development/FloatingWp";
import Faq from "components/Development/Faq";
import Preloader from "components/Development/Preloader";

const AppDevelopment = () => {
  const accordionData = [
    {
      id: 1,
      title: "Q1: What is a web service?",
      data: "A web service is a software system designed to enable communication and interoperability between different applications over a network, typically the internet. It allows applications to exchange data and perform various operations using standard protocols such as HTTP and XML. Web services are commonly used for integrating different systems, sharing data, and facilitating communication between applications developed on different platforms.",
    },
    {
      id: 2,
      title: "Q2: What are the advantages of using web services?",
      data: "Web services offer several advantages, including: Interoperability: Web services allow applications developed on different platforms to communicate and exchange data seamlessly",
    },
    {
      id: 3,
      title: "Q4: How can I consume a web service in my application?",
      data: `Identify the type of web service (SOAP, REST, GraphQL) and its endpoint URL. Use a programming language or framework that supports web service consumption. Generate or write code to make HTTP requests to the web service endpoint. Handle the responses returned by the web service and parse the data as needed. Integrate the consumed data into your application's logic or user interface.`,
    },
  ];

  const [isLoading, setisLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setisLoading(false);
    }, 1000);
  }, []);

  return (
    <>
      {isLoading === true ? (
        <Preloader />
      ) : (
        <div className="">
          {" "}
          <Helmet>
            <title>
              Mobile Application Development - Mobile Application Developers in
              India
            </title>
            <meta
              name="description"
              content="Mobile application development is the process of making software for smartphones, tablets and digital assistants, most commonly for the Android and iOS. Find out everything you need to know about the mobile app landscape."
            />
            <meta
              name="keywords"
              content="app development, mobile app development, mobile application development, app development company,app developers, best app development software"
            />
          </Helmet>{" "}
          <div className="fixed bg-hero-pattern bg-fixed bg-cover bg-center inset-0 -z-20"></div>
          <div className="App relative">
            {" "}
            <IndexNavbar fixed />
            {/* <!-- Start block --> */}
            <section className="">
              <div className="grid max-w-screen-xl px-4 pt-20 pb-8 mx-auto lg:gap-8 xl:gap-0 lg:py-16 lg:grid-cols-12 lg:pt-28">
                <div className="mr-auto place-self-center lg:col-span-7">
                  <SmartTag
                    title="No.1"
                    description="Mobile App Development Company in India"
                  />
                  <h1 className="max-w-2xl mb-4 font-extrabold leading-normal tracking-tight text-lg lg:text-4xl dark:text-white">
                    International App Development Company
                  </h1>
                  <p className="max-w-2xl mb-6 font-light text-justify justify-start lg:mb-8 md:text-base lg:text-base leading-normal">
                    We understand that every app idea is unique. Our team works
                    closely with you to understand your vision, goals, and
                    target audience, and then develops a customized solution
                    tailored to your specific requirements. Whether you need an
                    app for iOS, Android, or cross-platform development, our
                    developers have expertise in multiple platforms and
                    frameworks. We ensure your app works seamlessly across
                    different devices and operating systems.
                  </p>
                  <div className="space-y-4 sm:flex sm:space-y-0 sm:space-x-4">
                    <Link
                      to={"/"}
                      target=""
                      className="inline-block px-3 py-1.5 md:px-6 md:py-2.5 bg-[#44619d] text-white font-medium text-xs leading-tight uppercase rounded-full shadow-md hover:bg-blue-500 hover:shadow-lg focus:bg-blue-500 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-600 active:shadow-lg transition duration-150 ease-in-out"
                    >
                      Case Study
                    </Link>
                    <Link
                      to={"/contact-us"}
                      className=" mx-2 inline-block px-3 py-1.5 md:px-6 md:py-2.5 bg-gray-800 text-white font-medium text-xs leading-tight uppercase rounded-full shadow-md hover:bg-gray-900 hover:shadow-lg focus:bg-gray-900 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-gray-900 active:shadow-lg transition duration-150 ease-in-out"
                      target=""
                    >
                      Contact Us
                    </Link>
                  </div>
                </div>
                <div className="hidden lg:mt-0 lg:col-span-5 lg:flex">
                  <img
                    src={
                      "https://res.cloudinary.com/whiztechnology/image/upload/v1691046472/iebhjzm8vkmxgpmdv6aq.png"
                    }
                    alt="App Development"
                  />
                </div>
              </div>
            </section>
            <Corousel />
            <section className="bg-transparent">
              <div className="max-w-screen-xl backdrop-blur-md shadow-lg bg-[#d8eaff]/30 rounded-md px-4 py-8 mx-auto space-y-6 lg:py-10 lg:px-6">
                {appServices.map(
                  ({ id, tittle, description, icon, keyPoints, para }) => (
                    <div
                      className="flex flex-col sm:flex-col-reverse last:rounded-lg lg:flex-row lg:odd:flex-row-reverse justify-center items-center p-0 md:p-6"
                      id={id}
                    >
                      <div className="text-gray-500 sm:text-lg p-4 dark:text-gray-400 w-full md:w-1/2">
                        <h2 className="mb-4 text-xl md:text-3xl font-extrabold tracking-tight text-gray-900 dark:text-white">
                          {tittle}
                        </h2>
                        <h3 className="mb-8 text-justify text-base leading-normal font-light">
                          {description}
                        </h3>
                        <hr class="w-full mt-1 h-1 mb-4 bg-indigo-200" />
                        <ul className="pt-2 space-y-5 border-t border-gray-200 my-7 dark:border-gray-700">
                          {keyPoints.map(({ id, tittle, to }) => (
                            <Link to={to} key={id}>
                              <li className="flex mt-2">
                                <span className=" cursor-pointer flex space-x-3 hover:underline hover:text-purple-500">
                                  {svgIcon()}
                                  <span className="text-base font-medium leading-tight text-gray-900 dark:text-white">
                                    {tittle}
                                  </span>
                                </span>
                              </li>
                            </Link>
                          ))}
                        </ul>
                        <div className="mt-4">
                          <div className="block text-left">
                            <div className="flex items-center mx-auto w-full">
                              <Player
                                src={searchingLottie}
                                className="player"
                                loop
                                autoplay
                                style={{ height: "50px", width: "50px" }}
                              />
                              <div className="ml-2  item-center">
                                <div className="flex-col text-xs text-blue">
                                  <button className="px-2 py-1 bg-[#44619d] text-white font-medium text-xs leading-tight rounded-full shadow-md hover:bg-blue-500 hover:shadow-lg focus:bg-blue-500 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-600 active:shadow-lg transition duration-150 ease-in-out font-poppins cursor-pointer">
                                    <Link href="/contact-us">
                                      Get a quote ?
                                    </Link>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="w-full md:w-1/2 mb-4 lg:mb-0 lg:flex lg:justify-center mx-auto">
                        {" "}
                        <img
                          className="verticalAnimate"
                          src={icon}
                          alt="ERP Solutions"
                        />
                      </div>
                    </div>
                  )
                )}
              </div>
            </section>
            <Trusted />
            <Faq faqs={accordionData} />
            <FloatingWp />
            <Footer />
            <StickyEnquryForm />
            <PopUoForm />
          </div>
        </div>
      )}
    </>
  );
};

export default AppDevelopment;
const svgIcon = () => {
  return (
    <svg
      className="flex-shrink-0 w-5 h-5 text-purple-500 dark:text-purple-400"
      fill="currentColor"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
};
