export const appServices = [
  {
    id: 1,
    tittle: "App Development",
    icon: "https://res.cloudinary.com/whiztechnology/image/upload/v1691046761/xmpuyx3rjidpktp0pxmv.png",
    description:
      "Deliver great service experiences fast - without the complexity of traditional ITSM solutions. Accelerate critical development work, eliminate toil and deploy changes with ease.",
    keyPoints: [
      {
        id: 1,
        tittle: "E-Commerce App Development",
        to: "/e-commerce-app-development-company",
      },
      {
        id: 2,
        tittle: "Flight Booking App Development",
        to: "/flight-booking-app-development-company",
      },
      {
        id: 3,
        tittle: "Bus & Car Ticket Booking App",
        to: "/bus-car-booking-app-development-company",
      },
    ],
    para: "Deliver great service experiences fast - without the complexity of traditional ITSM solutions.",
  },
  {
    id: 2,
    tittle: "School & Healthcare Management App's",
    icon: "https://res.cloudinary.com/whiztechnology/image/upload/v1691046820/hvfcdn7is1npxpeew5kt.png",
    description:
      "Deliver great service experiences fast - without the complexity of traditional ITSM solutions. Accelerate critical development work, eliminate toil and deploy changes with ease.",
    keyPoints: [
      {
        id: 1,
        tittle: "Hotel Booking App",
        to: "/hotel-booking-app-development-company",
      },
      {
        id: 2,
        tittle: "School Management App",
        to: "/school-management-app-development-company",
      },
      {
        id: 3,
        tittle: "Hospital Management App",
        to: "/hospital-management-app-development-company",
      },
    ],
    para: "Deliver great service experiences fast - without the complexity of traditional ITSM solutions.",
  },
  {
    id: 3,
    tittle: "Bus & Car Ticket Booking App",
    icon: "https://res.cloudinary.com/whiztechnology/image/upload/v1691046939/bf20wof9s6reryrhdk0r.png",
    description:
      "Deliver great service experiences fast - without the complexity of traditional ITSM solutions. Accelerate critical development work, eliminate toil and deploy changes with ease.",
    keyPoints: [
      {
        id: 1,
        tittle: "Continuous integration and deployment",
        to: "",
      },
      {
        id: 2,
        tittle: "Development workflow",
        to: "",
      },
      {
        id: 3,
        tittle: "Knowledge management",
        to: "",
      },
    ],
    para: "Deliver great service experiences fast - without the complexity of traditional ITSM solutions.",
  },
];

export const appServicesLinks = [
  {
    id: 1,
    title: "E-Commerce App Development",
    to: "e-commerce-app-development-company",
  },
  {
    id: 2,
    title: "Flight Booking App Development",
    to: "flight-booking-app-development-company",
  },
  {
    id: 3,
    title: "Bus & Car Ticket Booking App",
    to: "bus-car-booking-app-development-company",
  },
  {
    id: 4,
    title: "Bus & Car Ticket Booking App",
    to: "bus-car-booking-app-development-company",
  },
];

export const hospitalAppServiceData = [
  {
    id: 1,
    title:
      "Enhance Communication in Virtual Healthcare with Real-Time Messaging",
    icon: "https://res.cloudinary.com/whiztechnology/image/upload/v1691046996/d7cas6g5hpp4urrcrla0.png",
    description:
      "In the rapidly growing field of telehealth, effective communication between patients and healthcare practitioners is paramount. With 97% of practitioners outsourcing patient care through telehealth, it's crucial to ensure clear and efficient communication channels for optimal healthcare outcomes.",
    keyFeatures: [
      {
        id: 1,
        keys: "Seamless Real-Time Communication:",
        keyPoints:
          "Our platform enables patients and healthcare practitioners to engage in instant, real-time conversations. With live messaging, both parties can communicate efficiently, addressing concerns, sharing updates, and discussing treatment plans in a seamless manner.",
      },
      {
        id: 2,
        keys: "Live and Offline Messages:",
        keyPoints:
          "Our messaging system allows users to exchange messages even when they are offline. This ensures that important information is never missed, and conversations can continue smoothly, regardless of connectivity issues.",
      },
      {
        id: 3,
        keys: "Message Delivery Status and Typing Indicator:",
        keyPoints:
          "Our system provides delivery status notifications, so users can track the progress of their messages. Additionally, the typing indicator feature lets participants know when the other person is composing a response, improving overall communication flow.",
      },
      {
        id: 4,
        keys: "User Activity Status:",
        keyPoints:
          "Patients and healthcare practitioners can view each other's activity status, indicating whether the other party is online or available for a conversation. This feature helps to manage expectations and ensures timely responses.",
      },
      {
        id: 5,
        keys: "Push and In-App Notifications:",
        keyPoints:
          "Our platform supports push notifications, delivering important alerts and reminders directly to users' devices. This includes timely notifications for report alerts, upcoming appointments, or any other critical updates, ensuring that patients stay informed and engaged.",
      },
    ],
    para: "With our real-time messaging feature, your virtual healthcare practice can foster effective communication, leading to enhanced patient engagement and improved healthcare outcomes. Contact us today to learn more about how our solution can revolutionize communication in your virtual healthcare setting.",
  },
  {
    id: 2,
    title: "Effortless Communication with Audio and Video Calls",
    icon: "https://res.cloudinary.com/whiztechnology/image/upload/v1691046996/he4kq6r0tpqfb0g7axnl.png",
    description:
      "We offer a comprehensive audio and video calling feature that simplifies scheduling, pre-screening appointments, and follow-ups with patients. With our modern communication solution, you can seamlessly connect all parties involved in the healthcare delivery chain. Here's how our audio and video calling feature can enhance your communication:",
    keyFeatures: [
      {
        id: 1,
        keys: "1:1 Audio and Video Calls:",
        keyPoints:
          "Our platform enables secure and high-quality audio and video calls between healthcare practitioners and patients. This allows for personalized and direct communication, fostering a strong patient-provider relationship.",
      },
      {
        id: 2,
        keys: "Group Audio and Video Calls (Up to 4 Users):",
        keyPoints:
          "In situations where multiple participants need to be involved, our platform supports group audio and video calls. This feature facilitates collaboration among healthcare providers, allowing for efficient discussions and decision-making.",
      },
      {
        id: 3,
        keys: "Audio and Video Conference Calls:",
        keyPoints:
          "Our solution extends beyond individual or group calls by providing audio and video conference capabilities. This enables larger-scale meetings or teleconferences, making it ideal for multidisciplinary discussions or virtual seminars.",
      },
      {
        id: 4,
        keys: "Screen Sharing Features:",
        keyPoints:
          "With our screen sharing functionality, healthcare practitioners can easily share their screens with patients, displaying medical reports, test results, or educational materials. This visual aid enhances communication and ensures a clear understanding of the information being discussed.",
      },
      {
        id: 5,
        keys: "Video Streaming:",
        keyPoints:
          "Our platform supports seamless video streaming, allowing for the real-time transmission of high-quality video content. This can be particularly useful for live demonstrations, remote consultations, or virtual training sessions.",
      },
    ],
    para: "By leveraging our audio and video calling feature, you can revolutionize communication in your healthcare practice, improving accessibility and ensuring effective collaboration. Whether it's for 1:1 interactions, group discussions, or conferences, our solution empowers you to connect with patients and colleagues in a modern and efficient manner. Get in touch with us today to explore how our audio and video calling feature can benefit your healthcare organization.",
  },
  {
    id: 3,
    title: "Enhance Remote Care with Comforting Chat Capabilities",
    icon: "https://res.cloudinary.com/whiztechnology/image/upload/v1691046996/rod4w3ano54quhmv4g0b.png",
    description:
      "We offer a technology platform that prioritizes seamless communication between patients and staff, ensuring their satisfaction and convenience. Our compliant chat feature allows for secure and efficient messaging, streamlining both individual and group interactions. Here's how our chat capabilities can benefit your remote care services:",
    keyFeatures: [
      {
        id: 1,
        keys: "Group Chat:",
        keyPoints:
          "Our platform facilitates group chat functionality, enabling healthcare professionals, patients, and other relevant parties to engage in collaborative discussions. This feature enhances teamwork, enabling efficient communication and decision-making among multiple participants.",
      },
      {
        id: 2,
        keys: "Public Chat:",
        keyPoints:
          "With our public chat feature, you can create designated chat rooms or channels for specific topics or departments. This fosters community engagement and information sharing, allowing participants to connect, ask questions, and share insights with ease.",
      },
      {
        id: 3,
        keys: "One-to-One Private Chat:",
        keyPoints:
          "Our platform provides a private chat feature, allowing patients and healthcare providers to have confidential and personalized conversations. This enables patients to address sensitive concerns and receive individualized support in a secure and private environment.",
      },
      {
        id: 4,
        keys: "File Sharing - Any Type of File:",
        keyPoints:
          "Our chat system allows for seamless file sharing, enabling users to exchange a wide range of file formats, including voice and video messages, images, documents, and even gifs. This facilitates efficient communication and supports a comprehensive exchange of information.",
      },
      {
        id: 5,
        keys: "Dynamic Messaging:",
        keyPoints:
          "Our chat feature supports dynamic messaging capabilities, allowing you to embed links and buttons within the chat interface. This empowers healthcare professionals to share relevant resources, appointment scheduling options, educational materials, and more, enhancing the overall user experience.",
      },
    ],
    para: "By incorporating our chat capabilities into your remote care services, you can create a comforting and supportive environment for patients and staff. Our secure and user-friendly chat platform streamlines communication, ensuring that important information is shared promptly and conveniently. Contact us today to learn more about how our chat feature can enhance your remote care services and improve patient satisfaction.",
  },
  {
    id: 4,
    title: "Effortless User Management with Advanced Security Measures",
    icon: "https://res.cloudinary.com/whiztechnology/image/upload/v1691046999/iarwnhhj6sn8tjc32j3u.png",
    description:
      "Our compliant video conferencing software prioritizes a more personalized and secure interaction between practitioners and patients. With our easy user management features, you can ensure optimum protection while maintaining a seamless user experience. Here's how our platform can enhance user management and security:",
    keyFeatures: [
      {
        id: 1,
        keys: "Integration with Existing Authentication System:",
        keyPoints:
          "Our software seamlessly integrates with your existing authentication system, allowing users to utilize their existing credentials for registration and login. This streamlines the user onboarding process and ensures a smooth transition for both practitioners and patients.",
      },
      {
        id: 2,
        keys: "User Registration and Login:",
        keyPoints:
          "Our platform enables user registration and login functionalities, providing a secure and personalized experience for each user. This allows practitioners and patients to access their respective accounts and maintain confidentiality throughout their interactions.",
      },
      {
        id: 3,
        keys: "User Detection and Ban:",
        keyPoints:
          "We incorporate robust user detection mechanisms to identify and ban any unwanted or unauthorized users. This ensures that only verified and approved individuals can participate in the video conferencing sessions, safeguarding the privacy and security of all parties involved.",
      },
      {
        id: 4,
        keys: "Prevention of Unauthorized Access:",
        keyPoints:
          "Our software implements stringent security measures to prevent unauthorized access to sensitive information. With strong encryption protocols and data transmission security, we prioritize the confidentiality and integrity of all patient-related data.",
      },
      {
        id: 5,
        keys: "Consultation Scheduling:",
        keyPoints:
          "Our platform offers convenient features for scheduling consultations with healthcare professionals. This allows patients to easily book appointments and ensures a structured workflow for practitioners, optimizing the patient-provider relationship.",
      },
      {
        id: 6,
        keys: "Secure Communication Channels:",
        keyPoints:
          "Through our platform, practitioners can securely send prescriptions, test results, and other sensitive information via encrypted and secure channels. This ensures that confidential medical data is protected during transmission, maintaining the highest standards of privacy.",
      },
      {
        id: 7,
        keys: "Linking Case Records to User Profiles:",
        keyPoints:
          "Our user management system allows for seamless linking of case records to user profiles. This ensures that practitioners have easy access to relevant patient information during video conferencing sessions, enhancing the quality and effectiveness of remote consultations.",
      },
    ],
    para: "With our easy user management features and advanced security measures, you can confidently provide a personalized and secure environment for practitioners and patients alike. Contact us today to learn more about how our HIPAA compliant video conferencing software can enhance your healthcare services.",
  },
];
