import React, { useState } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Service1 from "assets/smart-class-img/interactive_flat_pannel.jpg";
import Service2 from "assets/smart-class-img/vfx-studio.jpg";
import Service3 from "assets/smart-class-img/online_teaching_app.jpg";
import Service4 from "assets/smart-class-img/ptz-cameta-setup.png";
import Service5 from "assets/smart-class-img/conferenc_room_setup.jpg";
import Service6 from "assets/smart-class-img/website_designing.png";
import Service7 from "assets/smart-class-img/virtual_class_setup.webp";
import Service8 from "assets/smart-class-img/k-12_content.jpg";

export default function SmartClassServies() {
  const [showAll, setShowAll] = useState(false);

  const toggleShowAll = () => {
    setShowAll((prevShowAll) => !prevShowAll);
  };

  const SmartClassProductsData = [
    {
      id: 1,
      title: "Interactive Flat Panel",
      description: "Benchmark Interactive Flat Panel",
      imgSrc: Service1,
    },
    {
      id: 2,
      title: "Green VFX Recording Studio",
      description: " Complete Virtual Class",
      imgSrc: Service2,
    },
    {
      id: 3,
      title: "Online Teaching Application",
      description: "Create Your Own Digital Coaching",
      imgSrc: Service3,
    },
    {
      id: 4,
      title: "PTZ Camera Setup",
      description: "Best PTZ Camera for online class",
      imgSrc: Service4,
    },
    {
      id: 5,
      title: "Conference Room Setup",
      description:
        "All types of products & gadgets for conference room available.",
      imgSrc: Service5,
    },
    {
      id: 6,
      title: "Educational Websites",
      description: "All types of Eductaion, Teaching & School Website",
      imgSrc: Service6,
    },
    {
      id: 7,
      title: "Virtual Class Setup",
      description: "All types of Online & Offline class setup services",
      imgSrc: Service7,
    },
    {
      id: 8,
      title: "K-12 Content",
      description:
        "kindergarten, primary, secondary, high-school and pre-university educational services.",
      imgSrc: Service8,
    },
    {
      id: 9,
      title: "DMIT Solutions",
      description:
        "kindergarten, primary, secondary, high-school and pre-university educational services.",
      imgSrc: Service1,
    },
  ];

  return (
    <>
      <section className=" bg-transparent relative">
        {/* {Tab Section} */}
        <div className="w-full space-y-4 mx-auto sm:max-w-md md:max-w-2xl lg:max-w-5xl px-2 py-16 sm:px-0">
          <h2 className="mb-4 text-center text-3xl font-extrabold tracking-tight text-gray-900 dark:text-white">
            Our Best Services
          </h2>
          <div className="text-center">
            <Typography as="h5" variant="subtitle2" gutterBottom>
              We employ cutting-edge technologies and tools to make sure your
              applications are built on the latest technology. Have a look at
              our tech stack
            </Typography>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 place-content-center ">
            {SmartClassProductsData.slice(
              0,
              showAll ? SmartClassProductsData.length : 4
            ).map((item, index) => (
              <Card
                key={item.id}
                sx={{
                  minWidth: 253,
                  backgroundColor: "#d8eaff",
                  ":hover": { backgroundColor: "white" },
                }}
              >
                <CardContent
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    marginX: "12",
                    pb: 0,
                  }}
                >
                  <div className="flex flex-col items-center space-x-3 mb-4">
                    <div className="bg-transparent max-w-fit p-2 hover:bg-white rounded-md">
                      <img
                        src={item.imgSrc}
                        alt=""
                        className=" w-full h-auto"
                        srcset=""
                      />
                    </div>
                    <p className="text-xl text-center text-[14px] text-slate-400 font-semibold">
                      {item.title}
                    </p>
                  </div>
                  <div className="ml-2 text-center text-[12px]">
                    {item.description}
                  </div>
                </CardContent>
              </Card>
            ))}
            <div class="absolute md:flex inset-x-0 -bottom-2 justify-center  pt-32 pb-8 pointer-events-none">
              <button
                type="button"
                class="relative focus:outline-none text-md font-poppins flex items-center pointer-events-auto cursor-pointer bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 border border-blue-700 rounded"
                onClick={toggleShowAll}
              >
                {showAll ? "Show Less" : "Show More"}
              </button>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
