import React from "react";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Slider from "react-slick";
import David from "assets/img/david_s.jpg";
import Lisa from "assets/img/liisa_r.png";
import Emily from "assets/img/emily.jpg";
import Samantha from "assets/img/samantha.jpg";
import Instagram from "assets/logo/instagram.png";
import Twitter from "assets/logo/twitter.png";
import Facebook from "assets/logo/facebook.png";
import LinkedIn from "assets/logo/linkedin.png";

export default function ListSlider() {
  const testimonials = [
    {
      id: 1,
      name: "Lisa R.",
      company: "Marco Technology",
      data: "I had the pleasure of working with JKL Web Solutions for my company's website redesign, and they did an exceptional job. Their team was professional, creative, and responsive throughout the entire process. The end result was a visually stunning and user-friendly website that perfectly represents our brand. I highly recommend JKL Web Solutions for anyone in need of web services.",
      image: Lisa,
    },
    {
      id: 2,
      name: "David S.",
      company: "Selco Services",
      data: "Whiz Technology has been a game-changer for my online business. Their developement services are reliable, with minimal downtime and fast loading speeds. The customer support team is always available to assist with any technical issues promptly. I'm impressed with the level of security they provide, ensuring my website and customer data are safe. Whiz Technology is my go-to choice for web hosting.",
      image: David,
    },
    {
      id: 3,
      name: "Emily H.",
      company: "Heaven Team",
      data: "I approached Whiz Technology Web Design to create a website for my small business, and I am thrilled with the outcome. They listened to my ideas and transformed them into a visually appealing and functional website. The team at PQR Web Design was patient, communicative, and delivered the project on time. I am extremely satisfied with their services and would recommend them to anyone looking for web design expertise.",
      image: Emily,
    },
    {
      id: 4,
      name: "Samantha W.",
      company: "Syn Services",
      data: "I've been using VWX SEO Services to optimize my website for search engines, and I've seen a significant improvement in my website's visibility and organic traffic. Their team conducted thorough keyword research, implemented on-page optimization strategies, and developed a strong backlink profile. The results speak for themselves, and my business is thriving. VWX SEO Services is worth every penny for boosting your online presence.",
      image: Samantha,
    },
  ];

  var settings = {
    autoplay: true,
    dots: false,
    autoplayspeed: 2500,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    initialSlide: 0,
    arrows: false,
    vertical: true,
    verticalSwiping: true,
    responsive: [
      {
        breakpoint: 990,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 530,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <>
      <Slider {...settings} className="max-w-screen-xl">
        {testimonials.map((data) => (
          <div className="list-slide max-w-md mx-auto" key={data.id}>
            <li class="text-sm leading-6 shadow-xl border rounded-md bg-white my-4 mx-auto text-left">
              <figure class="relative flex flex-col-reverse bg-slate-50 rounded-lg p-6 dark:bg-slate-800 dark:highlight-white/5">
                <blockquote class="mt-2 space-x-2 text-slate-700 ml-4 flex flex-row dark:text-slate-300">
                  {[Facebook, Instagram, Twitter, LinkedIn].map((item) => (
                    <span className="mx-0.5">
                      <img
                        src={item}
                        alt=""
                        className=" w-5 h-5 bg-transparent"
                      />
                    </span>
                  ))}
                </blockquote>
                <figcaption class="flex items-center space-x-4">
                  <img
                    src={data.image}
                    alt=""
                    className="absolute z-[10000] rounded-full -right-5 bottom-4 w-14 h-14 "
                  />
                  <div class="flex-auto">
                    <div class="font-poppins text-md">
                      <p className="text-xs text-justify font-poppins leading-normal">
                        "{data.data}"
                      </p>
                    </div>
                    <div class="text-base text-slate-900">
                      <a
                        href="https://twitter.com/ryanflorence/status/1187951799442886656"
                        tabIndex="0"
                      >
                        <span class="mt-4 text-sm font-semibold font-poppins">
                          {data.name}
                        </span>
                        <p className="text-xs font-poppins">{data.company}</p>
                      </a>
                    </div>
                  </div>
                </figcaption>
              </figure>
            </li>
          </div>
        ))}
      </Slider>
    </>
  );
}
