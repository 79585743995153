import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import Typography from "@mui/material/Typography";
import Footer from "components/Footers/Footer.js";
import PopUoForm from "components/Forms/PopUoForm";
import Corousel from "components/Corousel/Corousel";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { keyFeatures } from "./webData";
import StickyEnquryForm from "components/Forms/StickyEnquryForm";
import Trusted from "components/Footers/Trusted";
import SmartSlider from "components/WebDevelopment/SmartSlider.jsx";
import { webServices, accordionData } from "./webData";
import SmartTag from "components/Development/SmartTag";
import FloatingWp from "components/Development/FloatingWp";
import Faq from "components/Development/Faq";
import Preloader from "components/Development/Preloader";

const WebDevelopment = () => {
  const [isLoading, setisLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setisLoading(false);
    }, 1000);
  }, []);
  return (
    <>
      {isLoading === true ? (
        <Preloader />
      ) : (
        <div className="">
          <Helmet>
            <title>
              Website Development Company - Web Design Services Near Me
            </title>
            <meta
              name="description"
              content="We offer the best quality of web development services india. Here's the best option for your web development needs. Delivered in 7 Days. 100% Money Back Guarantee. Designed by Experts."
            />
            <meta
              name="keywords"
              content="website development, web development, web development company,freelance web developer, website developer near me"
            />
          </Helmet>
          <div className="fixed bg-hero-pattern bg-fixed bg-cover bg-center inset-0 -z-20"></div>
          <div className="App relative">
            {" "}
            <IndexNavbar fixed />
            {/* <!-- Start block --> */}
            <section className="max-w-screen-xl mx-auto">
              <div className="grid  px-4 pt-20 pb-8  lg:gap-8 xl:gap-0 lg:py-16 lg:grid-cols-12 lg:pt-28">
                <div className="mr-auto place-self-center lg:col-span-7">
                  <SmartTag
                    title="Best"
                    description="Website Development Company in India"
                  />
                  <h1 className="max-w-2xl mb-4 text-4xl font-extrabold leading-none tracking-tight sm:text- xl:text-4xl dark:text-white">
                    WEB APP's DEVELOPMENT COMPANY
                  </h1>
                  <p className="max-w-2xl mb-6 font-light text-justify justify-start text-gray-800 lg:mb-8 md:text-base lg:text-bse">
                    Customer satisfaction is at the core of our values. We pride
                    ourselves on providing exceptional customer service, timely
                    project delivery, and ongoing support. Our team remains
                    dedicated to your success even after your website goes live,
                    offering maintenance and updates to keep your online
                    presence at its best.
                  </p>
                  <div className="space-y-4 sm:flex sm:space-y-0 sm:space-x-4">
                    <Link
                      to={"/"}
                      target=""
                      className="inline-block px-6 py-2.5 bg-[#44619d] text-white font-medium text-xs leading-tight uppercase rounded-full shadow-md hover:bg-blue-500 hover:shadow-lg focus:bg-blue-500 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-600 active:shadow-lg transition duration-150 ease-in-out"
                    >
                      Case Study
                    </Link>
                    <Link
                      to={"/about"}
                      className=" mx-2 inline-block px-6 py-2.5 bg-gray-800 text-white font-medium text-xs leading-tight uppercase rounded-full shadow-md hover:bg-gray-900 hover:shadow-lg focus:bg-gray-900 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-gray-900 active:shadow-lg transition duration-150 ease-in-out"
                      target=""
                    >
                      Contact Us
                    </Link>
                  </div>
                </div>
                <div className="hidden lg:mt-0 lg:col-span-5 lg:flex">
                  {/* <img src={HeroPng} alt="heroimage" /> */}
                  <SmartSlider />
                </div>
              </div>
            </section>
            {/* <!-- End block --> */}
            <section className=" max-w-screen-xl mx-auto">
              <Corousel />
            </section>
            <section className=" bg-transparent max-w-screen-xl mx-auto">
              <div className=" rounded-md backdrop-blur-sm bg-white/30 px-4 py-8  space-y-12 lg:space-y-20 lg:py-24 lg:px-6">
                {/* <!-- Row --> */}
                <div className="items-center gap-8 lg:grid lg:grid-cols-2 xl:gap-16">
                  <div className="text-gray-500 sm:text-lg dark:text-gray-400">
                    <h2 className="mb-4 text-3xl font-extrabold tracking-tight text-gray-900 dark:text-white">
                      Web App Development
                    </h2>
                    <p className="mb-8 font-light lg:text-xl">
                      Deliver great service experiences fast - without the
                      complexity of traditional solutions. Accelerate critical
                      development work, eliminate toil, and deploy changes with
                      ease.
                    </p>
                    {/* <!-- List --> */}
                    <ul className="pt-8 space-y-5 border-t border-gray-200 my-7 dark:border-gray-700">
                      {webServices.map((item) => (
                        <li className="flex" key={item.id}>
                          {/* <!-- Icon --> */}
                          <Link
                            to={item.to}
                            className=" cursor-pointer flex space-x-3 hover:underline hover:text-purple-500"
                          >
                            <svg
                              className="flex-shrink-0 w-5 h-5 text-purple-500 dark:text-purple-400"
                              fill="currentColor"
                              viewBox="0 0 20 20"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fill-rule="evenodd"
                                d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                clipRule="evenodd"
                              ></path>
                            </svg>
                            <span className="text-base font-medium leading-tight text-gray-900 dark:text-white">
                              {item.title}
                            </span>
                          </Link>
                        </li>
                      ))}
                    </ul>
                    <p className="mb-8 font-light lg:text-xl">
                      These are the sersvices and technologies involved in web
                      app development.
                    </p>
                  </div>
                  <div className="w-full backdrop-blur-md p-2 shadow-md verticalAnimate mb-4 rounded-lg lg:mb-0 lg:flex">
                    <img
                      className=""
                      src={
                        "https://res.cloudinary.com/whiztechnology/image/upload/v1691046206/cv3fzavgiausndle5njt.png"
                      }
                      alt="Our Apps"
                    />
                  </div>
                </div>
                {/* <!-- Row --> */}
              </div>
            </section>
            <section className="  mt-10 max-w-screen-xl mx-auto ">
              {/* {Tab Section} */}
              <div className="w-full space-y-4 mx-auto sm:max-w-md md:max-w-2xl lg:max-w-5xl px-2 py-16 sm:px-0">
                <h2 className="mb-4 text-center text-3xl font-extrabold tracking-tight text-gray-900 dark:text-white">
                  Process We Follow
                </h2>
                <div className="mx-auto text-center max-w-3xl">
                  <Typography variant="subtitle2" gutterBottom>
                    We employ cutting-edge technologies and tools to make sure
                    your applications are built on the latest technology. Have a
                    look at our tech stack
                  </Typography>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 place-content-center ">
                  {keyFeatures.map((item) => (
                    <Card
                      key={item.id}
                      sx={{
                        minWidth: 333,
                        backgroundColor: "#d8eaff",
                        borderRadius: 5,
                        ":hover": { backgroundColor: "white" },
                      }}
                    >
                      <CardContent
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          marginX: "12",
                          pb: 0,
                        }}
                      >
                        <div className="flex flex-row justify-evenly items-center space-x-3 mb-4">
                          <div className="bg-transparent max-w-fit p-2 hover:bg-white rounded-md">
                            <img
                              src={item.icon}
                              alt=""
                              className=" h-10 w-10
                          "
                              srcset=""
                            />
                          </div>
                          <p className="text-xl text-center text-slate-400 font-semibold">
                            {item.title}
                          </p>
                          <p className="text-xl text-center text-slate-400 font-semibold">
                            {item.id}
                          </p>
                        </div>
                        <div className=" text-center p-2">{item.data}</div>
                      </CardContent>
                    </Card>
                  ))}
                </div>
              </div>
            </section>
            <Trusted />
            <Faq faqs={accordionData} />
            <FloatingWp />
            <Footer />
            <StickyEnquryForm />
            <PopUoForm />
          </div>
        </div>
      )}
    </>
  );
};

export default WebDevelopment;
