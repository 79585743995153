import React from "react";
import { Link } from "react-router-dom";
import { UilWhatsapp } from "@iconscout/react-unicons";

const FloatingWp = () => {
  return (
    <>
      <div className="fixed block bottom-28 left-2 md:left-6 md:bottom-8 z-20 h-6 w-6 md:h-12 md:w-12">
        {" "}
        <Link to={"//wa.me/919560322639"}>
          <button
            type="button"
            class="p-2.5 text-xs font-medium text-white shadow-md transition duration-150 ease-in-out hover:shadow-lg focus:shadow-lg active:shadow-lg bg-green-400 rounded-[50%]"
          >
            <UilWhatsapp className=" text-white w-6 h-6 md:h-10 md:w-10" />
            <span className="sr-only">Whats App</span>
          </button>
        </Link>
      </div>
    </>
  );
};

export default FloatingWp;
