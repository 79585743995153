import React from "react";
import { Link } from "react-router-dom";
import SmartTag from "components/Development/SmartTag";

const HeroHeader = (props) => {
  return (
    <section className="max-w-screen-xl mx-auto">
      <div className="grid  px-4 pt-20 pb-8  lg:gap-8 xl:gap-0 lg:py-16 lg:grid-cols-12 lg:pt-28">
        <div className="mr-auto place-self-center lg:col-span-7">
          <SmartTag
            title={props.smartTagTitle}
            description={props.smartTagDescription}
          />
          <h1 className="max-w-2xl mb-4 font-extrabold leading-normal tracking-tight text-lg lg:text-4xl dark:text-white">
            {props.title}
          </h1>
          <p className="max-w-2xl mb-6 font-light text-justify justify-start lg:mb-8 md:text-base lg:text-base leading-normal">
            {props.description}
          </p>
          <div className="space-y-4 sm:flex sm:space-y-0 sm:space-x-4">
            <Link
              to={"/"}
              className="inline-block px-6 py-2.5 bg-[#44619d] text-white font-medium text-xs leading-tight uppercase rounded-full shadow-md hover:bg-blue-500 hover:shadow-lg focus:bg-blue-500 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-600 active:shadow-lg transition duration-150 ease-in-out"
            >
              Case Study
            </Link>
            <Link
              to={"/contact-us"}
              className="mx-2 inline-block px-6 py-2.5 bg-gray-800 text-white font-medium text-xs leading-tight uppercase rounded-full shadow-md hover:bg-gray-900 hover:shadow-lg focus:bg-gray-900 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-gray-900 active:shadow-lg transition duration-150 ease-in-out"
            >
              Contact Us
            </Link>
          </div>
        </div>
        <div className="hidden lg:mt-0 lg:col-span-5 lg:flex">
          <img src={props.image} alt="Hero Png" />
        </div>
      </div>
    </section>
  );
};

export default HeroHeader;
