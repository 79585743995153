import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import HeaderSwiper from "./HeaderSwiper";
import MousePng from "assets/images/offer_mouse.png";
import KeyboardPng from "assets/images/offer_keyboard.avif";
import HeadphonesPng from "assets/images/offer_headphones.jpg";
import OfferCorousel from "./OfferCorousel";
import MobileTopSlider from "./MobileTopSlider";
import Scanner from "components/Smart-Class/Scanner.jsx";

export default function SmartHeader() {
  const [time, setTime] = useState({
    hours: "00",
    minutes: "00",
    seconds: "00",
    milliseconds: "00",
  });

  useEffect(() => {
    let interval = null;

    const startTimer = () => {
      let endTime = localStorage.getItem("endTime");
      if (!endTime) {
        endTime = new Date();
        endTime.setHours(endTime.getHours() + 12);
        localStorage.setItem("endTime", endTime.toString());
      } else {
        endTime = new Date(endTime);
      }

      interval = setInterval(() => {
        const currentTime = new Date().getTime();
        const remainingTime = endTime - currentTime;

        if (remainingTime <= 0) {
          clearInterval(interval);
          localStorage.removeItem("endTime");

          setTime({
            hours: "00",
            minutes: "00",
            seconds: "00",
            milliseconds: "00",
          });
        } else {
          const hours = Math.floor(
            (remainingTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
          );
          const minutes = Math.floor(
            (remainingTime % (1000 * 60 * 60)) / (1000 * 60)
          );
          const seconds = Math.floor((remainingTime % (1000 * 60)) / 1000);
          const milliseconds = Math.floor((remainingTime % 1000) / 10);

          setTime({
            hours: padTime(hours),
            minutes: padTime(minutes),
            seconds: padTime(seconds),
            milliseconds: padTime(milliseconds),
          });
        }
      }, 10);
    };

    startTimer();

    return () => {
      clearInterval(interval);
    };
  }, []);

  const padTime = (time) => {
    return time.toString().padStart(2, "0");
  };

  return (
    <>
      <section className="max-w-screen-2xl mx-auto sm:px-1 md:px-4 pt-12 md:pt-20">
        <div className="mx-auto py-5 max-w-screen-2xl px-3 sm:px-10">
          <div className="flex flex-col lg:flex-row w-full">
            <div className="hidden flex-shrink-0 xl:pr-6 lg:block w-full lg:w-3/5 ">
              <HeaderSwiper />
            </div>
            <div className="w-full flex lg:hidden">
              <OfferCorousel />
            </div>
            <div className="w-full block md:hidden mx-auto mb-4">
              <MobileTopSlider />
            </div>
            <div className="w-full block md:hidden mx-auto">
              <Scanner />
            </div>

            <div className="w-full hidden lg:flex">
              <div className="w-full group">
                <div className="bg-gray-50 h-full border-2 border-orange-500 transition duration-150 ease-linear transform group-hover:border-emerald-500 rounded shadow">
                  <div className="bg-orange-100 text-gray-900 px-6 py-2 rounded-t border-b flex items-center justify-center">
                    <h3 className="text-base font-sans font-medium ">
                      Latest Super Discount Active Coupon Code
                    </h3>
                  </div>
                  <div className="overflow-hidden">
                    <div className="coupon coupon-home block md:flex lg:flex md:justify-between lg:justify-between items-center bg-white rounded-md shadow border-b border-dashed">
                      <div className="tengah p-2 flex items-center justify-items-start">
                        <figure>
                          <span className="box-border inline-block w-auto h-auto overflow-hidden relative max-w-full">
                            <span className="box-border block w-auto h-auto overflow-hidden relative max-w-full">
                              <img
                                src="data:image/svg+xml,%3csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20width=%27100%27%20height=%27100%27/%3e"
                                aria-hidden="true"
                                alt="Products"
                                className="block max-w-full w-auto h-auto bg-none"
                              />
                            </span>
                            <img
                              src={MousePng}
                              className="absolute inset-0 box-border m-auto block w-0 h-0 max-h-full min-w-full min-h-full max-w-full"
                              alt="Products"
                            />
                          </span>
                        </figure>
                        <div className="ml-3">
                          <div className="flex items-center font-sans">
                            <h6 className="pl-1 text-base font-medium text-gray-600">
                              <span className="text-xs md:text-base lg:text-base text-red-500 font-bold">
                                <span>Mouse</span>
                              </span>{" "}
                              Free
                            </h6>
                            <div className="ml-2">
                              <span className="text-red-600 inline-block px-4 py-1 rounded-full font-medium text-xs bg-red-100">
                                Inactive
                              </span>
                            </div>
                          </div>
                          <h2 className="pl-1 font-sans text-xs text-gray-700 leading-normal font-semibold mb-2">
                            On Dell Laptop
                          </h2>
                          <span className="inline-block mb-2">
                            <div className="flex items-center font-semibold">
                              <span className="flex items-center justify-center bg-red-500 text-white text-xs font-sans font-semibold mx-1 px-2 py-1 rounded">
                                {time.hours}
                              </span>
                              :
                              <span className="flex items-center justify-center bg-red-500 text-white text-xs font-sans font-semibold mx-1 px-2 py-1 rounded">
                                {time.minutes}
                              </span>
                              :
                              <span className="flex items-center justify-center bg-red-500 text-white text-xs font-sans font-semibold mx-1 px-2 py-1 rounded">
                                {time.seconds}
                              </span>
                              :
                              <span className="flex items-center justify-center bg-red-500 text-white text-xs font-sans font-semibold mx-1 px-2 py-1 rounded">
                                {time.milliseconds}
                              </span>
                            </div>
                          </span>
                        </div>
                      </div>
                      <div className="md:border-l-2 lg:border-l-2 border-dashed lg:w-1/3 md:w-1/3 relative px-4">
                        <div className="info flex items-center">
                          <div className="w-full">
                            <div className="block">
                              <div className="font-sans border border-dashed bg-emerald-50 py-1 border-emerald-300 rounded-lg text-center block">
                                <button className="block w-full">
                                  <Link to={"/contact-us"}>
                                    <span className="uppercase font-sans font-semibold text-sm leading-7 text-emerald-600">
                                      Grab Now !{" "}
                                    </span>
                                  </Link>
                                </button>
                              </div>
                            </div>
                            <p className="text-xs leading-4 text-gray-500 mt-2">
                              * This item apply when shopping any of Laptop{" "}
                              <span className="font-bold">TC</span>{" "}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="coupon coupon-home block md:flex lg:flex md:justify-between lg:justify-between items-center bg-white rounded-md shadow border-b border-dashed">
                      <div className="tengah p-2 flex items-center justify-items-start">
                        <figure>
                          <span className="box-border inline-block w-auto h-auto overflow-hidden relative max-w-full">
                            <span className="box-border block w-auto h-auto overflow-hidden relative max-w-full">
                              <img
                                src="data:image/svg+xml,%3csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20width=%27100%27%20height=%27100%27/%3e"
                                aria-hidden="true"
                                alt="Products"
                                className="block max-w-full w-auto h-auto bg-none"
                              />
                            </span>
                            <img
                              src={KeyboardPng}
                              className="absolute inset-0 box-border m-auto block w-0 h-0 max-h-full min-w-full min-h-full max-w-full"
                              alt="Products"
                            />
                          </span>
                        </figure>
                        <div className="ml-3">
                          <div className="flex items-center font-sans">
                            <h6 className="pl-1 text-base font-medium text-gray-600">
                              <span className="text-xs md:text-base lg:text-base text-red-500 font-bold">
                                <span>Keyboard</span>
                              </span>{" "}
                              Free
                            </h6>
                            <div className="ml-2">
                              <span className="text-red-600 inline-block px-4 py-1 rounded-full font-medium text-xs bg-red-100">
                                Inactive
                              </span>
                            </div>
                          </div>
                          <h2 className="pl-1 font-sans text-xs text-gray-700 leading-normal font-semibold mb-2">
                            On HP Laptop
                          </h2>
                          <span className="inline-block mb-2">
                            <div className="flex items-center font-semibold">
                              <span className="flex items-center justify-center bg-red-500 text-white text-xs font-sans font-semibold mx-1 px-2 py-1 rounded">
                                {time.hours}
                              </span>
                              :
                              <span className="flex items-center justify-center bg-red-500 text-white text-xs font-sans font-semibold mx-1 px-2 py-1 rounded">
                                {time.minutes}
                              </span>
                              :
                              <span className="flex items-center justify-center bg-red-500 text-white text-xs font-sans font-semibold mx-1 px-2 py-1 rounded">
                                {time.seconds}
                              </span>
                              :
                              <span className="flex items-center justify-center bg-red-500 text-white text-xs font-sans font-semibold mx-1 px-2 py-1 rounded">
                                {time.milliseconds}
                              </span>
                            </div>
                          </span>
                        </div>
                      </div>
                      <div className="md:border-l-2 lg:border-l-2 border-dashed lg:w-1/3 md:w-1/3 relative px-4">
                        <div className="info flex items-center">
                          <div className="w-full">
                            <div className="block">
                              <div className="font-sans border border-dashed bg-emerald-50 py-1 border-emerald-300 rounded-lg text-center block">
                                <button className="block w-full">
                                  <Link to={"/contact-us"}>
                                    <span className="uppercase font-sans font-semibold text-sm leading-7 text-emerald-600">
                                      Grab Now !{" "}
                                    </span>
                                  </Link>
                                </button>
                              </div>
                            </div>
                            <p className="text-xs leading-4 text-gray-500 mt-2">
                              * This item apply when shopping any of Laptop{" "}
                              <span className="font-bold">TC</span>{" "}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="coupon coupon-home block md:flex lg:flex md:justify-between lg:justify-between items-center bg-white rounded-md shadow border-b border-dashed">
                      <div className="tengah p-2 flex items-center justify-items-start">
                        <figure>
                          <span className="box-border inline-block w-auto h-auto overflow-hidden relative max-w-full">
                            <span className="box-border block w-auto h-auto overflow-hidden relative max-w-full">
                              <img
                                src="data:image/svg+xml,%3csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20width=%27100%27%20height=%27100%27/%3e"
                                aria-hidden="true"
                                alt="Products"
                                className="block max-w-full w-auto h-auto bg-none"
                              />
                            </span>
                            <img
                              src={HeadphonesPng}
                              className="absolute inset-0 box-border m-auto block w-0 h-0 max-h-full min-w-full min-h-full max-w-full"
                              alt="Products"
                            />
                          </span>
                        </figure>
                        <div className="ml-3">
                          <div className="flex items-center font-sans">
                            <h6 className="pl-1 text-base font-medium text-gray-600">
                              <span className="text-xs md:text-base lg:text-base text-red-500 font-bold">
                                <span>Headphones</span>
                              </span>{" "}
                              Free
                            </h6>
                            <div className="ml-2">
                              <span className="text-red-600 inline-block px-4 py-1 rounded-full font-medium text-xs bg-red-100">
                                Inactive
                              </span>
                            </div>
                          </div>
                          <h2 className="pl-1 font-sans text-xs text-gray-700 leading-normal font-semibold mb-2">
                            On MacBook
                          </h2>
                          <span className="inline-block mb-2">
                            <div className="flex items-center font-semibold">
                              <span className="flex items-center justify-center bg-red-500 text-white text-xs font-sans font-semibold mx-1 px-2 py-1 rounded">
                                {time.hours}
                              </span>
                              :
                              <span className="flex items-center justify-center bg-red-500 text-white text-xs font-sans font-semibold mx-1 px-2 py-1 rounded">
                                {time.minutes}
                              </span>
                              :
                              <span className="flex items-center justify-center bg-red-500 text-white text-xs font-sans font-semibold mx-1 px-2 py-1 rounded">
                                {time.seconds}
                              </span>
                              :
                              <span className="flex items-center justify-center bg-red-500 text-white text-xs font-sans font-semibold mx-1 px-2 py-1 rounded">
                                {time.milliseconds}
                              </span>
                            </div>
                          </span>
                        </div>
                      </div>
                      <div className="md:border-l-2 lg:border-l-2 border-dashed lg:w-1/3 md:w-1/3 relative px-4">
                        <div className="info flex items-center">
                          <div className="w-full">
                            <div className="block">
                              <div className="font-sans border border-dashed bg-emerald-50 py-1 border-emerald-300 rounded-lg text-center block">
                                <button className="block w-full">
                                  <Link to={"/contact-us"}>
                                    <span className="uppercase font-sans font-semibold text-sm leading-7 text-emerald-600">
                                      Grab Now !{" "}
                                    </span>
                                  </Link>
                                </button>
                              </div>
                            </div>
                            <p className="text-xs leading-4 text-gray-500 mt-2">
                              * This item apply when shopping any of Laptop{" "}
                              <span className="font-bold">TC</span>{" "}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="bg-emerald-50 px-10 py-6 rounded-lg mt-6 hidden lg:block">
            <div className="flex justify-between items-center">
              <div>
                <h1 className="font-sans text-xl">
                  <span className="text-[#44619d] font-bold">100% Quality</span>{" "}
                  Porduct
                </h1>
                <p className="text-gray-500">
                  Get Our latest products from here and get a special
                  <Link className="text-[#44619d] ml-1" to="/contact-us">
                    {" "}
                    discount product
                  </Link>
                </p>
              </div>
              <a
                className="text-sm font-sans font-medium px-6 py-2 bg-[#44619d] text-center rounded-full text-white hover:bg-[#5184e8]"
                href="/search?Category=organic-food"
              >
                Shop Now
              </a>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
