import React from "react";

function SmartSlider() {
  const ProjectsData = [
    {
      id: "1",
      imgSrc:
        "https://res.cloudinary.com/whiztechnology/image/upload/v1691045910/mvbjheuwi7ffzouipy5t.jpg",
    },
    {
      id: "2",
      imgSrc:
        "https://res.cloudinary.com/whiztechnology/image/upload/v1691045910/phsaq7x9sdfb0cmpslzp.jpg",
    },
    {
      id: "3",
      imgSrc:
        "https://res.cloudinary.com/whiztechnology/image/upload/v1691045910/r3ohsfcqnq8tls5x4bqu.jpg",
    },
    {
      id: "4",
      imgSrc:
        "https://res.cloudinary.com/whiztechnology/image/upload/v1691045909/lfy5lsngdppdzf2hzvzv.jpg",
    },
    {
      id: "5",
      imgSrc:
        "https://res.cloudinary.com/whiztechnology/image/upload/v1691045909/gqsx3pcq3hbbjvjopqse.jpg",
    },
    {
      id: "6",
      imgSrc:
        "https://res.cloudinary.com/whiztechnology/image/upload/v1691045909/z9r4spkra3svdxyt6dmn.jpg",
    },
    {
      id: "7",
      imgSrc:
        "https://res.cloudinary.com/whiztechnology/image/upload/v1691045909/p8ijl3ucnxcrj6ump3zf.jpg",
    },
    {
      id: "8",
      imgSrc:
        "https://res.cloudinary.com/whiztechnology/image/upload/v1691045909/ntom9nrvkcbedan1hqlh.jpg",
    },
    {
      id: "9",
      imgSrc:
        "https://res.cloudinary.com/whiztechnology/image/upload/v1691045909/co3o6xqbikkn380xkelz.jpg",
    },
    {
      id: "10",
      imgSrc:
        "https://res.cloudinary.com/whiztechnology/image/upload/v1691045909/eaj8hizn2k0mkyjvgrta.jpg",
    },
    {
      id: "11",
      imgSrc:
        "https://res.cloudinary.com/whiztechnology/image/upload/v1691045908/tso3fg7onc0svfaytnza.jpg",
    },
    {
      id: "12",
      imgSrc:
        "https://res.cloudinary.com/whiztechnology/image/upload/v1691045908/jkxmdbks1kuoyffdp337.jpg",
    },
    {
      id: "13",
      imgSrc:
        "https://res.cloudinary.com/whiztechnology/image/upload/v1691045908/ypkrc76li36nxmpm0zto.jpg",
    },
    {
      id: "14",
      imgSrc:
        "https://res.cloudinary.com/whiztechnology/image/upload/v1691045908/ypkrc76li36nxmpm0zto.jpg",
    },
    {
      id: "15",
      imgSrc:
        "https://res.cloudinary.com/whiztechnology/image/upload/v1691045908/ptkoe8j5hegmzlvykwbq.jpg",
    },
    {
      id: "16",
      imgSrc:
        "https://res.cloudinary.com/whiztechnology/image/upload/v1691045908/lwfbuv4lyndhoabmhjhj.jpg",
    },
    {
      id: "17",
      imgSrc:
        "https://res.cloudinary.com/whiztechnology/image/upload/v1691045908/ysq2qcp0lnakqhyorirt.jpg",
    },
  ];

  return (
    <>
      <div
        className="w-full h-[20rem] sm:h-[30rem] lg:h-[35rem] overflow-hidden"
        style={{ perspective: "700px" }}
      >
        <div
          className="grid grid-cols-3 gap-12 w-[60rem] sm:w-[80rem] lg:w-[50rem] h-[55rem] md:h-[90rem] lg:h-[75rem] overflow-hidden origin-[50%_0%]"
          style={{
            transform:
              "translate3d(7%, -2%, 0px) scale3d(0.9, 0.8, 1) rotateX(15deg) rotateY(-9deg) rotateZ(32deg)",
          }}
        >
          <div className="grid gap-9 w-full h-fit animation-sliding-img-up-1">
            {ProjectsData.slice(0, 6).map((item, index) => (
              <img
                className="w-full object-cover shadow-lg rounded-lg dark:shadow-gray-900/[.75] dark:hidden"
                src={item.imgSrc}
                alt="Projects"
                key={index}
              />
            ))}
          </div>
          <div className="grid gap-9 w-full h-fit animation-sliding-img-down-1">
            {ProjectsData.slice(7, 12).map((item, index) => (
              <img
                className="w-full object-cover shadow-lg rounded-lg dark:shadow-gray-900/[.75]"
                src={item.imgSrc}
                alt="Projects"
                key={index}
              />
            ))}
          </div>
          <div className="grid gap-9 w-full h-fit animation-sliding-img-up-2">
            {ProjectsData.slice(13, 17).map((item, index) => (
              <img
                className="w-full object-cover shadow-lg rounded-lg dark:shadow-gray-900/[.75] dark:hidden"
                src={item.imgSrc}
                alt="Projects"
                key={index}
              />
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default SmartSlider;
