import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth, GoogleAuthProvider } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyCeS_2hVPpSFmsFst6WDxndCbRnys9AUaU",
  authDomain: "whizpro-21a8c.firebaseapp.com",
  databaseURL: "https://whizpro-21a8c-default-rtdb.firebaseio.com",
  projectId: "whizpro-21a8c",
  storageBucket: "whizpro-21a8c.appspot.com",
  messagingSenderId: "925130118340",
  appId: "1:925130118340:web:d312d45e001cf215c72591",
  measurementId: "G-D9JLPRN3XX",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const googleProvider = new GoogleAuthProvider();
export const db = getFirestore(app);
