import React from "react";
import DownloadSvg from "assets/images/backgroundSvg.svg";

const AdsSectionBottom = () => {
  return (
    <>
      <section className="w-full">
        <div className="bg-indigo-50 py-10 lg:py-16 bg-repeat bg-center overflow-hidden">
          <div className="max-w-screen-2xl mx-auto px-4 sm:px-10">
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 2xl:grid-cols-3 gap-2 md:gap-3 lg:gap-3 items-center">
              <div className="flex-grow hidden lg:flex md:flex md:justify-items-center lg:justify-start relative">
                <span className="box-border inline-block w-auto h-auto overflow-hidden relative max-w-full">
                  <span className="box-border inline-block w-auto h-auto max-w-full">
                    {" "}
                    <img
                      className="block max-w-full w-auto h-auto"
                      src={DownloadSvg}
                      alt="Background"
                    />
                  </span>
                </span>
                <img
                  src={
                    "https://res.cloudinary.com/whiztechnology/image/upload/v1691054457/fj62n5r94ezjrk5mhxwc.png"
                  }
                  className="w-auto block absolute inset-0 box-border m-auto min-w-full min-h-full max-w-full max-h-full"
                  alt="Delivery Update"
                  srcset=""
                />
              </div>
              <div className="text-center">
                <h3 className="text-xl md:text-2xl lg:text-3xl font-bold font-serif mb-3">
                  Welcome to Whiz Technology
                </h3>
                <p className="text-base opacity-90 leading-7">
                  Your one-stop destination for all your smart classroom needs &
                  upgrades. We offer a wide range of products to cater to your
                  smart classroom requirements. Explore our store and take
                  advantage of our special offers.
                </p>
              </div>
              <div className="flex-grow hidden lg:flex md:flex md:justify-items-center lg:justify-start relative">
                <span className="box-border inline-block w-auto h-auto overflow-hidden relative max-w-full">
                  <span className="box-border inline-block w-auto h-auto max-w-full">
                    {" "}
                    <img
                      className="block max-w-full w-auto h-auto"
                      src={DownloadSvg}
                      alt="Background"
                    />
                  </span>
                </span>
                <img
                  src={
                    "https://res.cloudinary.com/whiztechnology/image/upload/v1691054453/vpnrikacayv5lrs5aneq.png"
                  }
                  className="w-auto block absolute inset-0 box-border m-auto min-w-full min-h-full max-w-full max-h-full"
                  alt="Delivery Update"
                  srcset=""
                />
              </div>
            </div>
          </div>
        </div>
        <div className="hidden relative lg:block mx-auto max-w-screen-2xl py-6 px-3 sm:px-10">
          <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-4 xl:grid-cols-4 mx-auto">
            <div className=" border-r border-gray-200 py-1 flex items-center justify-center bg-white">
              <div className="mr-3">
                <svg
                  stroke="currentColor"
                  fill="none"
                  stroke-width="2"
                  viewBox="0 0 24 24"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  className="flex-shrink-0 h-4 w-4 text-[#44619d]"
                  aria-hidden="true"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect x="1" y="3" width="15" height="13"></rect>
                  <polygon points="16 8 20 8 23 11 23 16 16 16 16 8"></polygon>
                  <circle cx="5.5" cy="18.5" r="2.5"></circle>
                  <circle cx="18.5" cy="18.5" r="2.5"></circle>
                </svg>
              </div>
              <div className="">
                <span className="block font-serif text-sm font-medium leading-5">
                  Free Shipping From $500.00
                </span>
              </div>
            </div>
            <div className=" border-r border-gray-200 py-1 flex items-center justify-center bg-white">
              <div className="mr-3">
                <svg
                  stroke="currentColor"
                  fill="none"
                  stroke-width="2"
                  viewBox="0 0 24 24"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  className="flex-shrink-0 h-4 w-4 text-[#44619d]"
                  aria-hidden="true"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M15.05 5A5 5 0 0 1 19 8.95M15.05 1A9 9 0 0 1 23 8.94m-1 7.98v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"></path>
                </svg>
              </div>
              <div className="">
                <span className="block font-serif text-sm font-medium leading-5">
                  Support 24/7 At Anytime
                </span>
              </div>
            </div>
            <div className=" border-r border-gray-200 py-1 flex items-center justify-center bg-white">
              <div className="mr-3">
                <svg
                  stroke="currentColor"
                  fill="none"
                  stroke-width="2"
                  viewBox="0 0 24 24"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  className="flex-shrink-0 h-4 w-4 text-[#44619d]"
                  aria-hidden="true"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect x="1" y="4" width="22" height="16" rx="2" ry="2"></rect>
                  <line x1="1" y1="10" x2="23" y2="10"></line>
                </svg>
              </div>
              <div className="">
                <span className="block font-serif text-sm font-medium leading-5">
                  Secure Payment Totally Safe
                </span>
              </div>
            </div>
            <div className=" border-r border-gray-200 py-1 flex items-center justify-center bg-white">
              <div className="mr-3">
                <svg
                  stroke="currentColor"
                  fill="none"
                  stroke-width="2"
                  viewBox="0 0 24 24"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  className="flex-shrink-0 h-4 w-4 text-[#44619d]"
                  aria-hidden="true"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <polyline points="20 12 20 22 4 22 4 12"></polyline>
                  <rect x="2" y="7" width="20" height="5"></rect>
                  <line x1="12" y1="22" x2="12" y2="7"></line>
                  <path d="M12 7H7.5a2.5 2.5 0 0 1 0-5C11 2 12 7 12 7z"></path>
                  <path d="M12 7h4.5a2.5 2.5 0 0 0 0-5C13 2 12 7 12 7z"></path>
                </svg>
              </div>
              <div className="">
                <span className="block font-serif text-sm font-medium leading-5">
                  Latest Offer Upto 20% Off
                </span>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AdsSectionBottom;
