import React from "react";
import { useState } from "react";

const ScrollToTopbtn = () => {
  const [visible, setVisible] = useState(false);

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300) {
      setVisible(true);
    } else if (scrolled <= 300) {
      setVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  window.addEventListener("scroll", toggleVisible);
  return (
    <>
      <div className=" fixed right-9 bottom-9 block w-5 h-5 md:hidden">
        {" "}
        <button
          type="button"
          className={`text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-full text-sm p-2.5 text-center  items-center mr-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 ${
            visible ? "inline-flex" : "hidden"
          }`}
          onClick={scrollToTop}
        >
          {upArrow()}
          <span className="sr-only">Scroll Top</span>
        </button>
      </div>
    </>
  );
};

export default ScrollToTopbtn;

const upArrow = () => {
  return (
    <svg
      className="w-4 h-4 -rotate-90"
      aria-hidden="true"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 14 10"
    >
      <path
        stroke="currentColor"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        d="M1 5h12m0 0L9 1m4 4L9 9"
      />
    </svg>
  );
};
