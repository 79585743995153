import React, { useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { useCart } from "components/utils/Products_api";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import emailjs from "emailjs-com";
import { handlePageChange } from "components/utils/useMyFunctions.js";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function ViewProduct({ isOpen, closeModal }, item) {
  const { viewItems, addToCart } = useCart();

  const [phoneNo, setPhoneNo] = useState("");

  // const [isAddedToCart, setIsAddedToCart] = useState(false);

  const addToCartFromView = (product) => {
    addToCart(product);
    // setIsAddedToCart(true);
    notifyA();
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const productName = viewItems[0].title;

    const serviceId = "service_xz6fdum";
    const templateId = "template_2qjudyq";

    emailjs.init("CM-cjnO4t4u12gvOz");

    const emailParams = {
      tel_no: phoneNo,
      product_name: productName,
    };

    if (!phoneNo) {
      notifyB();
      return;
    }
    emailjs
      .send(serviceId, templateId, emailParams)
      .then((response) => {
        console.log("Email sent successfully!", response);
        setPhoneNo("");
      })
      .catch((error) => {
        console.error("Error sending email:", error);
      });
    handlePageChange();
    notifyC();
  };

  let customId1 = "CustomId-1";
  let customId2 = "CustomId-2";
  let customId3 = "CustomId-3";

  const notifyA = () =>
    toast.success(`Added to cart successfully`, {
      toastId: customId1,
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });

  const notifyC = () =>
    toast.success(`Email sent successfully.`, {
      toastId: customId2,
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });

  const notifyB = () =>
    toast.error(`Please provide your phone no.!`, {
      toastId: customId3,
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });

  const renderButton = () => {
    return (
      <button
        className="text-sm leading-4 inline-flex items-center cursor-pointer transition ease-in-out duration-300 font-semibold font-serif text-center justify-center border-0 border-transparent rounded-md focus-visible:outline-none focus:outline-none text-white px-4 ml-4 md:px-6 lg:px-8 py-4 md:py-3.5 lg:py-4 hover:text-white bg-[#44619d] hover:bg-emerald-500 w-full h-12"
        onClick={() => addToCartFromView(item)}
      >
        Add to Cart
      </button>
    );
    // if (isAddedToCart) {
    //   return (
    //     <button className="text-sm leading-4 inline-flex items-center cursor-pointer transition ease-in-out duration-300 font-semibold font-serif text-center justify-center border-0 border-transparent rounded-md focus-visible:outline-none focus:outline-none text-white px-4 ml-4 md:px-6 lg:px-8 py-4 md:py-3.5 lg:py-4 hover:text-white bg-[#44619d] hover:bg-emerald-500 w-full h-12">
    //       View Cart
    //     </button>
    //   );
    // } else {
    //   return (
    //     <button
    //       className="text-sm leading-4 inline-flex items-center cursor-pointer transition ease-in-out duration-300 font-semibold font-serif text-center justify-center border-0 border-transparent rounded-md focus-visible:outline-none focus:outline-none text-white px-4 ml-4 md:px-6 lg:px-8 py-4 md:py-3.5 lg:py-4 hover:text-white bg-[#44619d] hover:bg-emerald-500 w-full h-12"
    //       onClick={() => addToCartFromView(item)}
    //     >
    //       Add to Cart
    //     </button>
    //   );
    // }
  };

  console.log("Products ~", viewItems);

  return (
    <>
      <Transition appear show={isOpen} as={Fragment} closeModal={closeModal}>
        <Dialog as="div" className="relative z-50" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div
              className="fixed inset-0 bg-black bg-opacity-25"
              onClick={!isOpen}
            />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto text-center">
            <div className="flex min-h-screen px-4 items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <div className="inline-block overflow-y-auto h-full align-middle transition-all transform bg-white shadow-xl rounded-2xl opacity-100 scale-100 relative">
                  <div
                    className="absolute right-2 top-2 z-50"
                    onClick={closeModal}
                  >
                    <CloseIcon className=" h-4 w-4 text-indigo-500 cursor-pointer" />
                  </div>
                  <form onSubmit={handleSubmit} method="post">
                    {viewItems.length > 0 ? (
                      viewItems.map((item) => (
                        <div className="flex flex-col lg:flex-row md:flex-row w-full max-w-4xl overflow-hidden">
                          <div className="flex-shrink-0 flex items-center justify-center h-auto cursor-pointer">
                            <span className="box-border inline-block overflow-hidden h-auto w-auto relative max-w-full">
                              <span className=" box-border h-auto w-auto block ">
                                <img
                                  src="data:image/svg+xml,%3csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20width=%27420%27%20height=%27420%27/%3e"
                                  alt=""
                                  className="block max-w-full w-auto h-auto"
                                  srcset=""
                                />
                              </span>
                              <img
                                src={item.imgSrc}
                                className="absolute inset-0 box-border m-auto block w-0 h-0 min-w-full min-h-full"
                                alt="Products"
                                srcset=""
                              />
                            </span>
                          </div>
                          <div className="w-full flex flex-col p-5 md:p-8 text-left">
                            <div className="mb-2 md:mb-2.5 block -mt-1.5">
                              <h1
                                className="text-heading text-lg md:text-xl lg:text-2xl font-semibold font-serif hover:text-black cursor-pointer"
                                href="/product/clementine"
                              >
                                {item.title}
                              </h1>
                              <div className="relative inline-flex">
                                <span className="bg-emerald-100 text-emerald-500 rounded-full inline-flex items-center justify-center px-2 py-0 text-xs font-semibold font-serif">
                                  Stock :
                                  <span className="text-red-500 dark:text-red-400 pl-1 font-bold">
                                    451{" "}
                                  </span>
                                </span>

                                <div class="ml-4 flex items-center space-x-1">
                                  <svg
                                    class="w-4 h-4 text-yellow-300"
                                    aria-hidden="true"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="currentColor"
                                    viewBox="0 0 22 20"
                                  >
                                    <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                                  </svg>
                                  <svg
                                    class="w-4 h-4 text-yellow-300"
                                    aria-hidden="true"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="currentColor"
                                    viewBox="0 0 22 20"
                                  >
                                    <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                                  </svg>
                                  <svg
                                    class="w-4 h-4 text-yellow-300"
                                    aria-hidden="true"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="currentColor"
                                    viewBox="0 0 22 20"
                                  >
                                    <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                                  </svg>
                                  <svg
                                    class="w-4 h-4 text-yellow-300"
                                    aria-hidden="true"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="currentColor"
                                    viewBox="0 0 22 20"
                                  >
                                    <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                                  </svg>
                                  <svg
                                    class="w-4 h-4 text-gray-300 dark:text-gray-500"
                                    aria-hidden="true"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="currentColor"
                                    viewBox="0 0 22 20"
                                  >
                                    <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                                  </svg>
                                </div>
                              </div>
                            </div>
                            <p className="text-sm leading-normal text-gray-500 text-justify justify-center">
                              {item.description}
                            </p>
                            <div className="flex items-center w-full my-4">
                              <PhoneInput
                                country={"in"}
                                value={phoneNo}
                                onChange={(e) => setPhoneNo(e.valueOf(phoneNo))}
                                requred
                                inputProps={{
                                  name: "phone",
                                  required: true,
                                  autoFocus: true,
                                }}
                              />
                              {console.log("Phone No", phoneNo)}
                            </div>
                            <div className="flex items-center mt-4">
                              <div className="flex items-center justify-between space-s-3 sm:space-s-4 w-full">
                                <button
                                  className="text-sm leading-4 inline-flex items-center cursor-pointer transition ease-in-out duration-300 font-semibold font-serif text-center justify-center border-0 border-transparent rounded-md focus-visible:outline-none focus:outline-none text-white px-4 md:px-6 lg:px-8 py-4 md:py-3.5 lg:py-4 hover:text-white bg-emerald-500 hover:bg-[#44619d] w-full h-12"
                                  onClick={handleSubmit}
                                  type="submit"
                                >
                                  Enquire Now !
                                </button>
                                {/* <button
                                  className="text-sm leading-4 inline-flex items-center cursor-pointer transition ease-in-out duration-300 font-semibold font-serif text-center justify-center border-0 border-transparent rounded-md focus-visible:outline-none focus:outline-none text-white px-4 ml-4 md:px-6 lg:px-8 py-4 md:py-3.5 lg:py-4 hover:text-white bg-[#44619d] hover:bg-emerald-500 w-full h-12"
                                  onClick={() => addToCartFromView(item)}
                                >
                                  Add to Cart
                                </button> */}
                                {renderButton()}
                              </div>
                            </div>
                          </div>
                        </div>
                      ))
                    ) : (
                      <div className="flex flex-col lg:flex-row md:flex-row w-full max-w-4xl overflow-hidden">
                        <span>your cart is empy</span>
                      </div>
                    )}
                  </form>
                </div>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <ToastContainer />
    </>
  );
}
