import Development from "assets/logo/user-interface.png";
import Quality from "assets/logo/repair-tools.png";
import Deployment from "assets/logo/physics.png";
import Maintainence from "assets/logo/chat.png";
import filePng from "assets/logo/file.png";
import UIUX from "assets/logo/ux-design.png";
import Prototype from "assets/logo/assignment.png";
import RetailSvg from "assets/svgs/retail.svg";
import StudentSvg from "assets/svgs/student.svg";
import HealthcareSvg from "assets/svgs/healthcare.svg";
import LogisticSvg from "assets/svgs/logistic.svg";
import NetworkingSvg from "assets/svgs/networking.svg";
import RealEstateSvg from "assets/svgs/real-estate.svg";
import TravelSvg from "assets/svgs/travel.svg";
import Food from "assets/svgs/food.svg";
import Sofa from "assets/logo/sofa.png";
import Interior from "assets/logo/sketch.png";

export const webData = [
  {
    id: 1,
    titlle: "Mobile App Development",
    description:
      "Deliver great service experiences fast - without the complexity of traditional solutions. Accelerate critical development work, eliminate toil, and deploy changes with ease.",
    listData: [
      {
        id: 1,
        content: "ERP Development",
        to: "erp-development-company",
      },
      {
        id: 2,
        content: "ERP Development",
      },
    ],
  },
];
export const accordionData = [
  {
    id: 1,
    title: "Q1: What is a web service?",
    data: "A web service is a software system designed to enable communication and interoperability between different applications over a network, typically the internet. It allows applications to exchange data and perform various operations using standard protocols such as HTTP and XML. Web services are commonly used for integrating different systems, sharing data, and facilitating communication between applications developed on different platforms.",
  },
  {
    id: 2,
    title: "Q2: What are the advantages of using web services?",
    data: "Web services offer several advantages, including: Interoperability: Web services allow applications developed on different platforms to communicate and exchange data seamlessly",
  },
  {
    id: 3,
    title: "Q4: How can I consume a web service in my application?",
    data: `Identify the type of web service (SOAP, REST, GraphQL) and its endpoint URL. Use a programming language or framework that supports web service consumption. Generate or write code to make HTTP requests to the web service endpoint. Handle the responses returned by the web service and parse the data as needed. Integrate the consumed data into your application's logic or user interface.`,
  },
];

export const webServices = [
  {
    id: 1,
    title: "ERP Development",
    to: "/erp-development-company",
  },
  {
    id: 2,
    title: "eCommerce Development",
    to: "/e-commerce-development-company",
  },
  {
    id: 3,
    title: "CRM Development",
    to: "/crm-development-company",
  },
  {
    id: 4,
    title: "Travel Portal Development",
    to: "/travel-portal-development-company",
  },
  {
    id: 5,
    title: "School Website Development",
    to: "/school-website-development-company",
  },
  {
    id: 6,
    title: "Hospital Website Development",
    to: "/hospital-website-development-company",
  },
  {
    id: 7,
    title: "Corporate Website Development",
    // to: "/bussiness-website-development-company",
  },
];

export const cardData = [
  {
    id: "01",
    title: "Quick & Safe Booking",
    content:
      "Hotel booking apps allow users to book nearby hotels faster and with more security. They simply need to download the hotel booking app and get the best suggestions nearby.",
  },
  {
    id: "02",
    title: "Brand Awareness",
    content:
      "Apps are one of the most used commodities, so having a hotel booking app will improve your hotel business's brand awareness.",
  },
  {
    id: "03",
    title: "Hassle-free Payment",
    content:
      "Say goodbye to the concern of offline payment management. Hotel booking apps enable hassle-free payment and allow users to pay on the go with a tap.",
  },
  {
    id: "04",
    title: "Streamlined Booking",
    content:
      "With a feature-rich and well-managed, users and business owners do not have to worry about booking management and get updates on upcoming, ongoing, and cancelled bookings.",
  },
  {
    id: "05",
    title: "Customized Push Notifications.",
    content:
      "Keeping the users timely notified of their booking status, upcoming offers, and more will increase your hotel booking app engagement and customer engagement.",
  },
  {
    id: "06",
    title: "Transparent Business Analytics",
    content:
      "While having hotel booking applications, businesses can look at the business analytics and stats clearly to analyze monthly or weekly profit and growth",
  },
];

export const keyFeatures = [
  {
    id: 1,
    icon: filePng,
    title: "Requirement Gathering",
    data: "We follow the first and foremost priority of gathering requirements, resources, and information to begin our project.",
  },
  {
    id: 2,
    icon: UIUX,
    title: "UI/UX Design",
    data: "We create catchy and charming designs with the latest tools of designing to make it a best user-friendly experience.",
  },
  {
    id: 3,
    icon: Prototype,
    title: "Prototype",
    data: "After designing, you will get your prototype, which will be sent ahead for the development process for the product.",
  },
  {
    id: 4,
    icon: Development,
    title: "Development",
    data: "Development of mobile application/web/blockchain started using latest tools and technologies with transparency.",
  },
  {
    id: 5,
    icon: Quality,
    title: "Quality Assurance",
    data: "Hyperlink values quality and provides 100% bug free application with no compromisation in it.",
  },
  {
    id: 6,
    icon: Deployment,
    title: "Deployment",
    data: "After trial and following all processes, your app is ready to launch on the App store or Play Store.",
  },
  {
    id: 7,
    icon: Maintainence,
    title: "Support & Maintenance",
    data: "Our company offers you all support and the team is always ready to answer every query after deployment.",
  },
];

export const industriesData = [
  {
    id: 1,
    title: "Retail, Ecommerce",
    icon: RetailSvg,
  },
  {
    id: 2,
    title: "Education & e-learning",
    icon: StudentSvg,
  },
  {
    id: 3,
    title: "Healthcare & Fitness",
    icon: HealthcareSvg,
  },
  {
    id: 4,
    title: "Logistics & Distribution",
    icon: LogisticSvg,
  },
  {
    id: 5,
    title: "Social Networking",
    icon: NetworkingSvg,
  },
  {
    id: 6,
    title: "Real Estate",
    icon: RealEstateSvg,
  },
  {
    id: 7,
    title: "Travel & Hospitality",
    icon: TravelSvg,
  },
  {
    id: 8,
    title: "Food & Restaurant",
    icon: Food,
  },
  {
    id: 9,
    title: "Decore & Interior Industry",
    icon: Interior,
  },
  {
    id: 10,
    title: "Furniture Industry",
    icon: Sofa,
  },
];
