import React, { useState } from "react";
import NewsLetter from "./NewsLetter.js";
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import ContactHeader from "./ContactHeader.js";
import Footer from "components/Footers/Footer.js";
import emailjs from "emailjs-com";
import { handlePageChange } from "components/utils/useMyFunctions.js.js";
import Trusted from "components/Footers/Trusted.jsx";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

import { db } from "config/firebase.js";
import { setDoc, doc } from "firebase/firestore";

const Contact = () => {
  const Services = [
    { id: 1, value: "Web Development", title: "Web Development" },
    { id: 2, value: "Mobile App Development", title: "Mobile App Development" },
    { id: 3, value: "Digital Marketing", title: "Digital Marketing" },
    { id: 4, value: "Web Designing", title: "Web Designing" },
    { id: 5, value: "Stack Development", title: "Stack Development" },
    {
      id: 6,
      value: "Technology Certification",
      title: "Technology Certification",
    },
    { id: 7, value: "Smart Products", title: "Smart Products" },
    { id: 8, value: "Media Works", title: "Media Works" },
    { id: 9, value: "Other Services", title: "Other Services" },
  ];

  // Form Validation Using EmailJs
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNo, setPhoneNo] = useState("");
  const [budget, setBudget] = useState("");
  const [address, setAdress] = useState("");
  const [bussiness, setBussiness] = useState("");
  const [message, setMessage] = useState("");
  const [selectedServices, setSelectedServices] = useState([]);

  const handleCheckboxChange = (e) => {
    const { value, checked } = e.target;

    if (checked) {
      setSelectedServices((prevSelectedServices) => [
        ...prevSelectedServices,
        value,
      ]);
    } else {
      setSelectedServices((prevSelectedServices) =>
        prevSelectedServices.filter((service) => service !== value)
      );
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const currentDate = new Date();
    const options = { day: "2-digit", month: "short", year: "numeric" };
    const createdAt = currentDate.toLocaleDateString("en-US", options);
    const updatedAt = currentDate.toLocaleDateString("en-US", options);
    const selectedServicesArray = selectedServices.join(", ");

    const formData = {
      id: Date.now(),
      full_name: name,
      read: false,
      email,
      services: selectedServicesArray,
      phone_no: phoneNo,
      status: "Add Status",
      followup: "Pending",
      createdAt,
      updatedAt,
      source: "contact_us",
    };

    const docId = Date.now();
    const collectionsRef = doc(db, "webServiceEnquiry", `${docId}`);

    try {
      setDoc(collectionsRef, formData)
        .then(() => {
          console.log("Document created successfully!");
        })
        .catch((error) => {
          console.error("Error creating document: ", error);
        });
    } catch (error) {
      console.error("Error creating document: ", error);
    }
    handlePageChange();
  };

  return (
    <>
      <Helmet>
        <title>Contact Us | +91-9560322639</title>
        <meta
          name="description"
          content="Address : T-29 8B, Second Floor, Rd Number 20, West Patel Nagar, New Delhi, Delhi 110008, Email : info.whiztechnology@gmail.com"
        />
        <meta
          name="keywords"
          content="contact-us, support, carier, IT jobs, Software development job, Hiring, freelancer hiring"
        />
      </Helmet>
      <div className="fixed bg-hero-pattern bg-fixed bg-cover bg-center inset-0 -z-20"></div>
      <div className="css-1wuiiom">
        {" "}
        <IndexNavbar fixed />
        <ContactHeader />
        <section
          id="contact"
          className="overflow-hidden max-w-screen-xl mx-auto py-16 md:py-20 lg:py-28"
        >
          <div className="container max-w-7xl mx-auto">
            <div className="-mx-4 flex flex-wrap">
              <div className="w-full backdrop-blur-sm bg-white/30 rounded-xl shadow-lg px-4 lg:w-7/12 xl:w-8/12">
                <div className="wow fadeInUp mb-12 rounded-md bg-primary/[3%] py-11 px-8 dark:bg-dark sm:p-[55px] lg:mb-5 lg:px-8 xl:p-[55px]">
                  <h2 className="mb-3 text-2xl font-bold text-black dark:text-white sm:text-3xl lg:text-2xl xl:text-3xl inline-block">
                    Contact Us |{" "}
                    <Link to={"tel:+91-9560322639"}>
                      {" "}
                      <span className=" text-black text-base md:text ">
                        +91-9560322639
                      </span>{" "}
                    </Link>
                  </h2>
                  <p className="mb-12 text-base font-medium text-body-color">
                    Our support team will get back to you ASAP via email.
                  </p>
                  <form onSubmit={handleSubmit}>
                    <div className="-mx-4 flex flex-wrap">
                      <div className="w-full px-4 md:w-1/2">
                        <div className="mb-8">
                          <label
                            htmlFor="name"
                            className="mb-3 block text-sm font-medium text-dark dark:text-white"
                          >
                            Your Name
                          </label>
                          <input
                            type="text"
                            placeholder="Enter your name"
                            className="w-full rounded-md border border-transparent py-3 px-6 text-base text-body-color placeholder-body-color shadow-one outline-none focus:border-primary focus-visible:shadow-none dark:bg-[#242B51] dark:shadow-signUp outline-2 outline-[#e5edff]"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            required
                          />
                        </div>
                      </div>

                      <div className="w-full px-4 md:w-1/2">
                        <div className="mb-8">
                          <label
                            htmlFor="email"
                            className="mb-3 block text-sm font-medium text-dark dark:text-white"
                          >
                            Your Email
                          </label>
                          <input
                            type="email"
                            placeholder="Enter your email"
                            className="w-full rounded-md border border-transparent py-3 px-6 text-base text-body-color placeholder-body-color shadow-one outline-none focus:border-primary focus-visible:shadow-none dark:bg-[#242B51] dark:shadow-signUp outline-2 outline-[#e5edff]"
                            required
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="w-full px-4 md:w-1/2">
                        <div className="mb-8">
                          <label
                            for="phone"
                            class="block mb-3 text-sm font-medium text-gray-900 dark:text-white"
                          >
                            Contact No:
                          </label>
                          <input
                            type="tel"
                            id="phone"
                            class="w-full rounded-md border border-transparent py-3 px-6 text-base text-body-color placeholder-body-color shadow-one outline-none focus:border-primary focus-visible:shadow-none dark:bg-[#242B51] dark:shadow-signUp outline-2 outline-[#e5edff]"
                            placeholder="123-45-678"
                            required
                            value={phoneNo}
                            onChange={(e) => setPhoneNo(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="w-full px-4 md:w-1/2">
                        <div className="mb-8">
                          <label
                            htmlFor="name"
                            className="mb-3 block text-sm font-medium text-dark dark:text-white"
                          >
                            State
                          </label>
                          <input
                            type="text"
                            placeholder="Enter your State"
                            className="w-full rounded-md border border-transparent py-3 px-6 text-base text-body-color placeholder-body-color shadow-one outline-none focus:border-primary focus-visible:shadow-none dark:bg-[#242B51] dark:shadow-signUp outline-2 outline-[#e5edff]"
                            value={budget}
                            onChange={(e) => setBudget(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="w-full mb-8 px-4">
                        <h3 className="mb-4 font-semibold text-gray-900 dark:text-white">
                          Services
                        </h3>
                        <ul className="items-center w-full text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-2 dark:bg-gray-700 dark:border-gray-600 dark:text-white">
                          {Services.map((item) => (
                            <li
                              className="w-full border-b border-[#e5edff] sm:border-b-0 sm:border-r dark:border-gray-600"
                              key={item.id}
                            >
                              <div className="flex items-center pl-3">
                                <input
                                  id={`vue-checkbox-list-${item.id}`}
                                  type="checkbox"
                                  value={item.value}
                                  className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500 outline-2 outline-[#e5edff]"
                                  onChange={handleCheckboxChange}
                                />
                                <label
                                  htmlFor={`vue-checkbox-list-${item.id}`}
                                  className="w-full py-3 ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                                >
                                  {item.title}
                                </label>
                              </div>
                            </li>
                          ))}
                        </ul>
                      </div>
                      <div className="w-full px-4 md:w-1/2">
                        <div className="mb-8">
                          <label
                            htmlFor="name"
                            className="mb-3 block text-sm font-medium text-dark dark:text-white"
                          >
                            Your Address
                          </label>
                          <input
                            type="text"
                            placeholder="Enter your address"
                            className="w-full rounded-md border border-transparent py-3 px-6 text-base text-body-color placeholder-body-color shadow-one outline-none focus:border-primary focus-visible:shadow-none dark:bg-[#242B51] dark:shadow-signUp outline-2 outline-[#e5edff]"
                            value={address}
                            onChange={(e) => setAdress(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="w-full px-4 md:w-1/2">
                        <div className="mb-8">
                          <label
                            htmlFor="name"
                            className="mb-3 block text-sm font-medium text-dark dark:text-white"
                          >
                            Your Bussiness Name
                          </label>
                          <input
                            type="text"
                            placeholder="Enter your bussiness name"
                            className="w-full rounded-md border border-transparent py-3 px-6 text-base text-body-color placeholder-body-color shadow-one outline-none focus:border-primary focus-visible:shadow-none dark:bg-[#242B51] dark:shadow-signUp outline-2 outline-[#e5edff]"
                            value={bussiness}
                            onChange={(e) => setBussiness(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="w-full px-4">
                        <div className="mb-8">
                          <label
                            htmlFor="message"
                            className="mb-3 block text-sm font-medium text-dark dark:text-white"
                          >
                            Your Message
                          </label>
                          <textarea
                            name="message"
                            rows={5}
                            placeholder="Enter your Message"
                            className="w-full resize-none rounded-md border border-transparent py-3 px-6 text-base text-body-color placeholder-body-color shadow-one outline-none focus:border-primary focus-visible:shadow-none dark:bg-[#242B51] dark:shadow-signUp outline-2 outline-[#e5edff]"
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                          ></textarea>
                        </div>
                      </div>
                      <div className="w-full px-4">
                        <button
                          type="submit"
                          className="rounded-md bg-[#44619d] hover:bg-black py-4 px-9 text-base font-medium text-white transition duration-300 ease-in-out hover:bg-opacity-80 hover:shadow-signUp outline-2 outline-[#e5edff]"
                        >
                          Enqure Now ?
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div className="w-full h-fit rounded-xl shadow-lg px-4 bg-gradient-to-r from-sky-400 to-blue-500 lg:w-5/12 xl:w-4/12">
                <NewsLetter />
              </div>
            </div>
          </div>
        </section>
        <Trusted />
        <Footer />
      </div>
    </>
  );
};

export default Contact;
