import React from "react";
import "./Tages.css";

const Tager = () => {
  return (
    <>
      <div className="max-w-xs mx-auto mt-[50px] overflow-hidden">
        {" "}
        <div className="tag-list MuiBox-root css-0 max-w-[14rem] rounded-xl">
          <div className="loop-slider MuiBox-root css-r4ptw3">
            <div className="inner MuiBox-root css-0">
              <div className="tager MuiBox-root css-0">
                <span className="MuiBox-root css-0">#</span>{" "}
                <p className="MuiTypography-root MuiTypography-body1 css-1azmrpm">
                  Gatsby
                </p>
              </div>
              <div className="tager MuiBox-root css-0">
                <span className="MuiBox-root css-0">#</span>{" "}
                <p className="MuiTypography-root MuiTypography-body1 css-1azmrpm">
                  HTML
                </p>
              </div>
              <div className="tager MuiBox-root css-0">
                <span className="MuiBox-root css-0">#</span>{" "}
                <p className="MuiTypography-root MuiTypography-body1 css-1azmrpm">
                  webdev
                </p>
              </div>
              <div className="tager MuiBox-root css-0">
                <span className="MuiBox-root css-0">#</span>{" "}
                <p className="MuiTypography-root MuiTypography-body1 css-1azmrpm">
                  UI/UX
                </p>
              </div>
              <div className="tager MuiBox-root css-0">
                <span className="MuiBox-root css-0">#</span>{" "}
                <p className="MuiTypography-root MuiTypography-body1 css-1azmrpm">
                  React
                </p>
              </div>
              <div className="tager MuiBox-root css-0">
                <span className="MuiBox-root css-0">#</span>{" "}
                <p className="MuiTypography-root MuiTypography-body1 css-1azmrpm">
                  Gatsby
                </p>
              </div>
              <div className="tager MuiBox-root css-0">
                <span className="MuiBox-root css-0">#</span>{" "}
                <p className="MuiTypography-root MuiTypography-body1 css-1azmrpm">
                  HTML
                </p>
              </div>
              <div className="tager MuiBox-root css-0">
                <span className="MuiBox-root css-0">#</span>{" "}
                <p className="MuiTypography-root MuiTypography-body1 css-1azmrpm">
                  webdev
                </p>
              </div>
              <div className="tager MuiBox-root css-0">
                <span className="MuiBox-root css-0">#</span>{" "}
                <p className="MuiTypography-root MuiTypography-body1 css-1azmrpm">
                  UI/UX
                </p>
              </div>
              <div className="tager MuiBox-root css-0">
                <span className="MuiBox-root css-0">#</span>{" "}
                <p className="MuiTypography-root MuiTypography-body1 css-1azmrpm">
                  React
                </p>
              </div>
            </div>
          </div>
          <div className="loop-slider MuiBox-root css-1drwi35">
            <div className="inner MuiBox-root css-0">
              <div className="tager MuiBox-root css-0">
                <span className="MuiBox-root css-0">#</span>{" "}
                <p className="MuiTypography-root MuiTypography-body1 css-1azmrpm">
                  Tailwind
                </p>
              </div>
              <div className="tager MuiBox-root css-0">
                <span className="MuiBox-root css-0">#</span>{" "}
                <p className="MuiTypography-root MuiTypography-body1 css-1azmrpm">
                  Typescript
                </p>
              </div>
              <div className="tager MuiBox-root css-0">
                <span className="MuiBox-root css-0">#</span>{" "}
                <p className="MuiTypography-root MuiTypography-body1 css-1azmrpm">
                  webdev
                </p>
              </div>
              <div className="tager MuiBox-root css-0">
                <span className="MuiBox-root css-0">#</span>{" "}
                <p className="MuiTypography-root MuiTypography-body1 css-1azmrpm">
                  HTML
                </p>
              </div>
              <div className="tager MuiBox-root css-0">
                <span className="MuiBox-root css-0">#</span>{" "}
                <p className="MuiTypography-root MuiTypography-body1 css-1azmrpm">
                  Gatsby
                </p>
              </div>
              <div className="tager MuiBox-root css-0">
                <span className="MuiBox-root css-0">#</span>{" "}
                <p className="MuiTypography-root MuiTypography-body1 css-1azmrpm">
                  Tailwind
                </p>
              </div>
              <div className="tager MuiBox-root css-0">
                <span className="MuiBox-root css-0">#</span>{" "}
                <p className="MuiTypography-root MuiTypography-body1 css-1azmrpm">
                  Typescript
                </p>
              </div>
              <div className="tager MuiBox-root css-0">
                <span className="MuiBox-root css-0">#</span>{" "}
                <p className="MuiTypography-root MuiTypography-body1 css-1azmrpm">
                  webdev
                </p>
              </div>
              <div className="tager MuiBox-root css-0">
                <span className="MuiBox-root css-0">#</span>{" "}
                <p className="MuiTypography-root MuiTypography-body1 css-1azmrpm">
                  HTML
                </p>
              </div>
              <div className="tager MuiBox-root css-0">
                <span className="MuiBox-root css-0">#</span>{" "}
                <p className="MuiTypography-root MuiTypography-body1 css-1azmrpm">
                  Gatsby
                </p>
              </div>
            </div>
          </div>
          <div className="loop-slider MuiBox-root css-3kn3cn">
            <div className="inner MuiBox-root css-0">
              <div className="tager MuiBox-root css-0">
                <span className="MuiBox-root css-0">#</span>{" "}
                <p className="MuiTypography-root MuiTypography-body1 css-1azmrpm">
                  Next.js
                </p>
              </div>
              <div className="tager MuiBox-root css-0">
                <span className="MuiBox-root css-0">#</span>{" "}
                <p className="MuiTypography-root MuiTypography-body1 css-1azmrpm">
                  UI/UX
                </p>
              </div>
              <div className="tager MuiBox-root css-0">
                <span className="MuiBox-root css-0">#</span>{" "}
                <p className="MuiTypography-root MuiTypography-body1 css-1azmrpm">
                  webdev
                </p>
              </div>
              <div className="tager MuiBox-root css-0">
                <span className="MuiBox-root css-0">#</span>{" "}
                <p className="MuiTypography-root MuiTypography-body1 css-1azmrpm">
                  Gatsby
                </p>
              </div>
              <div className="tager MuiBox-root css-0">
                <span className="MuiBox-root css-0">#</span>{" "}
                <p className="MuiTypography-root MuiTypography-body1 css-1azmrpm">
                  JavaScript
                </p>
              </div>
              <div className="tager MuiBox-root css-0">
                <span className="MuiBox-root css-0">#</span>{" "}
                <p className="MuiTypography-root MuiTypography-body1 css-1azmrpm">
                  Next.js
                </p>
              </div>
              <div className="tager MuiBox-root css-0">
                <span className="MuiBox-root css-0">#</span>{" "}
                <p className="MuiTypography-root MuiTypography-body1 css-1azmrpm">
                  UI/UX
                </p>
              </div>
              <div className="tager MuiBox-root css-0">
                <span className="MuiBox-root css-0">#</span>{" "}
                <p className="MuiTypography-root MuiTypography-body1 css-1azmrpm">
                  webdev
                </p>
              </div>
              <div className="tager MuiBox-root css-0">
                <span className="MuiBox-root css-0">#</span>{" "}
                <p className="MuiTypography-root MuiTypography-body1 css-1azmrpm">
                  Gatsby
                </p>
              </div>
              <div className="tager MuiBox-root css-0">
                <span className="MuiBox-root css-0">#</span>{" "}
                <p className="MuiTypography-root MuiTypography-body1 css-1azmrpm">
                  JavaScript
                </p>
              </div>
            </div>
          </div>
          <div className="loop-slider MuiBox-root css-nm1ugf">
            <div className="inner MuiBox-root css-0">
              <div className="tager MuiBox-root css-0">
                <span className="MuiBox-root css-0">#</span>{" "}
                <p className="MuiTypography-root MuiTypography-body1 css-1azmrpm">
                  Typescript
                </p>
              </div>
              <div className="tager MuiBox-root css-0">
                <span className="MuiBox-root css-0">#</span>{" "}
                <p className="MuiTypography-root MuiTypography-body1 css-1azmrpm">
                  webdev
                </p>
              </div>
              <div className="tager MuiBox-root css-0">
                <span className="MuiBox-root css-0">#</span>{" "}
                <p className="MuiTypography-root MuiTypography-body1 css-1azmrpm">
                  JavaScript
                </p>
              </div>
              <div className="tager MuiBox-root css-0">
                <span className="MuiBox-root css-0">#</span>{" "}
                <p className="MuiTypography-root MuiTypography-body1 css-1azmrpm">
                  animation
                </p>
              </div>
              <div className="tager MuiBox-root css-0">
                <span className="MuiBox-root css-0">#</span>{" "}
                <p className="MuiTypography-root MuiTypography-body1 css-1azmrpm">
                  Tailwind
                </p>
              </div>
              <div className="tager MuiBox-root css-0">
                <span className="MuiBox-root css-0">#</span>{" "}
                <p className="MuiTypography-root MuiTypography-body1 css-1azmrpm">
                  Typescript
                </p>
              </div>
              <div className="tager MuiBox-root css-0">
                <span className="MuiBox-root css-0">#</span>{" "}
                <p className="MuiTypography-root MuiTypography-body1 css-1azmrpm">
                  webdev
                </p>
              </div>
              <div className="tager MuiBox-root css-0">
                <span className="MuiBox-root css-0">#</span>{" "}
                <p className="MuiTypography-root MuiTypography-body1 css-1azmrpm">
                  JavaScript
                </p>
              </div>
              <div className="tager MuiBox-root css-0">
                <span className="MuiBox-root css-0">#</span>{" "}
                <p className="MuiTypography-root MuiTypography-body1 css-1azmrpm">
                  animation
                </p>
              </div>
              <div className="tager MuiBox-root css-0">
                <span className="MuiBox-root css-0">#</span>{" "}
                <p className="MuiTypography-root MuiTypography-body1 css-1azmrpm">
                  Tailwind
                </p>
              </div>
            </div>
          </div>
          <div className="loop-slider MuiBox-root css-3hnjun">
            <div className="inner MuiBox-root css-0">
              <div className="tager MuiBox-root css-0">
                <span className="MuiBox-root css-0">#</span>{" "}
                <p className="MuiTypography-root MuiTypography-body1 css-1azmrpm">
                  HTML
                </p>
              </div>
              <div className="tager MuiBox-root css-0">
                <span className="MuiBox-root css-0">#</span>{" "}
                <p className="MuiTypography-root MuiTypography-body1 css-1azmrpm">
                  CSS
                </p>
              </div>
              <div className="tager MuiBox-root css-0">
                <span className="MuiBox-root css-0">#</span>{" "}
                <p className="MuiTypography-root MuiTypography-body1 css-1azmrpm">
                  SVG
                </p>
              </div>
              <div className="tager MuiBox-root css-0">
                <span className="MuiBox-root css-0">#</span>{" "}
                <p className="MuiTypography-root MuiTypography-body1 css-1azmrpm">
                  JavaScript
                </p>
              </div>
              <div className="tager MuiBox-root css-0">
                <span className="MuiBox-root css-0">#</span>{" "}
                <p className="MuiTypography-root MuiTypography-body1 css-1azmrpm">
                  Next.js
                </p>
              </div>
              <div className="tager MuiBox-root css-0">
                <span className="MuiBox-root css-0">#</span>{" "}
                <p className="MuiTypography-root MuiTypography-body1 css-1azmrpm">
                  HTML
                </p>
              </div>
              <div className="tager MuiBox-root css-0">
                <span className="MuiBox-root css-0">#</span>{" "}
                <p className="MuiTypography-root MuiTypography-body1 css-1azmrpm">
                  CSS
                </p>
              </div>
              <div className="tager MuiBox-root css-0">
                <span className="MuiBox-root css-0">#</span>{" "}
                <p className="MuiTypography-root MuiTypography-body1 css-1azmrpm">
                  SVG
                </p>
              </div>
              <div className="tager MuiBox-root css-0">
                <span className="MuiBox-root css-0">#</span>{" "}
                <p className="MuiTypography-root MuiTypography-body1 css-1azmrpm">
                  JavaScript
                </p>
              </div>
              <div className="tager MuiBox-root css-0">
                <span className="MuiBox-root css-0">#</span>{" "}
                <p className="MuiTypography-root MuiTypography-body1 css-1azmrpm">
                  Next.js
                </p>
              </div>
            </div>
          </div>
          <div className="fade MuiBox-root css-0"></div>
        </div>
      </div>
    </>
  );
};

export default Tager;
