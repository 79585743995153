import { React, useState } from "react";
import { Popover, Transition } from "@headlessui/react";
import { ChevronDownIcon, ChevronRightIcon } from "@heroicons/react/solid";
import { Fragment } from "react";
import { Link } from "react-router-dom";
import { Tab } from "@headlessui/react";
import { Player } from "@lottiefiles/react-lottie-player";
import MeetingLoottie from "assets/lotties/client_meeting.json";

const IndexDropdown = () => {
  const [selectedIndex, setSelectedIndex] = useState(0);

  // Headless UI Sect
  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  const scrollToDiv = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  let [categories] = useState({
    "Web Development": [
      {
        id: 1,
        name: "ERP Development",
        to: "/erp-development-company",
      },
      {
        id: 2,
        name: "eCommerce Development",
        to: "/e-commerce-development-company",
      },
      {
        id: 3,
        name: "CRM Development",
        to: "/crm-development-company",
      },
      {
        id: 4,
        name: "Travel Portal Development",
        to: "/travel-portal-development-company",
      },
      {
        id: 5,
        name: "School Website Development",
        to: "/school-website-development-company",
      },
      {
        id: 6,
        name: "Hospital Website Development",
        to: "/hospital-website-development-company",
      },
    ],
    "Mobile App Development": [
      {
        id: 1,
        name: "eCommerce Mobile App",
        to: "/e-commerce-app-development-company",
      },
      {
        id: 2,
        name: "Flight Booking App",
        to: "/flight-booking-app-development-company",
      },
      {
        id: 3,
        name: "Bus & Car Booking App",
        to: "/bus-car-booking-app-development-company",
      },
      {
        id: 4,
        name: "Hotel Booking App",
        to: "/hotel-booking-app-development-company",
      },
      {
        id: 5,
        name: "Educational App Development",
        to: "/educational-app-development-company",
      },
      {
        id: 6,
        name: "Hospital Management App",
        to: "/hospital-management-app-development-company",
      },
      {
        id: 6,
        name: "Office Management App",
        to: "/office-management-app-development-company",
      },
    ],
    "Digital Marketing": [
      {
        id: 1,
        name: "Search Engine Optimization (SEO)",
        to: "/seo-marketing-company",
      },
      {
        id: 2,
        name: "Social Media Marketing (SMM)",
        to: "/social-media-marketing-company",
      },
      {
        id: 3,
        name: "PPC Google AD Marketing",
        to: "/ppc-google-ad-company",
      },
      {
        id: 4,
        name: "Content Writing",
        to: "/content-writing-company",
      },
    ],
    "Website Designing": [
      {
        id: 1,
        name: "UI/UX Design",
        to: "/ui-ux-website-designing-company",
      },
      {
        id: 2,
        name: "Responsive Website Design",
        to: "/",
      },
      {
        id: 3,
        name: "Graphic Design",
        to: "/",
      },
      {
        id: 4,
        name: "WordPress Website Design",
        to: "/",
      },
      {
        id: 5,
        name: "eCommerec Website Design",
        to: "/",
      },
      {
        id: 6,
        name: "Landing Page Web Design",
        to: "/",
      },
    ],
    "Stack Development": [
      {
        id: 1,
        name: "ReactJs Development",
        to: "react-development",
      },
      {
        id: 2,
        name: "VueJs Development",
        to: "/",
      },
      {
        id: 3,
        name: "Core PHP Development",
        to: "/",
      },
      {
        id: 4,
        name: "Angular Development",
        to: "/",
      },
      {
        id: 5,
        name: "NextJs Development",
        to: "/",
      },
      {
        id: 6,
        name: "AI Development",
        to: "/",
      },
      {
        id: 7,
        name: "Python Development",
        to: "/",
      },
    ],
    "Technology Certification": [
      {
        id: 1,
        name: "TBO API Certification",
        to: "/tbo-api-certification",
      },
      {
        id: 2,
        name: "Agoda API Certification",
        to: "/agoda-api-certification",
      },
      {
        id: 3,
        name: "Galilio API Certificaton",
        to: "/galilio-api-certification",
      },
      {
        id: 4,
        name: "White Label API Integration",
        to: "/",
      },
      {
        id: 5,
        name: "PCI Certification",
        to: "/pci-certification",
      },
      {
        id: 6,
        name: "Payment Gateway Certification",
        to: "/payment-getway-certification",
      },
      {
        id: 7,
        name: "Datebase Management",
        to: "/database-management",
      },
    ],
  });

  return (
    <>
      {" "}
      <div className=" ">
        <Popover className="relative">
          {({ open }) => (
            <>
              {" "}
              <Popover.Button
                className={`group inline-flex items-center hover:text-opacity-100 font-normal focus:outline-none `}
              >
                <span className="font-normal css-1vfsv3y font-IBM text-sm">
                  Web Services
                </span>
                <ChevronDownIcon
                  className={`${open ? "text-opacity-70" : "text-opacity-70"}
                  ml-1 h-5 w-5 text-[#212b36] transition duration-150 ease-in-out group-hover:text-opacity-80`}
                  aria-hidden="true"
                />
              </Popover.Button>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-200"
                enterFrom="opacity-0 translate-y-1"
                enterTo="opacity-100 translate-y-0"
                leave="transition ease-in duration-150"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-1"
              >
                <Popover.Panel className="fixed left-1/2 right-1/2 top-20 z-10 bg-gray-50  h-auto sm:px-0 rounded-md -translate-x-1/2 transform shadow-lg min-w-[90%]">
                  <div className="overflow-hidden rounded-lg mx-auto grid grid-cols-4 place-content-center">
                    {/* <div className="relative flex flex-row justify-between space-x-4 ">
                      {navigation.map((item) => (
                        <ul
                          className=" list-none css-19bjyhu flex flex-col space-y-4"
                          key={item.id}
                        >
                          <h4 className=" font-poppins text-xs font-semibold mb-1 text-black">
                            <Link to={item.to}>{item.title}</Link>
                          </h4>
                          <hr className=" w-full mt-1 h-0.5 mb-4 bg-indigo-500" />
                          {item.navigations.map((item) => (
                            <li className="css-7nslce" key={item.id}>
                              <Link to={item.to} className="">
                                <div className="">
                                  <p className="text-xs font-normal text-black">
                                    {item.name}
                                  </p>
                                </div>
                              </Link>
                            </li>
                          ))}
                        </ul>
                      ))}
                    </div> */}
                    <div className="min-h-[285px] p-1 bg-abstract-Bg">
                      <div className="flex flex-col px-8 py-2.5">
                        <div className=" mt-5">
                          <h3 className=" mb-4 text-3xl text-white font-semibold">
                            Services
                          </h3>
                        </div>
                        <div className=" mt-5 text-lg text-white font-normal">
                          Lets go together
                        </div>
                        <div className="text-xl mb-5 text-white font-medium">
                          Partner With Us!
                        </div>
                        <div className="font-semibold mt-5 text-white text-center lg:text-xl uppercase px-2 py-2 rounded-md border cursor-pointer bg-indigo-500 hover:bg-indigo-700">
                          Get a Quote!!
                        </div>
                      </div>
                    </div>
                    <Tab.Group
                      selectedIndex={selectedIndex}
                      onChange={setSelectedIndex}
                    >
                      <Tab.List className="flex min-h-[285px] flex-col bg-blue-100 p-3">
                        {Object.keys(categories).map((category) => (
                          <Tab
                            key={category}
                            className={({ selected }) =>
                              classNames(
                                "w-full text-left rounded-lg px-4 py-2.5 text-sm font-medium leading-5 text-blue-700",
                                "ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2",
                                selected
                                  ? "bg-white shadow text-blue-700"
                                  : "text-blue-500"
                              )
                            }
                          >
                            <div className="flex flex-row justify-between">
                              <span className="text-xs">{category}</span>
                              <span>
                                <ChevronRightIcon className=" text-blue-700 w-5 h-5" />
                              </span>
                            </div>
                          </Tab>
                        ))}
                      </Tab.List>
                      <Tab.Panels className="flex min-h-[285px]">
                        {Object.values(categories).map((posts, idx) => (
                          <Tab.Panel
                            key={idx}
                            className={classNames(
                              "w-full p-3 bg-blue-100",
                              "ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2"
                            )}
                          >
                            <ul>
                              {posts.map((post) => (
                                <Link to={post.to}>
                                  {" "}
                                  <li
                                    key={post.id}
                                    className="relative rounded-md p-3 hover:bg-white"
                                  >
                                    <div className="flex flex-row justify-between itemes-center">
                                      <h3
                                        className=" text-xs font-medium leading-3"
                                        onClick={() => scrollToDiv(post.to)}
                                      >
                                        {post.name}
                                      </h3>
                                      <span>
                                        <ChevronRightIcon className=" text-blue-700 w-5 h-5" />
                                      </span>
                                    </div>
                                  </li>
                                </Link>
                              ))}
                            </ul>
                          </Tab.Panel>
                        ))}
                      </Tab.Panels>
                    </Tab.Group>
                    <div className="min-h-[285px]">
                      <Player
                        autoplay
                        loop
                        src={MeetingLoottie}
                        className=" w-full h-full"
                      />
                    </div>
                  </div>
                </Popover.Panel>
              </Transition>
            </>
          )}
        </Popover>
      </div>
    </>
  );
};

export default IndexDropdown;
