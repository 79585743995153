import React from "react";
import ErrorSvg from "assets/svgs/8.svg";
import MetaHelmet from "./MetaHelmet";

const Error404 = () => {
  const handleToggleHome = () => {
    window.location.href = "/";
  };
  return (
    <>
      <MetaHelmet title={"Error Page Not Found"} />
      <div>
        <div class="relative h-screen overflow-hidden bg-indigo-900">
          <img
            src={ErrorSvg}
            alt="Error Page NOT FOUND"
            class="absolute object-cover w-full h-full"
          />
          <div class="absolute inset-0 bg-black opacity-25"></div>
          <div class="container relative z-10 flex items-center px-6 py-32 mx-auto md:px-12 xl:py-40">
            <div class="relative z-10 flex flex-col items-center w-full font-mono">
              <h1 class="mt-4 text-5xl font-extrabold leading-tight text-center text-white">
                Page Not Found
              </h1>
              <div className="mt-5">
                <button
                  class="relative inline-flex items-center justify-center p-0.5 mb-2 mr-2 overflow-hidden text-sm font-medium text-white rounded-lg group bg-gradient-to-br from-purple-500 to-pink-500 group-hover:from-purple-500 group-hover:to-pink-500 hover:text-black dark:text-white focus:ring-4 focus:outline-none focus:ring-purple-200 dark:focus:ring-purple-800"
                  onClick={() => handleToggleHome()}
                >
                  <span class="relative px-5 py-2.5 transition-all ease-in duration-75 bg-transparent dark:bg-gray-900 rounded-md group-hover:bg-opacity-0">
                    Back To Home
                  </span>
                </button>
              </div>
              <p class="font-extrabold text-3xl text-white lg:text-8xl my-10 lg:my-40 animate-bounce">
                404
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Error404;
