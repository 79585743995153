import React from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { SmartProducts } from "components/utils/Products_api";

export default function TeamServices() {
  const [initializeAnimation, setTnitializeAnimatio] = React.useState(false);

  React.useEffect(() => {
    setTimeout(() => {
      setTnitializeAnimatio(!initializeAnimation);
    }, 2000);
  });

  var settings = {
    className: "center",
    autoplay: true,
    autoplaySpeed: 2000,
    centerMode: true,
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 2,
    slidesToScroll: 1,
    initialSlide: 0,
    arrows: false,
    responsive: [
      {
        breakpoint: 990,
        settings: {
          slidesToShow: 10,
          slidesToScroll: 2,
          infinite: true,
          dots: false,
          centerMode: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
          centerMode: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: true,
        },
      },
    ],
  };

  const StockUpdate = () => {
    return Math.floor(Math.random() * (100 - 1) + 1);
  };

  let SwiperSlides = "";

  SwiperSlides = SmartProducts.map((product) => (
    <div className="flex justify-center items-center">
      <div className="group p-4 max-w-[220px] max-h-max shadow-md rounded-md backdrop-blur-md bg-white/30 h-[300px]">
        <div class="w-full overflow-hidden rounded-lg h-3/5">
          <img
            src={product.imgSrc}
            alt={product.title}
            class="h-full w-full object-cover object-center group-hover:opacity-75"
          />
        </div>
        <h3 class="mt-4 text-xs md:text-base font-semibold text-gray-700">
          {product.title}
        </h3>
        <p class="mt-1 text-xs md:text-sm font-normal text-gray-900">
          Stock: {StockUpdate()}
        </p>
      </div>{" "}
    </div>
  ));

  return (
    <>
      <div className="relative overflow-hidden py-16 md:pt-16">
        <span className="hidden absolute bg-radial-gradient opacity-[.15] pointer-events-none lg:inline-flex right-[-20%] top-0 w-[640px] h-[640px]"></span>
        <div className="hidden lg:block absolute -right-2 bottom-40 pointer-events-none">
          <svg
            className="text-indigo-600 animate-cube"
            width="46"
            height="53"
            viewBox="0 0 46 53"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            {/* ...SVG paths */}
          </svg>
        </div>
        <div className="hidden lg:block absolute left-2 top-48 pointer-events-none lg:top-auto lg:bottom-64 lg:left-1/2">
          <svg
            className="text-indigo-600 animate-cube"
            width="46"
            height="53"
            viewBox="0 0 46 53"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            {/* ...SVG paths */}
          </svg>
        </div>
        <div className="hidden lg:block absolute top-20 right-1/4 pointer-events-none md:top-48 md:right-1/4">
          <svg
            className="text-indigo-600 animate-cube"
            width="46"
            height="53"
            viewBox="0 0 46 53"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            {/* ...SVG paths */}
          </svg>
        </div>
        <div className="relative max-w-screen-xl w-full mx-auto xl:px-5">
          <div className="relative w-full grid gap-12 lg:grid-cols-2">
            <div className="overflow-hidden flex justify-center lg:order-last">
              <img
                src={
                  "https://res.cloudinary.com/whiztechnology/image/upload/v1690997624/Uploads/upck4avnqh7akrt4s4uj.png"
                }
                alt="Technology Certification"
              />
            </div>
            <div className="w-full flex justify-center items-center px-5 xl:px-0">
              <div className="sm:max-w-[480px]">
                <h2 className="font-bold text-3xl">Technology Certification</h2>
                <p className="mt-6 text-gray-700 leading-relaxed">
                  Seamless Integration: API integration services facilitate the
                  seamless exchange of data and functionality between different
                  systems. They enable businesses to connect their internal
                  applications, databases, or platforms with external APIs
                  provided by third-party services, vendors, or partners.
                </p>
                <Link
                  className="group relative inline-flex border border-[#44619d] focus:outline-none mt-6"
                  to={"/technology-certification"}
                >
                  <span className="w-full inline-flex items-center justify-center self-stretch px-4 py-2 text-sm text-indigo-600 text-center font-bold uppercase bg-white ring-1 ring-[#44619d] ring-offset-1 transform transition-transform group-hover:-translate-y-1 group-hover:-translate-x-1 group-focus:-translate-y-1 group-focus:-translate-x-1">
                    Start Certification
                  </span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="relative overflow-hidden py-16 md:pt-16">
        <span className="hidden absolute bg-radial-gradient opacity-[.15] pointer-events-none lg:inline-flex right-[-20%] top-0 w-[640px] h-[640px]"></span>
        <div className="hidden lg:block absolute -right-2 bottom-40 pointer-events-none">
          <svg
            className="text-indigo-600 animate-cube"
            width="46"
            height="53"
            viewBox="0 0 46 53"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            {/* ...SVG paths */}
          </svg>
        </div>
        <div className="hidden lg:block absolute left-2 top-48 pointer-events-none lg:top-auto lg:bottom-64 lg:left-1/2">
          <svg
            className="text-indigo-600 animate-cube"
            width="46"
            height="53"
            viewBox="0 0 46 53"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            {/* ...SVG paths */}
          </svg>
        </div>
        <div className="hidden lg:block absolute top-20 right-1/4 pointer-events-none md:top-48 md:right-1/4">
          <svg
            className="text-indigo-600 animate-cube"
            width="46"
            height="53"
            viewBox="0 0 46 53"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            {/* ...SVG paths */}
          </svg>
        </div>
        <div className="relative max-w-screen-xl w-full mx-auto xl:px-5">
          <div className="relative w-full grid gap-12 lg:grid-cols-2">
            <div className="flex justify-center lg:justify-between ">
              <div className="max-w-sm w-full pt-8 pb-12 px-6 border border-gray-200 -rotate-1 lg:px-12 lg:border-indigo-600">
                <img
                  src={
                    "https://res.cloudinary.com/whiztechnology/image/upload/v1690997699/Uploads/pfn0t2pyzgi6ugnwe375.png"
                  }
                  className="max-w-[218px] w-full mx-auto"
                  alt="Media Works"
                  srcset=""
                />
                <h3 className="mt-8 font-bold text-indigo-600 uppercase text-xs">
                  Meadi Works
                </h3>
                <h2 className="mt-3 font-bold text-4xl">Tune In</h2>
                <p class="mt-4 text-gray-700 text-sm leading-relaxed">
                  Laracasts includes free videos and tutorials covering the
                  entire Laravel ecosystem. Stay up to date by watching our
                  latest videos.
                </p>
                <Link
                  class="group relative inline-flex border border-indigo-600 focus:outline-none mt-8"
                  to="/media-works"
                >
                  <span class="w-full inline-flex items-center justify-center self-stretch px-4 py-2 text-sm text-indigo-600 text-center font-bold uppercase bg-white ring-1 ring-indigo-600 ring-offset-1 transform transition-transform group-hover:-translate-y-1 group-hover:-translate-x-1 group-focus:-translate-y-1 group-focus:-translate-x-1">
                    Visit Media Works
                  </span>
                </Link>
              </div>
            </div>
            <div className="w-full flex justify-center items-center px-5 xl:px-0">
              <div className="sm:max-w-[480px]">
                <h2 className="font-bold text-3xl">Media Works</h2>
                <p className="mt-6 text-gray-700 leading-relaxed">
                  We specialize in creating captivating and impactful
                  advertisements that leave a lasting impression on your target
                  audience. With our team of experienced professionals and
                  state-of-the-art equipment, we bring your brand's vision to
                  life through the power of visual storytelling.
                </p>
                <div className="mt-10 grid grid-cols-2 gap-10 md:grid-cols-3">
                  <Link to="/media-works" className="inline-flex items-center">
                    <svg
                      className="text-indigo-600 w-8 h-8"
                      viewBox="0 0 32 32"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path stroke="currentColor" d="M.5.5h31v31H.5z"></path>
                      <path
                        d="M10 9c7.18 0 13 5.82 13 13m-13-7a7 7 0 0 1 7 7m-6 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0Z"
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></path>
                    </svg>
                    <span className="ml-3 text-sm">Short Film Makers</span>
                  </Link>
                  <Link
                    to="/media-works"
                    target="_blank"
                    className="inline-flex items-center"
                  >
                    <svg
                      className="text-indigo-600 w-8 h-8"
                      viewBox="0 0 32 32"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path stroke="currentColor" d="M.5.5h31v31H.5z"></path>
                      <path
                        d="M11 12h10-10Zm0 4h4-4Zm5 8-4-4H9a2 2 0 0 1-2-2v-8a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2h-3l-4 4Z"
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></path>
                    </svg>
                    <span className="ml-3 text-sm">TV Commercials</span>
                  </Link>
                  <Link to="/media-works" className="inline-flex items-center">
                    <svg
                      className="text-indigo-600 w-8 h-8"
                      viewBox="0 0 32 32"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path stroke="currentColor" d="M.5.5h31v31H.5z"></path>
                      <path
                        d="M25 17.255A23.93 23.93 0 0 1 16 19c-3.183 0-6.22-.62-9-1.745M16 16h.01M20 10V8a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v2h8ZM9 24h14a2 2 0 0 0 2-2V12a2 2 0 0 0-2-2H9a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2Z"
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></path>
                    </svg>
                    <span className="ml-3 text-sm">Video Production</span>
                  </Link>
                  <Link to="/media-works" className="inline-flex items-center">
                    <svg
                      className="text-indigo-600 w-8 h-8"
                      viewBox="0 0 32 32"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path stroke="currentColor" d="M.5.5h31v31H.5z"></path>
                      <path
                        d="M23 24H9a2 2 0 0 1-2-2V10a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2v1m2 13a2 2 0 0 1-2-2V11m2 13a2 2 0 0 0 2-2v-9a2 2 0 0 0-2-2h-2m-4-3h-4m-2 12h6m-6-8h6v4h-6v-4Z"
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></path>
                    </svg>
                    <span className="ml-3 text-sm">Industrial Shoot</span>
                  </Link>
                  <Link to="/media-works" className="inline-flex items-center">
                    <svg
                      className="text-indigo-600 w-8 h-8"
                      viewBox="0 0 32 32"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path stroke="currentColor" d="M.5.5h31v31H.5z"></path>
                      <path
                        d="m18.752 15.168-3.197-2.132A1 1 0 0 0 14 13.87v4.263a1 1 0 0 0 1.555.832l3.197-2.132a.998.998 0 0 0 0-1.664v-.001Z"
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></path>
                      <path
                        d="M25 16a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></path>
                    </svg>
                    <span className="ml-3 text-sm">Corporate Video</span>
                  </Link>
                  <Link to="/media-works" className="inline-flex items-center">
                    <svg
                      className="text-indigo-600 w-8 h-8"
                      viewBox="0 0 32 32"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path stroke="currentColor" d="M.5.5h31v31H.5z"></path>
                      <path
                        d="m18.752 15.168-3.197-2.132A1 1 0 0 0 14 13.87v4.263a1 1 0 0 0 1.555.832l3.197-2.132a.998.998 0 0 0 0-1.664v-.001Z"
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></path>
                      <path
                        d="M25 16a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></path>
                    </svg>
                    <span className="ml-3 text-sm">Product Demonstration</span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="relative overflow-hidden py-16 md:pt-16">
        <span className="hidden absolute bg-radial-gradient opacity-[.15] pointer-events-none lg:inline-flex right-[-20%] top-0 w-[640px] h-[640px]"></span>
        <div className="relative max-w-screen-xl w-full mx-auto xl:px-5">
          <div className="relative w-full grid gap-12 lg:grid-cols-2">
            <div className="overflow-hidden flex justify-center">
              <div className="relative overflow-hidden">
                <Slider {...settings} className="mx-auto max-w-screen-xl mt-5">
                  {SwiperSlides}
                </Slider>{" "}
              </div>
            </div>
            <div className="w-full flex justify-center items-center px-5 xl:px-0">
              <div className="sm:max-w-[480px]">
                <h2 className="font-bold text-3xl">Smart Products</h2>
                <p className="mt-6 text-gray-700 leading-relaxed">
                  Welcome to our e-commerce website, where you can explore a
                  wide range of smart classroom products designed to enhance the
                  learning experience. Our collection includes innovative
                  technology and tools that transform traditional classrooms
                  into dynamic and interactive learning environments.
                </p>
                <Link
                  className="group relative inline-flex border border-indigo-600 focus:outline-none mt-6"
                  to={"/smart-products"}
                >
                  <span className="w-full inline-flex items-center justify-center self-stretch px-4 py-2 text-sm text-indigo-600 text-center font-bold uppercase bg-white ring-1 ring-[#44619d] ring-offset-1 transform transition-transform group-hover:-translate-y-1 group-hover:-translate-x-1 group-focus:-translate-y-1 group-focus:-translate-x-1">
                    More Products
                  </span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
