import { React, useState } from "react";
import { Link } from "react-router-dom";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Fragment } from "react";
import { UilBars } from "@iconscout/react-unicons";
import Logo from "assets/img/logo.png";
import { Container } from "@mui/material";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Collapse from "@mui/material/Collapse";
import { StarBorder } from "@mui/icons-material";
import HomeIcon from "@mui/icons-material/Home";
import SmartButtonIcon from "@mui/icons-material/SmartButton";
import SmartDisplayIcon from "@mui/icons-material/SmartDisplay";
import MiscellaneousServicesIcon from "@mui/icons-material/MiscellaneousServices";
import AutoStoriesOutlinedIcon from "@mui/icons-material/AutoStoriesOutlined";
import ContactMailOutlinedIcon from "@mui/icons-material/ContactMailOutlined";

export default function MobileDrawer() {
  const [state, setState] = useState(false);
  const [open, setOpen] = useState(false);

  const navigations = [
    {
      id: 1,
      name: "Home",
      icon: <HomeIcon color="primary" />,
      to: "/",
    },
    {
      id: 2,
      name: "About Us",
      icon: <AutoStoriesOutlinedIcon color="primary" />,
      to: "/about-us",
    },
    {
      id: 3,
      name: "Smart Products",
      icon: <SmartButtonIcon color="primary" />,
      to: "/smart-products",
    },
    {
      id: 4,
      name: "Media Works",
      icon: <SmartDisplayIcon color="primary" />,
      to: "/media-works",
    },
    {
      id: 5,
      name: "Support",
      icon: <ContactMailOutlinedIcon color="primary" />,
      to: "/contact-us",
    },
  ];

  const serviceNav = [
    {
      id: 1,
      title: "Services",
      icon: <MiscellaneousServicesIcon color="primary" />,
      navs: [
        {
          id: 1,
          name: "Website Development",
          to: "/website-development-company",
        },
        {
          id: 2,
          name: "Mobile App Development",
          to: "/mobile-app-development-company",
        },
        { id: 3, name: "Digital Marketing", to: "/digital-marketing-company" },
        { id: 4, name: "Web Designing", to: "/website-designing-company" },
        {
          id: 5,
          name: "Stack Development",
          to: "/full-stack-development-company",
        },
        {
          id: 6,
          name: "Technology Certification",
          to: "/technology-certification",
        },
      ],
    },
  ];

  const handleClick = () => {
    setOpen(!open);
  };

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState(open);
  };

  const list = () => (
    <Box
      sx={{
        width: true === "top" || true === "bottom" ? "auto" : 300,
      }}
      role="presentation"
    >
      <Container sx={{ mx: "0px auto", my: "10px" }}>
        <Link to={"/"} className="">
          <div className="flex items-center justify-center w-fit rounded-sm bg-[#44619d]">
            {" "}
            <img
              src={Logo}
              className=" w-16 md:w-24"
              alt="WhizTechnology Logo"
            />
          </div>
        </Link>
      </Container>
      <List>
        {navigations.map((item) => (
          <ListItem key={item.id} disablePadding sx={{ width: "100%" }}>
            <Link to={item.to}>
              <ListItemButton sx={{ width: "100%" }}>
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText sx={{ color: "#1976d2" }} primary={item.name} />
              </ListItemButton>
            </Link>
          </ListItem>
        ))}
      </List>
      <Divider sx={{ color: "#1976d2" }} />
      <List>
        <ListItemButton onClick={handleClick} sx={{ width: "100%" }}>
          <ListItemIcon>
            <MiscellaneousServicesIcon color="primary" />
          </ListItemIcon>
          <ListItemText primary="Services" sx={{ color: "#1976d2" }} />
          {open ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={open} timeout="auto" unmountOnExit>
          {serviceNav.map((data) => (
            <List component="div" disablePadding>
              {data.navs.map((index) => (
                <Link to={index.to}>
                  <ListItemButton sx={{ pl: 4 }}>
                    <ListItemIcon>
                      <StarBorder color="primary" />
                    </ListItemIcon>
                    <ListItemText
                      primary={index.name}
                      sx={{ color: "#1976d2" }}
                    />
                  </ListItemButton>
                </Link>
              ))}
            </List>
          ))}
        </Collapse>
      </List>
    </Box>
  );

  return (
    <div>
      <Fragment>
        <Button onClick={toggleDrawer(true)}>
          <UilBars className="h-5 w-5" />
        </Button>
        <Drawer anchor={"left"} open={state} onClose={toggleDrawer(false)}>
          {list()}
        </Drawer>
      </Fragment>
    </div>
  );
}
