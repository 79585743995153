import React, { useState, useEffect } from "react";
import { Player } from "@lottiefiles/react-lottie-player";
import searchingLottie from "assets/lotties/96916-searching.json";
import { Link } from "react-router-dom";
import Footer from "components/Footers/Footer";
import Trusted from "components/Footers/Trusted";
import StickyEnquryForm from "components/Forms/StickyEnquryForm";
import IndexNavbar from "components/Navbars/IndexNavbar";
import PopUoForm from "components/Forms/PopUoForm";
import Corousel from "components/Corousel/Corousel";
import { eCommerceData, eCommerceFeature, eCommerceFaq } from "./webPagesData";
import SmartTag from "components/Development/SmartTag";
import FloatingWp from "components/Development/FloatingWp";
import Faq from "components/Development/Faq";
import Preloader from "components/Development/Preloader";

const ECommerceDevelopment = () => {
  const [showAll, setShowAll] = useState(false);

  const toggleShowAll = () => {
    setShowAll((prevShowAll) => !prevShowAll);
  };

  const [isLoading, setisLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setisLoading(false);
    }, 1000);
  }, []);

  return (
    <>
      {isLoading === true ? (
        <Preloader />
      ) : (
        <div className="">
          {" "}
          <div className="fixed bg-hero-pattern bg-fixed bg-cover bg-center inset-0 -z-20"></div>
          <div className="App relative">
            <IndexNavbar />
            <section className="max-w-screen-xl mx-auto">
              <div className="grid  px-4 pt-20 pb-8  lg:gap-8 xl:gap-0 lg:py-16 lg:grid-cols-12 lg:pt-28">
                <div className="mr-auto place-self-center lg:col-span-7">
                  <SmartTag
                    title="Best"
                    description="E-Commerce Website Development Company in India"
                  />
                  <h1 className="max-w-2xl mb-4 font-extrabold leading-normal tracking-tight text-lg lg:text-4xl dark:text-white">
                    E-Commerce Development Company{" "}
                  </h1>
                  <p className="max-w-2xl mb-6 font-light text-justify justify-start lg:mb-8 md:text-base lg:text-base leading-normal">
                    Whiz Technology is a leading e-commerce development company
                    that empowers businesses to establish a strong online
                    presence and drive exceptional growth in the digital
                    marketplace. With our expertise in cutting-edge technologies
                    and a customer-centric approach, we deliver tailored
                    e-commerce solutions that meet your unique business
                    requirements.
                  </p>
                  <div className="space-y-4 sm:flex sm:space-y-0 sm:space-x-4">
                    <Link
                      to={"/"}
                      target=""
                      className="inline-block px-6 py-2.5 bg-[#44619d] text-white font-medium text-xs leading-tight uppercase rounded-full shadow-md hover:bg-blue-500 hover:shadow-lg focus:bg-blue-500 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-600 active:shadow-lg transition duration-150 ease-in-out"
                    >
                      Case Study
                    </Link>
                    <Link
                      to={"/contact-us"}
                      className=" mx-2 inline-block px-6 py-2.5 bg-gray-800 text-white font-medium text-xs leading-tight uppercase rounded-full shadow-md hover:bg-gray-900 hover:shadow-lg focus:bg-gray-900 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-gray-900 active:shadow-lg transition duration-150 ease-in-out"
                      target=""
                    >
                      Contact Us
                    </Link>
                  </div>
                </div>
                <div className="hidden lg:mt-0 lg:col-span-5 lg:flex">
                  <img
                    src={
                      "https://res.cloudinary.com/whiztechnology/image/upload/v1690997115/Uploads/eCommerce_development_amtaux.png"
                    }
                    alt="heroimage"
                  />
                </div>
              </div>
            </section>
            <section className=" max-w-screen-xl mx-auto">
              <Corousel />
            </section>
            <section className="max-w-screen-xl mb-4 shadow-lg backdrop-blur-md mx-auto bg-[#d8eaff]/30 rounded-md">
              <section className="bg-transparent">
                <div className="max-w-screen-xl backdrop-blur-md shadow-lg bg-[#d8eaff]/30 rounded-md px-4 py-8 mx-auto space-y-6 lg:py-10 lg:px-6">
                  {eCommerceFeature
                    .slice(0, showAll ? eCommerceFeature.length : 3)
                    .map(({ id, title, description, icon, keyPoints }) => (
                      <div
                        className="flex flex-col sm:flex-col-reverse last:rounded-lg lg:flex-row lg:odd:flex-row-reverse justify-center items-center p-0 md:p-6"
                        id={id}
                      >
                        <div className="text-gray-500 sm:text-lg p-4 dark:text-gray-400 w-full md:w-1/2">
                          <h2 className="mb-4 text-lg md:text-xl font-semibold tracking-tight text-gray-900 dark:text-white">
                            {title}
                          </h2>
                          <h3 className="mb-8 text-justify justify-start text-xs md:text-base leading-normal font-normal">
                            {description}
                          </h3>
                          <hr class="w-full mt-1 h-1 mb-4 bg-indigo-200" />
                          <ul className="pt-2 space-y-5 border-t border-gray-200 my-7 dark:border-gray-700">
                            {keyPoints.map(({ pointTitle }) => (
                              <li className="flex" key={id}>
                                <span className=" cursor-pointer flex space-x-3 hover:underline hover:text-purple-500">
                                  {svgIcon()}
                                  <span className=" text-xs md:text-base font-medium leading-tight text-gray-900 dark:text-white">
                                    {pointTitle}
                                  </span>
                                </span>
                              </li>
                            ))}
                          </ul>
                          <div className="mt-4">
                            <div className="block text-left">
                              <div className="flex items-center mx-auto w-full">
                                <Player
                                  src={searchingLottie}
                                  className="player"
                                  loop
                                  autoplay
                                  style={{ height: "50px", width: "50px" }}
                                />
                                <div className="ml-2  item-center">
                                  <div className="flex-col text-xs text-blue">
                                    <button className="px-2 py-1 bg-[#44619d] text-white font-medium text-xs leading-tight rounded-full shadow-md hover:bg-blue-500 hover:shadow-lg focus:bg-blue-500 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-600 active:shadow-lg transition duration-150 ease-in-out font-poppins cursor-pointer">
                                      <Link href="/contact-us">
                                        Get a quote ?
                                      </Link>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="w-full md:w-1/2 mb-4 lg:mb-0 lg:flex mx-auto lg:justify-center">
                          {" "}
                          <img
                            className="verticalAnimate"
                            src={icon}
                            alt="ERP Solutions"
                          />
                        </div>
                      </div>
                    ))}
                  <div className="flex flex-col space-y-3 items-center justify-center mb-4">
                    <p className="text-xs md:text-base text-center max-w-2xl font-medium text-black/70">
                      {showAll
                        ? ""
                        : "Contact us today to discuss your project and let us be your trusted partner in eCommerce development!"}
                    </p>
                    <button
                      class="relative inline-flex items-center justify-center p-0.5 mb-2 mr-2 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-purple-600 to-blue-500 group-hover:from-purple-600 group-hover:to-blue-500 hover:text-white dark:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800"
                      onClick={toggleShowAll}
                    >
                      <span class="relative px-5 py-2.5 transition-all ease-in duration-75 bg-white dark:bg-gray-900 rounded-md group-hover:bg-opacity-0">
                        {showAll ? "Read Less..." : "Read More..."}
                      </span>
                    </button>
                  </div>
                </div>
              </section>
              <div className=" px-4 py-8 mx-auto space-y-6 lg:py-10 lg:px-6">
                {eCommerceData.map((item) => (
                  <div className="items-center gap-8 lg:grid lg:grid-cols-2 xl:gap-16">
                    <div className="px-4 py-8 mx-auto space-y-6 lg:py-10 lg:px-6">
                      <div className="text-gray-500 sm:text-lg dark:text-gray-400">
                        <h2 className="mb-4 text-base md:text-lg font-semibold tracking-tight text-gray-900 dark:text-white">
                          {item.title}
                        </h2>
                        <hr className="w-full mt-1 h-1 mb-4 bg-indigo-200" />
                        {item.features.map((data) => (
                          <p className="mb-8 font-normal text-xs md:text-base text-justify justify-start text-gray-800 lg:mb-8 leading-normal">
                            {data.points}
                          </p>
                        ))}

                        <Link to={"/contact-us"}>
                          <p className="font-light mt-5 text-white text-center lg:text-xl uppercase px-2 py-3 rounded-md border cursor-pointer bg-indigo-500 hover:bg-indigo-700">
                            Get a qoute ?
                          </p>
                        </Link>
                      </div>
                    </div>
                    <div className="w-full  mb-4 rounded-lg lg:mb-0 lg:flex">
                      <img className="verticalAnimate" src={item.icon} alt="" />
                    </div>
                  </div>
                ))}
              </div>
            </section>
            <StickyEnquryForm />
            <Trusted />
            <Faq faqs={eCommerceFaq} />
            <FloatingWp />
            <PopUoForm />
            <Footer />
          </div>
        </div>
      )}
    </>
  );
};

export default ECommerceDevelopment;

const svgIcon = () => {
  return (
    <svg
      className="flex-shrink-0 w-5 h-5 text-purple-500 dark:text-purple-400"
      fill="currentColor"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
};
