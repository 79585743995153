import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Footer from "components/Footers/Footer.js";
import PopUoForm from "components/Forms/PopUoForm";
import Corousel from "components/Corousel/Corousel";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import {
  keyFeatures,
  webServices,
  accordionData,
  keyFeatures2,
} from "./webDesigningData";
import StickyEnquryForm from "components/Forms/StickyEnquryForm";
import Trusted from "components/Footers/Trusted";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import SmartTag from "components/Development/SmartTag";
import FloatingWp from "components/Development/FloatingWp";
import Preloader from "components/Development/Preloader";

const WebDesigning = () => {
  var settings = {
    className: "center",
    autoplay: true,
    autoplaySpeed: 2000,
    centerMode: true,
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    arrows: false,
    responsive: [
      {
        breakpoint: 990,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
          centerMode: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
          centerMode: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: false,
        },
      },
    ],
  };

  const [isLoading, setisLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setisLoading(false);
    }, 1000);
  }, []);

  return (
    <>
      {isLoading === true ? (
        <Preloader />
      ) : (
        <div className="">
          {" "}
          <Helmet>
            <title>Web Design Service | World Class Web Design Company</title>
            <meta
              name="description"
              content="Get online fast with our professional web design service. We offer a wide range of web design & development services as per the customer requirement."
            />
            <meta
              name="keywords"
              content="website designing, website development, web development company, web design agency, web design services,website developer near me, web development agency,modern website design"
            />
          </Helmet>
          <div className="fixed bg-hero-pattern bg-fixed bg-cover bg-center inset-0 -z-20"></div>
          <div className="App relative">
            {" "}
            <IndexNavbar fixed />
            {/* <!-- Start block --> */}
            <section className="">
              <div className="grid max-w-screen-xl px-4 pt-20 pb-8 mx-auto lg:gap-8 xl:gap-0 lg:py-16 lg:grid-cols-12 lg:pt-28">
                <div className="mr-auto place-self-center lg:col-span-7">
                  <SmartTag
                    title="No.1"
                    description="Web Designign, UI/UX Company in India"
                  />
                  <h1 className="max-w-2xl mb-4 text-lg lg:text-4xl font-extrabold leading-normal tracking-tight sm:text- xl:text-4xl dark:text-white">
                    WEBSITE, UI/UX & GRAPHIC DESIGNING COMPANY
                  </h1>
                  <p className="max-w-2xl mb-6 font-light text-justify justify-start lg:mb-8 md:text-base lg:text-base leading-normal">
                    At our web designing company, we follow a client-centric
                    approach, ensuring that every website we create is tailored
                    to meet the unique needs and goals of our clients. We
                    believe that a website should not only be visually appealing
                    but also user-friendly, responsive, and optimized for search
                    engines. With a focus on user experience and conversion
                    optimization, we strive to deliver websites that not only
                    look great but also drive results.
                  </p>
                  <div className="space-y-4 sm:flex sm:space-y-0 sm:space-x-4">
                    <Link
                      to={"/"}
                      target=""
                      className="inline-block px-6 py-2.5 bg-[#44619d] text-white font-medium text-xs leading-tight uppercase rounded-full shadow-md hover:bg-blue-500 hover:shadow-lg focus:bg-blue-500 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-600 active:shadow-lg transition duration-150 ease-in-out"
                    >
                      Case Study
                    </Link>
                    <Link
                      to={"/about"}
                      className=" mx-2 inline-block px-6 py-2.5 bg-gray-800 text-white font-medium text-xs leading-tight uppercase rounded-full shadow-md hover:bg-gray-900 hover:shadow-lg focus:bg-gray-900 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-gray-900 active:shadow-lg transition duration-150 ease-in-out"
                      target=""
                    >
                      Contact Us
                    </Link>
                  </div>
                </div>
                <div className=" lg:mt-0 lg:col-span-5 lg:flex">
                  <div className=" mt-5 lg:mt-0 overflow-hidden max-w-xs mx-auto md:max-w-lg lg:w-full shadow-md rounded-2xl backdrop-blur-md bg-white/30 p-2">
                    <Slider
                      {...settings}
                      className="center space-x-4 box-border "
                    >
                      <div className=" relative">
                        <div className=" w-full p-3">
                          <img
                            src={
                              "https://res.cloudinary.com/whiztechnology/image/upload/v1691052700/ua7tru3pjwfqgbqcxduq.webp"
                            }
                            className=" w-full h-auto"
                            alt="heroimage"
                          />
                        </div>
                      </div>
                      <div className=" relative">
                        <div className=" w-full p-3">
                          <img
                            src={
                              "https://res.cloudinary.com/whiztechnology/image/upload/v1691052743/dq0s4ujqk2eou1vjeayh.png"
                            }
                            className=" w-full h-auto"
                            alt="heroimage"
                          />
                        </div>
                      </div>
                    </Slider>
                  </div>
                </div>
              </div>
            </section>
            {/* <!-- End block --> */}
            <section className=" max-w-screen-xl mx-auto">
              <Corousel />
            </section>
            <section className=" bg-transparent">
              <div className="max-w-screen-xl rounded-md backdrop-blur-sm bg-white/30 px-4 py-8 mx-auto space-y-12 lg:space-y-20 lg:px-6">
                {/* <!-- Row --> */}
                <div className="items-center gap-8 lg:grid lg:grid-cols-2 xl:gap-16">
                  <div className="text-gray-500 sm:text-lg dark:text-gray-400">
                    <h2 className="mb-4 text-3xl font-extrabold tracking-tight text-gray-900 dark:text-white">
                      Web Designing
                    </h2>
                    <h3 className="mb-8 text-justify justify-start text-xs md:text-base leading-normal font-normal">
                      Website Design and Development: Our talented team of
                      designers and developers work closely to create customized
                      websites that reflect your brand identity and deliver an
                      engaging user experience. We leverage the latest design
                      trends and technologies to ensure your website stands out
                      from the competition.
                    </h3>
                    {/* <!-- List --> */}
                    <ul className="pt-8 space-y-5 border-t border-gray-200 my-7 dark:border-gray-700">
                      {webServices.map((item) => (
                        <li className="flex" key={item.id}>
                          {/* <!-- Icon --> */}
                          <Link
                            to={item.to}
                            className=" cursor-pointer flex space-x-3 hover:underline hover:text-purple-500"
                          >
                            <svg
                              className="flex-shrink-0 w-5 h-5 text-purple-500 dark:text-purple-400"
                              fill="currentColor"
                              viewBox="0 0 20 20"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fill-rule="evenodd"
                                d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                clipRule="evenodd"
                              ></path>
                            </svg>
                            <span className="text-base font-medium leading-tight text-gray-900 dark:text-white">
                              {item.title}
                            </span>
                          </Link>
                        </li>
                      ))}
                    </ul>
                    <p className="mb-8 text-justify justify-start text-xs md:text-base leading-normal font-normal">
                      These are the sersvices and technologies involved in web
                      app development.
                    </p>
                  </div>
                  <div className="w-full backdrop-blur-md p-2 shadow-md verticalAnimate mb-4 rounded-lg lg:mb-0 lg:flex">
                    <img
                      className=""
                      src={
                        "https://res.cloudinary.com/whiztechnology/image/upload/v1691046206/cv3fzavgiausndle5njt.png"
                      }
                      alt="Our Apps"
                    />
                  </div>
                </div>
                {/* <!-- Row --> */}
              </div>
              <div className="max-w-screen-xl rounded-md backdrop-blur-sm bg-white/30 px-4 py-8 mx-auto space-y-12 lg:space-y-20  lg:px-6">
                {/* <!-- Row --> */}
                <div className="items-center gap-8 lg:grid lg:grid-cols-2 xl:gap-16">
                  <div className="w-full verticalAnimate mb-4 rounded-lg lg:mb-0 lg:flex">
                    <div className="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-2 gap-4 place-content-center mx-auto ">
                      {keyFeatures2.map((item) => (
                        <Card
                          key={item.id}
                          sx={{
                            backgroundColor: "#6875f5",
                            borderRadius: 2,
                            ":hover": {
                              backgroundColor: "white",
                              color: "black",
                            },
                          }}
                        >
                          <CardContent
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              marginX: "12",
                              pb: 0,
                            }}
                          >
                            <div className="flex flex-col justify-center items-center space-x-3 mb-4">
                              <div className="bg-transparent max-w-fit p-2 hover:bg-white rounded-md">
                                <img
                                  src={item.icon}
                                  alt=""
                                  className=" h-20 w-20 "
                                  srcset=""
                                />
                              </div>
                              <p className="text-xl text-center text-white hover:text-black font-semibold">
                                {item.title}
                              </p>
                              {/* <p className="text-xl text-center text-slate-400 font-semibold">
                            {item.id}
                          </p> */}
                            </div>
                            {/* <div className=" text-center p-2">{item.data}</div> */}
                          </CardContent>
                        </Card>
                      ))}
                    </div>
                  </div>
                  <div className="text-gray-500 sm:text-lg dark:text-gray-400">
                    <h2 className="mb-4 text-3xl font-extrabold tracking-tight text-gray-900 dark:text-white">
                      Empowering Your Online Presence with Expert Services
                    </h2>
                    <p className="mb-8 font-normal text-xs text-justify justify-start text-gray-800 lg:mb-8 leading-normal">
                      We’re here to help you bring your business to life online
                      with everything from beautiful site design and development
                      to effective digital marketing solutions. Our skilled
                      staff works arduously to develop specialized solutions
                      catered to your particular requirements, making sure that
                      your brand stands out and achieves its full potential in
                      the digital world.
                    </p>
                    <Link to={"/contact-us"}>
                      <p className="font-light mt-5 text-white text-center lg:text-xl uppercase px-2 py-3 rounded-md border cursor-pointer bg-indigo-500 hover:bg-indigo-700">
                        Contact Us!
                      </p>
                    </Link>
                  </div>
                </div>
                {/* <!-- Row --> */}
              </div>
            </section>
            <section className="  mt-10 max-w-screen-xl mx-auto ">
              {/* {Tab Section} */}
              <div className="w-full space-y-4 mx-auto sm:max-w-md md:max-w-2xl lg:max-w-5xl px-2 py-16 sm:px-0">
                <h2 className="mb-4 text-center text-3xl font-extrabold tracking-tight text-gray-900 dark:text-white">
                  Process We Follow
                </h2>
                <div className="mx-auto text-center max-w-3xl">
                  <Typography variant="subtitle2" gutterBottom>
                    We employ cutting-edge technologies and tools to make sure
                    your applications are built on the latest technology. Have a
                    look at our tech stack
                  </Typography>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 place-content-center ">
                  {keyFeatures.map((item) => (
                    <Card
                      key={item.id}
                      sx={{
                        minWidth: 333,
                        backgroundColor: "#d8eaff",
                        borderRadius: 5,
                        ":hover": { backgroundColor: "white" },
                      }}
                    >
                      <CardContent
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          marginX: "12",
                          pb: 0,
                        }}
                      >
                        <div className="flex flex-row justify-evenly items-center space-x-3 mb-4">
                          <div className="bg-transparent max-w-fit p-2 hover:bg-white rounded-md">
                            <img
                              src={item.icon}
                              alt=""
                              className=" h-10 w-10
                          "
                              srcset=""
                            />
                          </div>
                          <p className="text-xl text-center text-slate-400 font-semibold">
                            {item.title}
                          </p>
                          <p className="text-xl text-center text-slate-400 font-semibold">
                            {item.id}
                          </p>
                        </div>
                        <div className=" text-center p-2">{item.data}</div>
                      </CardContent>
                    </Card>
                  ))}
                </div>
              </div>
            </section>
            <Trusted />
            <section className=" mt-12 bg-transparent">
              <div className="max-w-screen-xl px-4 pb-8 mx-auto lg:pb-24 lg:px-6 ">
                <h2 className="mb-6 text-3xl font-extrabold tracking-tight text-center text-gray-900 lg:mb-8 lg:text-3xl dark:text-white">
                  Frequently asked questions
                </h2>
                <div className="max-w-screen-md mx-auto">
                  {accordionData.map((item) => (
                    <Accordion
                      className="bg-transparent border-none shadow-none"
                      key={item.id}
                      sx={{
                        backgroundColor: "white",
                        border: "none",
                        borderBottom: "lightgray",
                        boxShadow: "none",
                      }}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        className=" bg-white border-none shadow-none"
                      >
                        <Typography className="bg-white border-none shadow-none">
                          {item.title}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>{item.data}</Typography>
                      </AccordionDetails>
                    </Accordion>
                  ))}
                </div>
              </div>
            </section>
            <FloatingWp />
            <Footer />
            <StickyEnquryForm />
            <PopUoForm />
          </div>
        </div>
      )}
    </>
  );
};

export default WebDesigning;
