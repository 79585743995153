/* eslint-disable react/jsx-no-comment-textnodes */
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import Footer from "components/Footers/Footer.js";
import PopUoForm from "components/Forms/PopUoForm";
import Corousel from "components/Corousel/Corousel";
import Card from "@mui/material/Card";
import { Tab } from "@headlessui/react";
import CardContent from "@mui/material/CardContent";
import {
  TechDevelopmentData,
  accordionData,
  keyFeatures,
  keyFeatures2,
} from "./techData.js";
import StickyEnquryForm from "components/Forms/StickyEnquryForm";
import Trusted from "components/Footers/Trusted";
import Cross from "assets/logo/cross.png";
import "./stack.css";
import APIServices from "components/Technology-Certification/TechServices.jsx";
import SmartTag from "components/Development/SmartTag.jsx";
import FloatingWp from "components/Development/FloatingWp.jsx";
import Faq from "components/Development/Faq.jsx";
import Preloader from "components/Development/Preloader.jsx";

const TechnologyCertification = () => {
  const [selectedIndex, setSelectedIndex] = useState(0);

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  const [categories] = useState({
    "Don't": [
      {
        id: 1,
        title: "Work without a social media marketing plan.",
      },
      {
        id: 2,
        title: "Oversell your products and services.",
      },
      {
        id: 3,
        title: "Buy followers.",
      },
      {
        id: 4,
        title: "Use irrelevant hashtags.",
      },
      {
        id: 5,
        title: "Keep silent on comment sections and threads.",
      },
      {
        id: 6,
        title: "Cross-post on your social media accounts.",
      },
    ],
    "Do's": [
      {
        id: 1,
        title: "Conduct extensive market research.",
      },
      {
        id: 2,
        title: "Analyze your audience behavior.",
      },
      {
        id: 3,
        title: "Craft engaging content.",
      },
      {
        id: 4,
        title: "Run targeted social media advertisements.",
      },
      {
        id: 5,
        title: "Optimize your social media pages.",
      },
      {
        id: 6,
        title: "Analyze campaign results.",
      },
    ],
  });

  const [isLoading, setisLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setisLoading(false);
    }, 1000);
  }, []);

  return (
    <>
      {isLoading === true ? (
        <Preloader />
      ) : (
        <div className="">
          <div className="fixed bg-hero-pattern bg-fixed bg-cover bg-center inset-0 -z-20"></div>
          <div className="App relative">
            {" "}
            <IndexNavbar fixed />
            {/* <!-- Start block --> */}
            <section className="">
              <div className="grid max-w-screen-xl px-4 pt-20 pb-8 mx-auto lg:gap-8 xl:gap-0 lg:py-16 lg:grid-cols-12 lg:pt-28">
                <div className="mr-auto place-self-center lg:col-span-7">
                  <SmartTag
                    title="Best"
                    description="Technology Certification Services in India"
                  />
                  <h1 className="max-w-2xl mb-4 text-lg lg:text-4xl font-extrabold leading-normal tracking-tight sm:text- xl:text-4xl dark:text-white">
                    TECHNOLOGY CERTIFICATION
                  </h1>
                  <p className="max-w-2xl mb-6 font-normal text-xs md:text-base text-justify justify-start text-gray-800 lg:mb-8 leading-normal first-letter:text-indigo-500 first-letter:ui-monospace first-letter:text-2xl">
                    We specialize in providing seamless API integration
                    solutions to help businesses connect and streamline their
                    systems, enabling efficient data exchange and improved
                    functionality. With our expertise in API integration, we
                    empower businesses to enhance their operations, automate
                    processes, and deliver exceptional user experiences.
                  </p>
                  <div className="space-y-4 sm:flex sm:space-y-0 sm:space-x-4">
                    <Link
                      to={"/"}
                      target=""
                      className="inline-block px-6 py-2.5 bg-[#44619d] text-white font-medium text-xs leading-tight uppercase rounded-full shadow-md hover:bg-blue-500 hover:shadow-lg focus:bg-blue-500 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-600 active:shadow-lg transition duration-150 ease-in-out"
                    >
                      Case Study
                    </Link>
                    <Link
                      to={"/about"}
                      className=" mx-2 inline-block px-6 py-2.5 bg-gray-800 text-white font-medium text-xs leading-tight uppercase rounded-full shadow-md hover:bg-gray-900 hover:shadow-lg focus:bg-gray-900 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-gray-900 active:shadow-lg transition duration-150 ease-in-out"
                      target=""
                    >
                      Contact Us
                    </Link>
                  </div>
                </div>
                <div className="hidden lg:mt-0 lg:col-span-5 lg:flex">
                  <img
                    src={
                      "https://res.cloudinary.com/whiztechnology/image/upload/v1691053581/kpwak8ljruyzqvutwuqx.png"
                    }
                    alt="API Integration"
                  />
                </div>
              </div>
            </section>
            {/* <!-- End block --> */}
            <section className="max-w-screen-xl mx-auto">
              <Corousel />
            </section>
            <section className=" bg-transparent">
              <div className="max-w-screen-xl rounded-md backdrop-blur-sm bg-white/30 px-4 py-8 mx-auto space-y-12 lg:space-y-20 lg:px-6">
                {/* <!-- Row --> */}
                <div className="items-center gap-8 lg:grid lg:grid-cols-2 xl:gap-16">
                  <div className="text-gray-500 sm:text-lg dark:text-gray-400">
                    <h2 className="mb-4 text-3xl font-extrabold tracking-tight text-gray-900 dark:text-white">
                      API Certification Services
                    </h2>
                    <p className="mb-4 font-normal text-xs md:text-base text-justify justify-start text-gray-800 lg:mb-8 leading-normal">
                      Our API Integration Services include:
                    </p>
                    <hr className="w-full mt-1 h-1 mb-4 bg-indigo-200" />
                    <ul className="space-y-5 border-t border-gray-200 my-7 dark:border-gray-700">
                      {TechDevelopmentData.map((item) => (
                        <li className="flex" key={item.id}>
                          <Link
                            to={item.to}
                            className=" cursor-pointer flex space-x-3 hover:underline hover:text-purple-500"
                          >
                            {rightSvg()}
                            <span className="text-base font-medium leading-tight text-gray-900 dark:text-white">
                              {item.title}
                            </span>
                          </Link>
                        </li>
                      ))}
                    </ul>
                    <div className="mb-8 ">
                      <Link to={"/contact-us"}>
                        <button
                          type="button"
                          class="text-white bg-gradient-to-r from-cyan-500 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-cyan-300 dark:focus:ring-cyan-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2"
                        >
                          Get A Quote!
                        </button>
                      </Link>
                    </div>
                  </div>
                  <div className="hidden mb-4 lg:mb-0 lg:flex">
                    <img
                      className=" rounded-xl verticalAnimate"
                      src={
                        "https://res.cloudinary.com/whiztechnology/image/upload/v1691053609/zo9u2y8urqtv8cs4s3rj.png"
                      }
                      alt="Our Apps"
                    />
                  </div>
                </div>
              </div>
              <div className="max-w-screen-xl rounded-md backdrop-blur-sm bg-white/30 px-4 py-8 mx-auto space-y-12 lg:space-y-20  lg:px-6">
                <div className="items-center gap-8 lg:grid lg:grid-cols-2 xl:gap-16">
                  <div className="w-full verticalAnimate mb-4 rounded-lg lg:mb-0 lg:flex">
                    <div className="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-2 gap-4 place-content-center mx-auto ">
                      {keyFeatures2.map((item) => (
                        <Card
                          key={item.id}
                          sx={{
                            backgroundColor: "#6875f5",
                            borderRadius: 2,
                            // ":hover": { backgroundColor: "white", color: "black" },
                          }}
                        >
                          <CardContent
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              marginX: "12",
                              pb: 0,
                            }}
                          >
                            <div className="flex flex-col justify-center items-center space-x-3 mb-4">
                              <div className="bg-transparent max-w-fit p-2 hover:bg-white rounded-md">
                                <img
                                  src={item.icon}
                                  alt=""
                                  className=" h-20 w-20 "
                                  srcset=""
                                />
                              </div>
                              <p className="text-xl text-center text-white hover:text-black font-semibold">
                                {item.title}
                              </p>
                            </div>
                          </CardContent>
                        </Card>
                      ))}
                    </div>
                  </div>
                  <div className="text-gray-500 sm:text-lg dark:text-gray-400">
                    <h2 className="mb-4 text-3xl font-extrabold tracking-tight text-gray-900 dark:text-white">
                      Empowering Your Online Presence with Expert Services
                    </h2>
                    <hr className="w-full mt-1 h-1 mb-4 bg-indigo-200" />
                    <p className="mb-8 font-normal text-xs md:text-base text-justify justify-start text-gray-800 lg:mb-8 leading-normal">
                      We’re here to help you bring your business to life online
                      with everything from beautiful site design and development
                      to effective digital marketing solutions. Our skilled
                      staff works arduously to develop specialized solutions
                      catered to your particular requirements, making sure that
                      your brand stands out and achieves its full potential in
                      the digital world.
                    </p>
                    <Link to={"/contact-us"}>
                      <p className="font-light mt-5 text-white text-center lg:text-xl uppercase px-2 py-3 rounded-md border cursor-pointer bg-indigo-500 hover:bg-indigo-700">
                        Contact Us!
                      </p>
                    </Link>
                  </div>
                </div>
                {/* <!-- Row --> */}
              </div>
            </section>
            <APIServices />
            <section className=" mt-10 max-w-screen-xl mx-auto ">
              {/* {Tab Section} */}
              <div className="w-full space-y-4 mx-auto sm:max-w-md md:max-w-2xl lg:max-w-5xl px-2 py-16 sm:px-0">
                <h2 className="mb-4 text-center text-3xl font-extrabold tracking-tight text-gray-900 dark:text-white">
                  Our Services Include
                </h2>
                {/* <div className="mx-auto text-center max-w-3xl">
              <Typography variant="subtitle2" gutterBottom>
                We employ cutting-edge technologies and tools to make sure your
                applications are built on the latest technology. Have a look at
                our tech stack
              </Typography>
            </div> */}
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 place-content-center ">
                  {keyFeatures.map((item) => (
                    <Card
                      key={item.id}
                      sx={{
                        minWidth: 333,
                        backgroundColor: "#d8eaff",
                        borderRadius: 5,
                        ":hover": { backgroundColor: "white" },
                      }}
                    >
                      <CardContent
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          marginX: "12",
                          pb: 0,
                        }}
                      >
                        <div className="flex flex-row justify-evenly items-center space-x-3 mb-4">
                          <div className="bg-transparent max-w-fit p-2 hover:bg-white rounded-md">
                            <img
                              src={item.icon}
                              alt=""
                              className=" h-10 w-10
                          "
                              srcset=""
                            />
                          </div>
                          <p className="text-base text-center text-slate-400 font-semibold">
                            {item.title}
                          </p>
                          <p className="text-base text-center text-slate-400 font-semibold">
                            {item.id}
                          </p>
                        </div>
                        <div className=" text-center text-base p-2">
                          {item.data}
                        </div>
                      </CardContent>
                    </Card>
                  ))}
                </div>
              </div>
            </section>
            <section className="bg-transparent">
              {/* {Tab Section} */}
              <div className="w-full mx-auto sm:max-w-md md:max-w-lg lg:max-w-2xl px-2 py-16 sm:px-0">
                <h2 className="text-xl text-center mb-2 font-extrabold tracking-tight text-gray-900 dark:text-white">
                  What Needs to Follow for Business Growth..
                </h2>
                <Tab.Group
                  selectedIndex={selectedIndex}
                  onChange={setSelectedIndex}
                >
                  <Tab.List className="flex space-x-1 rounded-xl bg-indigo-500 p-1">
                    {Object.keys(categories).map((category) => (
                      <Tab
                        key={category}
                        className={({ selected }) =>
                          classNames(
                            "w-full rounded-lg py-2.5 text-sm font-medium leading-5 text-blue-700",
                            "ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2",
                            selected
                              ? "bg-white shadow text-white"
                              : "text-blue-100 hover:bg-white/[0.12] hover:text-white"
                          )
                        }
                      >
                        {category}
                      </Tab>
                    ))}
                  </Tab.List>
                  <Tab.Panels className="mt-2">
                    {Object.values(categories).map((posts, idx) => (
                      <Tab.Panel
                        key={idx}
                        className={classNames(
                          "rounded-xl bg-white p-3",
                          "ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2"
                        )}
                      >
                        <div className="flex flex-col space-y-1">
                          {posts.map((post) => (
                            <ul>
                              <li
                                key={post.id}
                                className={classNames(
                                  selectedIndex === 0
                                    ? "relative bg-red-300 border-red-500 shadow-xl shadow-red-200 rounded-md p-3"
                                    : "relative bg-green-300 border-green-500 shadow-xl shadow-green-200 rounded-md p-3"
                                )}
                                // "relative bg-red-300 border-red-500 shadow-xl shadow-red-200 rounded-md p-3 hover:bg-gray-100"
                              >
                                <div className=" space-x-2 inline-flex">
                                  <span
                                    className={classNames(
                                      selectedIndex === 0 ? "block" : "hidden"
                                    )}
                                  >
                                    <img
                                      src={Cross}
                                      alt="Don't"
                                      className=" h-5 w-5"
                                      srcset=""
                                    />
                                  </span>
                                  <span
                                    className={classNames(
                                      selectedIndex === 1 ? "block" : "hidden"
                                    )}
                                  >
                                    <svg
                                      className="flex-shrink-0 w-5 h-5 text-purple-500 dark:text-purple-400"
                                      fill="currentColor"
                                      viewBox="0 0 20 20"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        fill-rule="evenodd"
                                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                        clipRule="evenodd"
                                      ></path>
                                    </svg>
                                  </span>
                                  <h3 className="text-sm font-medium leading-5">
                                    {post.title}
                                  </h3>
                                </div>
                              </li>
                            </ul>
                          ))}
                        </div>
                      </Tab.Panel>
                    ))}
                  </Tab.Panels>
                </Tab.Group>
              </div>
            </section>
            <Trusted />
            <Faq faqs={accordionData} />
            <FloatingWp />
            <Footer />
            <StickyEnquryForm />
            <PopUoForm />
          </div>
        </div>
      )}
    </>
  );
};

export default TechnologyCertification;

const rightSvg = () => {
  return (
    <svg
      className="flex-shrink-0 w-5 h-5 text-purple-500 dark:text-purple-400"
      fill="currentColor"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
};
