import React from "react";
import { Link } from "react-router-dom";
import { ChevronRight } from "@mui/icons-material";

const SmartTag = (props) => {
  return (
    <>
      <Link
        to={"/contact-us"}
        className="flex flex-row max-w-fit px-2 py-1.5 space-x-3 justify-center items-center rounded-xl bg-indigo-500 mb-4"
      >
        <span className="text-[10px] md:text-xs text-white bg-green-400 py-1 px-2 rounded-xl">
          {props.title}
        </span>
        <span class=" text-[10px] md:text-xs text-white">
          ⚡️️ {props.description}
        </span>{" "}
        <span>
          <ChevronRight sx={{ color: "white" }} className=" w-3 h-3" />
        </span>
      </Link>
    </>
  );
};

export default SmartTag;
