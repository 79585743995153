import React, { useRef } from "react";
import Slider from "react-slick";
import styled from "styled-components";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Banner_1 from "assets/images/smart-products-banner-1.png";
import Banner_2 from "assets/images/smart-products-banner-2.png";
import { id } from "date-fns/locale";

const sliderData = [
  {
    id: 1,
    title: "",
    for: "",
    image: Banner_1,
  },
  {
    id: 2,
    title: "",
    for: "",
    image: Banner_2,
  },
];

var settings = {
  className: "center",
  autoplay: true,
  autoplaySpeed: 3000,
  centerMode: true,
  dots: false,
  infinite: true,
  speed: 1000,
  slidesToShow: 1,
  slidesToScroll: 1,
  initialSlide: 0,
  arrows: false,
  responsive: [
    {
      breakpoint: 990,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        dots: false,
        centerMode: false,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 2,
        centerMode: false,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        centerMode: false,
      },
    },
  ],
};
const OfferCorousel = () => {
  const arrowRef = useRef(null);
  let sliderProject = "";
  sliderProject = sliderData.map((item) => (
    <div className="p-2 ml-auto" key={id}>
      <img src={item.image} alt="Ads" className=" rounded-2xl" srcset="" />
    </div>
  ));
  return (
    <Container className="bg-transparent w-full overflow-hidden">
      <Slider ref={arrowRef} {...settings} className="max-w-screen-xl mx-auto">
        {sliderProject}
      </Slider>
    </Container>
  );
};

export default OfferCorousel;

const Container = styled.div`
  position: relative;
  overflow: hidden;
`;
