import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import SchoolHeroPng from "assets/images/school_mobile_app_banner.png";
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import Online_Coaching_App from "assets/images/online_coaching_app.png";
import Typography from "@mui/material/Typography";
import Footer from "components/Footers/Footer.js";
import School_Management_App from "assets/images/school_management_app.png";
import Educational_Services_Banner from "assets/images/education_services_banner.png";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import atmPng from "assets/logo/atm-card.png";
import onlineLearningPng from "assets/logo/online-learning.png";
import documentsPng from "assets/logo/document.png";
import calederPng from "assets/logo/calendar.png";
import PerformancePng from "assets/logo/increase.png";
import { Tab } from "@headlessui/react";
import assignmentPng from "assets/logo/assignment.png";
import PopUoForm from "components/Forms/PopUoForm";
import StickyEnquryForm from "components/Forms/StickyEnquryForm";
import Trusted from "components/Footers/Trusted";
import Corousel from "components/Corousel/Corousel";
import SmartTag from "components/Development/SmartTag";
import FloatingWp from "components/Development/FloatingWp";
import Faq from "components/Development/Faq";
import Preloader from "components/Development/Preloader";

const EducationalAppDevelopement = () => {
  const keyFeatures = [
    {
      id: 1,
      icon: calederPng,
      title: "Accurate Attendance Track",
      data: "Effortlessly track attendance of students/trainees on using the app’s in-built functionalities.",
    },
    {
      id: 2,
      icon: PerformancePng,
      title: "Performance Monitoring ",
      data: "Enable users to smoothly subscribe to various packages and lessons with one-click subscription buttons.",
    },
    {
      id: 3,
      icon: atmPng,
      title: "Easy Payment Options ",
      data: "Enable users to smoothly subscribe to various packages and lessons with one-click subscription buttons.",
    },
    {
      id: 4,
      icon: documentsPng,
      title: "Online assignments ",
      data: "If the user’s plan is changed, in that case, they can easily cancel their hotel booking with this ‘Cancel booking’ feature. It will increase client loyalty.",
    },
    {
      id: 5,
      icon: onlineLearningPng,
      title: "Create digital libraries",
      data: "Digitally store and manage all your educational contents and learning materials and allow students access to the libraries.",
    },
    {
      id: 6,
      icon: assignmentPng,
      title: "Efficient content management ",
      data: "Manage all contents and teaching materials using the most-evolved CMS solutions and make your lessons engaging.",
    },
  ];

  const accordionData = [
    {
      id: 1,
      title: "Q1: Will you sign a nondisclosure agreement with me?",
      data: "Yes, of course. We sign an NDA with all our clients to ensure that the idea of your app will be protected and not shared with any third party individual or organization.",
    },
    {
      id: 2,
      title:
        "Q2: Why should I hire app developers from Space-O for my education app development?",
      data: "Being a leading mobile app development company, we have already developed over 50 mobile apps for the education industry. Like mobile apps for college students, education apps for students, and college apps. Our developed apps have helped many parents, teachers, and students to get constant updates and check their progress on a regular basis with ease. Moreover, our team of designers and developers knows what it requires to make a successful mobile app for schools and colleges.",
    },
    {
      id: 3,
      title:
        "Q3: I run several schools of the same name in different cities. Will I need a different app for each school?",
      data: "No, we will be making one comprehensive school app solution that you can use at all your schools or educational organizations. Every student, teacher and parent will use a separate username or email address and thus, there will be no issues in communication. However, you will need a server that handles a larger amount of data at one time.",
    },
    {
      id: 4,
      title:
        "Q4: I run several schools of the same name in different cities. Will I need a different app for each school?",
      data: "No, we will be making one comprehensive school app solution that you can use at all your schools or educational organizations. Every student, teacher and parent will use a separate username or email address and thus, there will be no issues in communication. However, you will need a server that handles a larger amount of data at one time.",
    },
  ];

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  const [categories] = useState({
    "Customer App Pannel": [
      {
        id: 1,
        title: "Quick Onboarding",
        data: "With direct social media profile integration, users can easily register and log in to the app. This hassle-free login process ensures smoother onboarding.",
      },
      {
        id: 2,
        title: "Social Login And Logout",
        data: "We help ease the whole login and sharing process by adding the comfort of Social Media integration to your application.",
      },
      {
        id: 3,
        title: "Easy Product Filtering",
        data: "With advanced product search functionality, users can easily search a product with filters like price, category, offers, promotions, and others.",
      },
      {
        id: 4,
        title: "Real-Time Shipment Tracking",
        data: "Ensure transparency for your users with real-time shipment tracking and notification. Users can track orders with real-time notifications with each milestone achieved.",
      },
      {
        id: 5,
        title: "Multiple Payment Gateways",
        data: "Users can make hassle-free payments with their own choice of payment methods like debit cards, credit cards, net banking, or any other secured payment apps.",
      },
      {
        id: 6,
        title: "Shipping Details Integration",
        data: "To keep your customers updated with how their product is moving in real-time, we add integrations of some of the top shipping providers: USPS, UPS, FedEx, Australian Post, etc.",
      },
      {
        id: 7,
        title: "Quick Push Notifications",
        data: "Inform users of the current status of their order, new offers, product promotion, or any other updates with quick push notifications.",
      },
      {
        id: 8,
        title: "Reviews and Ratings",
        data: "This section allows your users to review and rate the products that they purchased from your ecommerce app. With user feedback, you can work on the flaws.",
      },
    ],
    "Admin Pannel": [
      {
        id: 1,
        title: "Simplified Inventory Management",
        data: "Keep your inventory clean and up to date at all times with an easy-to-use interface. The inventory management dashboard comes with the ability to fetch inventory from various third-party databases.",
      },
      {
        id: 2,
        title: "Easy Product Management",
        data: "Streamline your products by managing the categories with an intuitive interface for adding photos, descriptions, prices, etc. Admins will benefit from the flexibility by adding and managing categories as per their own requirements.",
      },
      {
        id: 3,
        title: "Real-Time Notifications",
        data: "Receive real-time notifications for the placed orders, manage orders & payments, and inventory, across all your systems for optimizing order fulfillment and delivering orders in time.",
      },
      {
        id: 4,
        title: "Advanced Reporting",
        data: "Track the performances of your sales, orders, and delivery agents with AI-powered analytic tools. Generate customized reports, and create various reports for handling your sales, marketing, operations, manufacturing, finance, and other areas.",
      },
      {
        id: 5,
        title: "Manage Users",
        data: "Being an admin, you will be able to manage your users by giving them the right roles of manager, editor, or co-admin. Restrict or offer access to different parts of the app to your users thereby firmly remaining in complete control of your app.",
      },
      {
        id: 6,
        title: "Manage Promotions & Rewards",
        data: "As an admin, you can now create new offers for your users and manage the offer and discounts of your e-commerce app. In this section, your users can apply promo codes and avail discounts as they purchase their preferred products.",
      },
    ],
    "Vendor Pannel": [
      {
        id: 1,
        title: "Order Management",
        data: "With the order management feature, the vendor can keep a constant check on all the orders that are delivered to the customers and those that are still in the queue. With a seamless vendor panel dashboard, managing orders has now become simpler.",
      },
      {
        id: 2,
        title: "Catalog Management",
        data: "Vendors can easily manage their products effectively based on how they choose their featured products and product categories. With proper management of products, the vendors can attract the right target audience and generate more sales.",
      },
      {
        id: 3,
        title: "Manage Delivery Locations",
        data: "Customers get agitated when they choose a particular product and find that it is undeliverable to their location. This means that the product is not locally available. With our solution, vendors can enable product filtering and set their location so that the customers can only see the nearby vendors.",
      },
      {
        id: 4,
        title: "Easy Vendor Membership",
        data: "With a user-friendly interface, vendors cannot only view the membership plans but also opt for an ideal one. Membership can be renewed from the admin panel. Additionally, they can also store and collect their information like product limits, commission fees, categories, and so on.",
      },
      {
        id: 5,
        title: "Vendor Account Reports",
        data: "Allow your vendor to view complete transaction details of each order like commission amount, marketplace fee, net payable amount, and shipping cost. In this way, the vendors can manage their commission invoices for tracking what they are paying to sell on your marketplace.",
      },
      {
        id: 6,
        title: "Product Upload",
        data: "Uploading bulk products can be time-consuming for the vendors. With our multi-vendor marketplace solution, they can seamlessly upload product pictures and edit product information while making changes to the offers in real-time",
      },
    ],
  });

  const [isLoading, setisLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setisLoading(false);
    }, 1000);
  }, []);

  return (
    <>
      {isLoading === true ? (
        <Preloader />
      ) : (
        <div className="">
          <div className="fixed bg-hero-pattern bg-fixed bg-cover bg-center inset-0 -z-20"></div>
          <div className="App relative">
            <IndexNavbar fixed />
            <section className="">
              <div className="grid max-w-screen-xl px-4 pt-20 pb-8 mx-auto lg:gap-8 xl:gap-0 lg:py-16 lg:grid-cols-12 lg:pt-28">
                <div className="mr-auto place-self-center lg:col-span-7">
                  <SmartTag
                    title="Best"
                    description="Education App Development Company in India"
                  />
                  <h1 className="max-w-2xl mb-4 text-4xl uppercase font-extrabold leading-none tracking-tight sm:text- xl:text-4xl dark:text-white">
                    Educational Mobile App Development COMPANY
                  </h1>
                  <p className="max-w-2xl mb-6 font-light text-gray-500 lg:mb-8 md:text-base lg:text-bse dark:text-gray-400">
                    Deliver education to students where they live. Multimedia
                    courseware. Student authentication. Complete moderation for
                    teachers. Scheduling and timetables. You can run your entire
                    school from a mobile app. Contact us today for more
                    information.
                  </p>
                  <div className="space-y-4 sm:flex sm:space-y-0 sm:space-x-4">
                    <Link
                      to={"/"}
                      target=""
                      className="inline-block px-6 py-2.5 bg-[#44619d] text-white font-medium text-xs leading-tight uppercase rounded-full shadow-md hover:bg-blue-500 hover:shadow-lg focus:bg-blue-500 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-600 active:shadow-lg transition duration-150 ease-in-out"
                    >
                      Case Study
                    </Link>
                    <Link
                      to={"/contact-us"}
                      className=" mx-2 inline-block px-6 py-2.5 bg-gray-800 text-white font-medium text-xs leading-tight uppercase rounded-full shadow-md hover:bg-gray-900 hover:shadow-lg focus:bg-gray-900 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-gray-900 active:shadow-lg transition duration-150 ease-in-out"
                      target=""
                    >
                      Contact Us
                    </Link>
                  </div>
                </div>
                <div className="hidden lg:mt-0 lg:col-span-5 lg:flex">
                  <img src={SchoolHeroPng} alt="heroimage" />
                </div>
              </div>
            </section>
            {/* <!-- End block --> */}
            <section className="">
              <div className="max-w-screen-xl px-4 pb-8 mx-auto lg:pb-16">
                <Corousel />
              </div>
            </section>
            <section className=" bg-transparent">
              <div className="max-w-screen-xl rounded-md backdrop-blur-sm bg-white/30 px-4 py-8 mx-auto space-y-12 lg:space-y-20 lg:py-24 lg:px-6">
                {/* <!-- Row --> */}
                <div className="items-center gap-8 lg:grid lg:grid-cols-2 xl:gap-16">
                  <img
                    className="w-full mb-4 rounded-lg lg:mb-0 lg:flex"
                    src={Educational_Services_Banner}
                    alt="featureimage 2"
                  />
                  <div className="text-gray-500 sm:text-lg dark:text-gray-400">
                    <h2 className="mb-4 text-3xl font-extrabold tracking-tight text-gray-900 dark:text-white">
                      Types of Educational Apps We Develop
                    </h2>
                    <p className="mb-2 font-light lg:text-xl">
                      We are known globally as the best Educational app
                      development company that offers world-class Educational
                      development services. Our experts know what features
                      convert the fence viewers into loyal buyers.
                    </p>
                    {/* <!-- List --> */}
                    <ul className="pt-8 space-y-5 border-t border-gray-200 my-7 dark:border-gray-700">
                      <li className="flex space-x-3">
                        {/* <!-- Icon --> */}
                        <svg
                          className="flex-shrink-0 w-5 h-5 text-purple-500 dark:text-purple-400"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                            clipRule="evenodd"
                          ></path>
                        </svg>
                        <span className="text-base font-medium leading-tight text-gray-900 dark:text-white">
                          School Management App Development
                        </span>
                      </li>
                      <li className="flex space-x-3">
                        {/* <!-- Icon --> */}
                        <svg
                          className="flex-shrink-0 w-5 h-5 text-purple-500 dark:text-purple-400"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                            clipRule="evenodd"
                          ></path>
                        </svg>
                        <span className="text-base font-medium leading-tight text-gray-900 dark:text-white">
                          Institute Management App Development
                        </span>
                      </li>
                      <li className="flex space-x-3">
                        {/* <!-- Icon --> */}
                        <svg
                          className="flex-shrink-0 w-5 h-5 text-purple-500 dark:text-purple-400"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                            clipRule="evenodd"
                          ></path>
                        </svg>
                        <span className="text-base font-medium leading-tight text-gray-900 dark:text-white">
                          Online Coaching App Development
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="items-center gap-8 lg:grid lg:grid-cols-2 xl:gap-16">
                  <div className="text-gray-500 sm:text-lg dark:text-gray-400">
                    <h2 className="mb-4 text-3xl font-extrabold tracking-tight text-gray-900 dark:text-white">
                      School Management App Development – A Complete Solution
                      for Educational Institutions
                    </h2>
                    {/* <!-- List --> */}
                    <ul className="pt-2 space-y-5 border-t border-gray-200 my-7 ">
                      <li className="flex text-justify">
                        <Link
                          to={"/educational-app-development"}
                          className=" cursor-pointer flex space-x-3 hover:underline hover:text-purple-500"
                        >
                          <svg
                            className="flex-shrink-0 w-5 h-5 text-purple-500 dark:text-purple-400"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                              clipRule="evenodd"
                            ></path>
                          </svg>
                          <Typography variant="subtitle2" gutterBottom>
                            Smarther lets you create a complete school
                            management app to handle and coordinate updates
                            among students and teachers. With the assistance of
                            the mobile app, Management can engage with both
                            teachers and parents to update their day-to-day
                            activities and provide information
                          </Typography>
                        </Link>
                      </li>
                      <li className="flex text-justify">
                        <Link
                          to={"/educational-app-development"}
                          className=" cursor-pointer flex space-x-3 hover:underline hover:text-purple-500"
                        >
                          <svg
                            className="flex-shrink-0 w-5 h-5 text-purple-500 dark:text-purple-400"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                              clipRule="evenodd"
                            ></path>
                          </svg>
                          <Typography variant="subtitle2" gutterBottom>
                            The development of a mobile app for school
                            administration has now become a vital component of
                            many education institutions. There are indeed lots
                            of events that do occur in the school
                            administration. Thus, coordinating and arranging
                            these events and controlling them is inconvenient
                            for the management through the mobile app.
                          </Typography>
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <img
                    className="w-full verticalAnimate mb-4 rounded-lg lg:mb-0 lg:flex"
                    src={School_Management_App}
                    alt="eCoomerce App"
                  />
                </div>
                {/* <!-- Row --> */}

                {/* Row */}
                <div className="items-center gap-8 lg:grid lg:grid-cols-2 xl:gap-16">
                  <img
                    className="w-full mb-4 rounded-lg lg:mb-0 lg:flex"
                    src={Online_Coaching_App}
                    alt="dashboardfeatureimage"
                  />
                  <div className="text-gray-500 sm:text-lg dark:text-gray-400">
                    <h2 className="mb-4 text-3xl font-extrabold tracking-tight text-gray-900 dark:text-white">
                      Online Coaching App Development
                    </h2>
                    {/* <!-- List --> */}
                    <ul className="pt-2 space-y-5 border-t border-gray-200 my-7 ">
                      <li className="flex text-justify">
                        <Link
                          to={"/educational-app-development"}
                          className=" cursor-pointer flex space-x-3 hover:underline hover:text-purple-500"
                        >
                          <svg
                            className="flex-shrink-0 w-5 h-5 text-purple-500 dark:text-purple-400"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                              clipRule="evenodd"
                            ></path>
                          </svg>
                          <Typography variant="subtitle2" gutterBottom>
                            Safe and Secure: The app is built for your coaching
                            classes with your brand and log. The app uses a
                            separate database so that no one else has access to
                            the DB. Along with SSL security, your app becomes
                            safer and more secure.
                          </Typography>
                        </Link>
                      </li>
                      <li className="flex text-justify">
                        <Link
                          to={"/educational-app-development"}
                          className=" cursor-pointer flex space-x-3 hover:underline hover:text-purple-500"
                        >
                          <svg
                            className="flex-shrink-0 w-5 h-5 text-purple-500 dark:text-purple-400"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                              clipRule="evenodd"
                            ></path>
                          </svg>
                          <Typography variant="subtitle2" gutterBottom>
                            Group Discussion: One of the important factors in
                            the learning process is a topic discussed within the
                            group. This helps in learning specific topics
                            interested and easy to understand and at the same,
                            it is great fun for the students.
                          </Typography>
                        </Link>
                      </li>
                      <li className="flex text-justify">
                        <Link
                          to={"/educational-app-development"}
                          className=" cursor-pointer flex space-x-3 hover:underline hover:text-purple-500"
                        >
                          <svg
                            className="flex-shrink-0 w-5 h-5 text-purple-500 dark:text-purple-400"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                              clipRule="evenodd"
                            ></path>
                          </svg>
                          <Typography variant="subtitle2" gutterBottom>
                            User Admin: The app comes with a user admin login
                            for a single user who can control the app so make
                            sure there is no abusive content in the app.
                          </Typography>
                        </Link>
                      </li>
                      <li className="flex text-justify">
                        <Link
                          to={"/educational-app-development"}
                          className=" cursor-pointer flex space-x-3 hover:underline hover:text-purple-500"
                        >
                          <svg
                            className="flex-shrink-0 w-5 h-5 text-purple-500 dark:text-purple-400"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                              clipRule="evenodd"
                            ></path>
                          </svg>
                          <Typography variant="subtitle2" gutterBottom>
                            Notification and Alert: Sending an alert to single
                            students or in a group is a great way of
                            communication from one to many. You can easily send
                            alerts for important meetings, classes, or exams.
                          </Typography>
                        </Link>
                      </li>
                      <li className="flex text-justify">
                        <Link
                          to={"/educational-app-development"}
                          className=" cursor-pointer flex space-x-3 hover:underline hover:text-purple-500"
                        >
                          <svg
                            className="flex-shrink-0 w-5 h-5 text-purple-500 dark:text-purple-400"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                              clipRule="evenodd"
                            ></path>
                          </svg>
                          <Typography variant="subtitle2" gutterBottom>
                            Exam preparation: The coaching mobile application
                            can be used to prepare students for real exams by
                            providing various mockup tests. You can add any
                            number of tests for the students. The test comes
                            with a time or without a timer. This helps students
                            to complete the test in a specific period and get
                            ready to face the real-world examination.
                          </Typography>
                        </Link>
                      </li>
                      <li className="flex text-justify">
                        <Link
                          to={"/educational-app-development"}
                          className=" cursor-pointer flex space-x-3 hover:underline hover:text-purple-500"
                        >
                          <svg
                            className="flex-shrink-0 w-5 h-5 text-purple-500 dark:text-purple-400"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                              clipRule="evenodd"
                            ></path>
                          </svg>
                          <Typography variant="subtitle2" gutterBottom>
                            Coaching class promotions: You would surely love o
                            promote various offers on your mobile application.
                            This can be easily achieved by having a separate
                            section where you can promote various offers such as
                            coupon codes, group discounts, refers a friend
                            promotion, or even a specific event held by the
                            coaching institution.
                          </Typography>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </section>
            <section className=" bg-transparent">
              {/* {Tab Section} */}
              <div className="w-full space-y-4 mx-auto sm:max-w-md md:max-w-2xl lg:max-w-5xl px-2 py-16 sm:px-0">
                <h2 className="mb-4 text-center text-3xl font-extrabold tracking-tight text-gray-900 dark:text-white">
                  Key Features of Educational Applications
                </h2>
                <div className="text-center">
                  <Typography variant="subtitle2" gutterBottom>
                    We employ cutting-edge technologies and tools to make sure
                    your applications are built on the latest technology. Have a
                    look at our tech stack
                  </Typography>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 place-content-center ">
                  <div className="grid grid-rows-1 md:grid-rows-2 grid-flow-row lg:grid-flow-col gap-4">
                    {keyFeatures.map((item) => (
                      <Card
                        key={item.id}
                        sx={{
                          minWidth: 352,
                          backgroundColor: "#d8eaff",
                          borderRadius: 5,
                          ":hover": { backgroundColor: "white" },
                        }}
                      >
                        <CardContent
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            marginX: "12",
                            pb: 0,
                          }}
                        >
                          <div className="flex flex-row items-center space-x-3 mb-4">
                            <div className="bg-transparent max-w-fit p-2 hover:bg-white rounded-md">
                              <img
                                src={item.icon}
                                alt=""
                                className=" h-10 w-10
                          "
                                srcset=""
                              />
                            </div>
                            <p className="text-xl text-slate-400 font-semibold">
                              {item.title}
                            </p>
                          </div>
                          <div className="ml-2">{item.data}</div>
                        </CardContent>
                      </Card>
                    ))}
                  </div>
                </div>
              </div>
            </section>
            <section className="bg-transparent">
              {/* {Tab Section} */}
              <div className="w-full mx-auto sm:max-w-md md:max-w-lg lg:max-w-2xl px-2 py-16 sm:px-0">
                <h2 className="text-xl text-center mb-2 font-extrabold tracking-tight text-gray-900 dark:text-white">
                  Explore Our Feature-Rich Application Panels
                </h2>
                <Tab.Group>
                  <Tab.List className="flex space-x-1 rounded-xl bg-indigo-500 p-1">
                    {Object.keys(categories).map((category) => (
                      <Tab
                        key={category}
                        className={({ selected }) =>
                          classNames(
                            "w-full rounded-lg py-2.5 text-sm font-medium leading-5 text-blue-700",
                            "ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2",
                            selected
                              ? "bg-white shadow text-white"
                              : "text-blue-100 hover:bg-white/[0.12] hover:text-white"
                          )
                        }
                      >
                        {category}
                      </Tab>
                    ))}
                  </Tab.List>
                  <Tab.Panels className="mt-2">
                    {Object.values(categories).map((posts, idx) => (
                      <Tab.Panel
                        key={idx}
                        className={classNames(
                          "rounded-xl bg-white p-3",
                          "ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2"
                        )}
                      >
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-2 lg:grid-cols-3">
                          {posts.map((post) => (
                            <ul>
                              <li
                                key={post.id}
                                className="relative rounded-md p-3 hover:bg-gray-100"
                              >
                                <div className=" space-x-2 inline-flex">
                                  <span>
                                    <svg
                                      className="flex-shrink-0 w-5 h-5 text-purple-500 dark:text-purple-400"
                                      fill="currentColor"
                                      viewBox="0 0 20 20"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        fill-rule="evenodd"
                                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                        clipRule="evenodd"
                                      ></path>
                                    </svg>
                                  </span>
                                  <h3 className="text-sm font-medium leading-5">
                                    {post.title}
                                  </h3>
                                </div>

                                <ul className="mt-1 flex space-x-1 text-xs font-normal leading-4 text-gray-500">
                                  <li>{post.data}</li>
                                </ul>
                                <Link
                                  to="/e-commerce-app-development"
                                  className={classNames(
                                    "absolute inset-0 rounded-md",
                                    "ring-blue-400 focus:z-10 focus:outline-none focus:ring-2"
                                  )}
                                ></Link>
                              </li>
                            </ul>
                          ))}
                        </div>
                      </Tab.Panel>
                    ))}
                  </Tab.Panels>
                </Tab.Group>
              </div>
            </section>
            <Trusted />
            <Faq faqs={accordionData} />
            <FloatingWp />
            <Footer />
            <StickyEnquryForm />
            <PopUoForm />
          </div>
        </div>
      )}
    </>
  );
};

export default EducationalAppDevelopement;
