import React from "react";
import { Link } from "react-router-dom";
import IndexNavbar from "components/Navbars/IndexNavbar";
import Footer from "components/Footers/Footer";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import CallOutlinedIcon from "@mui/icons-material/CallOutlined";
import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
import { useNavigate } from "react-router-dom";
import {
  UilLinkedin,
  UilInstagram,
  UilTwitterAlt,
} from "@iconscout/react-unicons";
import { Helmet } from "react-helmet";

function ThankYou() {
  const navigate = useNavigate();

  const socialLinks = [
    {
      id: 1,
      name: "Instagram",
      icon: <UilInstagram className=" text-[#1976d2]" />,
      to: "https://www.instagram.com/whiztechnologydelhi/",
    },
    {
      id: 2,
      name: "Facebook",
      icon: <FacebookOutlinedIcon color="primary" />,
      to: "https://www.facebook.com/whiztechnologyindia/",
    },
    {
      id: 3,
      name: "LinkedIn",
      icon: <UilLinkedin className=" text-[#1976d2]" />,
      to: "https://in.linkedin.com/company/whiz-technology",
    },
    {
      id: 4,
      name: "mail",
      icon: <UilTwitterAlt className=" text-[#1976d2]" />,
      to: "https://twitter.com/whiztechnology?lang=en",
    },
  ];

  return (
    <>
      <Helmet>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'AW-716446954');
            gtag('event', 'conversion', {'send_to': 'AW-716446954/BTlJCJ_M-cYYEOq50NUC'});
          `}
        </script>
      </Helmet>
      <IndexNavbar fixed />
      <div class="min-h-screen bg-white flex justify-center items-center">
        <div class="bg-white shadow-md border-t-2 bg-hero-pattern bg-fixed bg-cover bg-center border-blue-500 rounded-lg p-5 w-full md:w-1/2">
          <div class="flex flex-col items-center space-y-4 justify-center mx-auto text-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="75"
              height="75"
              fill="currentColor"
              className="bi bi-check-circle text-center mb-4 text-green-400"
              viewBox="0 0 16 16"
            >
              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
              <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
            </svg>
            <div class="flex flex-col justify-center items-center space-y-4">
              <h1 class="text-3xl font-bold text-justify justify-center">
                Thank You !
              </h1>
              <p>
                Thank you for choosing our company! We appreciate your business
                and the opportunity to serve you. Your satisfaction is our top
                priority, and we strive to provide you with the best possible
                experience.
              </p>
            </div>
            <div className="">
              <Link
                to={"tel:+91-7503055896"}
                className="flex flex-row space-x-1 sm:space-x-3 justify-start items-center mb-2"
              >
                <CallOutlinedIcon color="primary" />
                <span className=" text-black text-[10px] sm:text-sm  font-medium">
                  Bussiness Support &nbsp;:
                </span>
                <span className=" text-black text-xs sm:text-sm font-normal">
                  +91-7503055896
                </span>
              </Link>
              <Link
                to={"@mailto:info.whiztechnology@gmail.com"}
                className="flex flex-row space-x-1 sm:space-x-3 justify-start items-center mb-2"
              >
                <EmailOutlinedIcon color="primary" />
                <span className=" text-black text-[10px] sm:text-sm font-medium">
                  Email Support &nbsp;:
                </span>
                <span className=" text-black text-xs sm:text-sm font-normal">
                  info.whiztechnology@gmail.com
                </span>
              </Link>
            </div>
            <ul className="flex justify-center items-center mt-1 space-x-5">
              {socialLinks.map((item) => (
                <li>
                  <Link
                    to={item.to}
                    target="_blank"
                    className="text-indigo-500 hover:text-gray-900 dark:hover:text-white dark:text-gray-400"
                  >
                    {item.icon}
                  </Link>
                </li>
              ))}
            </ul>

            <button
              class="bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded"
              onClick={navigate("/smart-products")}
            >
              Go Back
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default ThankYou;
