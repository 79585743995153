import React from "react";
import ProductListSlider from "./ProductListSlider";

export default function ProductVideo() {
  return (
    <>
      <section className="lg:mt-4 md:mt-4 pb-6 relative bg-transperent">
        <div className="container w-full lg:w-[1280px] mx-auto ">
          <div className="flex p-[20px] flex-wrap items-center">
            <div className="w-full md:w-7/12 lg:w-7/12 xl:w-7/12 px-4">
              <h1 className="text-2xl text-slate-600 lg:w-[75%] font-semibold font-poppins text-slate-400">
                See How Teams Are Using Whiz Technology..{" "}
              </h1>
              <hr className="my-4 h-[3px] w-[25%] bg-[#44619d] rounded-[25px]" />
              <video
                width="750"
                height="500"
                controls
                src={
                  "https://res.cloudinary.com/whiztechnology/video/upload/v1690891955/Uploads/pexels-pavel-danilyuk-9121382_an6g7f.mp4"
                }
                type="video/mp4"
              />
            </div>
            <div className="w-full md:w-5/12 lg:w-5/12 xl:w-5/12 px-4">
              <div className="pt-4 sm:pt-0">
                <ul className="mt-4 justify-center item-center overflow-hidden">
                  <ProductListSlider />
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
