/*eslint-disable*/
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import Footer from "components/Footers/Footer.js";
import PopUoForm from "components/Forms/PopUoForm";
import "../index.css";
import "../App.css";
import { UilWhatsapp } from "@iconscout/react-unicons";
// Lottie Animations
import { Player } from "@lottiefiles/react-lottie-player";
// Services Images
import MoreService from "components/Services/MoreService.jsx";
import VideoSection from "components/Services/VideoSection";
import DevServices from "components/Development/DevServices";
import TeamServices from "components/Development/TeamServices";
import ProductHeader from "components/Production-House/ProductHeader";
import ProductServices from "components/Production-House/ProductServices";
import OurServices from "components/Production-House/OurServices";
import MoreProduction from "components/Production-House/MoreProduction";
import ProductVideo from "components/Production-House/ProductVIdeo";
import Corousel from "components/Corousel/Corousel";
import Trusted from "components/Footers/Trusted";
import StickyEnquryForm from "components/Forms/StickyEnquryForm";
import { Helmet } from "react-helmet";
import FloatingWp from "components/Development/FloatingWp";
import Preloader from "components/Development/Preloader";
import SmartHeader from "components/Smart-Class/SmartHeader";
import HeroHeader from "components/Development/HeroHeader";

export default function MediaWorks() {
  const [isLoading, setisLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setisLoading(false);
    }, 1000);
  }, []);

  return (
    <>
      {isLoading === true ? (
        <Preloader />
      ) : (
        <div className="">
          <Helmet>
            <title>
              Best Film & Video Production House in India | Corporate Video
              Production
            </title>
            <meta
              name="description"
              content="We are one of the best production house located in Delhi, India. Whiztechnology was founded in 2004. We are video production house."
            />
            <meta
              name="keywords"
              content="media production company,production house near me,corporate video production company"
            />
          </Helmet>
          <div className="fixed bg-hero-pattern bg-fixed bg-cover bg-center inset-0 -z-20"></div>
          <div className="App relative">
            <IndexNavbar fixed />

            {/* <ProductHeader /> */}
            <HeroHeader
              title="Best Ad Agency"
              description="We are a team of professional content writers dedicated to helping businesses create engaging, informative, and compelling content that connects with their target audience. Whether you need website content, blog articles, product descriptions, or any other form of written content, we've got you covered. "
              image={
                "https://res.cloudinary.com/whiztechnology/image/upload/v1691056019/oajkeqaq0gnvcc83xalk.jpg"
              }
              smartTagTitle="No.1"
              smartTagDescription="Ad Agency In India"
            />

            {/*========= Expertise Corousel =======*/}
            <section className="container mt-20 m-auto justicy-center relative bg-transperent z-3 max-w-screen-xl h-auto">
              <Corousel />{" "}
            </section>

            {/* ======== Product Services ======== */}

            <section className="container mt-20 m-auto justicy-center relative bg-transperent z-3 max-w-screen-xl h-auto">
              <ProductServices />{" "}
            </section>

            {/* 1. Coorporate Photography 2. Coorporate Ad Design. 3. Video Editing 4. Print Media*/}
            {/* ========= Card Section ========= */}
            <section className="container mt-20 m-auto justicy-center relative bg-transperent z-3 max-w-screen-xl h-auto">
              <OurServices />
            </section>

            {/* ========Team Services Section======== */}
            <MoreProduction />

            {/* =======Video Section====== */}
            <section className="lg:mt-20 lg:mb-20 md:mt-4 pb-6 relative bg-transperent overflow-hidden">
              <ProductVideo />
            </section>
            {/* =======  Social Media Sticky Icons ====== */}
            <StickyEnquryForm />
            {/* =======PopUpImg====== */}
            <PopUoForm />
            <Trusted />
            <Footer />
            <FloatingWp />
          </div>
        </div>
      )}
    </>
  );
}
