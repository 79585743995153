import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

import { EffectFade } from "swiper/modules";

import "swiper/css";
import "swiper/css/effect-fade";

// import required modules
import { Autoplay, Pagination } from "swiper/modules";

// IMG Imports
import Slide_1 from "assets/images/slider_1.png";
import Slide_2 from "assets/images/slider_2.png";
import { Link } from "react-router-dom";

export default function HeaderSwiper() {
  const swiperData = [
    {
      id: 1,
      title: "Transform Your Classroom with Smart Technology",
      description:
        "Experience the future of education with our cutting-edge smart classroom products. Enhance engagement, collaboration, and learning outcomes with interactive displays, wireless presentation systems, and advanced educational tools. Empower your students with the latest in educational technology!",
      imgSrc: Slide_1,
    },
    {
      id: 2,
      title: "Bring Learning to Life with Interactive Displays",
      description:
        "Ignite curiosity and foster active participation with our interactive displays for classrooms. Featuring touch-enabled screens and interactive whiteboards, these tools make lessons interactive, engaging, and memorable. Make learning an immersive experience!",
      imgSrc: Slide_2,
    },
    {
      id: 3,
      title: "Unlock Creativity with Digital Tools for Education",
      description:
        "Nurture creativity and critical thinking skills with our range of digital tools for education. From coding kits and 3D printers to virtual reality headsets, inspire students to explore, experiment, and create. Empower the innovators of tomorrow!",
      imgSrc: Slide_1,
    },
    {
      id: 4,
      title: "Seamless Collaboration with Wireless Presentation Systems",
      description:
        "Facilitate seamless collaboration among students and teachers with our wireless presentation systems. Share content wirelessly from any device, encourage group discussions, and enable real-time collaboration. Unlock the power of teamwork in your classroom!",
      imgSrc: Slide_1,
    },
    {
      id: 5,
      title: "Create a Modern Learning Environment",
      description:
        "Design a modern and tech-savvy classroom with our comprehensive range of smart classroom solutions. From ergonomic furniture to charging stations and audiovisual equipment, create a dynamic and conducive environment for effective teaching and learning. Revolutionize education today!",
      imgSrc: Slide_1,
    },
  ];

  let swiperSlides = "";
  swiperSlides = swiperData.map((item) => (
    <SwiperSlide className="max-w-screen-xl mx-auto h-auto" key={item.id}>
      <div className="grid pt-10 md:pt-20 pb-8 mr-auto md:mx-auto lg:gap-8 xl:gap-0 lg:py-16 lg:grid-cols-12 before:bg-gradient-to-bl before:from-indigo-300 before:via-indigo-200 before:to-indigo-300 before:w-full before:absolute before:inset-0 rounded-lg m-1 min-h-max">
        <div className="mr-auto place-self-center lg:col-span-7 relative max-w-xs md:max-w-2xl">
          <div className="flex flex-col text-justify justify-start ml-5 p-4">
            <h1 className="mb-2 font-serif text-base md:text-xl line-clamp-1 md:line-clamp-none lg:line-clamp-none lg:text-xl font-bold text-gray-800 text-start">
              {item.title}
            </h1>
            <p className="text-base leading-6 text-gray-600 font-sans line-clamp-1 md:line-clamp-none lg:line-clamp-none">
              {item.description}
            </p>
            <Link
              class="hidden sm:block text-sm leading-6 font-serif font-medium mt-6 px-6 py-2 rounded-md bg-[#44619d] w-40 text-center hover:bg-[#5381df] hover:ease-in-out hover:delay-500 text-white"
              to={"/smart-products"}
            >
              Shop Now
            </Link>
          </div>
        </div>
        <div className="hidden lg:mt-0 lg:col-span-5 relative max-w-md md:flex">
          <img
            className="w-full verticalAnimate mb-4 rounded-lg lg:mb-0 lg:flex"
            src={item.imgSrc}
            alt="Best Innovative Smart Products"
          />
        </div>
      </div>
    </SwiperSlide>
  ));

  return (
    <>
      <Swiper
        pagination={{
          dynamicBullets: true,
        }}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        modules={[Pagination, Autoplay, EffectFade]}
        className="mySwiper"
        effect="fade"
      >
        {swiperSlides}
      </Swiper>
    </>
  );
}
