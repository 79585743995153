import React from "react";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";

import Typography from "@mui/material/Typography";

export default function DevServices() {
  const services = [
    {
      id: 1,
      title: "eCommerce Development",
      desciption:
        "We specialize in creating powerful and user-friendly e-commerce websites that help businesses establish a strong online presence, drive sales, and provide an exceptional shopping experience for their customers.....",
      image:
        "https://res.cloudinary.com/whiztechnology/image/upload/v1690997115/Uploads/eCommerce_development_amtaux.png",
      to: "/e-commerce-development-company",
    },
    {
      id: 2,
      title: "CRM Devlopment",
      desciption:
        "Our experienced team of professionals in creating powerful and customized CRM solutions that enable businesses to effectively manage customer relationships and improve overall customer satisfaction...",
      image:
        "https://res.cloudinary.com/whiztechnology/image/upload/v1690997150/Uploads/crm_development_mzw2vr.png",
      to: "/crm-development-company",
    },
    {
      id: 3,
      title: "ERP Solutions Design",
      desciption:
        "We have been very effective in creating efficient and tailored for small and big industry ERP solutions that streamline your business processes, increase productivity, and enhance overall performance...",
      image:
        "https://res.cloudinary.com/whiztechnology/image/upload/v1690997177/Uploads/erp_development_gwwygw.png",
      to: "/erp-development-company",
    },
    {
      id: 4,
      title: "Travel Portal Development",
      desciption:
        "We have the expertise in creating stunning and user-friendly websites tailored specifically for the Tour & Travel industry. We are capable to build a website that will enhance your online presence and attract more customers...",
      image:
        "https://res.cloudinary.com/whiztechnology/image/upload/v1690997204/Uploads/travel-portal_bxrpbn.png",
      to: "/travel-portal-development-company",
    },
  ];

  return (
    <>
      <section className="max-w-screen-xl mx-auto py-4 px-2">
        <div className="block mt-10 z-3 justify-center item-center m-auto">
          <h4 className="mt-4 text-xs md:text-base font-semibold mb-8 leading-normal text-slate-500 justify-center text-center">
            Centralize, organize and share customer insights on the platform
            powering the world's best reasearch teams
          </h4>
        </div>
        <hr className="bg-[#44619d] h-[5px] w-[20%] mx-auto rounded-[20px] mb-[50px]" />
        <div className="grid grid-cols-1 gap-y-12 sm:grid-cols-2 sm:gap-x-6 lg:grid-cols-4 lg:gap-x-8 justify-center">
          {services.map((item) => (
            <div
              className="flex items-center justify-center text-justify min-h-full"
              key={item.id}
            >
              {" "}
              <div className="max-w-sm rounded-lg bg-[#d8eaff] hover:bg-white ease-out duration-1000 shadow z-3 mx-2 hover:drop-shadow-lg min-h-full">
                <div className=" h-40 flex items-center justify-center">
                  <img
                    src={item.image}
                    alt="Development Images"
                    className="w-52 mx-auto h-auto"
                  />
                </div>
                <div className="p-5 ">
                  <Link href={item.to}>
                    <h5 className="mb-2 text-md font-semibold font-poppins tracking-tight text-gray-900">
                      {item.title}
                    </h5>
                  </Link>
                  <Typography
                    varient="body2"
                    fontSize={13}
                    gutterBottom
                    className="mb-3 max-h-56 overflow-y-scroll no-scrollbar text-black text-justify"
                  >
                    {item.desciption}
                  </Typography>

                  <Link to={item.to}>
                    {" "}
                    <Button variant="outlined">Read More</Button>
                  </Link>
                </div>
              </div>
            </div>
          ))}
        </div>
      </section>
    </>
  );
}
