import React from "react";
// React Slick Caraousel and Slider
import Slider from "react-slick";
import styled from "styled-components";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { SmartProducts } from "components/utils/Products_api";

export default function SmartCaraousel1() {
  var settings = {
    className: "center",
    autoplay: true,
    autoplaySpeed: 2000,
    centerMode: true,
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 5,
    slidesToScroll: 1,
    initialSlide: 0,
    arrows: false,
    responsive: [
      {
        breakpoint: 990,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
          centerMode: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
          centerMode: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: true,
        },
      },
    ],
  };

  const StockUpdate = () => {
    return Math.floor(Math.random() * (100 - 1) + 1);
  };

  let SwiperSlides = "";

  SwiperSlides = SmartProducts.map((product) => (
    <div className="flex justify-center items-center">
      <div className="group p-4 max-w-[220px] max-h-max shadow-md rounded-md backdrop-blur-md bg-white/30 h-[300px]">
        <div class="w-full overflow-hidden rounded-lg h-3/5">
          <img
            src={product.imgSrc}
            alt="Tall slender porcelain bottle with natural clay textured body and cork stopper."
            class="h-full w-full object-cover object-center group-hover:opacity-75"
          />
        </div>
        <h3 class="mt-4 text-xs md:text-base font-semibold text-gray-700">
          {product.title}
        </h3>
        <p class="mt-1 text-xs md:text-sm font-normal text-gray-900">
          Stock: {StockUpdate()}
        </p>
      </div>{" "}
    </div>
  ));

  const arrowRef = React.useRef(null);

  return (
    <>
      <div className="max-w-screen-xl mx-auto">
        <div className="block z-3 justify-center item-center m-auto max-w-2xl">
          <p className="mt-4 font-lg font-semibold mb-8 leading-relaxed text-slate-500 justify-center text-center capitalize">
            <p className="font-poppins text-xl text-center text-">
              Featured Products
            </p>
            <p className="font-poppins font-md text-center">
              Smart Class Products Best For Online/Offline Class
            </p>
          </p>
        </div>
        <hr className="bg-[#44619d] h-[5px] w-[20%] mx-auto rounded-[20px] mb-[20px]" />{" "}
        <Container className=" bg-transparent relative max-w-screen-xl overflow-hidden rounded-md py-4 px-2">
          {" "}
          <Slider
            ref={arrowRef}
            {...settings}
            className="mx-auto max-w-screen-xl mt-5"
          >
            {SwiperSlides}
          </Slider>{" "}
        </Container>
      </div>
    </>
  );
}

const Container = styled.div`
  position: relative;
  overflow: hidden;
`;
