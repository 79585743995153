import React from "react";
import { Link } from "react-router-dom";
import Logo from "assets/img/logo.png";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Unstable_Grid2";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
import { UilLinkedin, UilInstagram } from "@iconscout/react-unicons";
import CallOutlinedIcon from "@mui/icons-material/CallOutlined";
import { UilTwitterAlt } from "@iconscout/react-unicons";
import MobileNav from "components/Smart-Class/MobileNav.jsx";

export default function Footer() {
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  }));

  const socialLinks = [
    {
      id: 1,
      name: "Instagram",
      icon: <UilInstagram className=" text-[#1976d2]" />,
      to: "https://www.instagram.com/whiztechnologydelhi/",
    },
    {
      id: 2,
      name: "Facebook",
      icon: <FacebookOutlinedIcon color="primary" />,
      to: "https://www.facebook.com/whiztechnologyindia/",
    },
    {
      id: 3,
      name: "LinkedIn",
      icon: <UilLinkedin className=" text-[#1976d2]" />,
      to: "https://in.linkedin.com/company/whiz-technology",
    },
    {
      id: 4,
      name: "mail",
      icon: <UilTwitterAlt className=" text-[#1976d2]" />,
      to: "https://twitter.com/whiztechnology?lang=en",
    },
  ];

  const getYear = () => new Date().getFullYear();

  (function () {
    // "use strict";
    /*
     * Form Validation
     */

    // Fetch all the forms we want to apply custom validation styles to
    const forms = document.querySelectorAll(".needs-validation");
    const result = document.getElementById("result");
    // Loop over them and prevent submission
    Array.prototype.slice.call(forms).forEach(function (form) {
      form.addEventListener(
        "submit",
        function (event) {
          if (!form.checkValidity()) {
            event.preventDefault();
            event.stopPropagation();

            form.querySelectorAll(":invalid")[0].focus();
          } else {
            /*
             * Form Submission using fetch()
             */

            const formData = new FormData(form);
            event.preventDefault();
            event.stopPropagation();
            const object = {};
            formData.forEach((value, key) => {
              object[key] = value;
            });
            const json = JSON.stringify(object);
            result.innerHTML = "Please wait...";

            fetch("https://api.web3forms.com/submit", {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
              },
              body: json,
            })
              .then(async (response) => {
                let json = await response.json();
                if (response.status === 200) {
                  result.innerHTML = json.message;
                  result.classList.remove("text-gray-500");
                  result.classList.add("text-green-500");
                } else {
                  console.log(response);
                  result.innerHTML = json.message;
                  result.classList.remove("text-gray-500");
                  result.classList.add("text-red-500");
                }
              })
              .catch((error) => {
                console.log(error);
                result.innerHTML = "Something went wrong!";
              })
              .then(function () {
                form.reset();
                form.classList.remove("was-validated");
                setTimeout(() => {
                  result.style.display = "none";
                }, 5000);
              });
          }
          form.classList.add("was-validated");
        },
        false
      );
    });
  })();

  const footerNav = [
    {
      id: 1,
      title: "Services",
      to: "/services",
      data: [
        { id: 1, name: "Web Development", to: "/website-development-company" },
        {
          id: 2,
          name: "App Development",
          to: "/mobile-app-development-company",
        },
        { id: 3, name: "Digital Marketing", to: "/digital-marketing-company" },
        { id: 4, name: "Web Designing", to: "/website-designing-company" },
        {
          id: 5,
          name: "Stack Development",
          to: "/full-stack-development-company",
        },
        {
          id: 6,
          name: "Technology Cerification",
          to: "/technology-certification",
        },
      ],
    },
    {
      id: 2,
      title: "Smart Products",
      to: "/smart-products",
      data: [
        { id: 1, name: "Interactive Flat Panel", to: "/smartclass" },
        { id: 2, name: "Green VFX Recording Studio", to: "/smartclass" },
        { id: 3, name: "Online Teaching Application", to: "/smartclass" },
        { id: 4, name: "PTZ Camera Setup", to: "/smartclass" },
        { id: 5, name: "Conferencing Room Setup", to: "/smartclass" },
        { id: 6, name: "Social Media Support", to: "/smartclass" },
      ],
    },
    {
      id: 3,
      title: "Media Works",
      to: "/media-works",
      data: [
        { id: 1, name: "Industrial Shoot", to: "/production" },
        { id: 2, name: "Corporate Video", to: "/production" },
        { id: 3, name: "Product Demonstration", to: "/production" },
        { id: 4, name: "Video Production", to: "/production" },
        { id: 5, name: "TV Commercials", to: "/production" },
        { id: 6, name: "Short Film Makers", to: "/production" },
      ],
    },
    {
      id: 4,
      title: "Company",
      to: "/",
      data: [
        { id: 1, name: "About Us", to: "/about-us" },
        { id: 2, name: "Contact Us", to: "/contact-us" },
        { id: 3, name: "Case Study", to: "/case-study" },
        { id: 4, name: "Career", to: "/career" },
        { id: 5, name: "Market Place", to: "/market-place" },
      ],
    },
  ];

  return (
    <>
      <footer className=" dark:bg-gray-800 relative css-1wuiiom py-10 w-full bg-indigo-400">
        <div class="max-w-screen-xl px-4 py-8 mx-auto lg:py-16 lg:px-6 border-b-2 border-dotted">
          <div class="max-w-screen-sm mx-auto text-center ">
            <h2 class="mb-1 md:mb-2 !text-2xl !md:text-3xl font-semibold leading-tight text-red-500 dark:text-white">
              Make quick decisions, gain alignment <br />{" "}
            </h2>
            <p className=" text-indigo-600 !text-sm md:text-xl font-semibold leading-tight mb-4">
              and transform how your team works together.
            </p>
            <Link
              to="/contact-us"
              class="text-white bg-[#44619d] focus:ring-4 focus:ring-purple-300 font-medium rounded-lg text-sm px-3 py-1.5 md:px-5 md:py-2.5 mr-2 mb-2 focus:outline-none "
            >
              Get in Touch !
            </Link>
          </div>
        </div>
        <div className="max-w-screen-xl p-4 py-6 mx-auto lg:py-16 md:p-8 lg:p-10">
          <Box>
            <Grid container spacing={4}>
              <Grid xs={12} md={4}>
                <Item
                  sx={{
                    border: "none",
                    backgroundColor: "transparent",
                    boxShadow: "none",
                    "& .MuiListItem-root": {},
                    margin: "auto",
                  }}
                >
                  <div className="flex flex-col text-left max-w-md">
                    <div className="block mx-auto md:mx-0 w-fit mb-2 h-10 rounded-md bg-[#44619d]">
                      {" "}
                      <img
                        src={Logo}
                        className="h-10"
                        alt="WhizTechnology Logo"
                      />
                    </div>
                    <p className="max-w-xl tracking-normal font-semibold text-gray-500 text-lg dark:text-gray-400">
                      Full-Stack Development Company
                    </p>
                    <p className="max-w-xl mb-2 text-justify justify-start font-normal text-gray-500 lg:mb-4 text-sm">
                      Whiz Technology is a mission to define technology as per
                      your finger tips. We blend your ideas and opinions with
                      Technology.
                    </p>
                  </div>

                  <Link
                    to={"tel:+91-7503055896"}
                    className="flex flex-row space-x-1 sm:space-x-3 justify-start items-center mb-2"
                  >
                    <CallOutlinedIcon color="primary" />
                    <span className=" text-black text-[10px] sm:text-sm  font-medium">
                      Bussiness Support &nbsp;:
                    </span>
                    <span className=" text-black text-xs sm:text-sm font-normal">
                      +91-7503055896
                    </span>
                  </Link>
                  <Link
                    to={"@mailto:info.whiztechnology@gmail.com"}
                    className="flex flex-row space-x-1 sm:space-x-3 justify-start items-center mb-2"
                  >
                    <EmailOutlinedIcon color="primary" />
                    <span className=" text-black text-[10px] sm:text-sm font-medium">
                      Email Support &nbsp;:
                    </span>
                    <span className=" text-black text-xs sm:text-sm font-normal">
                      info.whiztechnology@gmail.com
                    </span>
                  </Link>
                  <ul className="flex justify-start mt-1 space-x-5">
                    {socialLinks.map((item) => (
                      <li>
                        <Link
                          to={item.to}
                          target="_blank"
                          className="text-indigo-500 hover:text-gray-900 dark:hover:text-white dark:text-gray-400"
                        >
                          {item.icon}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </Item>
              </Grid>
              <Grid xs={12} md={8}>
                <Item
                  sx={{
                    border: "none",
                    backgroundColor: "transparent",
                    boxShadow: "none",
                    "& .MuiListItem-root": {},
                    margin: "auto",
                  }}
                >
                  {" "}
                  <div className="grid grid-cols-2 text-left gap-8 md:grid-cols-3 lg:grid-cols-4">
                    {footerNav.map((item) => (
                      <div key={item}>
                        <h3 className="mb-6 text-base font-semibold text-black font-Arial dark:text-white">
                          <Link to={item.to}>{item.title}</Link>
                        </h3>
                        {item.data.map((item) => (
                          <ul
                            className="text-gray-500 dark:text-gray-400"
                            key={item.id}
                          >
                            <li className="mb-4">
                              <Link to={item.to} className=" hover:underline">
                                {item.name}
                              </Link>
                            </li>
                          </ul>
                        ))}
                      </div>
                    ))}
                  </div>
                </Item>
              </Grid>
            </Grid>
          </Box>

          <hr className="my-6 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-8" />
          <div className="text-center">
            <span className="block text-sm text-center text-gray-500 dark:text-gray-400">
              © {getYear()} Whiz Technology™. &nbsp; All Rights Reserved.
            </span>
          </div>
        </div>
      </footer>
      <MobileNav />
    </>
  );
}
